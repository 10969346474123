import {
  DKIcon,
  DKLabel,
  DKIcons,
  DKTooltipWrapper,
  removeLoader,
  showLoader,
  DKCalendar,
  showAlert
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { selectUOMs } from '../../Redux/Slices/CommonDataSlice';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import {
  FulfillmentPayload,
  FulfillmentPopupProps
} from '../../Models/Fulfillment';
import ic_delivery from '../../Assets/Icons/ic_delivery.png';
import ic_calender from '../../Assets/Icons/ic_calendar.png';
import {
  fetchProductInventoryByID,
  selectWarehouseProductByID
} from '../../Redux/Slices/WarehouseProductSlice';
import FulfillmentService from '../../Services/FulfillmentService';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { Address } from '../../Models/Address';
import DateFormatService from '../../Services/DateFormat';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../Assets/Icons/ic_barcode_red.svg';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import { fetchBatchTrackingProducts } from '../../Redux/Slices/BatchTrackingSlice';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import ic_bom_allocate_red from '../../Assets/Icons/ic_bom_allocate_red.svg';
import BuildAssembly from '../../Components/Product/BuildAssembly/BuildAssembly';
import ProductService from '../../Services/Product';
import { fetchProducts } from '../../Redux/Slices/ProductsSlice';
import BatchTrackingAssignment from '../AdvancedTrackingPopup/BatchTrackingAssignment';
import SerialTrackedAssignment from '../AdvancedTrackingPopup/SerialTrackedAssignment';
import NoneTrackedAssignment from '../WarehouseManagement/NoneTrackedAssignment';
import { localizedText } from '../../Services/Localization/Localization';
import ExpandedFulfillmentTable from './ExpandedFulfillmentTable';
import { isSalesOrderVisible } from '../DocumentForm/NewDocumentHelper';
import useQCConfirm from '../../Hooks/useQCConfirm';
import { QC_FLOW_MODULES } from '../../Components/Settings/QcFlow/QCFlowConstant';
import CommonQualityCheckService from '../../Services/QualityCheck';
import { allowedDocumentsForFulfillmentQC } from '../../Components/QualityCheck/QualityCheckConstant';
import {
  calculateBaseQtyDiff,
  calculateDocumentUomSchema,
  checkIsLocalizedUomQty,
  getLineItemsKeyForDocument,
  handleReverseSchemaForNoneTracked,
  handleReverseSchemaForBatchTracked,
  LOCALIZED_UOM_QTY,
  setInitialDataForLocalizedUOM,
  isBillNeedToChange,
  handleReverseSchemaForSerialTracked
} from './FulfilmentGridHelper';

const BulkFulillment: React.FC<FulfillmentPopupProps> = (props) => {
  const currentDate = new Date();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [fulfillmentDate, setFulfillmentDate] = useState<Date>(currentDate);
  const [fulfillmentDateOpen, setFulfillmentDateOpen] = useState(false);
  const lineItemKeyToUpdate = getLineItemsKeyForDocument(props.documentType);
  const [documentDetails, setDocumentDetails] = useState(
    getInitialDocumentDetails()
  );
  const docType = props.documentType;
  const [warehouseCode, setWarehousecode] = useState('');
  const warehouseProduct = useAppSelector(selectWarehouseProductByID);
  const [fulfillmentList, setFulfillmentList] = useState<any>([]);
  const [warehouseItem, setWarehouseItem] = useState<any>({});
  const [serialTrackingData, setSerialTrackingData] = useState<any>({});
  const [batchTrackingData, setBatchTrackingData] = useState<any>({});
  const lineItemsTable = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const expandAll = true;
  // const [showItemMore, setShowItemMore] = useState(-1);
  // const [isVisibleState, setIsVisibleState] = useState(false);
  const [showBuildAssemblyPopup, setShowBuildAssemblyPopup] = useState(false);
  const [assemblyProduct, setAssemblyProduct] = useState<any>({});
  const [itemKey, setItemKey] = useState<any>('');
  const defaultWarehouse = warehouseProduct.find(
    (warehouse: any) => warehouse.primary
  );
  const shipToAddress = new Address(documentDetails[0].shipTo);
  const uoms = useAppSelector(selectUOMs);
  const [quantityToFulfill, setQuantityToFulfill] = useState<any[]>([]);
  const [showMultipleWarehouse, setShowMultipleWarehouse] = useState(false);
  // const [multipleWarehouseData, setMultipleWarehouseData] = useState<any[]>([]);
  const [quantityFulfilled, setQuantityFulfilled] = useState<any>();
  const [itemIndex, setItemIndex] = useState<any>({});
  const [documentToFetch, setDocumentToFetch] = useState<any>({});
  // const [pendingQuantity, setPendingQuantity] = useState(0);

  const [showSerialTracking, setShowSerialTracking] = useState(false);
  const [showBatchTracking, setShowBatchTracking] = useState(false);

  const [displayError, setDisplayError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');
  const dispatch = useAppDispatch();

  const [currentSelectedCol, setCurrentSelectedCol] = useState<any>(null); // use it to disable committing qty
  const [isReceivingUpdatedManually, setIsReceivingUpdatedManually] =
    useState(false);

  const { qcConfirm } = useQCConfirm();

  type returnFunction = (index: number) => any;
  useEffect(() => {
    const ids: any = [];
    JSON.stringify(documentDetails, (key, value) => {
      if (key === 'productCode') ids.push(value);
      return value;
    });
    loadProductInventoryById(ids);
  }, []);

  useEffect(() => {
    setDocumentDetails(props.documentDetails);
  }, [props.documentDetails]);

  useEffect(() => {
    let currentFulfillmentList = deepClone(fulfillmentList);
    let item =
      docType === DOC_TYPE.INVOICE
        ? 'salesInvoiceItems'
        : 'quotationItemDtoList';
    let currentDocDetails = deepClone(documentDetails);
    if (docType === DOC_TYPE.INVOICE) {
      let doc = props.documentDetails.filter(
        (document: any) =>
          document.salesInvoiceCode === documentToFetch.salesInvoiceCode
      );
      if (doc && doc.length > 0) {
        currentDocDetails.forEach((currentDoc: any, index: any) => {
          if (currentDoc.salesInvoiceCode === doc[0].salesInvoiceCode) {
            currentDocDetails[index] = doc[0];
          }
        });
      }
      if (currentFulfillmentList && currentFulfillmentList.length > 0) {
        currentFulfillmentList.forEach(
          (currentFulfillment: any, index: any) => {
            if (
              currentFulfillment.salesInvoiceCode === doc?.[0]?.salesInvoiceCode
            ) {
              if (currentDocDetails && currentDocDetails.length > 0) {
                let fulfillmentItems: any[];
                // let fulfillmentItem: any;
                let documentData = [...currentDocDetails];
                currentDocDetails.forEach((items: any, i: any) => {
                  fulfillmentItems = [];
                  items[item].forEach((obj: any, x: any) => {
                    let pendingQuantity = Utility.getPendingQuantity(obj);

                    if (obj.type === PRODUCT_TYPE.NON_TRACKED) {
                      obj.availableProductQuantity = 0;
                    } else {
                      obj.availableProductQuantity =
                        obj.product.availableQuantity || 0;
                    }

                    obj.fulfilledQuantity =
                      pendingQuantity > obj.availableProductQuantity &&
                      obj.type !== PRODUCT_TYPE.NON_TRACKED
                        ? obj.availableProductQuantity
                        : pendingQuantity;
                    obj.pendingQuantity = pendingQuantity;

                    if (obj.documentUOMSchemaDefinition) {
                      obj.uomQuantity =
                        obj.uomQuantity ||
                        Utility.getUomQuantity(
                          obj.productQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomUnitPrice =
                        obj.uomUnitPrice ||
                        Utility.getUomPrice(
                          obj.unitPrice,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomAvailableQuantity =
                        obj.uomAvailableQuantity ||
                        Utility.getUomQuantity(
                          obj.availableQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomFulfilledQuantity =
                        obj.uomFulfilledQuantity ||
                        Utility.getUomQuantity(
                          obj.fulfilledQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomQuantityFulfilled =
                        obj.uomQuantityFulfilled ||
                        Utility.getUomQuantity(
                          obj.quantityFulfilled,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomPendingQuantity =
                        obj.uomPendingQuantity ||
                        Utility.getUomQuantity(
                          obj.pendingQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                    }

                    if (items.isPartialInvoice || items.hasPartialInvoice) {
                      obj.quantityToBeFulfilled =
                        obj.productQuantity - obj.quantityFulfilled;
                      obj.uomQuantityToBeFulfilled =
                        obj.uomPendingQuantity - obj.uomQuantityFulfilled;
                    }
                    obj = { ...obj, expanded: false };
                    fulfillmentItems.push(obj);
                  });
                  documentData[i][item] = fulfillmentItems;
                });

                setFulfillmentList(documentData);
              }
            }
          }
        );
      }
    } else if (docType === DOC_TYPE.QUOTE) {
      let doc = props.documentDetails.filter(
        (document: any) =>
          document.quotationCode === documentToFetch.quotationCode
      );
      if (doc && doc.length > 0) {
        currentDocDetails.forEach((currentDoc: any, index: any) => {
          if (currentDoc.quotationCode === doc[0].quotationCode) {
            currentDocDetails[index] = doc[0];
          }
        });
      }
      if (currentFulfillmentList && currentFulfillmentList.length > 0) {
        currentFulfillmentList.forEach(
          (currentFulfillment: any, index: any) => {
            if (currentFulfillment.quotationCode === doc[0].quotationCode) {
              if (currentDocDetails && currentDocDetails.length > 0) {
                let fulfillmentItems: any[];
                // let fulfillmentItem: any;
                let documentData = [...currentDocDetails];
                currentDocDetails.forEach((items: any, i: any) => {
                  fulfillmentItems = [];
                  items[item].forEach((obj: any, x: any) => {
                    let pendingQuantity = Utility.getPendingQuantity(obj);

                    if (obj.type === PRODUCT_TYPE.NON_TRACKED) {
                      obj.availableProductQuantity = 0;
                    } else {
                      obj.availableProductQuantity =
                        obj.product.availableQuantity || 0;
                    }

                    obj.fulfilledQuantity =
                      pendingQuantity > obj.availableProductQuantity &&
                      obj.type !== PRODUCT_TYPE.NON_TRACKED
                        ? obj.availableProductQuantity
                        : pendingQuantity;
                    obj.pendingQuantity = pendingQuantity;

                    if (obj.documentUOMSchemaDefinition) {
                      obj.uomQuantity =
                        obj.uomQuantity ||
                        Utility.getUomQuantity(
                          obj.productQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomUnitPrice =
                        obj.uomUnitPrice ||
                        Utility.getUomPrice(
                          obj.unitPrice,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomAvailableQuantity =
                        obj.uomAvailableQuantity ||
                        Utility.getUomQuantity(
                          obj.availableQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomFulfilledQuantity =
                        obj.uomFulfilledQuantity ||
                        Utility.getUomQuantity(
                          obj.fulfilledQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomQuantityFulfilled =
                        obj.uomQuantityFulfilled ||
                        Utility.getUomQuantity(
                          obj.quantityFulfilled,
                          obj.documentUOMSchemaDefinition
                        );
                      obj.uomPendingQuantity =
                        obj.uomPendingQuantity ||
                        Utility.getUomQuantity(
                          obj.pendingQuantity,
                          obj.documentUOMSchemaDefinition
                        );
                    }

                    if (items.isPartialInvoice || items.hasPartialInvoice) {
                      obj.quantityToBeFulfilled =
                        obj.productQuantity - obj.fulfilledQuantity;
                      obj.uomQuantityToBeFulfilled =
                        obj.uomQuantity - (obj.uomFulfilledQuantity || 0);
                    }
                    obj = { ...obj, expanded: false };
                    fulfillmentItems.push(obj);
                  });
                  documentData[i][item] = fulfillmentItems;
                });

                setFulfillmentList(documentData);
              }
            }
          }
        );
      }
    }

    setDocumentDetails(currentDocDetails);
  }, [props.documentDetails]);

  useEffect(() => {
    let item =
      docType === DOC_TYPE.INVOICE
        ? 'salesInvoiceItems'
        : 'quotationItemDtoList';
    if (documentDetails && documentDetails.length > 0) {
      let fulfillmentItems: any[];
      // let fulfillmentItem: any;
      let documentData = [...documentDetails];
      documentDetails.forEach((items: any, i: any) => {
        fulfillmentItems = [];
        items[item].forEach((obj: any, x: any) => {
          let pendingQuantity = Utility.getPendingQuantity(obj);

          if (obj.type === PRODUCT_TYPE.NON_TRACKED) {
            obj.availableProductQuantity = 0;
          } else {
            obj.availableProductQuantity = obj.product.availableQuantity || 0;
          }

          obj.fulfilledQuantity =
            pendingQuantity > obj.availableProductQuantity &&
            obj.type !== PRODUCT_TYPE.NON_TRACKED
              ? obj.availableProductQuantity
              : pendingQuantity;
          obj.pendingQuantity = pendingQuantity;

          if (obj.documentUOMSchemaDefinition) {
            obj.uomQuantity =
              obj.uomQuantity ||
              Utility.getUomQuantity(
                obj.productQuantity,
                obj.documentUOMSchemaDefinition
              );
            obj.uomUnitPrice =
              obj.uomUnitPrice ||
              Utility.getUomPrice(
                obj.unitPrice,
                obj.documentUOMSchemaDefinition
              );
            obj.uomAvailableQuantity =
              obj.uomAvailableQuantity ||
              Utility.getUomQuantity(
                obj.availableQuantity,
                obj.documentUOMSchemaDefinition
              );
            obj.uomFulfilledQuantity =
              obj.uomFulfilledQuantity ||
              Utility.getUomQuantity(
                obj.fulfilledQuantity,
                obj.documentUOMSchemaDefinition
              );
            obj.uomQuantityFulfilled =
              obj.uomQuantityFulfilled ||
              Utility.getUomQuantity(
                obj.quantityFulfilled,
                obj.documentUOMSchemaDefinition
              );
            obj.uomPendingQuantity =
              obj.uomPendingQuantity ||
              Utility.getUomQuantity(
                obj.pendingQuantity,
                obj.documentUOMSchemaDefinition
              );
          }

          if (items.isPartialInvoice || items.hasPartialInvoice) {
            switch (docType) {
              case DOC_TYPE.QUOTE:
                obj.quantityToBeFulfilled =
                  obj.productQuantity - obj.fulfilledQuantity;
                obj.uomQuantityToBeFulfilled =
                  obj.uomQuantity - (obj.uomFulfilledQuantity || 0);
                break;
              case DOC_TYPE.INVOICE:
                obj.quantityToBeFulfilled =
                  obj.productQuantity - obj.quantityFulfilled;
                obj.uomQuantityToBeFulfilled =
                  obj.uomPendingQuantity - obj.uomQuantityFulfilled;
                break;
            }
          }
          obj = { ...obj, expanded: false };
          fulfillmentItems.push(obj);
        });
        documentData[i][item] = fulfillmentItems;
      });
      setFulfillmentList(documentData);
    }
  }, [warehouseProduct]);

  useEffect(() => {
    if (currentSelectedCol === LOCALIZED_UOM_QTY) {
      return;
    }
    let item =
      docType === DOC_TYPE.INVOICE
        ? 'salesInvoiceItems'
        : 'quotationItemDtoList';
    setItemKey(item);
    let defaultWarehouseData =
      warehouseProduct.find((warehouse: any) => warehouse.primary) ||
      warehouseProduct[0];
    updateInventory(defaultWarehouseData?.code);
    if (
      documentDetails &&
      documentDetails.length > 0 &&
      !isReceivingUpdatedManually
    ) {
      let parentItem: any[] = [];
      documentDetails.forEach((items: any, i: any) => {
        let itemQuantity: any = [];
        items[item].forEach((obj: any, x: any) => {
          let pendingQuantity =
            obj.pendingQuantity || obj.uomPendingQuantity || 0;
          const availableQuantity = Utility.getAvailableQuantity(obj);

          pendingQuantity = obj?.documentUOMSchemaDefinition
            ? obj.uomPendingQuantity
            : obj.pendingQuantity;

          let qtyToFulfill: any =
            pendingQuantity > availableQuantity &&
            obj.type !== PRODUCT_TYPE.NON_TRACKED
              ? availableQuantity
              : pendingQuantity;
          if (
            documentToFetch &&
            documentToFetch.length > 0 &&
            quantityToFulfill &&
            quantityToFulfill.length > 0
          ) {
            qtyToFulfill = qtyToFulfill[i].obj[x];
          }
          itemQuantity = {
            ...itemQuantity,
            [x]: qtyToFulfill
          };
        });
        parentItem.push(itemQuantity);
      });
      setQuantityToFulfill(parentItem);
    }
    // if (tenantInfo.country === COUNTRY_CODES.IN) {
    //   setIsVisibleState(true);
    // }
  }, [fulfillmentList, warehouseProduct]);

  function getInitialDocumentDetails() {
    const updatedDocumentDetails = props?.documentDetails?.map(
      (documentItem: any) => {
        let items: any[] = [];
        if (props?.documentType === DOC_TYPE.INVOICE) {
          items = documentItem?.salesInvoiceItems;
        } else if (props?.documentType === DOC_TYPE.QUOTE) {
          items = documentItem?.quotationItemDtoList;
        } else if (props?.documentType === DOC_TYPE.SALES_ORDER) {
          items = documentItem?.salesOrderItems;
        }

        if (items?.length && Utility.isNotEmpty(lineItemKeyToUpdate)) {
          // for localized uom
          items = items?.map((item: any) => {
            const docSchema =
              item?.unlocalizedDocumentUOMSchemaDefinition ??
              item?.documentUOMSchemaDefinition ??
              null;
            item = {
              ...item,
              pendingQuantity: Utility.getPendingQuantity(item)
            };
            if (
              (item?.isLocalizedUomQty ||
                tenantInfo?.additionalSettings?.LOCALIZED_UOM_CONVERSION) &&
              Utility.isNotEmpty(docSchema)
            ) {
              return setInitialDataForLocalizedUOM(item, DOC_TYPE.FULFILLMENT);
            } else {
              return item;
            }
          });
          const updatedDocumentItem = {
            ...documentItem,
            [lineItemKeyToUpdate]: items
          };
          return updatedDocumentItem;
        }
      }
    );

    return updatedDocumentDetails;
  }

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BULK_FULFILLMENT,
        data: () => {
          saveFulfillment();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const fulfillmentPayload = (): FulfillmentPayload => {
    let fulfillmentPayload: any = [];
    documentDetails.forEach((element: any, docIndex: any) => {
      const fulfillmentItemsDataKey =
        props?.documentType === DOC_TYPE.INVOICE
          ? 'salesInvoiceItems'
          : 'quotationItemDtoList';

      let linkedSO = element.linkedDocuments?.find((doc: any) =>
        doc.documentType == isSalesOrderVisible
          ? DOC_TYPE.SALES_ORDER
          : DOC_TYPE.QUOTE
      );

      let tempDocument = new FulfillmentPayload({
        ...element,
        fulfillmentDate: DateFormatService.getDateStrFromDate(
          fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        fulfillmentType: 'DEFAULT',
        warehouseCode: warehouseCode,
        documentCode: element.salesInvoiceCode || element.quotationCode,
        documentType: docType,
        isPartialInvoice: false,
        linkedPIDocument: linkedSO || null,
        fulfillmentItems: element?.[fulfillmentItemsDataKey]?.map(
          (item: any, lineIndex: any) => {
            let inventory;
            if (item.documentUOMSchemaDefinition) {
              inventory = {
                ...item,
                uomFulfilledQuantity: quantityToFulfill[docIndex][lineIndex]
              };
            } else {
              inventory = {
                ...item,
                fulfilledQuantity: quantityToFulfill[docIndex][lineIndex]
              };
            }
            return {
              ...inventory,
              advancedTracking: inventory.product.advancedTracking
            };
          }
        )
      });
      // Filter line items with zero quantityToFulfill
      tempDocument = {
        ...tempDocument,
        fulfillmentItems: tempDocument?.fulfillmentItems?.filter(
          (item: any, lineIndex: any) =>
            item.pendingQuantity > 0 &&
            quantityToFulfill?.[docIndex]?.[lineIndex] > 0
        )
      };
      if (tempDocument?.fulfillmentItems?.length > 0) {
        fulfillmentPayload.push(tempDocument);
      }
    });

    const payload = fulfillmentPayload;
    return payload;
  };

  const saveFulfillment = async () => {
    let Payload: any = fulfillmentPayload();
    if (!Utility.isEmpty(Payload)) {
      let data: any = deepClone(Payload);
      const isValid = data.every((v: any) =>
        Utility.checkActiveDateRangeValidation(
          DateFormatService.getDateFromStr(
            v.fulfillmentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          tenantInfo,
          'Fulfillment date',
          'FULFILLMENT'
        )
      );
      if (!isValid) {
        return;
      }
      const isDateValid = data.every((v: any) =>
        Utility.checkClosingDate(
          DateFormatService.getDateFromStr(
            v.fulfillmentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          'Fulfillment date'
        )
      );
      if (!isDateValid) {
        return;
      }
    }
    let dataToValidate: any = deepClone(Payload);
    let hasAdvancedTracking: any[] = [];
    let hasRRBValidation: any = [];
    dataToValidate.forEach((element: any) => {
      element.fulfillmentItems.forEach((item: any) => {
        if (
          item.advancedTracking === TRACKING_TYPE.BATCH ||
          item.advancedTracking === TRACKING_TYPE.SERIAL
        ) {
          const tracking =
            item.advancedTrackingFulfilmentData &&
            item.advancedTrackingFulfilmentData.length > 0
              ? false
              : true;
          hasAdvancedTracking.push(tracking);
        } else {
          element.fulfillmentItems?.forEach((item: any) => {
            if (item.productType !== PRODUCT_TYPE.NON_TRACKED) {
              item?.warehouseInventoryData?.forEach((inventory: any) => {
                const warehouse = warehouseProduct?.find(
                  (w: any) => w.code === inventory.warehouseCode
                );
                if (
                  !Utility.isEmpty(
                    warehouse?.rowRackBinProductAvailableQuantityDtos
                  ) &&
                  Utility.isEmpty(inventory?.rowCode) &&
                  Utility.isEmpty(inventory?.rackCode) &&
                  Utility.isEmpty(inventory?.binCode)
                ) {
                  hasRRBValidation.push(true);
                  return;
                } else {
                  hasRRBValidation.push(false);
                }
              });
            }
          });
        }
      });
    });
    if (hasAdvancedTracking.includes(true)) {
      setDisplayError(true);
      setErrorMessageText(
        `Serial/Batch Number must be assigned to ${localizedText(
          'do the fulfillment'
        )}.`
      );
      return;
    }
    if (hasRRBValidation.includes(true)) {
      showAlert(
        'Warning!',
        'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
      );
      return;
    }

    let isQCCheckProductAvailable = await checkIfQCRequired();

    if (
      Utility.checkQCEnabledForModule(QC_FLOW_MODULES.FULFILLMENT) &&
      isQCCheckProductAvailable &&
      allowedDocumentsForFulfillmentQC.includes(docType)
    ) {
      const isConfirmed = await qcConfirm(
        `Would you like to perform a quality check on this fulfillment?`,
        `QC Flow`
      );

      if (isConfirmed === null) {
        removeLoader();
        return;
      }

      if (isConfirmed) {
        Payload = Payload?.map((payloadItem: any) => {
          return {
            ...payloadItem,
            isQcEnabled: true
          };
        });
      }
    }

    showLoader();
    FulfillmentService.saveBulkFulfillment(Payload)
      .then((res: any) => {
        setDisplayError(false);
        setErrorMessageText('');
        removeLoader();
        closePopup(docType);
      })
      .catch((error: any) => {
        setDisplayError(true);
        const errorMessage =
          error && error.data && error.data.errorMessage
            ? error.data.errorMessage
            : 'Unable to fulfill selected records';
        setErrorMessageText(errorMessage);
        removeLoader();
      });
  };

  const checkIfQCRequired = async () => {
    let productCodes: string[] = [];
    documentDetails?.forEach((item: any) =>
      item?.salesInvoiceItems?.forEach((item2: any) => {
        productCodes.push(item2?.productCode);
      })
    );
    let response: any =
      await CommonQualityCheckService.getTemplateUsageByProduct(productCodes);
    let isQualityCheck = response?.reduce(
      (prev: boolean, current: any) => prev || current.used,
      false
    );
    return isQualityCheck;
  };

  const showErrorMessage = () => {
    if (displayError) {
      return (
        <div className="bottom-0 rounded bg-red-100 p-2 mt-l parent-width">
          <span>{errorMessageText}</span>
        </div>
      );
    }
  };

  const closePopup = (docType: DOC_TYPE) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
    if (docType === DOC_TYPE.INVOICE) {
      dispatch(fetchInvoices());
    } else {
      dispatch(fetchQuotes());
    }
  };

  const updateFulfillmentStock = (
    key: string,
    value: any,
    docIndex: any,
    lineIndex: any
  ) => {
    let data = [...documentDetails];
    data[docIndex][itemKey][lineIndex][key] = value;
    setDocumentDetails(data);
  };

  const updateInventory = (key: string) => {
    let filteredProduct = warehouseProduct.find(
      (warehouse: any) => warehouse.code === key
    );
    setWarehousecode(filteredProduct?.code);
  };

  const onItemUpdated = (
    value: number,
    docIndex: any,
    lineIndex: any,
    isReceivingQtyChange: boolean = false
  ) => {
    let data = [...quantityToFulfill];
    data[docIndex][lineIndex] = value;

    let updatedFulfilmentList = [...fulfillmentList];
    if (
      updatedFulfilmentList[docIndex][lineItemKeyToUpdate][lineIndex]
        ?.unlocalizedDocumentUOMSchemaDefinition
    ) {
      let originalUOMQty = Utility.getUomWarehouseQuantity(
        value,
        updatedFulfilmentList[docIndex][lineItemKeyToUpdate][lineIndex]
          ?.unlocalizedDocumentUOMSchemaDefinition
      );
      if (isReceivingQtyChange) {
        updatedFulfilmentList[docIndex][lineItemKeyToUpdate][lineIndex] = {
          ...updatedFulfilmentList[docIndex][lineItemKeyToUpdate][lineIndex],
          localizedBaseQtyDiff: 0,
          isLocalizedUomQty: false,
          receiptQuantityForSeparateColumnDisplay: originalUOMQty,
          originalLocalizedUOMQty: originalUOMQty,
          // what should be uomschema
          documentUOMSchemaDefinition: calculateDocumentUomSchema(
            updatedFulfilmentList[docIndex][lineItemKeyToUpdate][lineIndex]
              ?.unlocalizedDocumentUOMSchemaDefinition,
            originalUOMQty,
            value
          )
        };
      }
    }

    setIsReceivingUpdatedManually(true);
    setFulfillmentList(updatedFulfilmentList);
    setQuantityToFulfill(data);
  };

  const getSelectedUomValue = (id: number) => {
    let filteredUOM = uoms.find((uom: any) => uom.id === id);
    return filteredUOM ? filteredUOM.name : '-';
  };

  const getSelectedWarehousesValue = (code: string) => {
    let filtered = warehouseProduct.filter(
      (warehouse: any) => warehouse.code === code
    );
    let first: any;
    let data;
    if (filtered.length > 0) {
      first = filtered[0];
      data = {
        label: first?.name,
        value: first?.code
      };
    } else if (warehouseProduct.length > 0) {
      first = warehouseProduct.find((warehouse: any) => warehouse.primary);
      data = {
        label: first?.name,
        value: first?.code
      };
      if (!first || first?.length === 0) {
        data = {
          label: warehouseProduct[0].name,
          value: warehouseProduct[0].code
        };
      }
    } else {
      data = {};
    }
    return data;
  };

  const getSelectionFieldWithCallback = (
    value: any,
    options: any,
    callback: returnFunction,
    hasError = false
  ) => {
    return (
      <div className="text-align-left">
        <Select
          styles={{
            control: (base, state) => ({
              ...base,
              '&:hover': { borderColor: hasError ? 'red' : '' },
              border: hasError ? '1px solid red' : ''
            })
          }}
          options={options}
          value={value}
          onChange={(value) => callback(value)}
        />
      </div>
    );
  };

  const getHeader = (
    <div
      className="row mt-l pr-10 parent-width overflow-hidden rounded-tl-xl rounded-tr-xl"
      style={{ backgroundColor: '#FAFAFA' }}
    >
      <DKLabel
        className="parent-width pt-s pb-s pl-s pr-s ml-5 font-medium"
        text=" Quote/Invoice Number "
      />
    </div>
  );

  const getLineItemHeader = (
    <tr>
      <th className="text-left" style={{ width: 50 }}>
        {' '}
      </th>
      <th className="text-left" style={{ width: 150, minWidth: 150 }}>
        {' '}
        Products{' '}
      </th>
      <th className="text-left" style={{ width: 150, minWidth: 150 }}>
        Description
      </th>
      <th
        className="text-center"
        style={{ width: 60, minWidth: 60, maxWidth: 100 }}
      >
        UOM
      </th>
      <th className="text-right" style={{ width: 100, minWidth: 100 }}>
        {' '}
        Required
      </th>
      <th className="text-right pr-2" style={{ width: 100, minWidth: 100 }}>
        {' '}
        Committing
      </th>
      {tenantInfo?.additionalSettings?.LOCALIZED_UOM_CONVERSION && (
        <th className="text-right pr-2" style={{ width: 170, minWidth: 170 }}>
          {' '}
          Base UOM Qty Assigned
        </th>
      )}
      <th className="text-center" style={{ width: 200, minWidth: 200 }}>
        {' '}
        Warehouse
      </th>
      <th style={{ width: 50, minWidth: 50 }}></th>
    </tr>
  );

  const getFulfillmentItemDetails = (item: any, docIndex: any) => {
    let code = '';
    let lineItems = [];
    if (docType === DOC_TYPE.INVOICE) {
      code = item.documentSequenceCode;
      lineItems = item.salesInvoiceItems;
    } else {
      code = item.documentSequenceCode;
      lineItems = item.quotationItemDtoList;
    }

    return (
      <div className="flex flex-col cursor-hand">
        <div className="row parent-width border-b-2  bg-white">
          <div
            className="row parent-width bg-white"
            onClick={() => {
              // setShowItemMore(-1);
              if (selectedIndex === docIndex) {
                setSelectedIndex(-1);
              } else {
                setSelectedIndex(docIndex);
              }
            }}
          >
            <DKIcon
              src={
                selectedIndex === docIndex || expandAll
                  ? DKIcons.ic_arrow_up2
                  : DKIcons.ic_arrow_down2
              }
              className="ic-s ml-r bg-white"
            />
            <DKLabel
              className="parent-width p-2 bg-white font-medium"
              text={'#' + code}
            />
            {fulfillmentList && fulfillmentList.length > 1 && (
              <DKIcon
                src={ic_delete}
                className="ic-s cursor-hand"
                title="Delete"
                onClick={() => {
                  deleteFulfillmentItemList(docIndex);
                }}
              />
            )}
          </div>
        </div>
        {(selectedIndex === docIndex || expandAll) &&
          getLineItems(lineItems, docIndex)}
      </div>
    );
  };

  const deleteFulfillmentItemList = (docIndex: any) => {
    let fulfillmentLocalState = fulfillmentList;
    fulfillmentLocalState.splice(docIndex, 1);
    setDocumentDetails((prevState: any[]) => fulfillmentLocalState);
    setFulfillmentList((prevState: any[]) => fulfillmentLocalState);
  };

  const getLineItems = (lineItems: any, docIndex: any) => {
    return (
      <div className="column parent-width mb-1 mt-1">
        <table
          ref={lineItemsTable}
          className="table my-5"
          style={{ width: '100%' }}
        >
          <thead>{getLineItemHeader}</thead>
          <tbody>
            {lineItems &&
              lineItems.map((lineItem: any, lineIndex: any) => {
                return getLineItem(lineItem, docIndex, lineIndex);
              })}
          </tbody>
        </table>
        {showSerialTracking && (
          <SerialTrackedAssignment
            isMRP={false}
            itemDetails={{
              ...serialTrackingData,
              requiredQuantity: quantityFulfilled,
              uomLocalizedquantityRequired:
                warehouseItem.receiptQuantityForSeparateColumnDisplay
            }}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={defaultWarehouse}
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfill: any
            ) => {
              let inventory = [...documentDetails];
              const fulfilledQuantity =
                serialTrackingData.documentUOMSchemaDefinition
                  ? Utility.getUomQuantity(
                      parseFloat(quantityToFulfill),
                      serialTrackingData.documentUOMSchemaDefinition
                    )
                  : quantityToFulfill;
              if (isQuickCommit) {
                inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex] =
                  {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    advancedTrackingFulfilmentData: data,
                    advancedTracking: TRACKING_TYPE.SERIAL,
                    quantityFulfilled: quantityToFulfill,
                    fulfilledQuantity: quantityToFulfill,
                    isQuickCommit: isQuickCommit
                  };
              } else {
                inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex] =
                  {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    advancedTrackingFulfilmentData: data,
                    advancedTracking: TRACKING_TYPE.SERIAL,
                    isQuickCommit: isQuickCommit,
                    quantityFulfilled: quantityToFulfill,
                    fulfilledQuantity: quantityToFulfill
                  };
              }
              if (serialTrackingData.documentUOMSchemaDefinition) {
                inventory[itemIndex?.docIndex][itemKey][
                  itemIndex?.lineIndex
                ].uomFulfilledQuantity = fulfilledQuantity;
                if (
                  documentDetails[itemIndex?.docIndex][itemKey][
                    itemIndex?.lineIndex
                  ]?.isLocalizedUomQty
                ) {
                  const {
                    localizedBaseQtyDiff,
                    isLocalizedUomQty,
                    receiptQuantityForSeparateColumnDisplay,
                    documentUOMSchemaDefinition
                  } = handleReverseSchemaForSerialTracked(
                    data,
                    {
                      documentUOMSchemaDefinition:
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.documentUOMSchemaDefinition,
                      originalLocalizedQty:
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.originalLocalizedUOMQty,
                      isLocalizedUomQty:
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.isLocalizedUomQty,
                      unlocalizedDocumentUOMSchemaDefinition:
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.unlocalizedDocumentUOMSchemaDefinition
                    },
                    DOC_TYPE.GOODS_RECEIPT
                  );
                  const receiptQuantityForUpdatedSchema =
                    serialTrackingData.documentUOMSchemaDefinition
                      ? Utility.getUomQuantity(
                          parseFloat(quantityToFulfill),
                          documentUOMSchemaDefinition
                        )
                      : quantityToFulfill;
                  if (
                    isBillNeedToChange(
                      documentUOMSchemaDefinition,
                      documentDetails[itemIndex?.docIndex][itemKey][
                        itemIndex?.lineIndex
                      ]?.documentUOMSchemaDefinition ||
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.unlocalizedDocumentUOMSchemaDefinition,
                      props?.documentType
                    )
                  ) {
                    setShowSerialTracking(false);
                    return;
                  }
                  inventory[itemIndex?.docIndex][itemKey][
                    itemIndex?.lineIndex
                  ] = {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    localizedBaseQtyDiff,
                    isLocalizedUomQty,
                    receiptQuantityForSeparateColumnDisplay,
                    documentUOMSchemaDefinition,
                    quantityFulfilled: receiptQuantityForUpdatedSchema, // for localizedUOM schema
                    fulfilledQuantity: receiptQuantityForUpdatedSchema
                  };
                }
              }

              onItemUpdated(
                fulfilledQuantity,
                itemIndex?.docIndex,
                itemIndex?.lineIndex,
                false
              );
              setShowSerialTracking(false);
            }}
            onClose={() => {
              setShowSerialTracking(false);
            }}
            parentDocumentType={props.documentType ?? ''}
            parentDocumentDetails={props.documentDetails}
          ></SerialTrackedAssignment>
        )}
        {showBatchTracking && (
          <BatchTrackingAssignment
            isMrpFlow={false}
            itemDetails={{
              ...batchTrackingData,
              requiredQuantity: quantityFulfilled,
              uomLocalizedquantityRequired:
                warehouseItem.receiptQuantityForSeparateColumnDisplay
            }}
            module={MODULE_TYPE.SELL}
            documentType={DOC_TYPE.FULFILLMENT}
            defaultProductWarehouse={defaultWarehouse}
            onBatchSave={(data: any, quantityToFulfill: any) => {
              let inventory = [...documentDetails];
              let isMultipleSchema =
                inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex]
                  .documentUOMSchemaDefinition;
              const fulfilledQuantity = isMultipleSchema
                ? Utility.getUomWarehouseQuantity(
                    parseFloat(quantityToFulfill),
                    inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ].documentUOMSchemaDefinition
                  )
                : quantityToFulfill;
              setShowBatchTracking(false);
              if (isMultipleSchema) {
                inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex] =
                  {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    advancedTrackingFulfilmentData: data,
                    advancedTracking: TRACKING_TYPE.BATCH,
                    quantityFulfilled: fulfilledQuantity,
                    fulfilledQuantity: fulfilledQuantity,
                    uomFulfilledQuantity: quantityToFulfill,
                    isQuickCommit: false
                  };
                if (warehouseItem?.isLocalizedUomQty) {
                  const {
                    localizedBaseQtyDiff,
                    isLocalizedUomQty,
                    receiptQuantityForSeparateColumnDisplay,
                    documentUOMSchemaDefinition
                  }: any = handleReverseSchemaForBatchTracked(
                    data,
                    {
                      documentUOMSchemaDefinition:
                        warehouseItem?.documentUOMSchemaDefinition,
                      originalLocalizedQty:
                        warehouseItem?.originalLocalizedUOMQty,
                      isLocalizedUomQty: warehouseItem?.isLocalizedUomQty,
                      unlocalizedDocumentUOMSchemaDefinition:
                        warehouseItem?.unlocalizedDocumentUOMSchemaDefinition
                    },
                    DOC_TYPE.FULFILLMENT
                  );
                  const receiptQuantityForUpdatedSchema = Utility.roundingOff(
                    documentUOMSchemaDefinition
                      ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                          parseFloat(quantityToFulfill.toString()),
                          documentUOMSchemaDefinition
                        )
                      : quantityToFulfill,
                    QTY_ROUNDOFF_PRECISION
                  );
                  if (
                    isBillNeedToChange(
                      documentUOMSchemaDefinition,
                      documentDetails[itemIndex?.docIndex][itemKey][
                        itemIndex?.lineIndex
                      ]?.documentUOMSchemaDefinition ||
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.unlocalizedDocumentUOMSchemaDefinition,
                      props?.documentType
                    )
                  ) {
                    setShowBatchTracking(false);
                    return;
                  }
                  inventory[itemIndex?.docIndex][itemKey][
                    itemIndex?.lineIndex
                  ] = {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    localizedBaseQtyDiff,
                    isLocalizedUomQty,
                    receiptQuantityForSeparateColumnDisplay,
                    documentUOMSchemaDefinition,
                    quantityFulfilled: receiptQuantityForUpdatedSchema, // for localizedUOM schema
                    fulfilledQuantity: receiptQuantityForUpdatedSchema
                  };
                }
              } else {
                inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex] =
                  {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    advancedTrackingFulfilmentData: data,
                    advancedTracking: TRACKING_TYPE.BATCH,
                    quantityFulfilled: quantityToFulfill,
                    fulfilledQuantity: quantityToFulfill,
                    isQuickCommit: false
                  };
              }
              onItemUpdated(
                quantityToFulfill,
                itemIndex?.docIndex,
                itemIndex?.lineIndex,
                false
              );
            }}
            onClose={() => {
              setShowBatchTracking(false);
            }}
            parentDocumentType={props.documentType ?? ''}
            parentDocumentDetails={props.documentDetails}
          ></BatchTrackingAssignment>
        )}
        {showMultipleWarehouse && (
          <NoneTrackedAssignment
            details={{
              ...warehouseItem,
              quantityFulfilled: quantityFulfilled,
              parentQuantityToFulfill: quantityFulfilled,
              uomLocalizedquantityRequired:
                warehouseItem.receiptQuantityForSeparateColumnDisplay,
              pendingQuantity:
                warehouseItem.receiptQuantityForSeparateColumnDisplay
            }}
            module={MODULE_TYPE.SELL}
            // defaultProductWarehouse={defaultWarehouse?.code}
            // hasMultilpleWarehouseData={multipleWarehouseData}
            onSave={(data: any) => {
              if (data && data.length > 0) {
                const updatedQuantity = data.reduce(function (
                  acc: any,
                  val: any
                ) {
                  return acc + parseFloat(val.quantity);
                },
                0);

                let inventory = [...documentDetails];
                let isMultipleSchema =
                  inventory[itemIndex?.docIndex][itemKey][itemIndex?.lineIndex]
                    .documentUOMSchemaDefinition;
                const fulfilledQuantity = isMultipleSchema
                  ? Utility.getUomWarehouseQuantity(
                      parseFloat(updatedQuantity),
                      data.documentUOMSchemaDefinition
                    )
                  : updatedQuantity;
                if (isMultipleSchema) {
                  inventory[itemIndex?.docIndex][itemKey][
                    itemIndex?.lineIndex
                  ] = {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    uomFulfilledQuantity: fulfilledQuantity,
                    warehouseInventoryData: data
                  };

                  if (warehouseItem?.isLocalizedUomQty) {
                    const {
                      localizedBaseQtyDiff,
                      isLocalizedUomQty,
                      receiptQuantityForSeparateColumnDisplay,
                      documentUOMSchemaDefinition
                    }: any = handleReverseSchemaForNoneTracked(data, {
                      documentUOMSchemaDefinition:
                        warehouseItem?.documentUOMSchemaDefinition,
                      originalLocalizedQty:
                        warehouseItem?.originalLocalizedUOMQty,
                      isLocalizedUomQty: warehouseItem?.isLocalizedUomQty,
                      unlocalizedDocumentUOMSchemaDefinition:
                        warehouseItem?.unlocalizedDocumentUOMSchemaDefinition
                    });
                    const receiptQuantityForUpdatedSchema = Utility.roundingOff(
                      documentUOMSchemaDefinition
                        ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                            parseFloat(updatedQuantity.toString()),
                            documentUOMSchemaDefinition
                          )
                        : updatedQuantity,
                      QTY_ROUNDOFF_PRECISION
                    );
                    if (
                      isBillNeedToChange(
                        documentUOMSchemaDefinition,
                        documentDetails[itemIndex?.docIndex][itemKey][
                          itemIndex?.lineIndex
                        ]?.documentUOMSchemaDefinition ||
                          documentDetails[itemIndex?.docIndex][itemKey][
                            itemIndex?.lineIndex
                          ]?.unlocalizedDocumentUOMSchemaDefinition,
                        props?.documentType
                      )
                    ) {
                      setShowMultipleWarehouse(false);
                      return;
                    }
                    inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ] = {
                      ...inventory[itemIndex?.docIndex][itemKey][
                        itemIndex?.lineIndex
                      ],
                      localizedBaseQtyDiff,
                      isLocalizedUomQty,
                      receiptQuantityForSeparateColumnDisplay,
                      documentUOMSchemaDefinition,
                      quantityFulfilled: receiptQuantityForUpdatedSchema, // for localizedUOM schema
                      fulfilledQuantity: receiptQuantityForUpdatedSchema,
                      receiptQuantity: receiptQuantityForUpdatedSchema
                    };
                  }
                } else {
                  inventory[itemIndex?.docIndex][itemKey][
                    itemIndex?.lineIndex
                  ] = {
                    ...inventory[itemIndex?.docIndex][itemKey][
                      itemIndex?.lineIndex
                    ],
                    quantityFulfilled: parseFloat(updatedQuantity),
                    fulfilledQuantity: parseFloat(updatedQuantity),
                    warehouseInventoryData: data
                  };
                }

                onItemUpdated(
                  fulfilledQuantity,
                  itemIndex?.docIndex,
                  itemIndex?.lineIndex,
                  false
                );
                // setFulfillmentList(inventory);

                // setMultipleWarehouseData(data);
                setShowMultipleWarehouse(false);
              }
            }}
            onCancel={() => {
              setShowMultipleWarehouse(false);
            }}
            parentDocumentType={props.documentType ?? ''}
            parentDocumentDetails={props.documentDetails}
          ></NoneTrackedAssignment>
        )}
      </div>
    );
  };

  const showBomIcon = (
    docType: any,
    lineItem: any,
    docIndex: any,
    lineIndex: any
  ) => {
    if (docType === DOC_TYPE.INVOICE || docType === DOC_TYPE.QUOTE) {
      if (lineItem.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
        if (lineItem && lineItem.availableProductQuantity) {
          if (
            quantityToFulfill[docIndex][lineIndex] >
            lineItem.availableProductQuantity
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getBuildAssemblyPopup = () => {
    return (
      <BuildAssembly
        product={assemblyProduct}
        onClose={() => {
          setShowBuildAssemblyPopup(false);
        }}
        onSave={(data: any) => {
          ProductService.createBuildAssembly(data)
            .then((res: any) => {
              setShowBuildAssemblyPopup(false);
              dispatch(fetchProducts());
              loadProductInventoryById([assemblyProduct.documentSequenceCode]);

              //   FulfillmentService.getInvoiceBulkFulfillmentDetails(
              //     documentToFetch[docType === DOC_TYPE.QUOTE ? documentToFetch.quotationCode : documentToFetch.salesInvoiceCode]
              //   ).then((data: any) => {
              //     // setBulkFulfillmentDetails(data);
              //     // setEditableInvoice(data[0]);
              //     // setShowBulkFulfillmentPopup(true);
              //   });

              props.refreshFulfillmentDetails();
            })
            .catch((error: any) => {
              showAlert('Error', 'Failed to create build assembly');
            });
        }}
      />
    );
  };

  const onChangeAssemblyProduct = (data: any, docIndex: any) => {
    setAssemblyProduct(data);
    setDocumentToFetch(documentDetails[docIndex]);
    setShowBuildAssemblyPopup(true);
  };

  const openBuildAssembly = (data: any, docIndex: any) => {
    onChangeAssemblyProduct(data, docIndex);
  };

  const getRequiredQuantity = (data: any): number => {
    let pendingQty = data.documentUOMSchemaDefinition
      ? data.uomPendingQuantity
      : data.pendingQuantity;
    return pendingQty;
  };

  const updateLocalizedUOMQty = (
    keyToUpdate: any,
    rowToUpdate: any,
    docIndexToUpdate: any,
    lineItemIndexToUpdate: any,
    userVal: any
  ) => {
    let updatedFulfilmentList = [...fulfillmentList];
    const docSchema =
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].documentUOMSchemaDefinition;
    const userInput = userVal ?? 0;
    const baseQtyDiff = calculateBaseQtyDiff(
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ]?.originalLocalizedUOMQty,
      userInput
    );
    updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
      lineItemIndexToUpdate
    ][keyToUpdate] = userInput;
    updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
      lineItemIndexToUpdate
    ].isLocalizedUomQty = checkIsLocalizedUomQty(
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ]?.originalLocalizedUOMQty,
      userInput
    );
    if (
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].isLocalizedUomQty
    ) {
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].documentUOMSchemaDefinition = calculateDocumentUomSchema(
        docSchema,
        userInput,
        quantityToFulfill[docIndexToUpdate][lineItemIndexToUpdate]
      );
    } else {
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].documentUOMSchemaDefinition =
        updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
          lineItemIndexToUpdate
        ]?.unlocalizedDocumentUOMSchemaDefinition;
    }
    updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
      lineItemIndexToUpdate
    ].localizedBaseQtyDiff = baseQtyDiff;
    setFulfillmentList(updatedFulfilmentList);
  };

  const checkBillValidation = (
    keyToUpdate: any,
    rowToUpdate: any,
    docIndexToUpdate: any,
    lineItemIndexToUpdate: any,
    userVal?: any
  ) => {
    let updatedFulfilmentList = [...fulfillmentList];
    const docSchema =
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].documentUOMSchemaDefinition;

    if (
      updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
        lineItemIndexToUpdate
      ].isLocalizedUomQty
    ) {
      const originalLineItem = props?.documentDetails?.find(
        (ele: any) =>
          ele.documentSequenceCode ===
          updatedFulfilmentList[docIndexToUpdate].documentSequenceCode
      )?.[lineItemKeyToUpdate][lineItemIndexToUpdate];
      const orignalSchema = originalLineItem?.documentUOMSchemaDefinition;

      if (isBillNeedToChange(docSchema, orignalSchema, props?.documentType)) {
        const unlocalizedSchema =
          originalLineItem?.unlocalizedDocumentUOMSchemaDefinition ??
          orignalSchema;
        const originalUOMLocalizedQty = Utility.getUomWarehouseQuantity(
          quantityToFulfill[docIndexToUpdate][lineItemIndexToUpdate],
          orignalSchema
        );
        const ORIGINAL_QTY = Utility.getUomWarehouseQuantity(
          quantityToFulfill[docIndexToUpdate][lineItemIndexToUpdate],
          unlocalizedSchema
        );
        const baseQtyDiff = calculateBaseQtyDiff(
          ORIGINAL_QTY,
          originalUOMLocalizedQty
        );
        updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
          lineItemIndexToUpdate
        ] = {
          ...updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
            lineItemIndexToUpdate
          ],
          localizedBaseQtyDiff: baseQtyDiff,
          isLocalizedUomQty:
            updatedFulfilmentList[docIndexToUpdate][lineItemKeyToUpdate][
              lineItemIndexToUpdate
            ].isLocalizedUomQty,
          receiptQuantityForSeparateColumnDisplay: originalUOMLocalizedQty,
          originalLocalizedUOMQty: originalUOMLocalizedQty,
          documentUOMSchemaDefinition: calculateDocumentUomSchema(
            orignalSchema,
            originalUOMLocalizedQty,
            quantityToFulfill[docIndexToUpdate][lineItemIndexToUpdate]
          )
        };
        setFulfillmentList(updatedFulfilmentList);
        return;
      }
    }
  };

  const getLineItem = (lineItem: any, docIndex: any, lineIndex: any) => {
    if (lineItem.pendingQuantity <= 0) {
      return;
    }
    // end check if fulfilled record exists

    let advancedTrackingType =
      lineItem.product.advancedTracking === TRACKING_TYPE.BATCH ||
      lineItem.product.advancedTracking === TRACKING_TYPE.SERIAL;
    return (
      <>
        <tr className="dk-data-grid-row-bg">
          <td>
            <div
              className="cursor-hand row justify-content-center"
              onClick={() => {
                let copyFulfillmentList: any = [...fulfillmentList];
                if (docType === DOC_TYPE.INVOICE) {
                  copyFulfillmentList[docIndex]['salesInvoiceItems'][
                    lineIndex
                  ].expanded =
                    !copyFulfillmentList[docIndex]['salesInvoiceItems'][
                      lineIndex
                    ].expanded;
                } else {
                  copyFulfillmentList[docIndex]['quotationItemDtoList'][
                    lineIndex
                  ].expanded =
                    !copyFulfillmentList[docIndex]['quotationItemDtoList'][
                      lineIndex
                    ].expanded;
                }
                setFulfillmentList([...copyFulfillmentList]);
              }}
              style={{ width: 'auto' }}
            >
              <DKIcon
                src={
                  lineItem.expanded
                    ? DKIcons.ic_arrow_up2
                    : DKIcons.ic_arrow_down2
                }
                className="ic-s bg-white"
              />
            </div>
          </td>
          <td className="py-2 fs-r">{lineItem.product.name}</td>
          <td className="py-2 fs-r">{lineItem.product.description}</td>
          <td className="py-2 fs-r text-align-center">
            {getSelectedUomValue(lineItem.documentUom)}
          </td>
          <td className="text-align-right py-2 fs-r">
            {getRequiredQuantity(lineItem)}
          </td>
          <td className="text-align-right py-2 fs-r pl-4">
            <input
              type="text"
              className="w-full h-full text-right p-2"
              value={quantityToFulfill[docIndex][lineIndex]}
              onChange={(e: any) => {
                setCurrentSelectedCol(null);
                onItemUpdated(e.target.value, docIndex, lineIndex, true);
              }}
            />
          </td>
          {/* uom quantity */}
          {tenantInfo?.additionalSettings?.LOCALIZED_UOM_CONVERSION && (
            <td className="text-align-right py-2 fs-r pl-4">
              <input
                type="text"
                className="w-full h-full text-right p-2"
                value={lineItem?.receiptQuantityForSeparateColumnDisplay ?? 0}
                disabled={
                  Utility.isEmpty(
                    fulfillmentList?.[docIndex]?.[lineItemKeyToUpdate]?.[
                      lineIndex
                    ]?.documentUOMSchemaDefinition
                  ) || tenantInfo?.allowNegativeInventory
                }
                onChange={(e: any) => {
                  setCurrentSelectedCol(LOCALIZED_UOM_QTY);
                  updateLocalizedUOMQty(
                    'receiptQuantityForSeparateColumnDisplay',
                    lineItem,
                    docIndex,
                    lineIndex,
                    e.target.value
                  );
                }}
                onBlur={() => {
                  if (props?.documentType === DOC_TYPE.INVOICE) {
                    checkBillValidation(
                      'receiptQuantityForSeparateColumnDisplay',
                      lineItem,
                      docIndex,
                      lineIndex
                    );
                  }
                }}
              />
            </td>
          )}
          <td className="text-align-right py-2 px-4">
            {!advancedTrackingType &&
              !lineItem.warehouseInventoryData &&
              lineItem.product?.type !== PRODUCT_TYPE.NON_TRACKED &&
              getSelectionFieldWithCallback(
                getWarehousecode(docIndex, lineIndex),
                warehouseProduct.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.code
                  };
                }),
                (value: any) => {
                  updateInventory(value.value);
                  updateFulfillmentStock(
                    'warehouseCode',
                    value.value,
                    docIndex,
                    lineIndex
                  );
                }
              )}
            {advancedTrackingType && (
              <div>
                <DKLabel
                  text="Advanced Tracking"
                  className="fw-r text-align-center text-gray-500"
                />
              </div>
            )}
            {!advancedTrackingType &&
              lineItem.warehouseInventoryData &&
              lineItem.warehouseInventoryData.length > 0 && (
                <div>
                  <DKLabel
                    text="Multiple Warehouse"
                    className="fw-r text-align-center text-gray-500"
                  />
                </div>
              )}
            {lineItem.product &&
              lineItem.product?.type === PRODUCT_TYPE.NON_TRACKED && (
                <div>
                  <DKLabel
                    text="-"
                    className="fw-r text-align-center text-gray-500"
                  />
                </div>
              )}
          </td>
          <td className="text-align-right py-2 row justify-content-center">
            {getAdvanceTracking(lineItem, docIndex, lineIndex)}
            {showBomIcon(docType, lineItem, docIndex, lineIndex) && (
              <DKTooltipWrapper content={'Build Assembly'} tooltipClassName="">
                <div className="row">
                  <DKIcon
                    src={ic_bom_allocate_red}
                    className="ic-r flex align-items-center cursor-hand"
                    onClick={() =>
                      openBuildAssembly(lineItem.product, docIndex)
                    }
                  />
                </div>
              </DKTooltipWrapper>
            )}

            {getMultiWarehouse(lineItem, docIndex, lineIndex)}
          </td>
        </tr>
        {lineItem.expanded && (
          <tr>
            <td colSpan={8}>
              {lineItem.product.advancedTracking === TRACKING_TYPE.BATCH && (
                // <div></div>
                <ExpandedFulfillmentTable
                  data={lineItem}
                  type={TRACKING_TYPE.BATCH}
                  module={DOC_TYPE.FULFILLMENT}
                />
              )}
              {lineItem.product.advancedTracking === TRACKING_TYPE.SERIAL && (
                // <div></div>
                <ExpandedFulfillmentTable
                  data={lineItem}
                  type={TRACKING_TYPE.SERIAL}
                  module={DOC_TYPE.FULFILLMENT}
                />
              )}
              {lineItem.product.advancedTracking === TRACKING_TYPE.NONE && (
                // <div></div>
                <ExpandedFulfillmentTable
                  data={lineItem}
                  type={TRACKING_TYPE.NONE}
                  module={DOC_TYPE.FULFILLMENT}
                />
              )}
            </td>
          </tr>
        )}
      </>
    );
  };

  const getAdvanceTracking = (item: any, docIndex: any, lineIndex: any) => {
    let availableQty = item.documentUOMSchemaDefinition
      ? item?.uomAvailableQuantity
      : item.availableQty
      ? item.availableQty
      : item.availableQuantity;

    const errorMessage =
      'Allocate quantities from multiple warehouses. Total available quantity is (' +
      availableQty +
      `${
        item.documentUOMSchemaDefinition
          ? ' ' + item.documentUOMSchemaDefinition.name
          : ''
      }` +
      ')';
    if (item.product.advancedTracking === TRACKING_TYPE.SERIAL) {
      return (
        <DKTooltipWrapper
          content={errorMessage}
          tooltipPositionAbsolute={true}
          tooltipStyle={{ top: 40, right: -5, left: 'unset' }}
        >
          <div className="row justify-content-center h-12">
            <DKIcon
              src={
                item?.isQuickCommit ||
                item?.advancedTrackingFulfilmentData?.length ===
                  (item.documentUOMSchemaDefinition
                    ? Utility.getUomWarehouseQuantity(
                        quantityToFulfill[docIndex][lineIndex],
                        item.documentUOMSchemaDefinition
                      )
                    : quantityToFulfill[docIndex][lineIndex])
                  ? ic_barcode_green
                  : ic_barcode_red
              }
              onClick={() => {
                setSerialTracking(item);
                setSerialTrackingData(item);
                setQuantityFulfilled(quantityToFulfill[docIndex][lineIndex]);
                setItemIndex({
                  docIndex: docIndex,
                  lineIndex: lineIndex
                });
              }}
              title="Advanced Tracking"
              className="ic-r flex align-items-center cursor-hand"
              style={{ width: '100%' }}
            />
          </div>
        </DKTooltipWrapper>
      );
    } else if (item.product.advancedTracking === TRACKING_TYPE.BATCH) {
      let totalItem =
        item.advancedTrackingFulfilmentData &&
        item.advancedTrackingFulfilmentData.length > 0 &&
        item.advancedTrackingFulfilmentData.reduce(
          (a: any, b: any) =>
            +a +
            +parseFloat(
              item.documentUOMSchemaDefinition
                ? Utility.getUomQuantity(
                    b.qtyToFulfil,
                    item.isLocalizedUomQty
                      ? item.unlocalizedDocumentUOMSchemaDefinition
                      : item.documentUOMSchemaDefinition
                  )
                : b.qtyToFulfil
            ),
          0
        );
      return (
        <DKTooltipWrapper
          content={errorMessage}
          tooltipPositionAbsolute={true}
          tooltipStyle={{ top: 40, right: -5, left: 'unset' }}
        >
          <div className="row justify-content-center h-12">
            <DKIcon
              src={
                totalItem === quantityToFulfill[docIndex][lineIndex]
                  ? ic_barcode_green
                  : ic_barcode_red
              }
              onClick={() => {
                setBatchTracking(item);
                setBatchTrackingData(item);
                setQuantityFulfilled(quantityToFulfill[docIndex][lineIndex]);
                setItemIndex({
                  docIndex: docIndex,
                  lineIndex: lineIndex
                });
              }}
              title="Advanced Tracking"
              className="ic-r flex align-items-center cursor-hand"
              style={{ width: '100%' }}
            />
          </div>
        </DKTooltipWrapper>
      );
    }
  };

  const getMultiWarehouse = (item: any, docIndex: any, lineIndex: any) => {
    if (
      (item.type === PRODUCT_TYPE.TRACKED ||
        item.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
      item.product.advancedTracking === TRACKING_TYPE.NONE
    ) {
      let availableQty = item.documentUOMSchemaDefinition
        ? item?.uomAvailableQuantity
        : item.availableQty
        ? item.availableQty
        : item.availableQuantity;
      const errorMessage =
        'Allocate quantities from multiple warehouses. Total available quantity is (' +
        availableQty +
        ')';

      const qtyToCompare = documentDetails[docIndex][itemKey][lineIndex]
        ?.documentUOMSchemaDefinition
        ? documentDetails[docIndex][itemKey][lineIndex]?.uomFulfilledQuantity
        : documentDetails[docIndex][itemKey][lineIndex]?.quantityFulfilled;
      return (
        <DKTooltipWrapper
          content={errorMessage}
          tooltipPositionAbsolute={true}
          tooltipStyle={{ top: 40, right: -5, left: 'unset' }}
        >
          <div className="row justify-content-center h-12">
            <DKIcon
              src={
                quantityToFulfill[docIndex][lineIndex] !== qtyToCompare
                  ? ic_barcode_red
                  : ic_barcode_green
              }
              className="ic-r flex cursor-hand"
              style={{ width: '100%' }}
              onClick={() => {
                setWarehouseItem(item);
                setQuantityFulfilled(quantityToFulfill[docIndex][lineIndex]);
                setItemIndex({
                  docIndex: docIndex,
                  lineIndex: lineIndex
                });
                setShowMultipleWarehouse(true);
              }}
            />
          </div>
        </DKTooltipWrapper>
      );
    }
  };

  const setSerialTracking = (item: any) => {
    loadSerialTrackingProducts(item.productCode);
    setShowSerialTracking(true);
  };

  const setBatchTracking = (item: any) => {
    loadBatchTrackingProducts(item.productCode);
    setShowBatchTracking(true);
  };

  const getWarehousecode = (docIndex: any, lineIndex: any) => {
    let warehouseCode =
      documentDetails[docIndex][itemKey][lineIndex]['warehouseCode'] ||
      defaultWarehouse?.code;
    return getSelectedWarehousesValue(warehouseCode);
  };
  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const loadBatchTrackingProducts = async (code: any) => {
    showLoader();
    try {
      const data = await dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
      if (data) {
        removeLoader();
      }
    } catch (err) {
      removeLoader();
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (!Utility.checkClosingDate(newDate, 'Fulfillment date')) {
        return;
      }
      if (
        !Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Fulfillment date',
          'FULFILLMENT'
        )
      ) {
        return;
      }
      callback(newDate);
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-content-between">
        <div className="flex flex-col">
          <div className="flex align-items-center label">
            <DKIcon
              src={ic_delivery}
              className="ic-r ic-s"
              style={{ opacity: 0.8 }}
            />
            <span className="fs-r pl-2 font-medium">Ship To</span>
          </div>
          <div className="flex flex-col value pt-1">
            <span className="fs-r address1">
              {Utility.getFullAddress(shipToAddress)}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-48">
          <span className="text-black fs-r ml-0 font-medium">Date</span>
          <div className="position-relative pt-2">
            <div
              className="text-align-left cursor-pointer font-medium"
              onClick={() => {
                setFulfillmentDateOpen(!fulfillmentDateOpen);
              }}
            >
              <div className="flex flex-row justify-between bg-gray-100 p-2 rounded">
                <span className="mr-1 w-24">
                  {DateFormatService.getDateStrFromDate(fulfillmentDate)}
                </span>
                <span className="mt-0.5">
                  <img
                    src={ic_calender}
                    alt="calender"
                    className="h-4 object-cover"
                  />
                </span>
              </div>
            </div>
            {fulfillmentDateOpen &&
              getCalendarView(
                fulfillmentDate,
                (newDate: any) =>
                  validateAndUpdateDate(
                    newDate,
                    DateFormatService.getDateFromStr(
                      tenantInfo.bookBeginningStartDate,
                      BOOKS_DATE_FORMAT['YYYY-MM-DD']
                    ),
                    setFulfillmentDate,
                    'Fulfillment date cannot be before the book beginning date: ' +
                      tenantInfo.bookBeginningStartDate
                  ),
                setFulfillmentDateOpen
              )}
          </div>
        </div>
      </div>
      {showErrorMessage()}
      <div className="mt-l">
        {getHeader}
        {fulfillmentList &&
          fulfillmentList.length > 0 &&
          fulfillmentList.map((item: any, index: number) => {
            return getFulfillmentItemDetails(item, index);
          })}

        {/* {showBuildAssemblyPopup && <>{getBuildAssemblyPopup()}</>} */}
      </div>
      {showBuildAssemblyPopup && getBuildAssemblyPopup()}
    </div>
  );
};

export default BulkFulillment;
