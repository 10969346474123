import { useEffect, useState } from 'react';
import { showAlert, showLoader, removeLoader } from 'deskera-ui-library';
import { PurchaseOrder } from '../../Models/Order';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  LABELS,
  MODULES_NAME,
  RECEIVED_GOODS_STATUS
} from '../../Constants/Constant';
import { billInitialState } from '../../Models/Bill';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../Models/Drafts';
import DateFormatService from '../../Services/DateFormat';
import ic_convert_fulfill from '../../Assets/Icons/ic_convert_fulfill.svg';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import ic_convert_partially from '../../Assets/Icons/ic_convert_partially.svg';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { getUpdatedPurchaseOrderObject } from './PurchaseOrderHelper';
import AttachmentService from '../../Services/Attachment';
import { FAPurchaseOrder } from '../../Models/FAOrder';
import { selectFullBillCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';
import { calculateDocumentUomSchema } from '../../SharedComponents/FulfillmentPopup/FulfilmentGridHelper';
import { isEqual } from 'lodash';

interface PurchaseOrderToBillProps {
  poDocument: PurchaseOrder;
  closePopup: () => void;
  closeParentDoc?: () => void;
  receivedGoodsDetails?: any;
}

enum POConversionAction {
  PARTIAL_BILL = 'partial_bill',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_RECEIVED = 'convert_and_received'
}

export default function PurchaseOrderToBillPopup(
  props: PurchaseOrderToBillProps
) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const billSequenceFormatsInStore = useAppSelector(
    selectFullBillCustomNumbersFormats
  );
  const [attachmentIds, setAttachmentsIds] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);

  const [po, setPo] = useState(props.poDocument);
  const [showMultiCurrecyWarn, setShowMultiCurrecyWarn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: MODULES_NAME.ORDER,
      EntityId: po.id
    };

    AttachmentService.getAllAttachments()
      .then((res: any) => {
        let attachmentIds: any[] = [];
        res &&
          res.length &&
          res.forEach((attachment: any) => {
            attachmentIds.push(attachment.attachmentId);
          });
        setAttachmentsIds(attachmentIds);
        setAttachments(attachmentIds.map(String));
      })
      .catch((err: any) => {
        console.error(err);
      });

    loadPurchaseOrderDetails(po.poCode);
  }, []);

  useEffect(() => {
    let order = {
      ...po,
      purchaseOrderItems: po.purchaseOrderItems?.map((item: any) => {
        return {
          ...item,
          advancedTracking: item?.product?.advancedTracking,
          receivedQuantityInBills: item.receivedQuantityInBills || 0,
          receivedQuantityInOrder: item.receivedQuantityInOrder || 0
        };
      })
    };
    const updatedPO = {
      ...po,
      purchaseOrderItems: order.purchaseOrderItems,
      items: order.purchaseOrderItems
    };
    if (
      updatedPO.dropShip ||
      (updatedPO.receiveGoodsStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
        !updatedPO.hasPartialBill)
    ) {
      preparePOForConversion(POConversionAction.CONVERT_ONLY);
    }
    setPo(updatedPO);
  }, []);

  useEffect(() => {
    if (po.currencyCode !== tenantInfo.currency) {
      setShowMultiCurrecyWarn(true);
    }
  }, [po.currencyCode, tenantInfo.currency]);

  // Load Quote Details
  const loadPurchaseOrderDetails = async (code: any) => {
    showLoader();
    PurchaseOrderService.fetchOrderDetails(code).then(
      (data: any) => {
        removeLoader();
        const pODetailsData: any = getUpdatedPurchaseOrderObject(data);
        setPo(pODetailsData);
      },
      (err) => {
        removeLoader();
        console.error('Error while fetching purchase order details: ', err);
      }
    );
  };

  const checkIsLocalizedUomQty = () => {
    return po.purchaseOrderItems?.some((item: any) => item.isLocalizedUomQty);
  };

  const convertToPartialBill = (selectedOrder: any) => {
    if (selectedOrder.buyType && selectedOrder.buyType === BUY_TYPE.ASSET) {
      convertToPartialFABill(selectedOrder);
    } else {
      convertPartialBill(selectedOrder);
    }
  };

  const convertToPartialFABill = (selectedOrder: FAPurchaseOrder) => {
    let bill: any = {
      ...billInitialState,
      ...selectedOrder
    };

    let itemsList;
    if (bill.buyType === BUY_TYPE.EXPENSE) {
      itemsList = Utility.getValue(bill.items, bill.purchaseInvoiceAccounts);
    } else {
      itemsList = Utility.getValue(
        bill.items,
        bill.purchaseInvoiceItems,
        bill.purchaseInvoiceProducts
      );
    }
    itemsList = itemsList ? itemsList : [];

    bill = {
      ...bill,
      documentType: DOC_TYPE.FA_BILL,
      isPartialBill: false,
      isPartialInvoice: false,
      documentCode: Utility.getValue(
        bill.documentCode,
        bill.purchaseInvoiceCode
      ),
      receivedComplete: bill.receivedComplete || false,
      billDue: Utility.getValue(
        bill.dueDate,
        bill.purchaseInvoiceDueDate,
        bill.validTillDate
      ),
      documentDate: Utility.getValue(
        bill.documentDate,
        bill.purchaseInvoiceDate
      ),
      validTillDate: Utility.getValue(
        bill.validTillDate,
        bill.purchaseInvoiceDueDate
      ),
      fulfillmentDate: Utility.getValue(
        bill.fulfillmentDate,
        bill.receiveByDate
      ),
      buyType: BUY_TYPE.ASSET,
      receiveByDate: bill.fulfillmentDate,
      purchaseInvoiceDueDate: bill.validTillDate,
      purchaseInvoiceType: bill.buyType,
      supplierInvoiceNo: bill.supplierInvoiceNo,
      purchaseInvoiceDate: bill.documentDate,
      attachments: bill.entityId ? attachments : [],
      attachmentIds: bill.entityId ? attachmentIds : [],
      isConverting: true,
      contact: {
        ...bill.contact,
        ...bill.contactDto,
        address: Utility.getStringAddress(bill.contactDto)
      },
      contactCode: bill.contactDto.code,
      currency: bill.currency,
      receiveGoodsStatus:
        bill.receiptStatus ||
        bill.receiveGoodsStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      status: !bill.id ? DOCUMENT_STATUS.OPEN : bill.status,
      gstin: bill.gstin,
      dropShip: bill.dropShip ? bill.dropShip : false,
      placeOfSupply: bill.placeOfSupply,
      isCreditable: bill.isCreditable,
      taxInvoiceNo: bill.taxInvoiceNo,
      customerType: bill.customerType,
      vendorType: bill.vendorType,
      openingInvoice: bill.openingInvoice,
      customField: bill.customField,
      paymentStatus: bill.paymentStatus,
      whtRate: bill.whtRate && bill.whtRate !== null ? bill.whtRate : 0,
      purchaseInvoiceProducts: [],
      purchaseInvoiceAssets: bill.purchaseOrderAssets.map((item: any) => {
        item.fixedAssetGroup = {
          id: item.assetGroupId,
          name: item.assetGroupName
        };
        item.id = null;
        item.documentCode = null;
        item.fulfillmentByDoc = null;
        //revisit
        if (item.taxDetails.length > 0) {
          item.tax = {
            id: item.taxDetails[0].taxId,
            name: item.taxDetails[0].taxName,
            taxCode: item.taxDetails[0].taxCode,
            percent: item.taxDetails[0].taxRate,
            code: item.taxDetails[0].taxSeqCode,
            type: item.taxDetails[0].taxType
              ? item.taxDetails[0].taxType
              : 'BOTH'
          };
        }

        item.productQuantity = item.quantity;
        delete item.assetOrder;
        delete item.assetOrderResponse;
        return {
          ...item,
          unitPriceGstInclusive: bill.unitPriceGstInclusive,
          exchangeRate: bill.exchangeRate,
          documentItemCode: Utility.getValue(
            item.documentItemCode,
            item.purchaseOrderItemCode,
            item.purchaseInvoiceItemCode
          )
        };
      }),
      linkedDocuments: [
        {
          documentCode: bill.documentCode as string,
          documentType: bill.documentType,
          documentSequenceCode: bill.documentSequenceCode as string
        }
      ],
      tdsInfoIndia: bill.tdsInfoIndia,
      tdsProcessedFlag: bill.tdsProcessedFlag
    };
    delete bill.purchaseOrderAssets;

    const {
      contactDto,
      poCode,
      purchaseOrderItems,
      documentSequenceCode,
      ...billToSave
    } = bill;

    let payloadData: any = {
      type: LABELS.FA_BILL,
      title: LABELS.FA_BILL,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: billToSave,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(billToSave)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };
  const convertPartialBill = (selectedOrder: PurchaseOrder) => {
    let bill: any = {
      ...billInitialState,
      ...selectedOrder
    };

    let itemsList;
    if (bill.buyType === BUY_TYPE.EXPENSE) {
      itemsList = Utility.getValue(bill.items, bill.purchaseInvoiceAccounts);
    } else {
      itemsList = Utility.getValue(
        bill.items,
        bill.purchaseInvoiceItems,
        bill.purchaseInvoiceProducts
      );
    }
    itemsList = itemsList ? itemsList : [];
    let workorderLinked = bill.linkedDocuments?.find(
      (ele: any) => ele.documentType === 'WORK_ORDER'
    );
    let securityGateEntryLinkedDoc = bill.linkedDocuments?.filter(
      (ele: any) => ele.documentType === DOC_TYPE.GATE_ENTRY
    );

    bill = {
      ...bill,
      id: null,
      documentType: DOC_TYPE.BILL,
      isPartialBill: true,
      isPartialInvoice: true,
      documentCode: Utility.getValue(
        bill.documentCode,
        bill.purchaseInvoiceCode
      ),
      receivedComplete: bill.receivedComplete || false,
      billDue: Utility.getValue(
        bill.dueDate,
        bill.purchaseInvoiceDueDate,
        bill.validTillDate
      ),
      documentDate: Utility.getValue(
        bill.documentDate,
        bill.purchaseInvoiceDate
      ),
      validTillDate: Utility.getValue(
        bill.validTillDate,
        bill.purchaseInvoiceDueDate
      ),
      fulfillmentDate: Utility.getValue(
        bill.fulfillmentDate,
        bill.receiveByDate
      ),
      receiveByDate: bill.fulfillmentDate,
      purchaseInvoiceDueDate: bill.validTillDate,
      purchaseInvoiceType: bill.buyType,
      supplierInvoiceNo: bill.supplierInvoiceNo,
      purchaseInvoiceDate: bill.documentDate,
      attachments: bill.entityId ? attachments : [],
      attachmentIds: bill.entityId ? attachmentIds : [],
      isConverting: true,
      contact: {
        ...bill.contact,
        ...bill.contactDto,
        address: Utility.getStringAddress(bill.contactDto)
      },
      contactCode: bill.contactDto.code,
      currency: bill.currency,
      receiveGoodsStatus:
        bill.receiptStatus ||
        bill.receiveGoodsStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      buyType: Utility.getValue(bill.buyType, bill.purchaseInvoiceType),
      // status: !bill.id ? DOCUMENT_STATUS.OPEN : bill.status,
      status: DOCUMENT_STATUS.OPEN,
      gstin: bill.gstin,
      dropShip: bill.dropShip ? bill.dropShip : false,
      placeOfSupply: bill.placeOfSupply,
      isCreditable: bill.isCreditable,
      taxInvoiceNo: bill.taxInvoiceNo,
      customerType: bill.customerType,
      vendorType: bill.vendorType,
      openingInvoice: bill.openingInvoice,
      customField: bill.customField,
      paymentStatus: bill.paymentStatus,
      whtRate: bill.whtRate && bill.whtRate !== null ? bill.whtRate : 0,
      poReceiveGoodsStatus: bill.poReceiveGoodsStatus,
      purchaseInvoiceProducts: itemsList?.map((item: any) => {
        return {
          ...item,
          id: null,
          productName: item.product.name, // productName does not in PO detail API response, so adding it
          isPartialBill: true,
          isPartialInvoice: true,
          documentItemCode: null,
          fulfillmentByDoc: null,
          linkedOrderItem: item.id,
          unitPriceGstInclusive: bill.unitPriceGstInclusive,
          exchangeRate: bill.exchangeRate,
          unitPrice:
            bill.buyType === BUY_TYPE.EXPENSE
              ? item.amount || item.unitPrice
              : item.unitPrice,
          taxDetails: item.taxDetails.map((taxDetail: any) => {
            return {
              ...taxDetail,
              id: null,
              additionalTaxIn: null
            };
          })
        };
      }),
      linkedDocuments: [
        {
          documentCode: bill.documentCode as string,
          documentType: bill.documentType,
          documentSequenceCode: bill.documentSequenceCode as string
        }
      ],
      tdsInfoIndia: bill.tdsInfoIndia,
      tdsProcessedFlag: bill.tdsProcessedFlag,
      approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
      multiApprovalDetails: null,
      isConvertFromPOToBill: true
    };
    if (!Utility.isEmpty(workorderLinked)) {
      bill['linkedDocuments'].push({
        documentCode: workorderLinked.documentCode as string,
        documentType: workorderLinked.documentType,
        documentSequenceCode: workorderLinked.documentSequenceCode as string,
        lineItemDetails: workorderLinked.lineItemDetails
      });
    }
    if (!Utility.isEmpty(securityGateEntryLinkedDoc)) {
      securityGateEntryLinkedDoc?.forEach((entry: any) => {
        bill['linkedDocuments'].push({
          ...entry,
          isGateEntryDetailsCopiedFromPO: true
        });
      });
    }

    const {
      contactDto,
      poCode,
      purchaseOrderItems,
      documentSequenceCode,
      ...billToSave
    } = bill;

    let payloadData: any = {
      type: LABELS.BILLS,
      title: LABELS.BILLS,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: billToSave,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(billToSave)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const getDocSchemaAccordingToGR = (poItem: any) => {
    let schema = poItem.documentUOMSchemaDefinition;
    if (
      Utility.isNotEmpty(props?.receivedGoodsDetails) &&
      Utility.isNotEmpty(schema)
    ) {
      const poReceiptGoods = props?.receivedGoodsDetails?.filter(
        (ele: any) => ele.documentType === DOC_TYPE.ORDER
      );
      if (Utility.isNotEmpty(poReceiptGoods)) {
        let items = poReceiptGoods
          ?.flatMap((ele: any) => ele.items)
          ?.filter(
            (item: any) =>
              item.productCode === poItem.productCode &&
              Utility.isNotEmpty(item.documentUOMSchemaDefinition)
          );
        if (Utility.isNotEmpty(items)) {
          let sumOfReceiptQuantity = items.reduce(
            (a: any, b: any) => +a + +parseFloat(b.receiptQuantity),
            0
          );

          let sumOfUomReceiptQuantity = items.reduce(
            (a: any, b: any) => +a + +parseFloat(b.uomReceiptQuantity),
            0
          );

          schema = calculateDocumentUomSchema(
            schema,
            sumOfReceiptQuantity,
            sumOfUomReceiptQuantity
          );
        }
      }
    }
    return {
      schema
    };
  };

  const calculateBaseQtyDiff = (
    unlocalizedSchema: any,
    updatedSchema: any,
    qty: any
  ) => {
    if (
      Utility.isNotEmpty(unlocalizedSchema) &&
      Utility.isNotEmpty(updatedSchema)
    ) {
      const uomQty = Utility.getUomQuantity(qty, unlocalizedSchema);
      const baseQty = Utility.getUomWarehouseQuantity(
        uomQty,
        unlocalizedSchema
      );
      const localizedQty = Utility.getUomWarehouseQuantity(
        uomQty,
        updatedSchema
      );
      return Utility.roundOffToTenantDecimalScale(baseQty - localizedQty) ?? 0;
    }
  };

  const preparePOForConversion = (action: POConversionAction) => {
    let billSequenceFormat: any;
    if (!Utility.isEmpty(billSequenceFormatsInStore)) {
      billSequenceFormat = billSequenceFormatsInStore?.find(
        (seqFormat: any) => seqFormat?.isDefault
      );
      if (!Utility.isEmpty(billSequenceFormat)) {
        billSequenceFormat = billSequenceFormat?.id;
      } else {
        billSequenceFormat = '';
      }
    }
    let itemsPendingToConvert = 0;
    let selectedPO = {
      ...po,
      entityId: po.id ? po.id : undefined,
      documentType: DOC_TYPE.ORDER,
      documentCode: po.poCode,
      vendorCode: Utility.getValue(po.vendorCode, po.contactCode),
      buyType: Utility.getValue(po.buyType, po.orderType),
      orderType: po.buyType,
      status: !po.id ? DOCUMENT_STATUS.OPEN : po.status,
      placeOfSupply: po.placeOfSupply,
      reorder: po.reorder || false,
      dropShip: po.dropShip ? po.dropShip : false,
      backOrder: po.backOrder ? po.backOrder : false,
      linkedPurchaseInvoices: po.linkedPurchaseInvoices,
      orderDueDate: Utility.getValue(po.dueDate, po.validTillDate),
      fulfillmentDate: Utility.getValue(po.fulfillmentDate, po.receiveByDate),
      validTillDate: po.dueDate,
      receiveGoodsStatus:
        po.receiveGoodsStatus ||
        po.receiptStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      poReceiveGoodsStatus:
        po.receiveGoodsStatus ||
        po.receiptStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      currency: Utility.getValue(po.currency, po.currencyCode),
      items: po.purchaseOrderItems
        ?.filter((item) => Utility.getPendingQtyForConversion(item) > 0)
        .map((item) => {
          const { schema } = getDocSchemaAccordingToGR(item);
          const pendingQty = Utility.getPendingQtyForConversion(item);
          if (pendingQty > 0) {
            ++itemsPendingToConvert;
          }
          const qtyToConvert =
            action === POConversionAction.PARTIAL_BILL
              ? Utility.getReceivedQtyForConversion(item)
              : pendingQty;
          const diff = calculateBaseQtyDiff(
            item.unlocalizedDocumentUOMSchemaDefinition ??
              item.documentUOMSchemaDefinition,
            schema,
            qtyToConvert
          );
          return {
            ...item,
            documentItemCode: Utility.getValue(
              item.documentItemCode,
              item.purchaseOrderItemCode,
              item.purchaseInvoiceItemCode
            ),
            pendingQuantity: pendingQty,
            productQuantity: qtyToConvert,
            uomQuantity: Utility.getUomQuantity(
              qtyToConvert,
              item.unlocalizedDocumentUOMSchemaDefinition ??
                item.documentUOMSchemaDefinition
            ),
            unlocalizedDocumentUOMSchemaDefinition:
              item.unlocalizedDocumentUOMSchemaDefinition ??
              item.documentUOMSchemaDefinition,
            isLocalizedUomQty: item.documentUOMSchemaDefinition
              ? !isEqual(schema, item.documentUOMSchemaDefinition)
              : false,
            documentUOMSchemaDefinition: schema,
            localizedBaseQtyDiff: item.documentUOMSchemaDefinition ? diff : 0
          };
        }),
      hasPartialBill:
        po.linkedPurchaseInvoices && po.linkedPurchaseInvoices.length > 0,
      sequenceFormat: billSequenceFormat,
      isDocumentTouched: true
    };

    if (itemsPendingToConvert === 0 && po.orderType !== BUY_TYPE.ASSET) {
      showAlert(
        '',
        'Sorry, all products in this Order have been billed in full. You cannot convert this Order to Bill anymore'
      );
    } else {
      // to set default dates for bill
      selectedPO = {
        ...selectedPO,
        documentDate: '',
        validTillDate: '',
        receiveByDate: '',
        fulfillmentDate: ''
      };
      switch (action) {
        case POConversionAction.CONVERT_AND_RECEIVED:
          selectedPO = {
            ...selectedPO,
            receivedComplete: true,
            fulfillmentDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            receiveByDate: DateFormatService.getDateStrFromDate(
              new Date(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          };
          convertToPartialBill(selectedPO);
          break;
        case POConversionAction.CONVERT_ONLY:
        case POConversionAction.PARTIAL_BILL:
          convertToPartialBill(selectedPO);
          break;
        default:
          break;
      }
    }
  };

  const getFAPOLabel = () => {
    if (
      po &&
      po.buyType !== null &&
      po.buyType !== null &&
      po.buyType === BUY_TYPE.ASSET
    ) {
      return 'Fixed Asset PO';
    }
    return 'Purchase Order';
  };
  const getFABillLabel = () => {
    if (po && po.buyType !== null && po.buyType === BUY_TYPE.ASSET) {
      return 'Fixed Asset Bill';
    }
    return 'Bill';
  };
  const checkIsFa = () => {
    if (po && po.buyType !== null && po.buyType === BUY_TYPE.ASSET) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="flex flex-col w-full px-1 pb-3 text-sm p-1">
        <div className="flex flex-row w-full">
          {`Your ${getFAPOLabel()} has not been received, are you sure you want to
          convert this ${getFAPOLabel()} into ${getFABillLabel()}?`}
        </div>
        {!Utility.isAdvancedTracking(po) && (
          <div className="flex flex-row w-full mt-3">
            {`Alternatively, you can auto-receive this ${getFAPOLabel()} before
            proceeding to convert it into a ${getFABillLabel()}.`}
          </div>
        )}
        {showMultiCurrecyWarn && (
          <div className="flex flex-row w-full mt-3">
            {`Note - Goods valuation will be calculated based on exchange rate of
            ${getFABillLabel()}`}
          </div>
        )}
        <div className="flex flex-col mt-3 w-full">
          {!(po.hasPartialBill || po.linkedPurchaseInvoices?.length) && (
            <>
              {!Utility.isAdvancedTracking(po) &&
                !checkIsFa() &&
                Utility.getDocumentReceivedQty(po.purchaseOrderItems || []) ===
                  0 &&
                !checkIsLocalizedUomQty() && (
                  <div
                    className={
                      'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
                    }
                    onClick={() => {
                      preparePOForConversion(
                        POConversionAction.CONVERT_AND_RECEIVED
                      );
                    }}
                  >
                    <div
                      className="flex rounded-l rounded-bl"
                      style={{ height: 80 }}
                    >
                      <img
                        src={ic_convert_fulfill}
                        alt="receive and convert"
                        style={{ width: 60 }}
                      />
                    </div>
                    <div className="flex flex-col items-start px-4 leading-5 text-left">
                      <span className="fw-m text-blue">
                        Receive and Convert
                      </span>
                      <span className="text-gray">
                        {`Receive this ${getFAPOLabel()} in full before converting in
                      to a Full ${getFABillLabel()}.`}
                      </span>
                    </div>
                  </div>
                )}
              <div
                className={
                  'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
                }
                onClick={() => {
                  preparePOForConversion(POConversionAction.CONVERT_ONLY);
                }}
              >
                <div
                  className="flex rounded-l rounded-bl"
                  style={{ height: 80 }}
                >
                  <img
                    src={ic_convert_only}
                    alt="convert only"
                    style={{ width: 60 }}
                  />
                </div>
                <div className="flex flex-col items-start px-4 leading-5 text-left">
                  <span className="fw-m text-blue">Convert Fully</span>
                  <span className="text-gray">
                    {`Convert this ${getFAPOLabel()} into a Full ${getFABillLabel()}.`}
                  </span>
                </div>
              </div>
            </>
          )}
          {!checkIsFa() && (
            <div
              className={
                'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
              }
              onClick={() => {
                preparePOForConversion(POConversionAction.PARTIAL_BILL);
              }}
            >
              <div className="flex rounded-l rounded-bl" style={{ height: 80 }}>
                <img
                  src={ic_convert_partially}
                  alt="convert partially"
                  style={{ width: 60 }}
                />
              </div>
              <div className="flex flex-col items-start px-4 leading-5 text-left">
                <span className="fw-m text-blue">Convert Partially</span>
                <span className="text-gray">
                  {`Create a Partial ${getFABillLabel()} from this ${getFAPOLabel()}.`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
