import { DKLabel, DKTooltipWrapper, DKIcon, DKIcons } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  GST_TYPE,
  PRODUCT_TYPE,
  TAX_SYSTEM,
  TAX_TYPES
} from '../Constants/Constant';
import { Store } from '../Redux/Store';
import NumberFormatService from '../Services/NumberFormat';
import { ITC_OPTION_SUMMARY } from '../SharedComponents/DocumentForm/ItcOptions';
import {
  getUomQuantity,
  isSalesDocumentShortfallAlertsEnabled
} from '../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from './Utility';
import { isArray } from 'lodash';
import DateFormatService from '../Services/DateFormat';
export class DocumentConfigUtility {
  static documentMode: DOCUMENT_MODE;
  static documentType: DOC_TYPE;
  static bomProductsTotalLeadTimeMap: {
    [key: string]: {
      availableQuantity: number;
      totalLeadTime: number;
      hasShortfall: boolean;
    };
  } = {};
  static productFormatter = ({ value }: any) =>
    !Utility.isEmpty(value)
      ? `${
          value.name
        }<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
          value.hsnOrSacCode ? `HSN/SAC: ${value.hsnOrSacCode}` : ''
        }</span>`
      : '';

  static productFormatterDocument = ({ rowData, value }: any) =>
    !Utility.isEmpty(value)
      ? `${value.name}${
          rowData.optional
            ? `<br><span class="text-gray">(Optional)</span>`
            : ''
        }<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
          Utility.isEmpty(rowData.hsnOrSacCode)
            ? value.hsnOrSacCode
              ? `HSN/SAC: ${value.hsnOrSacCode}`
              : ''
            : rowData.hsnOrSacCode
            ? `HSN/SAC: ${rowData.hsnOrSacCode}`
            : ''
        }</span>`
      : '';
  static unitPriceFormatterDocument = ({ rowData, value }: any) => {
    return `${Utility.getCurrencySymbolFromCode(rowData.symbol)}` + ' ' + value;
  };

  static fixedAssetGroupFormatterDocument = ({ rowData, value }: any) =>
    !Utility.isEmpty(value) ? `${value.name}` : '';

  static getTaxSummaryRow = (
    label: string,
    percentage: number,
    value: number
  ) => {
    let summaryStr = `<div style="font-size: 10px; color:gray; margin-top: 3px;">${label} ${
      percentage ? NumberFormatService.getNumber(percentage) + '%' : ''
    } - ${NumberFormatService.getNumber(value)}</div>`;
    return summaryStr;
  };

  static faTaxRenderer = ({ rowData, value }: any) => {
    const {
      igstRate,
      igstAmount,
      cgstRate,
      cgstAmount,
      sgstRate,
      sgstAmount,
      cessAmount,
      cessRule,
      gstType,
      taxPreference,
      isTaxable,
      isRcmApplied,
      rcmRate,
      taxExemptionReason,
      compositionTaxPercent,
      itcIneligibleType
    } = rowData;

    let htmlBlock = (
      <div>
        {value && <DKLabel text={value.name} />}
        {/* {isTaxable === false && (
          <DKLabel
            className="text-gray fs-s"
            text={`NON-TAXABLE ${
              taxExemptionReason ? ': ' + taxExemptionReason : ''
            }`}
          />
        )} */}
        {/* {
          isRcmApplied !== true && (
            <DKLabel
              className="text-gray fs-s"
              text={`NON-TAXABLE ${
                taxExemptionReason ? ': ' + taxExemptionReason : ''
              }`}
            />
          )} */}
        {value && gstType === GST_TYPE.INTER && (
          <div>
            <DKLabel
              className="text-gray fs-s"
              text={`IGST ${
                igstRate ? NumberFormatService.getNumber(igstRate) + '%' : ''
              } - ${NumberFormatService.getNumber(igstAmount)}`}
            />
          </div>
        )}
        {value && gstType === GST_TYPE.INTRA && (
          <div>
            <DKLabel
              className="text-gray fs-s"
              text={`CGST ${
                cgstRate ? NumberFormatService.getNumber(cgstRate) + '%' : ''
              } - ${NumberFormatService.getNumber(cgstAmount)}`}
            />
            <DKLabel
              className="text-gray fs-s"
              text={`SGST ${
                sgstRate ? NumberFormatService.getNumber(sgstRate) + '%' : ''
              } - ${NumberFormatService.getNumber(sgstAmount)}`}
            />
          </div>
        )}
        {value && cessRule && (
          <div>
            <DKLabel className="text-gray fs-s" text={`CESS - ${cessAmount}`} />
          </div>
        )}
        {value && gstType === GST_TYPE.EXEMPT && (
          <div>
            <DKLabel className="text-gray fs-s" text={`GST - EXEMPT`} />
          </div>
        )}
      </div>
    );

    if (itcIneligibleType) {
      htmlBlock = (
        <>
          {htmlBlock}
          <div>
            <DKLabel
              className="text-gray fs-s"
              text={ITC_OPTION_SUMMARY[itcIneligibleType]}
            />
          </div>
        </>
      );
    }

    // htmlBlock = (<><DKLabel text={'test'}></DKLabel></>)

    return htmlBlock;
  };
  static taxRenderer = ({ rowData, value }: any) => {
    const {
      product,
      igstRate,
      igstAmount,
      cgstRate,
      cgstAmount,
      sgstRate,
      sgstAmount,
      cessAmount,
      cessRule,
      gstType,
      taxPreference,
      isTaxable,
      isRcmApplied,
      rcmRate,
      taxExemptionReason,
      compositionTaxPercent,
      itcIneligibleType,
      taxAmount,
      taxSystem
    } = rowData;

    let htmlBlock = (
      <div>
        {!compositionTaxPercent &&
          isTaxable === true &&
          taxPreference !== false &&
          value && <DKLabel className="fs-m" text={value.name} />}
        {!compositionTaxPercent &&
          isTaxable === true &&
          taxPreference !== false &&
          taxAmount > 0 &&
          value.name !== '' &&
          taxSystem !== TAX_SYSTEM.US &&
          taxSystem !== TAX_SYSTEM.INDIA_GST && (
            <DKLabel
              className="text-gray fs-s"
              text={`Tax Amount-: ${taxAmount}`}
            />
          )}
        {!Utility.isEmpty(product) && isTaxable === false && (
          <DKLabel
            className="text-gray fs-s"
            text={`NON-TAXABLE ${
              taxExemptionReason ? ': ' + taxExemptionReason : ''
            }`}
          />
        )}
        {!Utility.isEmpty(product) &&
          taxPreference === false &&
          isRcmApplied !== true && (
            <DKLabel
              className="text-gray fs-s"
              text={`NON-TAXABLE ${
                taxExemptionReason ? ': ' + taxExemptionReason : ''
              }`}
            />
          )}
        {!Utility.isEmpty(product) &&
          taxPreference !== false &&
          isRcmApplied !== true &&
          value &&
          gstType === GST_TYPE.INTER && (
            <div>
              <DKLabel
                className="text-gray fs-s"
                text={`IGST ${
                  igstRate ? NumberFormatService.getNumber(igstRate) + '%' : ''
                } - ${NumberFormatService.getNumber(igstAmount)}`}
              />
            </div>
          )}
        {!Utility.isEmpty(product) &&
          taxPreference !== false &&
          isRcmApplied !== true &&
          value &&
          gstType === GST_TYPE.INTRA && (
            <div>
              <DKLabel
                className="text-gray fs-s"
                text={`CGST ${
                  cgstRate ? NumberFormatService.getNumber(cgstRate) + '%' : ''
                } - ${NumberFormatService.getNumber(cgstAmount)}`}
              />
              <DKLabel
                className="text-gray fs-s"
                text={`SGST ${
                  sgstRate ? NumberFormatService.getNumber(sgstRate) + '%' : ''
                } - ${NumberFormatService.getNumber(sgstAmount)}`}
              />
            </div>
          )}
        {!Utility.isEmpty(product) &&
          taxPreference !== false &&
          isRcmApplied !== true &&
          !isRcmApplied &&
          value &&
          cessRule && (
            <div>
              <DKLabel
                className="text-gray fs-s"
                text={`CESS - ${cessAmount}`}
              />
            </div>
          )}
        {!Utility.isEmpty(product) &&
          taxPreference !== false &&
          isRcmApplied !== true &&
          value &&
          gstType === GST_TYPE.EXEMPT && (
            <div>
              <DKLabel className="text-gray fs-s" text={`GST - EXEMPT`} />
            </div>
          )}
      </div>
    );

    if (!Utility.isEmpty(product) && isRcmApplied) {
      htmlBlock = (
        <div className="row justify-content-between relative">
          <div>
            <DKLabel text="NA" />
            <DKLabel className="text-gray fs-s" text={`RCM ${rcmRate}%`} />
          </div>
          <DKTooltipWrapper
            content={DocumentConfigUtility.getRCMTootltipContent(rowData)}
            tooltipClassName="bg-deskera-secondary"
          >
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-xs opacity-60 cursor-pointer ml-s"
            />
          </DKTooltipWrapper>
        </div>
      );
    }

    if (!Utility.isEmpty(product) && itcIneligibleType) {
      htmlBlock = (
        <>
          {htmlBlock}
          <div>
            <DKLabel
              className="text-gray fs-s"
              text={ITC_OPTION_SUMMARY[itcIneligibleType]}
            />
          </div>
        </>
      );
    }

    return htmlBlock;
  };

  static discountRenderer = (
    { rowData, value }: any,
    currency: string,
    isGlobalDiscountAppliedBeforeTax: boolean,
    isTotalDiscountColumn: boolean
  ) => {
    let discountText = '';
    const discountInPercent = rowData?.discountInPercent;
    if (isTotalDiscountColumn) {
      discountText = DocumentConfigUtility.amountFormatter(value, currency);
    } else {
      if (discountInPercent) {
        discountText = value + '%';
      } else {
        discountText = DocumentConfigUtility.amountFormatter(value, currency);
      }
    }
    return (
      <div className="column parent-width align-items-end">
        <DKLabel className="fs-m" text={discountText} />
        {isGlobalDiscountAppliedBeforeTax && (
          <div className="row justify-content-end flex-wrap">
            <DKLabel
              className="column text-gray fs-s"
              style={{ whiteSpace: 'nowrap' }}
              text="Global Discount:"
            />
            <DKLabel
              className="column text-gray fs-s ml-xs"
              style={{ whiteSpace: 'nowrap' }}
              text={DocumentConfigUtility.amountFormatter(
                rowData?.preTaxGlobalDiscount,
                currency
              )}
            />
          </div>
        )}
      </div>
    );
  };

  static getRCMTootltipContent = (lineItem: any) => {
    const {
      igstRate,
      igstAmount,
      cgstRate,
      cgstAmount,
      sgstRate,
      sgstAmount,
      cessAmount,
      cessRule,
      rcmRate,
      gstType,
      tax
    } = lineItem;

    if (rcmRate === 0) {
      return;
    }

    let htmlStr = '<div class="column">';
    if (gstType === GST_TYPE.INTER) {
      htmlStr += `<div>IGST ${igstRate}% - ${NumberFormatService.getNumber(
        igstAmount
      )}</div>`;
    }

    if (gstType === GST_TYPE.INTRA) {
      htmlStr += `<div>
      CGST ${cgstRate}% - ${NumberFormatService.getNumber(cgstAmount)}
    </div>
    <div>
      SGST ${sgstRate}% - ${NumberFormatService.getNumber(sgstAmount)}
    </div>`;
    }

    if (gstType !== GST_TYPE.INTER && gstType !== GST_TYPE.INTRA && tax) {
      htmlStr += `<div>GST ${tax.percent}%</div>`;
    }

    if (cessRule) {
      htmlStr += `<div>CESS - ${NumberFormatService.getNumber(
        cessAmount
      )}</div>`;
    }
    htmlStr += '</div>';

    return htmlStr;
  };

  static getStockWarehouseTootltipContent = (lineItem: any) => {
    let htmlStr = '<div class="column">';

    htmlStr += `<div>
    <b>Total Stock</b>: ${Utility.roundOffToTenantDecimalScale(
      lineItem.availableQty
    )}
  </div>
  <div>
  <b>Reserved Stock</b> : ${Utility.roundOffToTenantDecimalScale(
    lineItem.reservedQty
  )}
 </div>
    <div>
     <b>Available Stock</b>: ${Utility.roundOffToTenantDecimalScale(
       lineItem.availableQty - lineItem.reservedQty
     )}
    </div>
   `;

    return htmlStr;
  };

  static rateAnlysisConfig = ({ rowData, value }: any) => {
    return (
      <div
        className="row justify-content-end"
        style={{ cursor: 'not-allowed' }}
      >
        {value}
      </div>
    );
  };

  static rateAnlysisTax = ({ rowData, value }: any) => {
    return (
      <div
        className="row justify-content-end"
        style={{ cursor: 'not-allowed' }}
      >
        {value.name}
      </div>
    );
  };

  static discount = ({ rowData, value }: any) => {
    return (
      <div className="row justify-content-end">
        {value}
        {rowData.discountInPercent ? '%' : ''}
      </div>
    );
  };

  static rateAnlysisConfigProductCode = ({ rowData, value }: any) => {
    return <div style={{ cursor: 'not-allowed' }}>{value}</div>;
  };

  static rateAnlysisConfigProduct = ({ rowData, value }: any) => {
    return (
      <div style={{ cursor: 'not-allowed' }}>
        {!Utility.isEmpty(value) ? value.name : ''}
      </div>
    );
  };

  static checkShortFallAndGetTooltip = (rowData: any, value: any) => {
    let tooltipBlock: JSX.Element | null = null;
    const userEnteredQty = +value || 0;
    const productCode = rowData?.product?.productId;
    if (
      !Utility.isEmpty(productCode) &&
      typeof productCode !== 'undefined' &&
      productCode !== null
    ) {
      const leadTimeInfo =
        DocumentConfigUtility.bomProductsTotalLeadTimeMap?.[productCode];
      if (!Utility.isEmpty(leadTimeInfo)) {
        let availableQty = leadTimeInfo?.availableQuantity || 0;
        if (!Utility.isEmpty(rowData?.documentUOMSchemaDefinition)) {
          availableQty = getUomQuantity(
            availableQty,
            rowData?.documentUOMSchemaDefinition
          );
        }
        const totalLeadTime = leadTimeInfo?.totalLeadTime || 0;
        const productHasShortFall = leadTimeInfo?.hasShortfall;

        if (productHasShortFall) {
          tooltipBlock = (
            <DKTooltipWrapper
              content={`There is a shortfall for one of the items in the bill of material.<br>Max lead time for the items is about ${Utility.roundOffToTenantDecimalScale(
                totalLeadTime
              )} day(s)`}
              tooltipClassName="bg-deskera-secondary width-auto"
              tooltipStyle={{ width: 'auto', whiteSpace: 'normal' }}
            >
              <DKIcon
                src={DKIcons.ic_warning_red}
                className="ic-xs cursor-pointer mt-xs"
              />
            </DKTooltipWrapper>
          );
        }
      }
    }
    return tooltipBlock;
  };

  static quantityRenderer = (
    { rowData, value }: any,
    isSalesDocument?: boolean
  ) => {
    let availableQuantity = rowData?.availableQuantity;
    if (
      typeof availableQuantity === 'undefined' ||
      availableQuantity === null ||
      !Utility.isEmpty(rowData.product?.inventory)
    ) {
      if (rowData.product?.inventory) {
        availableQuantity = rowData.product?.inventory?.availableQuantity;
      } else {
        availableQuantity = rowData.product?.availableQuantity;
      }
    }

    if (rowData.product?.type === PRODUCT_TYPE.NON_TRACKED) {
      availableQuantity = undefined;
    }

    let shortfallTooltip = null;
    if (
      Utility.isMRPWithURLCheck() &&
      isSalesDocumentShortfallAlertsEnabled() &&
      isSalesDocument &&
      rowData.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    ) {
      shortfallTooltip = DocumentConfigUtility.checkShortFallAndGetTooltip(
        rowData,
        value
      );
    }

    return (
      <div
        className={`row align-items-start ${
          DocumentConfigUtility.documentMode === DOCUMENT_MODE.VIEW ||
          typeof availableQuantity === 'undefined' ||
          availableQuantity === null
            ? 'justify-content-end'
            : 'justify-content-between'
        }`}
      >
        <div className="row width-auto">
          {DocumentConfigUtility.documentMode !== DOCUMENT_MODE.VIEW &&
            typeof availableQuantity !== 'undefined' &&
            availableQuantity !== null && (
              <DKTooltipWrapper
                content={DocumentConfigUtility.getQuantityTooltip(
                  rowData,
                  availableQuantity
                )}
                tooltipClassName="bg-deskera-secondary width-auto"
              >
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-xs opacity-60 cursor-pointer mr-xs mt-xs"
                />
              </DKTooltipWrapper>
            )}

          {DocumentConfigUtility.documentMode === DOCUMENT_MODE.VIEW &&
            rowData &&
            (rowData.qtyBeforeToleranceGR || rowData.qtyBeforeToleranceFF) && (
              <DKTooltipWrapper
                content={DocumentConfigUtility.getHistoryTooltip(rowData)}
                tooltipClassName="bg-deskera-secondary"
              >
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-xs opacity-60 cursor-pointer mr-xs mt-xs"
                />
              </DKTooltipWrapper>
            )}
          {shortfallTooltip}
        </div>
        <DKLabel
          className="fs-m parent-width text-trailing"
          text={NumberFormatService.getNumber(value)}
          style={{ textAlign: 'right' }}
        />
      </div>
    );
  };

  static receivedAndBilledQtyRenderer = ({ rowData, value }: any) => {
    return (
      <div className={`row parent-width`}>
        <DKLabel className="fs-m" text={NumberFormatService.getNumber(value)} />
      </div>
    );
  };

  static getQuantityTooltip = (lineItem: any, availableQuantity: any) => {
    const enteredQuantity = lineItem.productQuantity
      ? lineItem.productQuantity
      : 0;

    const documentUOMSchemaDefinition = lineItem.documentUOMSchemaDefinition;
    let availableCount: any = 'NA';
    if (
      typeof availableQuantity !== 'undefined' &&
      availableQuantity !== null
    ) {
      if (documentUOMSchemaDefinition && availableQuantity) {
        availableCount =
          (availableQuantity *
            documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor;
        if (availableCount) {
          availableCount = Utility.roundOff(availableCount);
        }
      } else {
        availableCount =
          typeof availableQuantity !== 'undefined' && availableQuantity !== null
            ? availableQuantity
            : 'NA';
      }
    }

    let htmlStr = '<div>';

    if (
      DocumentConfigUtility.documentType === DOC_TYPE.QUOTE ||
      DocumentConfigUtility.documentType === DOC_TYPE.INVOICE ||
      DocumentConfigUtility.documentType === DOC_TYPE.SALES_ORDER
    ) {
      if (typeof availableCount !== 'undefined' && availableCount !== null) {
        htmlStr += `<div>Total Stock: ${Utility.roundOffToTenantDecimalScale(
          availableCount
        )}</div>`;
      }
      const productDetails = lineItem?.product;
      if (
        (productDetails?.inventory ||
          productDetails?.reservedQuantity !== undefined) &&
        !isNaN(availableCount)
      ) {
        let reservedQuantity = productDetails.inventory
          ? productDetails.inventory.reservedQuantity || 0
          : productDetails.reservedQuantity;

        if (
          documentUOMSchemaDefinition &&
          reservedQuantity &&
          !documentUOMSchemaDefinition.isBaseUom
        ) {
          reservedQuantity = getUomQuantity(
            reservedQuantity,
            documentUOMSchemaDefinition
          );
        }
        htmlStr += `<div>Reserve Stock: ${Utility.roundOffToTenantDecimalScale(
          reservedQuantity
        )}</div>`;
        htmlStr += `<div>Available Stock: ${Utility.roundOffToTenantDecimalScale(
          availableCount - reservedQuantity
        )}</div>`;
      }
    } else {
      if (typeof availableCount !== 'undefined' && availableCount !== null) {
        htmlStr += `<div>Available Qty: ${Utility.roundOffToTenantDecimalScale(
          availableCount
        )}</div>`;
      }
      htmlStr += `<div>Entered Qty: ${Utility.roundOffToTenantDecimalScale(
        enteredQuantity
      )}</div>`;
      if (lineItem.qtyBeforeToleranceGR || lineItem.qtyBeforeToleranceFF) {
        htmlStr += DocumentConfigUtility.getQtyHistory(lineItem);
      }
    }

    htmlStr += '</div>';
    return htmlStr;
  };

  static getQtyHistory = (lineItem: any) => {
    let htmlStr = ``;
    const isQtyHistory = lineItem?.qtyBeforeToleranceGR
      ? [lineItem.qtyBeforeToleranceGR]
      : lineItem?.qtyBeforeToleranceFF
      ? [lineItem.qtyBeforeToleranceFF]
      : [];
    if (isQtyHistory && isQtyHistory.length > 0) {
      htmlStr += `<div><b>Quantity History</b></div>`;
      isQtyHistory.forEach((qty) => {
        htmlStr += `<div>Quantity: ${qty}</div>`;
      });
    }
    return htmlStr;
  };

  static getHistoryTooltip = (lineItem: any) => {
    if (lineItem?.qtyBeforeToleranceGR || lineItem?.qtyBeforeToleranceFF) {
      let htmlStr = '<div>';
      htmlStr += DocumentConfigUtility.getQtyHistory(lineItem);
      htmlStr += '</div>';
      return htmlStr;
    } else {
      return '';
    }
  };

  static unitPriceRenderer = (price: number) => {
    return NumberFormatService.getNumber(price);
  };

  static historicalUnitPriceRenderer = (obj: any) => {
    let date = obj.created_date
      ? DateFormatService.getFormattedDateString(
          obj.created_date,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      : '';
    let contactName = obj.contact_name || '';
    return (
      <>
        <div className="column parent-width">
          <div className="row fw-b">
            {NumberFormatService.getNumber(obj.price)}
          </div>
          <div
            className="row parent-width fs-s fw-b"
            style={{ whiteSpace: 'normal' }}
          >
            <DKTooltipWrapper content={contactName} tooltipClassName="bg-gray0">
              <DKLabel
                text={contactName}
                className="cursor-pointer border-none"
                style={{
                  maxWidth: 130,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </DKTooltipWrapper>
          </div>
          <div className="row parent-width fs-s fw-b">{date}</div>
        </div>
      </>
    );
  };

  static productRenderer = (index: number, product: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{product.name}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          <div className="flex flex-row text-gray">
            {product.documentSequenceCode}
          </div>
          {product.barcode && (
            <div className="flex flex-row justify-items-end">
              <div style={{ marginTop: 1, marginRight: 2 }}>
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M0 1.50006H1.17434V10.5001H0V1.50006ZM1.89308 10.5001H2.55422V1.50006H1.89308V10.5001ZM3.03077 10.5001H3.6906V1.50006H3.03077V10.5001ZM4.55859 10.5001H6.00393V1.50006H4.55859V10.5001ZM7.16125 10.5001H7.88392V1.50006H7.16125V10.5001ZM9.00458 10.5001H12V1.50006H9.00458V10.5001Z"
                    fill="#B5B4B4"
                  ></path>
                </svg>
              </div>
              <div className="text-gray">{product.barcode}</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  static fixedAssetGroupRenderer = (index: number, fixedAssetGroup: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{fixedAssetGroup.name}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          <div className="flex flex-row text-gray">
            {fixedAssetGroup.documentSequenceCode}
          </div>
        </div>
      </div>
    );
  };

  static contactRenderer = (index: number, contact: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{'demo'}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          <div className="flex flex-row text-gray">{'demo'}</div>
          {'demo' && (
            <div className="flex flex-row justify-items-end">
              <div style={{ marginTop: 1, marginRight: 2 }}>
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                  fill="none"
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M0 1.50006H1.17434V10.5001H0V1.50006ZM1.89308 10.5001H2.55422V1.50006H1.89308V10.5001ZM3.03077 10.5001H3.6906V1.50006H3.03077V10.5001ZM4.55859 10.5001H6.00393V1.50006H4.55859V10.5001ZM7.16125 10.5001H7.88392V1.50006H7.16125V10.5001ZM9.00458 10.5001H12V1.50006H9.00458V10.5001Z"
                    fill="#B5B4B4"
                  ></path>
                </svg>
              </div>
              <div className="text-gray">{'demo'}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  static taxOptionRenderer = (index: number, tax: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{tax.name}</div>
      </div>
    );
  };
  static uomOptionRenderer = (index: number, uom: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{uom?.name}</div>
      </div>
    );
  };
  // uom.sourceConversionFactor

  static getBaseUomName(findUOM: any) {
    let uomToFind = findUOM.sourceUOM ? findUOM.sourceUOM : findUOM.id;
    let filtered = Store.getState().commonData.data.uoms.filter(
      (uomobj: any) => uomobj.id === uomToFind
    );
    if (!Utility.isEmpty(filtered)) {
      return filtered[0];
    } else {
      return { name: '' };
    }
  }

  static uomFormatter = (obj: any) => {
    const value = obj.value;
    if (!Utility.isEmpty(value) && obj?.rowData?.productQuantity) {
      return `${
        value.name
      }<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
        value.sourceConversionFactor
          ? `${Utility.roundOff(
              (value.sourceConversionFactor / value.sinkConversionFactor) *
                obj.rowData.productQuantity
            )} ${DocumentConfigUtility.getBaseUomName(value).name}`
          : ''
      }</span>`;
    } else {
      return value.name;
    }
  };
  static productDataParser = (data: any, docType: string) => {
    if (docType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      return data?.content
        ? data?.content?.filter(
            (item: any) => item.type === PRODUCT_TYPE.BILL_OF_MATERIALS
          )
        : [];
    } else {
      return data?.content ? data.content : [];
    }
  };

  static rendererForFilter = (index: number, product: any) => {
    return (
      <div className="column parent-width fs-r">
        <div className="row parent-width">{product.name}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          <div className="flex flex-row text-gray">
            {product.documentSequenceCode}
          </div>
        </div>
      </div>
    );
  };

  static formatterDocumentForFilter = ({ rowData, value }: any) =>
    !Utility.isEmpty(value) ? `${value.name}` : '';

  static fixedAssetGroupDataParser = (data: any, docType: string) => {
    if (docType === DOC_TYPE.FA_BILL || docType === DOC_TYPE.FA_ORDER) {
      //   return data?.content
      //     ? data?.content?.filter(
      //         (item: any) => item.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
      //       )
      //     : [];
      // } else if (docType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      //   return data?.content
      //     ? data?.content?.filter(
      //         (item: any) => item.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      //       )
      //     : [];
      // } else {
      return data?.content ? data.content : [];
    }
  };

  static contactDataParser = (data: any, docType: string) => {
    if (
      docType === DOC_TYPE.ORDER ||
      docType === DOC_TYPE.BILL ||
      docType === DOC_TYPE.REQUISITION
    ) {
      return data?.content
        ? data?.content?.filter(
            (item: any) => item.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
          )
        : [];
    } else {
      return data?.content ? data.content : [];
    }
  };

  static unitePriceDataParser = (data: any, docType: string) => {
    return data;
  };
  static taxDataParser = (data: any, docType: string) => {
    if (
      docType === DOC_TYPE.INVOICE ||
      docType === DOC_TYPE.QUOTE ||
      docType === DOC_TYPE.SALES_ORDER
    ) {
      return data.content.filter(
        (tax: any) =>
          tax.type === TAX_TYPES.SALES || tax.type === TAX_TYPES.BOTH
      );
    } else {
      return data.content.filter(
        (tax: any) =>
          tax.type === TAX_TYPES.PURCHASE || tax.type === TAX_TYPES.BOTH
      );
    }
  };
  static amountFormatter = (amount: number, currency: any) =>
    `${Utility.getCurrencySymbolFromCode(currency)} ${
      amount < 0 ? '(' : ''
    }${NumberFormatService.getNumber(Math.abs(amount))}${
      amount < 0 ? ')' : ''
    }`;

  static rendererForTemplate = (index: number, obj: any) => {
    return (
      <div className="column parent-width fs-r">
        <div className="row parent-width">{obj?.templateName}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          {obj?.wordTemplate ? (
            <div className="flex flex-row text-gray">Word Template</div>
          ) : (
            <div className="flex flex-row text-gray">Document Template</div>
          )}
        </div>
      </div>
    );
  };
}
