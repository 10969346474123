import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS, STORE_FILTER_DATA_TYPES } from '../../Constants/Constant';
import Utility from '../../Utility/Utility';

const initialFilterState: any = {
  config: {},
  SearchTerm: '',
  queries: [],
  filterDates: {}
};

const salesPersonFilterInitialState: any = {
  salesPersonEnable: false,
  salesPersonMapping: false,
  canApplyFilter: false,
  loggedInUserInfo : []
}; 

const initialState = {
  data: {
    contact: initialFilterState,
    product: initialFilterState,
    invoice: initialFilterState,
    salesOrder: initialFilterState,
    bill: initialFilterState,
    purchaseOrder: initialFilterState,
    requisition: initialFilterState,
    rfq: initialFilterState,
    fixedAsset: initialFilterState,
    AssetGroup: initialFilterState,
    spq: initialFilterState,
    quotes: initialFilterState,
    banks: initialFilterState,
    priceList: initialFilterState,
    warehouse: initialFilterState,
    coa: initialFilterState,
    budget: initialFilterState,
    debitNotes: initialFilterState,
    creditNotes: initialFilterState,
    expenses: initialFilterState,
    deposits: initialFilterState,
    journal: initialFilterState,
    ob_accounts: initialFilterState,
    ob_inventory: initialFilterState,
    ob_contacts: initialFilterState,
    financial_year_closing: initialFilterState,
    customFields: initialFilterState,
    workstation: initialFilterState,
    stockAdjustment: initialFilterState,
    jobCard: initialFilterState,
    jobWorkOut: initialFilterState,
    qualityCheck: initialFilterState,
    foreCast: initialFilterState,
    revenueCategory: initialFilterState,
    amortizationTemplates: initialFilterState,
    amortizationSchedules: initialFilterState,
    expenseAllocationTemplates: initialFilterState,
    generateBankFileInvoice: initialFilterState,
    generateBankFileBill: initialFilterState,
    salesPerson: salesPersonFilterInitialState,
    gstr1NilReportSupplyType: '',
    productsByVendor: initialFilterState,
    scheduling: {
      ...initialFilterState,
      dateFilterType: '',
      isCollapseExpended: '',
      statusFilter: ''
    }
  } as any,
  status: API_STATUS.IDLE
};

export const contactFilter = createAsyncThunk(
  'create_contact_filter',
  async (data: any) => {
    let contact = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...contact };
  }
);

export const productFilter = createAsyncThunk(
  'create_product_filter',
  async (data: any) => {
    let product = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...product };
  }
);

export const invoiceFilter = createAsyncThunk(
  'create_invoice_filter',
  async (data: any) => {
    let invoice = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...invoice };
  }
);

export const fulfillmentReportFilter = createAsyncThunk(
  'create_fulfillment_report_filter',
  async (data: any) => {
    let fulfillmentReport = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...fulfillmentReport };
  }
);
// goodsReceivedNotesFilter
export const goodsReceivedNotesFilter = createAsyncThunk(
  'create_goods_received_notes_filter',
  async (data: any) => {
    let goodsReceivedNotes = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...goodsReceivedNotes };
  }
);

export const salesOrderFilter = createAsyncThunk(
  'create_sales_order_filter',
  async (data: any) => {
    let salesOrder = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...salesOrder };
  }
);

export const workStationFilter = createAsyncThunk(
  'create_workstation_filter',
  async (data: any) => {
    let workStation = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...workStation };
  }
);

export const stockAdjustmentFilter = createAsyncThunk(
  'create_stock_adjustment_filter',
  async (data: any) => {
    let stockAdjustment = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...stockAdjustment };
  }
);

export const billFilter = createAsyncThunk(
  'create_bill_filter',
  async (data: any) => {
    let bill = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...bill };
  }
);

export const purchaseOrderFilter = createAsyncThunk(
  'create_purchaseOrder_filter',
  async (data: any) => {
    let purchaseOrder = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...purchaseOrder };
  }
);

export const jobWorkOutFilter = createAsyncThunk(
  'create_jobWorkOut_filter',
  async (data: any) => {
    let jobWorkOut = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...jobWorkOut };
  }
);

export const qualityCheckFilter = createAsyncThunk(
  'create_quality_check_filter',
  async (data: any) => {
    let qualityCheck = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...qualityCheck };
  }
);

export const requisitionFilter = createAsyncThunk(
  'create_requisition_filter',
  async (data: any) => {
    let requisitionOrder = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...requisitionOrder };
  }
);

export const rfqFilter = createAsyncThunk(
  'create_rfq_filter',
  async (data: any) => {
    let requestForQuote = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...requestForQuote };
  }
);

export const spqFilter = createAsyncThunk(
  'create_spq_filter',
  async (data: any) => {
    let supplierQuote = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...supplierQuote };
  }
);

export const fixedAssetFilter = createAsyncThunk(
  'create_fixedAsset_filter',
  async (data: any) => {
    let fixedAsset = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...fixedAsset };
  }
);

export const assetGroupFilter = createAsyncThunk(
  'create_assetGroup_filter',
  async (data: any) => {
    let assetGroup = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...assetGroup };
  }
);

export const quotesFilter = createAsyncThunk(
  'create_quotes_filter',
  async (data: any) => {
    let quotes = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...quotes };
  }
);

export const banksFilter = createAsyncThunk(
  'create_banks_filter',
  async (data: any) => {
    let banks = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...banks };
  }
);

export const priceListFilter = createAsyncThunk(
  'create_priceList_filter',
  async (data: any) => {
    let priceList = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...priceList };
  }
);

export const priceBookListFilter = createAsyncThunk(
  'create_priceBookList_filter',
  async (data: any) => {
    let priceBookList = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...priceBookList };
  }
);

export const warehouseFilter = createAsyncThunk(
  'create_warehouse_filter',
  async (data: any) => {
    let priceList = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...priceList };
  }
);

export const coaFilter = createAsyncThunk(
  'create_coa_filter',
  async (data: any) => {
    let priceList = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...priceList };
  }
);

export const budgetFilter = createAsyncThunk(
  'create_budget_filter',
  async (data: any) => {
    let budget = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...budget };
  }
);

export const customFieldFilter = createAsyncThunk(
  'create_cf_filter',
  async (data: any) => {
    let cfs = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...cfs };
  }
);

export const updateFilter = createAsyncThunk(
  'update_filter',
  async (data: any) => {
    let type = data.type;
    let dataFilter = {
      config: data.config ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...dataFilter, type };
  }
);

export const revenueCategoryFilter = createAsyncThunk(
  'create_rc_filter',
  async (data: any) => {
    let rcs = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...rcs };
  }
);

export const amortizationTemplatesFilter = createAsyncThunk(
  'create_amortization_tmp_filter',
  async (data: any) => {
    let tmps = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...tmps };
  }
);

export const amortizationSchedulesFilter = createAsyncThunk(
  'create_amortization_sch_filter',
  async (data: any) => {
    let schs = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...schs };
  }
);

export const expenseAllocationTemplatesFilter = createAsyncThunk(
  'create_expense_allocation_tmp_filter',
  async (data: any) => {
    let tmps = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : []
    };
    return { ...tmps };
  }
);

export const schedulingFilter = createAsyncThunk(
  'create_scheduling_filter',
  async (data: any) => {
    let scheduling = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {},
      dateFilterType: Utility.isObject(data.dateFilterType)
        ? data.dateFilterType
        : '',
      isCollapseExpended: Utility.isObject(data.isCollapseExpended)
        ? data.isCollapseExpended
        : '',
      statusFilter: Utility.isObject(data.statusFilter) ? data.statusFilter : ''
    };
    return { ...scheduling };
  }
);

export const goodsReceiptMasterFilter = createAsyncThunk(
  'create_goods_receipt_master_filter',
  async (data: any) => {
    let goodsReceiptMaster = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...goodsReceiptMaster };
  }
);

export const goodsReceiptApprovalFilter = createAsyncThunk(
  'create_goods_receipt_tolerance_filter',
  async(data:any) => {
    let goodsReceiptApproval = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...goodsReceiptApproval };
  }
);

export const generateBankFileInvoiceFilter = createAsyncThunk(
  'create_generate_bank_file_invoice_filter',
  async (data: any) => {
    let generateBankFileInvoice = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...generateBankFileInvoice };
  }
);

export const generateBankFileBillFilter = createAsyncThunk(
  'create_generate_bank_file_bill_filter',
  async (data: any) => {
    let generateBankFileBill = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...generateBankFileBill };
  }
);

export const applySalesPersonFilter = createAsyncThunk(
  'create_apply_sales_person_filter',
  async (data: any) => {
    let salesPerson = {
      salesPersonEnable: data?.salesPersonEnable ? data?.salesPersonEnable : false,
      salesPersonMapping: data?.salesPersonEnable ? data?.salesPersonEnable : false,
      canApplyFilter: data?.canApplyFilter ? data?.canApplyFilter : false,
      loggedInUserInfo: data?.loggedInUserInfo ? data?.loggedInUserInfo : []
    };
    return { ...salesPerson };
  }
);

export const gstr1NilReportSupplyType = createAsyncThunk(
  'gstr1_nil_report_supply_type',
  async (supplyType: any) => supplyType
);

export const productsByVendorFilter = createAsyncThunk(
  'create_products_by_vendor_filter',
  async (data: any) => {
    let productsByVendor = {
      config: Utility.isObject(data.config) ? data.config : {},
      SearchTerm: data.SearchTerm ? data.SearchTerm : '',
      queries: data.queries ? data.queries : [],
      filterDates: Utility.isObject(data.filterDates) ? data.filterDates : {}
    };
    return { ...productsByVendor };
  }
);


export const BookFilterSlice = createSlice({
  name: 'bookFilter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(contactFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.contact = { ...action.payload };
      })
      .addCase(customFieldFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(customFieldFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.customFields = { ...action.payload };
      })
      .addCase(productFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(productFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.product = { ...action.payload };
      })
      .addCase(invoiceFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(invoiceFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.invoice = { ...action.payload };
      })
      .addCase(salesOrderFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(salesOrderFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.salesOrder = { ...action.payload };
      })
      .addCase(workStationFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(workStationFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.workstation = { ...action.payload };
      })
      .addCase(billFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(billFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.bill = { ...action.payload };
      })
      .addCase(purchaseOrderFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(purchaseOrderFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.purchaseOrder = { ...action.payload };
      })
      .addCase(requisitionFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(requisitionFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.requisition = { ...action.payload };
      })

      .addCase(rfqFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(rfqFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.rfq = { ...action.payload };
      })
      .addCase(fixedAssetFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fixedAssetFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.fixedAsset = { ...action.payload };
      })
      .addCase(assetGroupFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(assetGroupFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.assetGroup = { ...action.payload };
      })
      .addCase(spqFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(spqFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.spq = { ...action.payload };
      })
      .addCase(quotesFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(quotesFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.quotes = { ...action.payload };
      })
      .addCase(banksFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(banksFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.banks = { ...action.payload };
      })
      .addCase(priceListFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(priceListFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.priceList = { ...action.payload };
      })
      .addCase(priceBookListFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.priceBookList = { ...action.payload };
      })
      .addCase(warehouseFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(warehouseFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.warehouse = { ...action.payload };
      })
      .addCase(coaFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(coaFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.coa = { ...action.payload };
      })
      .addCase(budgetFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(budgetFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.budget = { ...action.payload };
      })
      .addCase(updateFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        switch (action.payload.type) {
          case STORE_FILTER_DATA_TYPES.CONTACT:
            state.data.contact = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.CUSTOM_FIELD:
            state.data.customFields = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.DEBIT_NOTES:
            state.data.debitNotes = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.CREDIT_NOTES:
            state.data.creditNotes = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.EXPENSES:
            state.data.expenses = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.DEPOSITS:
            state.data.deposits = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.JOURNAL:
            state.data.journal = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.OB_ACCOUNT:
            state.data.ob_accounts = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.OB_INVENTORY:
            state.data.ob_inventory = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.OB_CONTACTS:
            state.data.ob_contacts = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.FINANCIAL_CLOSING_YEAR:
            state.data.financial_year_closing = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.STOCK_ADJUSTMENTS:
            state.data.stockAdjustment = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.JOB_CARD:
            state.data.jobCard = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.JOB_WORK_OUT:
            state.data.jobWorkOut = { ...action.payload };
            break;
          case STORE_FILTER_DATA_TYPES.QUALITY_CHECK:
            state.data.qualityCheck = { ...action.payload };
            break;
          default:
            break;
        }
      })
      .addCase(revenueCategoryFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(revenueCategoryFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.revenueCategory = { ...action.payload };
      })
      .addCase(amortizationTemplatesFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(amortizationTemplatesFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.amortizationTemplates = { ...action.payload };
      })
      .addCase(amortizationSchedulesFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(amortizationSchedulesFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.amortizationSchedules = { ...action.payload };
      })
      .addCase(expenseAllocationTemplatesFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(expenseAllocationTemplatesFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.expenseAllocationTemplates = { ...action.payload };
      })
      .addCase(schedulingFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(schedulingFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.scheduling = { ...action.payload };
      })
      .addCase(goodsReceivedNotesFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(goodsReceivedNotesFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.goodsReceivedNotes = { ...action.payload };
      })
      .addCase(goodsReceiptMasterFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(goodsReceiptMasterFilter.fulfilled,( state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.goodsReceiptMasterList = { ...action.payload};
      })
      .addCase(goodsReceiptApprovalFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(goodsReceiptApprovalFilter.fulfilled,( state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.goodsReceiptApprovalList = { ...action.payload};
      })
      .addCase(generateBankFileInvoiceFilter.fulfilled,( state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.generateBankFileInvoice = { ...action.payload };
      })
      .addCase(generateBankFileBillFilter.fulfilled,( state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.generateBankFileBill = { ...action.payload };
      })
      .addCase(applySalesPersonFilter.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(applySalesPersonFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.salesPerson = { ...action.payload };
      })
      .addCase(gstr1NilReportSupplyType.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(gstr1NilReportSupplyType.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.gstr1NilReportSupplyType = action.payload;
      })
      .addCase(productsByVendorFilter.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.productsByVendor = { ...action.payload };
      });
  }
});

export const selectContactFilter = (state: any) =>
  state.bookFilter.data.contact;
export const selectFulfillmentFilter = (state: any) =>
  state.bookFilter.data.report;
export const selectGoodsReceivedNotesFilter = (state: any) =>
  state.bookFilter.data.goodsReceivedNotes;
export const selectProductFilter = (state: any) =>
  state.bookFilter.data.product;
export const selectInvoiceFilter = (state: any) =>
  state.bookFilter.data.invoice;
export const selectSalesOrderFilter = (state: any) =>
  state.bookFilter.data.salesOrder;
export const selectStockAdjustmentFilter = (state: any) =>
  state.bookFilter.data.stockAdjustment;
export const selectBillFilter = (state: any) => state.bookFilter.data.bill;
export const selectPurchaseOrderFilter = (state: any) =>
  state.bookFilter.data.purchaseOrder;
export const selectJobWorkOutFilter = (state: any) =>
  state.bookFilter.data.jobWorkOut;
export const selectForeCastFilter = (state: any) =>
  state.bookFilter.data.foreCast;
export const selectRequisitionFilter = (state: any) =>
  state.bookFilter.data.requisition;
export const selectRfqFilter = (state: any) => state.bookFilter.data.rfq;
export const selectFixedAssetFilter = (state: any) =>
  state.bookFilter.data.fixedAsset;
export const selectAssetGroupFilter = (state: any) =>
  state.bookFilter.data.assetGroup;
export const selectSpqFilter = (state: any) => state.bookFilter.data.spq;
export const selectQuotesFilter = (state: any) => state.bookFilter.data.quotes;
export const selectBanksFilter = (state: any) => state.bookFilter.data.banks;
export const selectPriceListFilter = (state: any) =>
  state.bookFilter.data.priceList;
export const selectPriceBookListFilter = (state: any) =>
  state.bookFilter.data.priceBookList;
export const selectWarehouseFilter = (state: any) =>
  state.bookFilter.data.warehouse;
export const selectCoaFilter = (state: any) => state.bookFilter.data.coa;
export const selectBudgetFilter = (state: any) => state.bookFilter.data.budget;
export const selectDebitNotesFilter = (state: any) =>
  state.bookFilter.data.debitNotes;
export const selectCFFilter = (state: any) =>
  state.bookFilter.data.customFields;
export const selectQualityCheckFilter = (state: any) =>
  state.bookFilter.data.qualityCheck;
export const selectStoreFilters = (state: any) => state.bookFilter.data;
export const selectWorkStationFilter = (state: any) =>
  state.bookFilter.data.workstation;
export const selectRevenueCategoryFilter = (state: any) =>
  state.bookFilter.data.revenueCategory;
export const selectAmortizationTemplatesFilter = (state: any) =>
  state.bookFilter.data.amortizationTemplates;
export const selectAmortizationSchedulesFilter = (state: any) =>
  state.bookFilter.data.amortizationSchedules;
export const selectExpenseAllocationTemplatesFilter = (state: any) =>
  state.bookFilter.data.expenseAllocationTemplates;
export const selectSchedulingFilter = (state: any) =>
  state.bookFilter.data.scheduling;
export const selectGoodsReceiptMasterFilter = (state: any) =>
  state.bookFilter.data.goodsReceiptMasterList;
export const selectGoodsReceiptApprovalFilter = (state:any) => 
  state.bookFilter.data.goodsReceiptApprovalList;
export const selectFulfillmentMasterFilter = (state: any) => 
  state.bookFilter.data.fulfillmentMasterList;
export const selectFulfillmentApprovalFilter = (state: any) => 
  state.bookFilter.data.fulfillmentApprovalList
export const selectGenerateBankFileInvoiceFilter = (state: any) => 
  state.bookFilter.data.generateBankFileInvoice
export const selectGenerateBankFileBillFilter = (state: any) => 
  state.bookFilter.data.generateBankFileBill
export const selectApplySalesPersonFilter = (state: any) =>
  state.bookFilter.data.salesPerson;
export const selectGstr1NilSupplyType = (state: any) =>
  state.bookFilter.data.gstr1NilReportSupplyType;
export const selectProductsByVendorFilter = (state: any) =>
  state.bookFilter.data.productsByVendor;
