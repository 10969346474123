import {
  DKButton,
  DKDataGrid,
  DKLabel,
  DKLine,
  DKSegmentControl,
  showAlert,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useMemo, useState } from 'react';
import {
  DOC_TYPE,
  MODULE_TYPE,
  ROW_RACK_BIN_CONSTANT
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { selectSerialTrackingProduct } from '../../Redux/Slices/SerialTrackingSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  sortSerialNumbers
} from '../../Utility/Utility';

import { cloneDeep } from 'lodash';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { DynamicPopupWrapper } from '../PopupWrapper';
import WarehouseManagementHelper from '../WarehouseManagement/WarehouseManagementHelper';
import {
  getRowRackBinData,
  SERIAL_TRACK_COLUMNS
} from './AdvancedTrackConstants';

export interface ISerialTrackedAssignment {
  itemDetails?: any;
  isMRP?: boolean;
  module?: keyof typeof MODULE_TYPE;
  defaultProductWarehouse?: any;
  onSerialSave: (
    data: any,
    isQuickCommit: boolean,
    quantityToFulfill: any
  ) => void;
  onClose: () => void;
  parentDocumentType?: DOC_TYPE;
  parentDocumentDetails?: any;
  mrpTitle?: string;
  filterBatchData?: boolean;
  selectedItem?: any;
  filteredWarehouseInventoryData?: any;
  buildQuantity?: number;
  disableBtn?: boolean;
  state?: string;
  targetWarehouse?: any;
  taggedWarehouse?: any;
  existingWarehouseInventoryData?: any[];
  isFromStockIssue?: boolean;
  serialMultipleWarehouseData?: any;
  documentType?: any;
}

const updateColumns = (tenantInfo: any, customFields: any[]) => {
  let columns = cloneDeep(SERIAL_TRACK_COLUMNS);
  const rrbData = getRowRackBinData(
    tenantInfo?.additionalSettings?.ROW_RACK_BIN
  );

  columns.forEach((column: any) => {
    switch (column.id) {
      case 'warehouseCode':
        column['renderer'] = (data: any) => {
          const { rowData } = data;
          return (
            <div>
              {rowData?.warehouseName}
              <div>({rowData?.warehouseCode ?? '-'})</div>
            </div>
          );
        };
        break;
      case 'manufacturingDate':
      case 'expiryDate':
        column['renderer'] = (data: any) => {
          const { value } = data;
          return <div>{value ?? '-'}</div>;
        };
        break;
      case 'rowCode':
        column['name'] = rrbData[ROW_RACK_BIN_CONSTANT.ROW]?.label;
        column['renderer'] = (data: any) => {
          const { rowData } = data;
          return <div>{rowData['rowName'] ?? '-'}</div>;
        };
        break;
      case 'rackCode':
        column['name'] = rrbData[ROW_RACK_BIN_CONSTANT.RACK]?.label;
        column['renderer'] = (data: any) => {
          const { rowData } = data;
          return <div>{rowData['rackName'] ?? '-'}</div>;
        };
        break;
      case 'binCode':
        column['name'] = rrbData[ROW_RACK_BIN_CONSTANT.BIN]?.label;
        column['renderer'] = (data: any) => {
          const { rowData } = data;
          return <div>{rowData['binName'] ?? '-'}</div>;
        };
        break;
      default:
        break;
    }
  });
  let columnsToFilter: string[] = [];
  if (!Utility.isRRBEnabled()) {
    columnsToFilter = ['rowCode', 'rackCode', 'binCode'];
  } else {
    const enabledKeys = Object.keys(rrbData);
    if (!enabledKeys.includes(ROW_RACK_BIN_CONSTANT.ROW)) {
      columnsToFilter.push('rowCode');
    }
    if (!enabledKeys.includes(ROW_RACK_BIN_CONSTANT.RACK)) {
      columnsToFilter.push('rackCode');
    }
    if (!enabledKeys.includes(ROW_RACK_BIN_CONSTANT.BIN)) {
      columnsToFilter.push('binCode');
    }
  }
  columns = columns.filter((column) => !columnsToFilter.includes(column.id));
  customFields?.forEach((cf: any) => {
    columns.push({
      id: cf.id,
      key: cf.code,
      name: cf.label,
      type: cf?.fieldType?.toLowerCase(),
      width: 200,
      systemField: false,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true
    });
  });
  return columns;
};

const filterSerialData = (serialNumbers: any[], { search }: any): any[] => {
  search = search?.toLowerCase();
  return serialNumbers?.filter(
    (serial: any) =>
      serial?.serialBatchNumber?.toLowerCase()?.includes(search) ||
      serial?.warehouseName?.toLowerCase()?.includes(search)
  );
};

const filterWarehouseInventoryData = (
  allWarehouseData: any[],
  warehouseInventoryData: any[]
) => {
  let filteredWHData: any[] = [];
  allWarehouseData?.forEach((localWH: any) => {
    let sameWHAdvancedTrackData: any = [];
    warehouseInventoryData?.forEach((whData: any) => {
      if (whData.warehouseCode === localWH.code) {
        localWH?.advancedTrackingMeta?.forEach((x: any) => {
          const found = whData?.advancedTrackingData?.find((y: any) => {
            return x.serialBatchNumber === y.serialBatchNumber;
          });
          if (!Utility.isEmpty(found)) {
            sameWHAdvancedTrackData.push(x);
          }
        });
      }
    });
    const isWarehousePresent = warehouseInventoryData?.some(
      (item: any) => item.warehouseCode === localWH.code
    );
    if (isWarehousePresent) {
      localWH = {
        ...localWH,
        advancedTrackingMeta: sameWHAdvancedTrackData
      };
      filteredWHData.push(localWH);
    }
  });
  const arrayUniqueByKey = filteredWHData.filter(
    (a, i) => filteredWHData.findIndex((s: any) => a.code === s.code) === i
  );
  return arrayUniqueByKey;
};
export default function SerialTrackedAssignment(
  props: ISerialTrackedAssignment
) {
  const currentProductTrackingData = useAppSelector(
    selectSerialTrackingProduct
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [availableSerialData, setAvailableSerialData] = useState<any[]>([]);

  const item = props.itemDetails;
  const requiredQuantity = item?.productQuantity;

  const tenantInfo = useAppSelector(activeTenantInfo);
  // const [productDetails, setProductDetails] = useState<any>();

  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const columns = useMemo<any[]>(
    () => updateColumns(tenantInfo, batchSerialCFfromStore?.content),
    [tenantInfo, batchSerialCFfromStore]
  );
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const {
    targetWarehouse,
    taggedWarehouse,
    filterBatchData,
    isMRP,
    filteredWarehouseInventoryData
  } = props;

  const selectedRows =
    availableSerialData?.filter((serial) => !!serial.selected) ?? [];

  // const fetchAndSetProductDetails = useCallback(async () => {
  //   try {
  //     const response = await ProductService.getProductsByProductIds([
  //       props.itemDetails?.productCode
  //     ]);
  //     if (Utility.isNotEmpty(response?.[0])) {
  //       setProductDetails(response?.[0]);
  //     }
  //   } catch (error) {}
  // }, [props.itemDetails?.productCode]);

  useEffect(() => {
    // fetchAndSetProductDetails();
  }, []);

  useEffect(() => {
    let allWarehouseData: any[] = [];

    const filterFn = (ele: any) =>
      ele.rowCode == taggedWarehouse?.rowCode &&
      ele.rackCode == taggedWarehouse?.rackCode &&
      ele.binCode == taggedWarehouse?.binCode;

    if (Utility.isNotEmpty(taggedWarehouse)) {
      allWarehouseData = currentProductTrackingData.filter((item: any) => {
        return item.code === taggedWarehouse?.code;
      });
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(allWarehouseData)
      ) {
        const [firstWarehouseData] = allWarehouseData;
        const filteredAdvancedTrackData =
          firstWarehouseData?.advancedTrackingMeta?.filter(filterFn);
        const taggedRRBDetails =
          firstWarehouseData?.rowRackBinProductAvailableQuantityDtos?.filter(
            filterFn
          );
        allWarehouseData = [
          {
            ...firstWarehouseData,
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else if (Utility.isNotEmpty(targetWarehouse)) {
      allWarehouseData = currentProductTrackingData.filter((item: any) => {
        return WarehouseManagementHelper.isRRBEnabledForWarehouse(item)
          ? item
          : item.code !== targetWarehouse;
      });
    } else {
      allWarehouseData = currentProductTrackingData;
    }
    if (Utility.isNotEmpty(allWarehouseData)) {
      if (isMRP && filterBatchData) {
        const { warehouseInventoryData } = filteredWarehouseInventoryData;
        allWarehouseData = filterWarehouseInventoryData(
          allWarehouseData,
          warehouseInventoryData
        );
      }
      setLocalWarehouse(allWarehouseData);
    }
  }, [
    currentProductTrackingData,
    taggedWarehouse,
    targetWarehouse,
    filterBatchData,
    filteredWarehouseInventoryData,
    isMRP
  ]);

  useEffect(() => {
    let serialData: any[] = [];
    const { advancedTrackingFulfilmentData = [], reservedQuantitiesData = [] } =
      item;
    if (Utility.isNotEmpty(localWarehouse)) {
      const trackingDataFromAllWH = localWarehouse.reduce(
        (acc: any[], inventoryWarehouse: any) => {
          acc.push(
            ...inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
              return { ...item, warehouseName: inventoryWarehouse?.name };
            })
          );
          return acc;
        },
        []
      );

      serialData = getAvailableStockData(trackingDataFromAllWH);

      if (Utility.isEmptyObject(serialData)) {
        if (isMRP && filterBatchData) {
          serialData = trackingDataFromAllWH;
        } else {
          serialData = advancedTrackingFulfilmentData?.map((ele: any) => ({
            ...ele,
            selected: true
          }));
        }
      }
      if (Utility.isNotEmpty(serialData)) {
        let allocatedSerialNumbers: string[] = [];
        if (Utility.isNotEmpty(advancedTrackingFulfilmentData)) {
          allocatedSerialNumbers = advancedTrackingFulfilmentData?.map(
            (serialData: any) => serialData?.serialBatchNumber
          );
        } else {
          if (Utility.isNotEmpty(reservedQuantitiesData)) {
            allocatedSerialNumbers = reservedQuantitiesData
              .flatMap((ele: any) => ele.advancedTrackingMetaDtos)
              .filter(
                (ele: any) => ele.reservedQuantityFulfilled < ele.batchSize
              )
              .map((serialData: any) => serialData?.serialBatchNumber);
          }
        }
        serialData?.forEach((trackingInfo: any) => {
          if (allocatedSerialNumbers.includes(trackingInfo.serialBatchNumber)) {
            trackingInfo.selected = true;
          }
        });
      }

      if (Utility.isNotEmpty(serialData)) {
        serialData = serialData.map((data) => {
          if (Utility.isNotEmpty(data?.customField)) {
            data?.customField.forEach((item: any) => {
              data[item.code] = item.value;
            });
          }
          return data;
        });

        setAvailableSerialData(sortSerialNumbers(serialData));
      }
    }
  }, [localWarehouse]);

  const getRequireQuantity = () => {
    return props.itemDetails.documentUOMSchemaDefinition
      ? props.itemDetails.isLocalizedUomQty
        ? props.itemDetails.uomLocalizedquantityRequired
        : Utility.getUomWarehouseQuantityWithoutRoundOff(
            item?.productQuantity
              ? item?.productQuantity
              : props.itemDetails.productQuantity,
            props.itemDetails.documentUOMSchemaDefinition
          )
      : item?.productQuantity
      ? item?.productQuantity
      : props.itemDetails.productQuantity;
  };

  const onSave = () => {
    if (props.onSerialSave) {
      const serialTrackingData: any[] = selectedRows.map((item, i) => {
        let mappedObject: any = {
          qtyToFulfil: 1,
          customField: item?.customField ?? [],
          batchSize: item?.batchSize,
          costPerUnit: Utility.roundOffToTenantDecimalScale(
            Number(item?.acquiredCost) / Number(item?.batchSize)
          ),
          serialBatchNumber: item.serialBatchNumber,
          warehouseCode: item.warehouseCode,
          warehouseName: item.warehouseName,
          destinationWarehouseCode: item?.destinationWarehouseCode,
          destinationWarehouseName: item?.destinationWarehouseName,
          rowCode: item?.rowCode ?? null,
          rowName: item?.rowName ?? null,
          rackCode: item?.rackCode ?? null,
          rackName: item?.rackName ?? null,
          binCode: item?.binCode ?? null,
          binName: item?.binName ?? null
        };
        if (props?.itemDetails?.isLocalizedUomQty) {
          mappedObject['localizedBatchSize'] = 1;
        }
        return {
          ...mappedObject,
          advancedTrackingData: [{ ...mappedObject }]
        };
      });

      if (!Number.isInteger(requiredQuantity)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as required quantity in decimal.'
        );
        return;
      }
      if (props.itemDetails.documentUOMSchemaDefinition) {
        const uomQty = Utility.getUomQuantityWithoutRoundOff(
          serialTrackingData.length,
          props.itemDetails.documentUOMSchemaDefinition
        );
        if (!Number.isInteger(uomQty)) {
          showAlert(
            'Error',
            'Fulfillment is not allowed for this product as uom conversion in decimal.'
          );
          return;
        }
      }
      const reqQty = props.itemDetails.documentUOMSchemaDefinition
        ? props.itemDetails.isLocalizedUomQty
          ? props.itemDetails.uomLocalizedquantityRequired
          : Utility.getUomWarehouseQuantity(
              item?.productQuantity,
              props.itemDetails.documentUOMSchemaDefinition
            )
        : requiredQuantity;
      if (
        !Utility.isSellsToleranceSettingsEnabled(
          props.parentDocumentType,
          props.parentDocumentDetails
        ) &&
        serialTrackingData.length > reqQty
      ) {
        const { isInvoiceLinked, isQuotationLinked, isReservedQuantity } =
          Utility.getSellsLinkedDocument(
            props.parentDocumentType,
            props.parentDocumentDetails
          );
        let errorMessage =
          'Quantity should not be more than required (' + reqQty + ')';
        if (isInvoiceLinked || isQuotationLinked) {
          errorMessage = `Quantity used cannot be more than required quantity as the Invoice/${
            Utility.isIndiaOrganisation() ? 'Quotation' : 'Estimates'
          } is already generated for this document.`;
        } else if (isReservedQuantity) {
          errorMessage =
            'Quantity used cannot be more than required quantity in case of reserved quantity.';
        }
        showToast(errorMessage, TOAST_TYPE.FAILURE);
        return;
      } else if (hasErrorTracking()) {
        showToast('Duplicate Serial Number is not allowed', TOAST_TYPE.FAILURE);
        return;
      }
      let updatedLineItems = serialTrackingData.map((item: any) => ({
        ...item, // Spread existing properties of each item
        id: props?.itemDetails?.id, // Add or overwrite the 'id' property
        productCode: props.itemDetails.productCode
      }));

      props.onSerialSave(updatedLineItems, false, serialTrackingData.length);
    }
  };

  const allocateAutomatically = () => {
    let warehouseData = localWarehouse;
    if (props?.isMRP && props?.filterBatchData) {
      warehouseData = filterWarehouseInventoryData(
        warehouseData,
        filteredWarehouseInventoryData?.warehouseInventoryData
      );
    }
    let productAvailableQuantity = warehouseData.reduce(
      (prev: any[], current: any) => {
        let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
          return {
            ...item,
            warehouseName: current.name
          };
        });
        return [...prev, ...advTracking];
      },
      []
    );
    const { existingWarehouseInventoryData = [] } = props;
    let serialData;
    if (props?.isMRP && props?.filterBatchData) {
      serialData = productAvailableQuantity;
    } else {
      serialData = getAvailableStockData(productAvailableQuantity);
    }

    let serialTrackingData = serialData.map((item, i) => {
      let object: any = {
        qtyToFulfil: 1,
        batchSize: item?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.acquiredCost) / Number(item?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber,
        warehouseCode: item.warehouseCode,
        warehouseName: item.warehouseName,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName,
        rowCode: item?.rowCode ?? null,
        rowName: item?.rowName ?? null,
        rackCode: item?.rackCode ?? null,
        rackName: item?.rackName ?? null,
        binCode: item?.binCode ?? null,
        binName: item?.binName ?? null,
        customField: item?.customField
      };
      if (props?.itemDetails?.isLocalizedUomQty) {
        object['localizedBatchSize'] = 1;
      }
      return {
        ...object,
        advancedTrackingData: [{ ...object }]
      };
    });
    let requiredQuantityValue = getRequireQuantity();
    serialTrackingData = serialTrackingData.slice(
      0,
      requiredQuantityValue - existingWarehouseInventoryData?.length
    );
    const mappedSerialTrackedData = existingWarehouseInventoryData.map(
      (item: any) => ({
        ...item,
        qtyToFulfil: item?.['advancedTrackingData']?.[0]?.qtyToFulfil,
        batchSize: item?.['advancedTrackingData']?.[0]?.batchSize,
        serialBatchNumber:
          item?.['advancedTrackingData']?.[0]?.serialBatchNumber
      })
    );
    serialTrackingData.push(...deepClone(mappedSerialTrackedData));
    if (props.itemDetails.documentUOMSchemaDefinition) {
      const uomQty = Utility.getUomQuantityWithoutRoundOff(
        serialTrackingData.length,
        props.itemDetails.documentUOMSchemaDefinition
      );
      if (!Number.isInteger(uomQty)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as uom conversion in decimal.'
        );
        return;
      }
    }
    let updatedLineItems = serialTrackingData.map((item: any) => ({
      ...item, // Spread existing properties of each item
      id: props?.itemDetails?.id, // Add or overwrite the 'id' property
      productCode: props.itemDetails.productCode
    }));

    props.onSerialSave(updatedLineItems, false, serialTrackingData.length);
  };

  const hasErrorTracking = () => {
    return item?.advancedTrackingMetaData?.some(
      (item: any) => item?.invalidFields?.length
    );
  };

  const getHeader = () => {
    const enableSaveBtn =
      !props?.disableBtn && Utility.isNotEmpty(selectedRows);
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={`Allocate Serial-Tracked Products (${item?.product?.name})`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title={'Save'}
            disabled={!enableSaveBtn}
            className={`text-white ${
              enableSaveBtn ? 'bg-button' : 'bg-gray-300'
            }`}
            onClick={() => {
              enableSaveBtn && onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getTitleValue = (title: string, value: any, uom = '') => {
    return !uom ? (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m" />
        <DKLabel text={value || '-'} className="" />
      </div>
    ) : (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m " />
        <div className="row align-items-end gap-1 ">
          <DKLabel text={value >= 0 ? value : '-'} className="" />
          <DKLabel
            style={{
              height: 15
            }}
            text={uom}
            className="fs-s fw-m text-gray "
          />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    return (
      <div className="row justify-content-between gap-2">
        {getTitleValue(
          `${props?.mrpTitle ? 'Material' : 'Product'} Name`,
          item?.product?.name
        )}
        {getTitleValue(
          `${props?.mrpTitle ? 'Material' : 'Product'} Code`,
          item?.documentSequenceCode
        )}
        {getTitleValue(
          'Required Quantity',
          item?.productQuantity
            ? item?.productQuantity
            : props.itemDetails.productQuantity,
          props.itemDetails.documentUOMSchemaDefinition
            ? props.itemDetails.documentUOMSchemaDefinition.name
            : Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
        )}
        {props.itemDetails.documentUOMSchemaDefinition &&
          getTitleValue(
            'Required Quantity In Base UOM',
            props.itemDetails.documentUOMSchemaDefinition
              ? props.itemDetails.isLocalizedUomQty
                ? props.itemDetails.uomLocalizedquantityRequired
                : Utility.getUomWarehouseQuantity(
                    item?.productQuantity,
                    props.itemDetails.documentUOMSchemaDefinition
                  )
              : item?.productQuantity,
            Utility.getUOMForStockUOMId(
              props.itemDetails.documentUOMSchemaDefinition
                ? props.itemDetails.documentUOMSchemaDefinition.sourceUOM
                : item?.product?.stockUom
            )?.name
          )}
        {getTitleValue(
          'Quantity Allocated',
          props.itemDetails.documentUOMSchemaDefinition
            ? Utility.getUomQuantityWithoutRoundOff(
                selectedRows.length,
                props.itemDetails.documentUOMSchemaDefinition
              )
            : selectedRows.length,
          props.itemDetails.documentUOMSchemaDefinition
            ? props.itemDetails.documentUOMSchemaDefinition.name
            : Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
        )}
      </div>
    );
  };

  const getGrid = () => {
    const onRowSelect = ({ rowData }: any) => {
      const copyOfSerialData = cloneDeep(availableSerialData);
      const indexOfSelected = copyOfSerialData.findIndex(
        (data) => data.serialBatchNumber === rowData.serialBatchNumber
      );
      copyOfSerialData[indexOfSelected] = rowData;
      setAvailableSerialData(copyOfSerialData);
    };

    const onAllRowSelected = ({ selected }: any) => {
      const copyOfSerialData = cloneDeep(availableSerialData);
      copyOfSerialData.forEach((item) => {
        item.selected = selected;
      });
      setAvailableSerialData(copyOfSerialData);
    };

    return (
      <>
        <div className="row justify-content-between">
          <div style={{ width: 200 }}>
            <DKSegmentControl
              segments={['Available', `Selected (${selectedRows.length})`]}
              backgroundColor="bg-gray2"
              selectedColor=""
              barColor="bg-white"
              selectedIndex={selectedViewIndex}
              onSelect={(index: number) => {
                setSelectedViewIndex(index);
              }}
            />
          </div>
          <DKButton
            title="Auto Allocate"
            className="text-app-color underline"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => {
              allocateAutomatically();
            }}
          />
        </div>
        <div className="parent-width flex-1 column overflow-auto hide-scrollbar">
          <DKDataGrid
            isAllRowSelected={
              Utility.isNotEmpty(selectedRows) &&
              availableSerialData.length === selectedRows.length
            }
            onAllRowSelect={onAllRowSelected}
            needShadow={true}
            needBorder={true}
            needColumnIcons={false}
            needTrailingColumn={true}
            allowBulkOperation={true}
            allowColumnSort={false}
            allowColumnAdd={false}
            allowColumnEdit={false}
            allowSearch={true}
            onSearch={Utility.debouncer(
              (searchTerm) => setSearch(searchTerm),
              1000
            )}
            searchBarConfig={{ searchText: search }}
            allowRowEdit={false}
            onRowUpdate={() => {}}
            onRowClick={() => {}}
            currentPage={1}
            totalPageCount={1}
            title={''}
            columns={columns}
            rows={filterSerialData(
              selectedViewIndex === 0 ? availableSerialData : selectedRows,
              { search }
            )}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo?.dateFormat?.toUpperCase()
            )}
            onRowSelect={onRowSelect}
          />
        </div>
      </>
    );
  };

  const getAvailableStockData = (advancedTrackingData: any[]) => {
    if (props.serialMultipleWarehouseData) {
      let sameId = props?.serialMultipleWarehouseData?.some((data: any) => {
        return data.id === props.itemDetails.id;
      });

      if (!sameId) {
        let filteredAdvancedTrackingData = (
          advancedTrackingData: any[]
        ): any[] => {
          return advancedTrackingData?.filter((trackItem: any) => {
            // Check if the serialBatchNumber exists in props?.serialMultipleWarehouseData
            return !props?.serialMultipleWarehouseData?.some(
              (warehouseItem: any) =>
                warehouseItem?.serialBatchNumber ===
                trackItem?.serialBatchNumber
            );
          });
        };

        advancedTrackingData =
          filteredAdvancedTrackingData(advancedTrackingData);
      } else {
        let filteredAdvancedTrackingData = (
          advancedTrackingData: any[]
        ): any[] => {
          // Filter out items from advancedTrackingData whose serialBatchNumber already exists in props.serialMultipleWarehouseData
          let unusedData =
            advancedTrackingData?.filter((trackItem: any) => {
              return !props?.serialMultipleWarehouseData?.some(
                (warehouseItem: any) =>
                  warehouseItem?.serialBatchNumber ===
                  trackItem?.serialBatchNumber
              );
            }) || [];

          // Retain items from advancedTrackingData where serialBatchNumber exists in props.serialMultipleWarehouseData
          let retainedData =
            advancedTrackingData?.filter((trackItem: any) => {
              return props?.serialMultipleWarehouseData?.some(
                (warehouseItem: any) =>
                  warehouseItem?.serialBatchNumber ===
                    trackItem?.serialBatchNumber &&
                  warehouseItem.id === props?.itemDetails?.id
              );
            }) || [];

          // Concatenate the unused data with the retained data
          return [...unusedData, ...retainedData];
        };

        advancedTrackingData =
          filteredAdvancedTrackingData(advancedTrackingData);
      }
    }

    if (
      props?.isMRP ||
      Utility.isEmpty(props?.itemDetails?.reservedQuantitiesData)
    ) {
      return advancedTrackingData?.filter(
        (serial: any) =>
          serial.batchSizeFulfilled < serial.batchSize &&
          serial.reservedQuantity < serial.batchSize
      );
    } else {
      // Not MRP flow and doc has reserved quantities
      return advancedTrackingData?.filter((serial: any) => {
        const currentProdQty = serial?.batchSize ?? 0;
        const prodReservedQtyInWH = serial?.reservedQuantity ?? 0;

        const reservedQtyAdvTrackingData =
          props?.itemDetails?.reservedQuantitiesData
            ?.filter(
              (data: any) =>
                data.productCode === props?.itemDetails?.productCode
            )
            ?.flatMap((data: any) => data.advancedTrackingMetaDtos);

        const prodReservedQtyInfoInDoc = reservedQtyAdvTrackingData?.find(
          (resData: any) =>
            resData?.warehouseCode == serial?.warehouseCode &&
            resData?.serialBatchNumber == serial?.serialBatchNumber
        );

        let prodReservedQtyInDoc = 0;
        if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
          prodReservedQtyInDoc =
            prodReservedQtyInfoInDoc?.reservedQuantity ?? 0;
        }

        const availableBatchSize =
          currentProdQty - prodReservedQtyInWH + prodReservedQtyInDoc;

        return (
          serial.batchSizeFulfilled < serial.batchSize &&
          availableBatchSize > 0 &&
          availableBatchSize <= serial.batchSize
        );
      });
    }
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 900,
            width: '90%',
            maxHeight: '95%',
            height: 565,
            padding: 0,
            paddingBottom: 60,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-4 gap-2">
            {getHeaderSection()}
            <DKLine style={{ height: 2 }} />
            {getGrid()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
