import { addDays, isBefore } from 'date-fns';
import {
  DKButton,
  DKDataGrid,
  DKInput,
  DKLabel,
  DKLine,
  DKListPicker2,
  DKSpinner,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert,
  removeLoader,
  showLoader,
  DKIcon,
  DKIcons
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../../Constants/ApiConstants';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_NUMBER_INPUT_MODULES,
  DATE_FORMAT,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  INPUT_VIEW_DIRECTION,
  MRP,
  TIME_TYPES,
  TIME_TYPES_CONSTANT,
  TRANSITION_STATUS
} from '../../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { fetchJobCardsList } from '../../../../Redux/Slices/MRP/JobCardSlice';
import { selectMRPProducts } from '../../../../Redux/Slices/MRP/MRPProductSlice';
import {
  fetchOperationsList,
  selectOperations
} from '../../../../Redux/Slices/MRP/OperationSlice';
import { selectOperators } from '../../../../Redux/Slices/MRP/OperatorSlice';
import {
  fetchWorkOrderList,
  selectWorkOrder
} from '../../../../Redux/Slices/MRP/WorkOrderSlice';
import { selectWorkstations } from '../../../../Redux/Slices/MRP/WorkstationSlice';
import { selectWarehouse } from '../../../../Redux/Slices/WarehouseSlice';
import AttachmentService from '../../../../Services/Attachment';
import DateFormatService from '../../../../Services/DateFormat';
import JobCardService from '../../../../Services/MRP/JobCard';

import { DATE_COMPARISON } from '../../../../Constants/Enum';
import OperationsService, {
  OperationsAPIConfig
} from '../../../../Services/MRP/Operations';
import WorkOrderService, {
  WorkOrderAPIConfig,
  parseDateFromStringForWorkOrder
} from '../../../../Services/MRP/WorkOrder';
import WorkstationsService, {
  WorkstationsAPIConfig
} from '../../../../Services/MRP/Workstations';
import ProductService from '../../../../Services/Product';
import CustomNumberFormatInput from '../../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { DynamicPopupWrapper } from '../../../../SharedComponents/PopupWrapper';
import EmailEditor from '../../../../SharedComponents/email_editor/EmailEditor_Quill';
import {
  dateWithoutTime,
  daysBetweenDates
} from '../../../../SharedComponents/gantt-chart/common/GanttUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../../Utility/Utility';
import { AttachmentsPopup } from '../../../Common/AttachmentsPopup';
import FilePreview from '../../../Common/FilePreview';
import { triggerDownload } from '../../../ImportExport/utility/ExportData';
import CreateMRPProductView from '../../BOM_Assembly/CreateMrpProduct/CreateMRPProductView';
import {
  JC_STATUS,
  JOB_CARD_COLS,
  JOB_CARD_STATUS,
  WORK_ORDER_STATUS
} from '../../Constants/MRPColumnConfigs';
import {
  REQUIRED_ITEM_TABLE,
  WORK_ORDER_OPERATION_TABLE
} from '../../Constants/TableConstant';
import { INPUT_TITLE_STYLE } from '../../Constants/UIHelper';
import AddNewOperation from '../../Operations/AddNewOperation';
import { JC_DATES, JC_DATES_MODEL, JobCardHelper } from '../JobCardHelper';
import {
  AddJobCardPresenter,
  GENERALFIELD_JC_LABEL_WIDTH,
  GENERALFIELD_JC_VALUE_WIDTH,
  JC_POPUP_WIDTH_PROCESS_MANAGEMENT,
  JC_POPUP_WIDTH_WITHOUT_PROCESS_MANAGEMENT,
  JC_PROCESS_MANAGEMENT_GRID_WIDTH,
  MANUFACTURING_JC_COLUMN_KEYS,
  PROCESS_MANUFACTURING_QC_OPTIONS,
  consumeOrProcessMaterial,
  costDetailsInitialState,
  getConsumeOrProcessButton,
  isAssignOptionVisible,
  isConsumptionOptionVisible,
  getQCAssignButton,
  isAvailableForQC,
  isDependentOnFieldReadOnly,
  isProcessOptionVisible,
  validateJCFormAndList,
  getAttachedSubstitutes,
  getUpdatedWODetailsFromShortInfo,
  getUpdatedWarehouseInventoryDataConsideringUOM,
  processGetWODetailsByJCCode,
  processGetProcessJCDetailsByCode,
  getUsedJCArray
} from './AddJobCardPresenter';
import JobCardQC from '../../Shared/JobCardQC/JobCardQC';
import DetailsOpener from '../../../Common/DetailsOpener';
import {
  JC_PROCESS_TYPE,
  validateProcessTypeChange
} from '../../Shared/JobCardListComponent/JobCardListPresenter';
import { PROCESS_QC_STATUS } from '../../Constants/MRPEnums';
import { checkUserPermission } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import TransactionHistory from '../TransactionHistory';
import { isMachineEnabled } from '../../../../Services/MachineMasterService';
import OperatorsService from '../../../../Services/MRP/Operators';
import ProcessManufacturingService from '../../../../Services/MRP/ProcessManufacturing';
import AddNewWorkOrder from '../../WorkOrder/AddWorkOrder';

const AddJobCard = (props: any) => {
  /** variable Declaration */
  const isEditMode =
    props?.inProgressToCompleted || !Utility.isEmpty(props.editableJobCard);
  const isReadOnlyMode = props?.inProgressToCompleted
    ? false
    : isEditMode &&
      [JOB_CARD_STATUS.COMPLETED, JOB_CARD_STATUS.CANCELLED].includes(
        props.editableJobCard?.status
      );

  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [labelLoader, setLabelLoader] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const [woStateData, setWOStateData] = useState<any[]>([]);
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({});
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showStatusPicker, setShowStatusPicker] = useState(false);
  const [showWOPicker, setShowWOPicker] = useState(false);
  const [showAddOperationPopup, setShowAddOperationPopup] = useState(false);
  const [editableOperation, setEditableOperation] = useState<any>(undefined);
  const [attachments, setAttachments] = useState<any>([]);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [woAssociatedJobCards, setWOAssociatedJobCards] = useState<any[]>([]);
  const [dependentOnJC, setDependentOnJC] = useState<any>();

  /** Selectors */
  const dispatch = useAppDispatch();
  const workOrderData = useAppSelector(selectWorkOrder);
  const workstationData = useAppSelector(selectWorkstations);
  const warehouseData = useAppSelector(selectWarehouse);
  const operationData = useAppSelector(selectOperations);
  const operatorsData = useAppSelector(selectOperators);
  const productsData = useAppSelector(selectMRPProducts);
  const tenantInfo = useAppSelector(activeTenantInfo);

  /** State Declaration */
  const [formData, setFormData] = useState<any>(
    !Utility.isEmpty(props.editableJobCard)
      ? props.editableJobCard
      : {
          status: props.currentKanbanCol?.status || JOB_CARD_STATUS.OPEN,
          workStation: props.currentKanbanCol?.id
            ? workstationData?.content?.find(
                (item: any) => item.id === props.currentKanbanCol?.id
              )
            : '',
          actualTime: '',
          plannedTime: 0,
          jobCardDate: new Date(),
          operatorCostDetails: costDetailsInitialState,
          operationCostDetails: costDetailsInitialState,
          operationTimeType: TIME_TYPES[1],
          instructions: '',
          jobcardDependency: {},
          plannedStartDate: new Date(),
          plannedEndDate: new Date(),
          operatorDetails:
            operatorsData?.content?.find(
              (item: any) => item.id === props.currentKanbanCol?.id
            ) ?? ''
        }
  );
  const [ipTime, setIpTime] = useState('NA');
  const [holdTime, setHoldTime] = useState('NA');
  const [trackingHistory, setTrackingHistory] = useState([]);
  //states related to process manufacturing
  const [processJCDetails, setProcessJCDetails] = useState<any>([]);
  const [manufacturingRows, setManufacturingRows] = useState<any[]>([]);
  var presenter = new AddJobCardPresenter(formData, manufacturingRows);
  const [manufacturingColumns, setManufacturingColumns] = useState(
    presenter.getProcessManufacturingColumns() ?? []
  );
  const [workOrderDetails, setWorkOrderDetails] = useState<any>();
  const [showQCPopup, setShowQCPopup] = useState(false);
  const [selectedGridIndex, setSelectedGridIndex] = useState(-1);
  const [isManufacturingRowUpdated, setIsManufacturingRowUpdated] =
    useState(false);
  const [dependentJobCardsMapping, setDependentJobCardsMapping] =
    useState<any>();
  const [detailsPopupData, setDetailsPopupData] = useState<any>();
  const [showCustomSavePopup, setShowCustomSavePopup] = useState(false);
  const [
    openWOCompleteProcessThroughJCPopup,
    setOpenWOCompleteProcessThroughJCPopup
  ] = useState(false);
  const [woDetailsForAutoClosing, setWODetailsForAutoClosing] =
    useState<any>(null);

  const transactionColumn = [
    {
      name: 'Status',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: true,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'status',
      key: 'status',
      id: 'status',
      allowFilter: false
    },
    {
      name: 'Time',
      type: INPUT_TYPE.TEXT,
      index: 1,
      options: null,
      required: true,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: false,
      columnCode: 'time',
      key: 'time',
      id: 'time',
      allowFilter: false
    }
  ];
  /** Effects */
  useEffect(() => {
    if (isEditMode) {
      getOperationAndOperatorById();

      //set initial state in edit mode after operations api

      if (presenter.isLinkedInventory) {
        getProcessManufacturingAPICalls();
      }
    }
    return () => {
      setIsManufacturingRowUpdated(false);
    };
  }, []);

  useEffect(() => {
    if (ipTime !== 'NA' || holdTime !== 'NA') {
      if (
        !isReadOnlyMode &&
        (formData?.status === JOB_CARD_STATUS.COMPLETED ||
          formData?.status === JOB_CARD_STATUS.CANCELLED)
      ) {
        let currentIpTime =
          ipTime === 'NA' || parseFloat(ipTime) < 0
            ? -1
            : Math.floor(parseFloat(ipTime) / 60);
        if (currentIpTime !== -1) {
          onActualTimeChange(currentIpTime);
        }
      }
    }
  }, [formData?.status]);

  useEffect(() => {
    if (!Utility.isEmpty(workOrderData?.content)) {
      let workOrders = workOrderData?.content?.filter(
        (wo: any) => wo.status !== WORK_ORDER_STATUS.COMPLETED
      );
      setWOStateData([...workOrders]);
    }
  }, [workOrderData]);

  useEffect(() => {
    if (
      presenter.isLinkedInventory &&
      !Utility.isEmpty(formData) &&
      !Utility.isEmpty(processJCDetails)
    ) {
      const jobCardData = { ...formData };
      const foundProcessTypeObj = presenter
        .getProcessTypeData()
        .find(
          (typeObj: any) =>
            typeObj.key ===
            processJCDetails?.jobCardLinkDetailsResponses?.[0]?.processType
        );
      jobCardData.processType = foundProcessTypeObj;
      setFormData(jobCardData);
    }
  }, [processJCDetails]);

  useEffect(() => {
    if (
      !Utility.isEmpty(manufacturingRows) &&
      !Utility.isEmpty(workOrderDetails) &&
      !Utility.isEmpty(formData) &&
      !Utility.isEmpty(processJCDetails)
    ) {
      updateConfig();
    }
  }, [manufacturingRows, workOrderDetails, formData]);

  const updateConfig = () => {
    const updatedConfig = presenter.processColumnConfig(
      manufacturingColumns,
      manufacturingRows,
      workOrderDetails,
      formData,
      dependentJobCardsMapping,
      handlePresenterCallback
    );
    setManufacturingColumns([...updatedConfig]);
  };

  const getOperationAndOperatorById = () => {
    const operationId = formData?.operationId ?? '';
    const operatorId = formData?.operatorDetails?.operatorId ?? '';
    Promise.all([
      OperationsService.getOperationsById([operationId]),
      OperatorsService.getOperatorsById([operatorId])
    ]).then((res: any) => {
      const operationDetails = res?.[0]?.[0];
      const operatorDetails = res?.[1]?.[0];
      let updatedFormData = parseDataForEdit({ ...props.editableJobCard });
      updatedFormData.operation = operationDetails;
      updatedFormData.operatorDetails = operatorDetails;
      updatedFormData.operatorCostDetails =
        calculateOperatorCostDetails(updatedFormData);
      setFormData({ ...updatedFormData });
      fetchWOAssociatedJobCards(props?.editableJobCard?.workOrderCode);
    });
  };

  const getOperationsById = () => {
    showLoader('Loading operation details...');
    const operationId = formData?.operationId ?? '';
    OperationsService.getOperationsById([operationId])
      .then((res: any) => {
        removeLoader();
        let updatedFormData = parseDataForEdit({ ...props.editableJobCard });
        updatedFormData.operation = res?.[0];
        setFormData({ ...updatedFormData });
        fetchWOAssociatedJobCards(props?.editableJobCard?.workOrderCode);
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getOperatorById = (id: any) => {
    showLoader('Loading operator details...');
    const operatorId = id ?? '';
    OperatorsService.getOperatorsById([operatorId])
      .then((res: any) => {
        removeLoader();
        let updatedFormData = parseDataForEdit({ ...props.editableJobCard });
        updatedFormData.operatorDetails = res?.[0];
        setFormData({ ...updatedFormData });
        fetchWOAssociatedJobCards(props?.editableJobCard?.workOrderCode);
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getProcessManufacturingAPICalls = async () => {
    showLoader('Loading...please wait');
    const jcCode = props?.editableJobCard?.jobCardCode ?? '';
    let woDetails = await presenter.getWODetailsByJCCode(jcCode);
    woDetails = processGetWODetailsByJCCode(woDetails);
    let jcProcessDetails = await presenter.getProcessJCDetailsByCode(jcCode);
    jcProcessDetails = processGetProcessJCDetailsByCode(jcProcessDetails);

    if (!Utility.isEmpty(woDetails)) {
      const shortInfoPayload = presenter.getProductShortInfoPayload(
        woDetails?.workOrderItems
      );

      const productShortInfos = await presenter.getProductShortInfoForWOItems(
        shortInfoPayload
      );

      removeLoader();

      let updatedWorkOrderDetails = getUpdatedWODetailsFromShortInfo(
        productShortInfos,
        woDetails
      );

      let allAttachedSubstitutes: any[] = getAttachedSubstitutes(
        woDetails.workOrderItems,
        productShortInfos
      );

      updatedWorkOrderDetails = {
        ...updatedWorkOrderDetails,
        workOrderItems: [
          ...updatedWorkOrderDetails?.workOrderItems,
          ...allAttachedSubstitutes
        ]
      };

      let rows = [...jcProcessDetails?.jobCardLinkDetailsResponses]?.map(
        (woItem: any) => {
          const qcObj = woItem?.qcNeeded
            ? PROCESS_MANUFACTURING_QC_OPTIONS[0]
            : PROCESS_MANUFACTURING_QC_OPTIONS[1];
          const itemObj = updatedWorkOrderDetails?.workOrderItems?.find(
            (orderItem: any) => orderItem.productCode === woItem.productCode
          );
          return {
            item: {
              ...itemObj,
              itemWarehouseInventoryData: woItem?.itemWarehouseInventoryData
            },
            productCode: itemObj?.productCode,
            qcNeeded: qcObj,
            editable: true,
            invalidFields: Utility.isEmpty(itemObj?.productCode)
              ? [
                  MANUFACTURING_JC_COLUMN_KEYS.ITEM,
                  MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE
                ]
              : [],
            detailsForQtyToBeAssign:
              jcProcessDetails?.jobCardLinkDetailsQuantityDetails?.filter(
                (item: any) => {
                  return item.productCode === itemObj?.productCode;
                }
              ),
            jobCardLinkDetails:
              jcProcessDetails?.jobCardLinkDetailsResponses?.filter(
                (item: any) => {
                  return item.productCode === itemObj?.productCode;
                }
              ),
            jobCardId: props?.editableJobCard?.id
          };
        }
      );

      setWorkOrderDetails(updatedWorkOrderDetails);
      setManufacturingRows([...rows]);
      setProcessJCDetails(jcProcessDetails);
    }
  };

  const fetchWOAssociatedJobCards = (code: any) => {
    JobCardService.fetchAllWOLinkedJobCards(code)
      .then((res: any) => {
        const currentJCFound = res?.find((resItem: any) => {
          return props?.editableJobCard?.id === resItem?.id;
        });
        const jcDependentCode =
          currentJCFound?.jobcardDependency?.jobcardDependencyList?.[0];
        const dependentOnJCFound = res?.find((resItem: any) => {
          return resItem?.jobCardCode === jcDependentCode;
        });

        const dependentByJCs =
          res?.filter((resItem: any) => {
            return (
              resItem?.jobcardDependency?.jobcardDependencyList?.[0] ===
              currentJCFound?.jobCardCode
            );
          }) ?? [];
        setIpTime(
          currentJCFound?.iptime === -1 ? 'NA' : currentJCFound?.iptime
        );
        setHoldTime(
          currentJCFound?.holdTime === -1 ? 'NA' : currentJCFound?.holdTime
        );
        setInterval(() => {
          if (isEditMode) {
            if (formData?.status === JOB_CARD_STATUS.IN_PROGRESS) {
              setIpTime((ipTime: any) =>
                Utility.isNumber(ipTime) ? ipTime + 1 : ipTime
              );
            } else if (formData?.status === JOB_CARD_STATUS.ON_HOLD) {
              setHoldTime((holdTime: any) =>
                Utility.isNumber(holdTime) ? holdTime + 1 : holdTime
              );
            }
          }
        }, 1000);
        if (formData?.status !== JOB_CARD_STATUS.OPEN) {
          setTrackingHistory(currentJCFound?.trackingHistory);
        }
        if (
          !Utility.isNumber(formData?.actualTime) &&
          (formData?.status === JOB_CARD_STATUS.COMPLETED ||
            formData?.status === JOB_CARD_STATUS.CANCELLED) &&
          currentJCFound?.iptime !== -1
        ) {
          let currentIpTime =
            currentJCFound?.iptime === 'NA'
              ? 0
              : Math.floor(parseFloat(currentJCFound?.iptime) / 60);
          onActualTimeChange(currentIpTime);
        }
        setDependentJobCardsMapping({
          currentJCDependentOn: dependentOnJCFound,
          currentJCDetails: currentJCFound,
          currentJCDependentBy: dependentByJCs,
          allJCDetails: res
        });

        setDependentOnJC(dependentOnJCFound);
        setWOAssociatedJobCards(res ?? []);
      })
      .catch((err: any) => {});
  };

  const handlePresenterCallback = (details: any) => {
    setDetailsPopupData(details);
  };

  /** Business Logic */
  const parseDataForEdit = (jobCard: any) => {
    let copyOfFormDate = { ...formData };
    let jobCardToEdit = { ...jobCard };
    copyOfFormDate.documentSequenceCode = jobCardToEdit?.documentSequenceCode;
    copyOfFormDate.jobCardDate = parseDateFromStringForWorkOrder(
      jobCardToEdit?.jobCardDate
    );
    copyOfFormDate.totalCompletedQty = jobCardToEdit?.totalCompletedQuantity;
    copyOfFormDate.workOrder =
      workOrderData?.content?.find(
        (item: any) => item.workOrderCode === jobCardToEdit.workOrderCode
      ) ||
      (jobCardToEdit.workOrderSequenceCode
        ? {
            workOrderStatus: jobCardToEdit.workOrderStatus,
            workOrderCode: jobCardToEdit.workOrderCode,
            documentSequenceCode: jobCardToEdit.workOrderSequenceCode,
            productCode: jobCardToEdit.workOrderItemCode,
            productSeqCpde: jobCardToEdit.workOrderItemDocSeqCode,
            productName: jobCardToEdit.workOrderItemName
          }
        : null);

    copyOfFormDate.wipWarehouse = warehouseData?.content?.find(
      (item: any) => item.code === jobCardToEdit.wipWarehouseCode
    );
    copyOfFormDate.operation = operationData?.content?.find(
      (item: any) => item.id === jobCardToEdit.operationId
    ) || { id: jobCardToEdit?.operationId, name: jobCardToEdit?.operationName };
    copyOfFormDate.workStation = jobCardToEdit?.workstationDetails;
    copyOfFormDate.fgQty = jobCardToEdit?.fgquantityTransferredRawMaterials;
    copyOfFormDate.requestedQty = jobCardToEdit?.requestedQuantity;
    copyOfFormDate.status = jobCardToEdit?.status;
    copyOfFormDate.remarks = jobCardToEdit?.remarks;
    copyOfFormDate.plannedTime = jobCardToEdit?.plannedTime || 0;
    copyOfFormDate.actualTime = jobCardToEdit?.actualTime ?? '';
    copyOfFormDate.operatorDetails = jobCardToEdit?.operatorDetails || null;
    copyOfFormDate.operatorCostDetails =
      jobCardToEdit?.operatorCostDetails || costDetailsInitialState;
    copyOfFormDate.operationCostDetails =
      jobCardToEdit?.operationCostDetails || costDetailsInitialState;

    const checkOperationTimeType = Utility.checkTimeType(
      jobCardToEdit?.plannedTime || 0
    );

    copyOfFormDate.operationTimeType = checkOperationTimeType;
    copyOfFormDate.plannedTime = Utility.convertMinutesInFormat(
      jobCardToEdit?.plannedTime || 0,
      checkOperationTimeType?.value,
      true
    );

    copyOfFormDate.instructions = Utility.decodeHTML(
      jobCardToEdit?.instructions || ''
    );

    copyOfFormDate.jobcardDependency = jobCardToEdit?.jobcardDependency ?? {};

    copyOfFormDate.oldPlannedStartDate = Utility.isValidDate(
      jobCardToEdit?.plannedStartDate
    )
      ? new Date(jobCardToEdit?.plannedStartDate)
      : new Date();
    copyOfFormDate.plannedStartDate = Utility.isValidDate(
      jobCardToEdit?.plannedStartDate
    )
      ? new Date(jobCardToEdit?.plannedStartDate)
      : new Date();
    copyOfFormDate.plannedEndDate = Utility.isValidDate(
      jobCardToEdit?.plannedEndDate
    )
      ? new Date(jobCardToEdit?.plannedEndDate)
      : new Date();
    copyOfFormDate.actualStartDate = Utility.isValidDate(
      jobCardToEdit?.actualStartDate
    )
      ? new Date(jobCardToEdit?.actualStartDate)
      : null;
    copyOfFormDate.actualEndDate = Utility.isValidDate(
      jobCardToEdit?.actualEndDate
    )
      ? new Date(jobCardToEdit?.actualEndDate)
      : null;
    fetchAttachments(
      copyOfFormDate.workOrder.id,
      true,
      jobCardToEdit.operationId
    );

    return copyOfFormDate;
  };
  const validateForm = () => {
    const isStatusCancelledOrCompleted =
      formData?.status === JOB_CARD_STATUS.CANCELLED ||
      formData?.status === JOB_CARD_STATUS.COMPLETED;

    //process manufacturing validation variables
    const firstRow = manufacturingRows?.[0];
    const hasInvalidFieldsInGrid = manufacturingRows?.some((gridItem: any) => {
      return !Utility.isEmpty(gridItem.invalidFields);
    });
    const dependentJCNameHTML = `<span class="fw-b">${
      dependentOnJC?.operationName ?? ''
    }</span>`;

    if (
      Utility.isEmpty(formData?.workOrder) ||
      Utility.isEmpty(formData?.operation)
    ) {
      return false;
    }

    if (
      isEditMode &&
      isStatusCancelledOrCompleted &&
      Utility.isEmpty(formData?.actualTime?.toString())
    ) {
      return false;
    }

    if (
      isEditMode &&
      presenter.isLinkedInventory &&
      Utility.isNotEmpty(formData?.processType)
    ) {
      if (hasInvalidFieldsInGrid) {
        return false;
      }

      if (
        !validateJCFormAndList(
          firstRow,
          dependentJobCardsMapping,
          formData,
          dependentOnJC,
          true,
          (updatedDependentJc: any) => {
            setDependentOnJC(updatedDependentJc);
          },
          true
        )
      ) {
        return false;
      }

      if (
        isStatusCancelledOrCompleted &&
        firstRow?.jobCardLinkDetails?.[0]?.processType !==
          JC_PROCESS_TYPE.PRODUCTION &&
        firstRow?.jobCardLinkDetails?.[0]?.status !== PROCESS_QC_STATUS.COMPLETE
      ) {
        showAlert(
          'Please note.',
          'Consume/Process status is not yet completed, please finish the process or delete the materials tagged.'
        );
        return false;
      }

      if (
        Utility.isNotEmpty(formData?.processType) &&
        Utility.isNotEmpty(dependentOnJC) &&
        Utility.isEmpty(dependentOnJC?.jobCardLinkDetails) &&
        firstRow?.jobCardLinkDetails?.[0]?.status !== PROCESS_QC_STATUS.QA_DONE
      ) {
        showAlert(
          'Please note.',
          `As this job card is a dependent job card, you need to tag material in ${dependentJCNameHTML} job card first.`
        );
        return false;
      }

      if (
        firstRow?.jobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.PRODUCTION &&
        props?.editableJobCard?.status !== formData?.status &&
        formData?.status === JOB_CARD_STATUS.COMPLETED
      ) {
        setShowCustomSavePopup(true);
        return;
      }

      if (
        formData?.status === JOB_CARD_STATUS.CANCELLED &&
        Utility.isNotEmpty(firstRow)
      ) {
        showAlert(
          'Please note.',
          `You are about to cancel this jobcard and you have materials tagged to this jobcard, are you sure you want to proceed?`,
          [
            {
              title: 'No',
              className: 'bg-gray1 text-black border-m',
              onClick: () => {}
            },
            {
              title: 'Yes',
              className: 'bg-button text-white ml-m',
              onClick: () => {
                getAPICalls();
              }
            }
          ]
        );
        return false;
      }
    }
    const linkedInMachines = formData.operation?.linkedInMachines;
    const idsOfSelectedMachnies = formData?.machinesUsed || [];
    const machineOutOfCapacity: any[] = [];

    linkedInMachines?.forEach((machine: any) => {
      const machineStatus = {
        machineName: machine.name,
        noOfParallelJobs: machine.noOfParallelJobs,
        noOfJobsRunning: machine.noOfJobsRunning
      };
      if (idsOfSelectedMachnies.includes(String(machine.id))) {
        if (machine.noOfJobsRunning >= machine.noOfParallelJobs) {
          machineOutOfCapacity.push(machineStatus);
        }
      }
    });

    if (
      machineOutOfCapacity.length > 0 &&
      formData.machinesInUse === true &&
      formData.status === JOB_CARD_STATUS.IN_PROGRESS &&
      isMachineEnabled()
    ) {
      let alertHtml = `<div class="">`;
      alertHtml += `<div class="text-center">These Machines will go over capacity, Please review the machine allocation.</div>`;
      alertHtml += `<div class="text-center mt-2">`;
      alertHtml += `<h4 class="font-bold text-left">Machines:</h4>`;
      machineOutOfCapacity.forEach((machine) => {
        alertHtml += `<div class="text-left">- ${machine.machineName} </div>`;
      });
      alertHtml += `</div>`;
      alertHtml += `</div>`;
      showAlert('Please note.', alertHtml);
      return false;
    }
    return true;
  };

  const getDataToSave = (editor_container: any) => {
    /* picking contents from inner element for handling quill editor */
    const editor_body = editor_container.querySelector('.ql-editor');
    const html_text = editor_body
      ? replacePlaceholders(editor_body)
      : editor_container.innerHTML;

    /* sanitize text for email placeholders */
    return getEditorValue(html_text);
  };

  const replacePlaceholders = (editor_body: any) => {
    const placeholders = editor_body.getElementsByClassName(
      'custom_email_placeholder'
    );
    Array.from(placeholders).forEach((placeholder: any) => {
      const dataId = placeholder.getAttribute('data-id');
      placeholder.innerHTML = `%${dataId}%`;
    });

    return editor_body.innerHTML;
  };

  const getEditorValue = (emailBody: any) => {
    if (emailBody === '<div><br></div>') {
      return '';
    }
    const html_text = Utility.encodeHTML(emailBody);
    return html_text;
  };

  const getPayload = (currentFormData?: any) => {
    const div_body = document.getElementById('jobCard-editor-body');
    let payload: any = {
      documentSequenceCode: currentFormData?.documentSequenceCode,
      fgquantityTransferredRawMaterials: Number(currentFormData?.fgQty),
      jobCardDate: DateFormatService.getDateStrFromDate(
        currentFormData?.jobCardDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      jobCardCode: currentFormData?.jobCardCode,
      jobCardTimeLogs: [],
      operationId: currentFormData?.operation?.id,
      remarks: currentFormData?.remarks,
      requestedQuantity: Number(currentFormData?.requestedQty),
      sequenceFormat: currentFormData?.sequenceFormat,
      status: currentFormData?.status ?? JOB_CARD_STATUS.OPEN,
      totalCompletedQuantity: Number(currentFormData?.totalCompletedQty),
      wipWarehouseCode: currentFormData?.wipWarehouse?.code,
      workOrderCode: currentFormData?.workOrder?.workOrderCode,
      plannedTime: Utility.calculateTimeInMinutes(
        currentFormData?.plannedTime,
        !Utility.isEmpty(currentFormData?.operationTimeType?.value)
          ? currentFormData?.operationTimeType?.value
          : TIME_TYPES[1]?.value
      ),
      actualTime: currentFormData?.actualTime,
      operatorDetails: {
        ...currentFormData?.operatorDetails,
        operatorId:
          currentFormData?.operatorDetails?.id ??
          currentFormData?.operatorDetails?.operatorId,
        operatorName:
          currentFormData?.operatorDetails?.name ??
          currentFormData?.operatorDetails?.operatorName
      },
      workOrderItemCode: currentFormData?.workOrder?.productCode,
      workOrderItemName: currentFormData?.workOrder?.productName,
      operatorCostDetails: currentFormData?.operatorCostDetails,
      operationCostDetails: currentFormData?.operationCostDetails,
      workstationId: currentFormData?.workStation?.id,
      instructions: getDataToSave(div_body) || '',
      salesOrderCustomerOrderNumber:
        formData?.workOrder?.workOrderSourceDetails?.[0]
          ?.salesOrderCustomerOrderNumber,
      jobcardDependency: {
        jobcardDependencyList:
          currentFormData?.jobcardDependency?.jobcardDependencyList ?? []
      },
      plannedStartDate: Utility.formatDate(
        currentFormData?.plannedStartDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      ),
      plannedEndDate: Utility.formatDate(
        currentFormData?.plannedEndDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      ),
      jobCardLinkDetails: currentFormData?.jobCardLinkDetails,
      jobCardLinkDetailsAvailableToAssign:
        currentFormData?.jobCardLinkDetailsAvailableToAssign,
      workstationDetails: currentFormData?.workstationDetails,
      machinesUsed: currentFormData?.machinesUsed,
      machinesInUse: currentFormData?.machinesInUse
    };

    if (!isEditMode && !Utility.isEmpty(payload.documentSequenceCode)) {
      delete payload.sequenceFormat;
    }

    if (
      currentFormData?.status === JOB_CARD_STATUS.IN_PROGRESS ||
      currentFormData?.status === JOB_CARD_STATUS.ON_HOLD
    ) {
      const actualStartDate = currentFormData?.actualStartDate ?? new Date();
      payload.actualStartDate = Utility.formatDate(
        actualStartDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      );
      const actualEndDate = DateFormatService.addMinutes(
        actualStartDate,
        +currentFormData?.actualTime ?? 0
      );
      payload.actualEndDate = Utility.formatDate(
        actualEndDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      );
    }
    if (
      currentFormData?.status === JOB_CARD_STATUS.COMPLETED ||
      currentFormData?.status === JOB_CARD_STATUS.CANCELLED
    ) {
      const actualStartDate = currentFormData?.actualStartDate ?? new Date();
      payload.actualStartDate = Utility.formatDate(
        actualStartDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      );
      const actualEndDate = currentFormData?.actualEndDate ?? new Date();
      payload.actualEndDate = Utility.formatDate(
        actualEndDate,
        DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
      );
    }

    let formattedPlannedEndDate = balanceDates(
      payload?.plannedStartDate,
      payload?.plannedEndDate
    );
    if (Utility.isValidDate(formattedPlannedEndDate)) {
      payload.plannedEndDate = formattedPlannedEndDate;
    }
    return payload;
  };

  const getPayloadForEditModeOnly = () => {
    let jobCardsDependentOnCurrentJC: any[] = JobCardHelper.findDependents(
      woAssociatedJobCards,
      props?.editableJobCard?.id,
      dependentOnJC
    );
    let objectMappedJCs: any = {};
    jobCardsDependentOnCurrentJC = jobCardsDependentOnCurrentJC?.map(
      (item, index) => {
        if (index == 0) {
          item = {
            ...getPayload({ ...formData }),
            id: props.editableJobCard?.id,
            jobcardDependency: {
              jobcardDependencyList: dependentOnJC?.jobCardCode
                ? [dependentOnJC?.jobCardCode]
                : []
            }
          };
          objectMappedJCs[item.id] = {
            ...getPayload({ ...formData }),
            id: props.editableJobCard?.id
          };
        } else {
          const prevItem = jobCardsDependentOnCurrentJC[index - 1];
          const prevItemFromPayload = objectMappedJCs?.[prevItem?.id];
          const startDate = isBefore(
            new Date(item?.plannedStartDate),
            new Date(prevItemFromPayload?.plannedEndDate)
          )
            ? new Date(prevItemFromPayload?.plannedEndDate)
            : new Date(item?.plannedStartDate);
          const endDate = startDate;
          // DateFormatService.addMinutes(startDate, item?.plannedTime);
          let diff =
            daysBetweenDates(
              new Date(item?.plannedStartDate),
              new Date(item?.plannedEndDate)
            ) ?? 0;
          const updatedEndDate = addDays(endDate, diff) ?? endDate;
          objectMappedJCs[item.id] = {
            plannedStartDate: startDate,
            plannedEndDate: updatedEndDate,
            id: item.id
          };
          item = {
            plannedStartDate: startDate,
            plannedEndDate: updatedEndDate,
            id: item.id
          };
        }
        return item;
      }
    );

    let payload = woAssociatedJobCards?.map((woJCItem: any) => {
      if (woJCItem.id === jobCardsDependentOnCurrentJC?.[0]?.id) {
        woJCItem = {
          ...jobCardsDependentOnCurrentJC?.[0]
        };
      } else {
        const findInDependentArr = jobCardsDependentOnCurrentJC?.find(
          (xItem: any) => {
            return xItem.id === woJCItem?.id;
          }
        );
        if (findInDependentArr) {
          woJCItem = {
            ...woJCItem,
            plannedStartDate: findInDependentArr?.plannedStartDate,
            plannedEndDate: findInDependentArr?.plannedEndDate
          };
        }
      }
      return woJCItem;
    });
    return payload;
  };

  const getAPICalls = () => {
    const payload = getPayload({ ...formData });
    let apiCall: any;
    if (isEditMode) {
      const payloadForEdit = getPayloadForEditModeOnly();
      const payloadForProcessManufactureDetails =
        presenter.getPayloadForProcessManufactureDetails(
          processJCDetails,
          dependentOnJC
        );
      apiCall = JobCardService.bulkUpdateJobCard(payloadForEdit);
      if (
        presenter.isLinkedInventory &&
        !Utility.isEmpty(formData?.processType)
      ) {
        presenter.addOrUpdateProcessDetailsInJC(
          payloadForProcessManufactureDetails
        );
      }
    } else {
      apiCall = JobCardService.addJobCard(payload);
    }
    setUpdating(true);
    apiCall
      .then((res: any) => {
        dispatch(fetchWorkOrderList());
        const areAllJCCompletedOrCancelled = Array.isArray(res)
          ? res?.find(
              (resItem: any) =>
                resItem?.workOrderCode === formData?.workOrderCode
            )?.allJobCardsCompleted
          : false;
        if (
          props?.handleAutoClosingWOFlow &&
          areAllJCCompletedOrCancelled &&
          formData?.workOrder?.isAutoClosed
        ) {
          autoCloseWorkOrderFlow(res);
        } else {
          props.onSuccess(res, formData?.workOrder?.workOrderId);
        }
      })
      .catch((err: any) => {
        showAlert('Error', err?.message ?? 'Unable to save job card');
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const autoCloseWorkOrderFlow = (jobCardUpdated: any) => {
    WorkOrderService.getWorkOrderByCode(
      formData?.workOrder.documentSequenceCode
    )
      .then((woRes: any) => {
        setWODetailsForAutoClosing(woRes?.content?.[0]);
        setOpenWOCompleteProcessThroughJCPopup(
          !openWOCompleteProcessThroughJCPopup
        );
      })
      .catch((err: any) => {});
  };

  const onSave = () => {
    setCanValidate(true);
    if (validateForm() && !updating) {
      getAPICalls();
    }
  };

  const populateBomOperations = (data: any) => {
    let selectedBomConfigurations = data?.bomOperationsConfiguration;
    let selectedProductConfigurations = data?.bomProductsConfiguration;

    let bomOperationsArr: any[] = [];
    let productItemsArr: any[] = [];

    // bom operations
    selectedBomConfigurations?.map((item: any, bomOperationIndex: number) => {
      const found = operationData?.content?.find((item2: any) => {
        return item.operationId === item2.id;
      });

      if (found) {
        let updatedOperationTableDataObj = {
          [WORK_ORDER_OPERATION_TABLE.OPERATION_NAME]: found,
          [WORK_ORDER_OPERATION_TABLE.OPERATION_TIME]: 0,
          [WORK_ORDER_OPERATION_TABLE.OPERATION_STATUS]: ['PENDING'],
          [WORK_ORDER_OPERATION_TABLE.OPERATION_COMPLETED_QTY]: 0,
          [WORK_ORDER_OPERATION_TABLE.OPERATION_DESCRIPTION]:
            found?.description,
          [WORK_ORDER_OPERATION_TABLE.OPERATION_INDEX]: bomOperationIndex + 1
        };
        bomOperationsArr.push(updatedOperationTableDataObj);
      }
    });

    let allProductDetails: any[] = [];

    let ids = selectedProductConfigurations?.map((itemBom: any) => {
      return itemBom.itemId;
    });

    //fetch product details apis
    setLabelLoader(true);
    ProductService.getProductDetailsByIds(ids)
      .then((res: any) => {
        setLabelLoader(false);
        allProductDetails = res;

        // product items
        selectedProductConfigurations?.map(
          (item: any, productItemIndex: number) => {
            let updatedProductItemTableDataObj = {
              [REQUIRED_ITEM_TABLE.ITEM_NAME]: allProductDetails?.find(
                (productDetail: any) => {
                  return productDetail.id === item.itemId;
                }
              ),
              [REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE]: {},
              [REQUIRED_ITEM_TABLE.REQUIRED_QTY]: item?.quantity
            };
            productItemsArr.push(updatedProductItemTableDataObj);
          }
        );

        setFormData({
          ...formData,
          operationTableData: bomOperationsArr,
          requiredItemsTableData: productItemsArr,
          itemToManufacture: data
        });
      })
      .catch((err: any) => {
        setLabelLoader(false);
        setFormData({
          ...formData,
          operationTableData: bomOperationsArr,
          requiredItemsTableData: productItemsArr,
          itemToManufacture: data
        });
      });
  };

  const getStatusColumnConfig = () => {
    let statusColumnConfig = JOB_CARD_COLS.find(
      (col: any) => col.columnCode === 'status'
    );
    var statusColumnConfigArray: any = [];
    if (statusColumnConfig) {
      statusColumnConfigArray = statusColumnConfig.options;
      let currentStatus = statusColumnConfigArray.find(
        (item: any) => item.id === formData.status
      );
      if (!Utility.isEmpty(currentStatus)) {
        return currentStatus;
      }
    }
    return {
      id: 'OPEN',
      color: 'data-grid-badge-color-1',
      name: 'Open'
    };
  };

  const getActionButtonTitle = () => {
    if (formData?.status === 'OPEN') {
      return 'Start Job';
    } else if (formData?.status === 'IN_PROGRESS') {
      return 'Complete Job';
    } else if (formData?.status === 'ON_HOLD') {
      return 'Resume Job';
    } else if (formData?.status === 'CANCELLED') {
      return 'Start Job';
    }
  };

  const onStatusUpdateClick = () => {
    let isDependentJCFinished = JobCardHelper.statusValidator(dependentOnJC);
    if (!isDependentJCFinished) {
      showAlert(
        'Please note',
        `You can only change the status once the dependent operation: <span class="fw-b">${
          dependentOnJC?.operationName ?? ''
        }</span> has been finished.`
      );
      return;
    }

    let alertMessage = '';
    let editFormData = { ...formData };
    if (formData?.status === JOB_CARD_STATUS.OPEN) {
      editFormData.status = JOB_CARD_STATUS.IN_PROGRESS;
      alertMessage = `Job card ${formData.jobCardCode} started.`;
    } else if (formData?.status === JOB_CARD_STATUS.IN_PROGRESS) {
      editFormData.status = JOB_CARD_STATUS.COMPLETED;
      alertMessage = `Job card ${formData.jobCardCode} completed.`;
    } else if (formData?.status === JOB_CARD_STATUS.ON_HOLD) {
      editFormData.status = JOB_CARD_STATUS.IN_PROGRESS;
      alertMessage = `Job card ${formData.jobCardCode} started.`;
    } else if (formData?.status === JOB_CARD_STATUS.CANCELLED) {
      editFormData.status = JOB_CARD_STATUS.IN_PROGRESS;
      alertMessage = `Job card ${formData.jobCardCode} started.`;
    }
    setCanValidate(true);
    const validated = validateForm();
    if (validated && !updating) {
      const payload = getPayloadForEditModeOnly();
      setUpdating(true);
      JobCardService.bulkUpdateJobCard(payload)
        .then((res: any) => {
          dispatch(fetchJobCardsList());
          setFormData(parseDataForEdit(res));
          setUpdating(false);
          if (res?.allJobCardsCompleted) {
            showAlert(
              'Alert',
              `All Job Cards for Work Order ${formData?.workOrder.documentSequenceCode} are Completed, Do You Want to Mark It Complete?`,
              [
                {
                  title: 'Cancel',
                  className: 'bg-gray1 text-black border-m',
                  onClick: () => {
                    props?.onSuccess();
                  }
                },
                {
                  title: 'Mark Complete',
                  className: 'bg-button text-white ml-m',
                  onClick: () => {
                    props?.onSuccess({ markWorkOrderComplete: true });
                  }
                }
              ]
            );
          } else {
            showAlert('Alert', alertMessage, [
              {
                title: 'Ok',
                className: 'bg-button text-white border-m',
                onClick: () => {
                  props?.onSuccess();
                }
              }
            ]);
          }
        })
        .catch((err: any) => {
          setUpdating(false);
          showAlert('Error', 'Something went wrong, please try again later.');
        });
    }
  };

  const onOperationChange = (operation: any) => {
    const jobCardData = { ...formData };
    jobCardData.operation = operation;
    jobCardData.operatorDetails = null;
    const wkFound = workstationData.content?.find(
      (workstation: any) => workstation.id === operation.defaultWorkstation
    );
    jobCardData.workStation = wkFound;
    jobCardData.workstationDetails = wkFound;
    jobCardData.plannedTime = operation?.operationTime || 0;
    jobCardData.operationCostDetails =
      calculateOperationCostDetails(jobCardData);

    const checkOperationTimeType = Utility.checkTimeType(
      operation?.operationTime || 0
    );

    jobCardData.instructions = operation?.instructions
      ? Utility.decodeHTML(operation?.instructions || '')
      : ' ';

    jobCardData.operationTimeType = checkOperationTimeType;
    jobCardData.plannedTime = Utility.convertMinutesInFormat(
      operation?.operationTime || 0,
      checkOperationTimeType?.value
    );
    setFormData(jobCardData);
    fetchAttachments(operation?.id);
  };

  const fetchAttachments = (
    id: any,
    isWorkOrder = false,
    operationId = null
  ) => {
    let moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.WORKORDER_OPERATION];
    if (isWorkOrder) {
      moduleType = 'WORKORDER_DOCUMENT';
    }
    let dataToPassToNextCall: any = [];
    const entityId = id;
    if (!entityId) return;
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };
    if (!operationId) {
      AttachmentService.getAllAttachments().then((attachmentList: any) => {
        let filesData: any[] = isWorkOrder ? [] : [...attachments];
        let initalLength = filesData.length;
        if (attachmentList.length > 0) {
          Promise.all(
            attachmentList.map((attachment: any) =>
              getAttachmentPath(attachment)
            )
          )
            .then((responseList) => {
              attachmentList.forEach((attachment: any, index: any) => {
                filesData.push({ ...attachment, path: responseList[index] });
                if (
                  attachmentList?.length ===
                  filesData.length - initalLength
                ) {
                  setAttachments(filesData);
                }
              });
            })
            .catch((err) => {})
            .finally(() => {});
        } else {
          setAttachments(filesData);
        }
      });
    } else {
      AttachmentService.getAllAttachments().then((attachmentList: any) => {
        let filesData: any[] = isWorkOrder ? [] : [...attachments];
        if (attachmentList.length > 0) {
          Promise.all(
            attachmentList.map((attachment: any) =>
              getAttachmentPath(attachment)
            )
          )
            .then((responseList) => {
              attachmentList.forEach((attachment: any, index: any) => {
                filesData.push({ ...attachment, path: responseList[index] });
                if (attachmentList?.length === filesData.length) {
                  dataToPassToNextCall = filesData;
                  setAttachments(filesData);
                }
              });
            })
            .then(() => {
              AttachmentService.attachmentConfig = {
                ...AttachmentService.attachmentConfig,
                Module:
                  DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.WORKORDER_OPERATION],
                EntityId: operationId
              };
              AttachmentService.getAllAttachments().then(
                (attachmentList: any) => {
                  let filesData: any[] = [...dataToPassToNextCall];
                  let initalLength = filesData.length;
                  if (attachmentList.length > 0) {
                    Promise.all(
                      attachmentList.map((attachment: any) =>
                        getAttachmentPath(attachment)
                      )
                    )
                      .then((responseList) => {
                        attachmentList.forEach(
                          (attachment: any, index: any) => {
                            filesData.push({
                              ...attachment,
                              path: responseList[index]
                            });
                            if (
                              attachmentList?.length ===
                              filesData.length - initalLength
                            ) {
                              setAttachments(filesData);
                            }
                          }
                        );
                      })
                      .catch((err) => {})
                      .finally(() => {});
                  } else {
                    setAttachments(filesData);
                  }
                }
              );
            })
            .catch((err) => {})
            .finally(() => {});
        } else {
          if (isWorkOrder) {
            AttachmentService.attachmentConfig = {
              ...AttachmentService.attachmentConfig,
              Module: DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.WORKORDER_OPERATION],
              EntityId: operationId
            };
            AttachmentService.getAllAttachments().then(
              (attachmentList: any) => {
                let filesData: any[] = [...dataToPassToNextCall];
                let initalLength = filesData.length;
                if (attachmentList.length > 0) {
                  Promise.all(
                    attachmentList.map((attachment: any) =>
                      getAttachmentPath(attachment)
                    )
                  )
                    .then((responseList) => {
                      attachmentList.forEach((attachment: any, index: any) => {
                        filesData.push({
                          ...attachment,
                          path: responseList[index]
                        });
                        if (
                          attachmentList?.length ===
                          filesData.length - initalLength
                        ) {
                          setAttachments(filesData);
                        }
                      });
                    })
                    .catch((err) => {})
                    .finally(() => {});
                } else {
                  setAttachments(filesData);
                }
              }
            );
          } else {
            dataToPassToNextCall = filesData;

            setAttachments(filesData);
          }
        }
      });
    }
  };

  const downloadAttachment = (attachmentData: any) => {
    triggerDownload(
      null,
      attachmentData.attachmentFileName,
      attachmentData.path
    );
  };

  const getAttachmentPath = async (attachment: any) => {
    return await AttachmentService.downloadAttachment(attachment.attachmentId);
  };

  const calculateOperationCostDetails = (jobCardData: any) => {
    const {
      operation,
      operationTimeType,
      operationCostDetails,
      actualTime,
      plannedTime
    } = jobCardData;

    const isActualCost = isEditMode ? true : false;
    const time = isActualCost ? actualTime : plannedTime;
    let cost = 0;
    let timeInMinutes: any = time;
    let timeInHours = 0;

    if (!isActualCost) {
      switch (operationTimeType?.value) {
        case TIME_TYPES_CONSTANT.DAYS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.HOURS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.MINUTES:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.SECONDS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        default:
          break;
      }

      timeInHours = Utility.calculateMinutesInHours(timeInMinutes);

      cost = Utility.roundOffToTenantDecimalScale(
        timeInHours * operation?.costPerHour
      );

      cost = cost + operation?.fixedRate || 0;

      return {
        ...jobCardData.operationCostDetails,
        plannedCost: cost
      };
    } else if (isActualCost) {
      let plannedTimeInMinutes: any = plannedTime;
      let plannedTimeInHours: any = 0;
      switch (operationTimeType?.value) {
        case TIME_TYPES_CONSTANT.DAYS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.HOURS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.MINUTES:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.SECONDS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        default:
          break;
      }

      plannedTimeInHours =
        Utility.calculateMinutesInHours(plannedTimeInMinutes);
      timeInHours = Utility.calculateMinutesInHours(timeInMinutes);

      let plannedCostWithoutFixRate = Utility.roundOffToTenantDecimalScale(
        plannedTimeInHours * operation?.costPerHour
      );

      let plannedCost = plannedCostWithoutFixRate + operation?.fixedRate || 0;

      let actualCost =
        timeInHours * (operation?.costPerHour || 0) +
        (operation?.fixedRate || 0) +
        (operationCostDetails?.additionalCost || 0);

      return {
        ...jobCardData.operationCostDetails,
        actualCost: Utility.roundOffToTenantDecimalScale(actualCost)
      };
    }
  };

  const calculateOperatorCostDetails = (jobCardData: any) => {
    const operator: any = jobCardData?.operatorDetails;

    const { operationTimeType, operatorCostDetails, actualTime, plannedTime } =
      jobCardData;

    const isActualCost = isEditMode ? true : false;
    const time = isActualCost ? actualTime : plannedTime;
    let cost = 0;
    let timeInMinutes: any = time;
    let timeInHours = 0;

    if (!isActualCost) {
      switch (operationTimeType?.value) {
        case TIME_TYPES_CONSTANT.DAYS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.HOURS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.MINUTES:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.SECONDS:
          timeInMinutes = Utility.calculateTimeInMinutes(
            time,
            operationTimeType?.value
          );
          break;
        default:
          break;
      }

      timeInHours = Utility.calculateMinutesInHours(timeInMinutes);

      cost = Utility.roundOffToTenantDecimalScale(
        timeInHours * operator?.costPerHour
      );

      cost = cost + operator?.fixedRate || 0;

      return {
        ...jobCardData.operatorCostDetails,
        plannedCost: cost
      };
    } else if (isActualCost) {
      let plannedTimeInMinutes: any = plannedTime;
      let plannedTimeInHours: any = 0;
      switch (operationTimeType?.value) {
        case TIME_TYPES_CONSTANT.DAYS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.HOURS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.MINUTES:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        case TIME_TYPES_CONSTANT.SECONDS:
          plannedTimeInMinutes = Utility.calculateTimeInMinutes(
            plannedTime,
            operationTimeType?.value
          );
          break;
        default:
          break;
      }

      plannedTimeInHours =
        Utility.calculateMinutesInHours(plannedTimeInMinutes);
      timeInHours = Utility.calculateMinutesInHours(timeInMinutes);

      let plannedCostWithoutFixRate = Utility.roundOffToTenantDecimalScale(
        plannedTimeInHours * operator?.costPerHour
      );

      let plannedCost = plannedCostWithoutFixRate + operator?.fixedRate || 0;

      let actualCost =
        Utility.roundOffToTenantDecimalScale(
          timeInHours * (operator?.costPerHour || 0)
        ) +
        (operator?.fixedRate || 0) +
        (operatorCostDetails?.additionalCost || 0);
      return {
        ...jobCardData.operatorCostDetails,
        plannedCost,
        actualCost: Utility.roundOffToTenantDecimalScale(actualCost)
      };
    }
  };

  const onOperatorChange = async (operator: any) => {
    const jobCardData = { ...formData };

    let operatorDetails = await OperatorsService.getOperatorsById([
      operator?.operatorId
    ]);
    operatorDetails = operatorDetails?.[0];
    operatorDetails = {
      ...operatorDetails,
      operatorId: operatorDetails?.id,
      operatorName: operatorDetails?.name
    };

    jobCardData.operatorDetails = operatorDetails;
    jobCardData.operatorCostDetails = calculateOperatorCostDetails(jobCardData);
    if (isEditMode) {
      jobCardData.operationCostDetails =
        calculateOperationCostDetails(jobCardData);
      jobCardData.operatorCostDetails =
        calculateOperatorCostDetails(jobCardData);
    }
    setFormData(jobCardData);
  };

  const onPlannedTimeChange = (time: number) => {
    const jobCardData = { ...formData };
    jobCardData.plannedTime = time;
    jobCardData.operationCostDetails =
      calculateOperationCostDetails(jobCardData);
    jobCardData.operatorCostDetails = calculateOperatorCostDetails(jobCardData);
    setFormData(jobCardData);
  };

  const onPlannedTimeBlur = (time: number) => {
    const jobCardData = { ...formData };
    jobCardData.plannedTime = Utility.roundOffToTenantDecimalScale(time);
    jobCardData.operationCostDetails =
      calculateOperationCostDetails(jobCardData);
    jobCardData.operatorCostDetails = calculateOperatorCostDetails(jobCardData);
    setFormData(jobCardData);
  };

  const onPlannedTimeTypeChange = (value: any) => {
    const jobCardData = { ...formData };
    jobCardData.operationTimeType = value;
    jobCardData.operationCostDetails =
      calculateOperationCostDetails(jobCardData);

    jobCardData.operatorCostDetails = calculateOperatorCostDetails(jobCardData);
    setFormData(jobCardData);
  };

  const onActualTimeChange = (time: number) => {
    setFormData((prev: any) => {
      if (time === null || time === undefined) {
        time = 0;
      }
      let jcData = { ...prev, actualTime: time };
      return {
        ...jcData,
        operationCostDetails: calculateOperationCostDetails(jcData),
        operatorCostDetails: calculateOperatorCostDetails(jcData)
      };
    });
  };

  const getAllOperations = async (config: any) => {
    setUpdating(true);
    try {
      OperationsService.apiConfig = config;
      const data = await dispatch(fetchOperationsList());
      let updatedOperator = data?.payload?.content?.filter(
        (data: any) => data.id === formData?.operation?.id
      );
      if (updatedOperator?.length > 0) {
        const updatedFormData = { ...formData };
        updatedFormData.operation = updatedOperator[0];
        setFormData({ ...updatedFormData });
      }
      setUpdating(false);
    } catch (err) {
      setUpdating(false);
    }
  };

  const handleCancelClick = () => {
    !updating && props.onCancel();

    if (isManufacturingRowUpdated) {
      props?.updateJCList();
    }
  };
  const getTrackingHistory = () => {
    if (
      isEditMode &&
      formData?.status !== JOB_CARD_STATUS.OPEN &&
      trackingHistory?.length > 0
    ) {
      setShowTransactionHistory(true);
    }
  };
  /** Renderer */
  const getHeader = () => {
    const getLabelHeader = () => {
      if (isReadOnlyMode) {
        return 'View Job Card';
      } else if (isEditMode) {
        return 'Edit Job Card';
      } else {
        return 'Add Job Card';
      }
    };
    return (
      <div
        className="row justify-content-between bg-gray1 p-h-r p-v-s"
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <div className="row pop-header-drag-handle">
          <DKLabel text={getLabelHeader()} className="fw-m fs-l" />
          {labelLoader && <DKSpinner iconClassName="ic-s ml-s" />}
          {/* {isEditMode && (
            <DKLabel
              text={getStatusColumnConfig().name}
              className={`fw-m ml-r p-h-s p-v-xs border-radius-l text-gray ${
                getStatusColumnConfig().color
              }`}
            />
          )} */}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className={`border-m bg-white ${'mr-r'} `}
            onClick={() => {
              handleCancelClick();
            }}
          />
          {isEditMode &&
            formData?.status !== JOB_CARD_STATUS.OPEN &&
            trackingHistory?.length > 0 && (
              <DKButton
                className={`border-m bg-white mr-r `}
                title={`Tracking Logs`}
                onClick={getTrackingHistory}
              />
            )}

          {/* {isEditMode &&
            formData?.status &&
            formData?.status !== JOB_CARD_STATUS.COMPLETED &&
            formData?.status !== JOB_CARD_STATUS.CANCELLED && (
              <div
                className={`row border-radius-m mr-r ${
                  updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
                }`}
              >
                <DKButton
                  title={getActionButtonTitle()}
                  className=""
                  onClick={onStatusUpdateClick}
                />
                {updating && <DKSpinner iconClassName="ic-s" />}
              </div>
            )} */}

          {((Utility.isProcessManufacturingEnabled() && !isReadOnlyMode) ||
            (!Utility.isProcessManufacturingEnabled() &&
              formData?.workOrder?.status !== WORK_ORDER_STATUS.COMPLETED &&
              (!dependentJobCardsMapping?.currentJCDetails?.hasConsumption ||
                dependentJobCardsMapping?.currentJCDetails?.status !==
                  JOB_CARD_STATUS.COMPLETED))) && (
            <div
              className={`row border-radius-m ${
                updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
              }`}
            >
              <DKButton title={'Save'} onClick={onSave} />
              {updating && <DKSpinner iconClassName="ic-s" />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getPostingDateView = () => {
    return (
      <div className="row justify-content-start gap-2" style={{ height: 30 }}>
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="Posting Date"
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <DKInput
          value={formData?.jobCardDate}
          readOnly={isReadOnlyMode}
          type={INPUT_TYPE.DATE}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
          onChange={(value: any) => {
            const updatedFormData = { ...formData };
            updatedFormData.jobCardDate = value;
            setFormData({ ...updatedFormData });
          }}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          required={false}
          valueStyle={{
            backgroundColor: 'white',
            border: 'none',
            width: GENERALFIELD_JC_VALUE_WIDTH,
            paddingLeft: 0
          }}
          className="mt-s"
        />
      </div>
    );
  };

  const getBomNumberView = () => {
    return (
      <div className="row justify-content-start gap-2" style={{ height: 30 }}>
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="BOM No"
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <DKInput
          type={INPUT_TYPE.TEXT}
          required={false}
          readOnly={true}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formData?.workOrder?.productDocSeqCode ?? 'NA'}
          onChange={(text: any) => {}}
          titleStyle={{ color: 'gray' }}
          valueStyle={{
            backgroundColor: 'white',
            border: 'none',
            idth: GENERALFIELD_JC_VALUE_WIDTH,
            marginLeft: -8
          }}
          titleWidth={GENERALFIELD_JC_VALUE_WIDTH}
          className="mt-s"
        />
      </div>
    );
  };

  const getSOCustomerOrderNoView = () => {
    return (
      <div className="row justify-content-start gap-2" style={{ height: 30 }}>
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="SO Customer Order No."
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <DKInput
          type={INPUT_TYPE.TEXT}
          required={false}
          readOnly={true}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={
            formData?.workOrder?.workOrderSourceDetails
              ?.filter(
                (sourceDetail: any) =>
                  sourceDetail.workOrderSource === DOC_TYPE.SALES_ORDER
              )
              ?.map((soDetail: any) => soDetail.salesOrderCustomerOrderNumber)
              ?.join(',') ?? 'NA'
          }
          onChange={(text: any) => {}}
          titleStyle={{ color: 'gray' }}
          valueStyle={{
            backgroundColor: 'white',
            border: 'none',
            idth: GENERALFIELD_JC_VALUE_WIDTH,
            marginLeft: -8
          }}
          titleWidth={GENERALFIELD_JC_VALUE_WIDTH}
          className="mt-s"
        />
      </div>
    );
  };

  const getProductNameView = () => {
    return (
      <div className="row justify-content-start gap-2" style={{ height: 30 }}>
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="Product Name"
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <DKInput
          type={INPUT_TYPE.TEXT}
          required={false}
          readOnly={true}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formData?.workOrder?.productName ?? 'NA'}
          onChange={(text: any) => {}}
          titleStyle={{ color: 'gray' }}
          valueStyle={{
            backgroundColor: 'white',
            border: 'none',
            width: GENERALFIELD_JC_VALUE_WIDTH,
            marginLeft: -8
          }}
          titleWidth={GENERALFIELD_JC_VALUE_WIDTH}
          className="mt-s"
        />
      </div>
    );
  };
  const getWorkOrderView = () => {
    return (
      <div
        className="row justify-content-start gap-2 position-relative"
        style={{ height: 30 }}
      >
        <div style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="Work Order"
            className="text-gray row"
            style={{ color: INPUT_TITLE_STYLE.color }}
          />
        </div>

        <div
          onClick={() => {
            if (!isEditMode) {
              setShowWOPicker(!showWOPicker);
            }
          }}
          className={`${!isEditMode ? 'cursor-hand' : 'cursor-not-allowed'} `}
          style={{ maxHeight: 29 }}
        >
          <DKTooltipWrapper
            content={`${formData?.workOrder?.documentSequenceCode ?? ''}`}
            tooltipClassName="bg-gray0"
          >
            <DKLabel
              text={formData?.workOrder?.documentSequenceCode ?? 'Select'}
              className={`bg-gray1 border-radius-s p-v-xs p-h-s ${
                canValidate && Utility.isEmpty(formData?.workOrder)
                  ? 'border-red text-red bg-chip-red'
                  : ''
              }`}
              style={{
                width: GENERALFIELD_JC_VALUE_WIDTH,
                height: 28,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            />
          </DKTooltipWrapper>
        </div>
        {showWOPicker && getWOPickerView()}
      </div>
    );
  };

  const getWOPickerView = () => {
    return (
      <DKListPicker2
        data={woStateData}
        onClose={() => {
          setShowWOPicker(false);
        }}
        className="position-absolute shadow-md bg-white border-m z-index-1"
        style={{ top: 0, left: 180 }}
        searchApiConfig={{
          getUrl: (searchValue: any) => {
            const config: WorkOrderAPIConfig = {
              ...WorkOrderService.apiConfig,
              SearchTerm: searchValue,
              Limit: 25,
              Page: 0
            };
            WorkOrderService.apiConfig = config;
            return ApiConstants.URL.BASE + WorkOrderService.woEndPoint();
          },
          dataParser: (data: any) => {
            return data?.content?.filter(
              (wo: any) => wo.status !== WORK_ORDER_STATUS.COMPLETED
            );
          }
        }}
        allowSearch={true}
        searchableKey="documentSequenceCode"
        onSelect={(index: any, obj: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.workOrder = obj;
          fetchAttachments(obj.id, true);
          updatedFormData.operation = null;
          setFormData({ ...updatedFormData });
          setShowWOPicker(false);
          fetchWOAssociatedJobCards(obj?.workOrderCode);
          // setCanValidate(false);
        }}
        renderer={(index: number, obj: any) => {
          return obj?.documentSequenceCode;
        }}
        width={150}
      />
    );
  };

  const getAutoNumberingView = () => {
    let module_name = CUSTOM_NUMBER_INPUT_MODULES.JOB_CARD;

    const selectedFormat = (selected: any) => {
      if (selected.manualMode) {
        setFormData({
          ...formData,
          sequenceFormat: selected.id,
          documentSequenceCode: selected.text,
          manualMode: selected.manualMode
        });
      } else {
        if (selected.id) {
          setFormData({
            ...formData,
            sequenceFormat: selected.id,
            manualMode: selected.manualMode
          });
        }
      }
      setAutoNumberingFormat({ ...selected });
    };

    if (module_name) {
      return (
        <div className="row justify-content-start gap-2" style={{ height: 30 }}>
          <div style={{ width: GENERALFIELD_JC_LABEL_WIDTH }} className="">
            <DKLabel
              text="Auto-Numbering Format"
              className="text-gray row"
              style={{ color: INPUT_TITLE_STYLE.color }}
            />
          </div>
          {isEditMode ? (
            <div
              className="row bg-white"
              style={{ width: GENERALFIELD_JC_VALUE_WIDTH }}
            >
              <DKLabel text={formData?.documentSequenceCode} />
            </div>
          ) : (
            <div className="row" style={{ width: GENERALFIELD_JC_VALUE_WIDTH }}>
              <CustomNumberFormatInput
                module={module_name}
                selectedFormat={selectedFormat}
                isRow={true}
                showCompact={true}
                autoNumberingFormat={autoNumberingFormat}
                buttonStylePadding="4px 4px 4px 0"
              />
            </div>
          )}
        </div>
      );
    }
  };

  const getStatusCard = (value: any) => {
    return JC_STATUS[value as keyof typeof JC_STATUS] || {};
  };

  const getStatusView = () => {
    let status = getStatusCard(formData?.status);
    let statusReadOnly =
      isEditMode &&
      formData?.status &&
      [JOB_CARD_STATUS.COMPLETED, JOB_CARD_STATUS.CANCELLED].includes(
        props.editableJobCard?.status
      );
    let isDependentJCFinished = JobCardHelper.statusValidator(dependentOnJC);
    return (
      <div
        className="row position-relative justify-content-start gap-2"
        style={{ height: 30 }}
      >
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text="Status"
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <div
          onClick={() => {
            if (!isDependentJCFinished) {
              showAlert(
                'Please note',
                `This operation is dependent on <span class="fw-b">${
                  dependentOnJC?.operationName ?? ''
                }</span>, and it is not yet finished.`
              );
              return;
            }
            if (!statusReadOnly) {
              setShowStatusPicker(!showStatusPicker);
            }
          }}
          className={`${
            !statusReadOnly ? 'cursor-hand' : 'cursor-not-allowed'
          }`}
        >
          <DKLabel
            text={Utility.convertInTitleCase(
              formData?.status?.replaceAll('_', ' ')
            )}
            className={`p-h-s border-radius-m p-v-xs ${status.color} text-white border-${status.color}`}
          />
        </div>
        {showStatusPicker && getStatusPickerView()}
      </div>
    );
  };

  const getStatusFromIndex = (index: number) =>
    Object.values(JOB_CARD_STATUS)[index] || JOB_CARD_STATUS.OPEN;

  const getStatusPickerView = () => {
    return (
      <DKListPicker2
        onClose={() => {
          setShowStatusPicker(false);
        }}
        className="position-absolute shadow-md bg-white border-m z-index-1"
        style={{ top: 0, left: 175 }}
        data={Object.values(JOB_CARD_STATUS).map((status) =>
          Utility.convertInTitleCase(status.replaceAll('_', ' '))
        )}
        onSelect={(index: any, obj: any) => {
          const updatedFormData = { ...formData };
          const idsOfSelectedMachines: any[] =
            updatedFormData?.machinesUsed || [];
          if (
            obj === TRANSITION_STATUS.IN_PROGRESS &&
            idsOfSelectedMachines.length > 0 &&
            isMachineEnabled()
          ) {
            showAlert(
              'Alert',
              'Are you sure that you want to use selected Machines?',
              [
                {
                  title: 'No',
                  className: 'bg-gray1 text-black-light',
                  onClick: () => {
                    updatedFormData.machinesInUse = false;
                    onJobCardStatusUpdate(updatedFormData, obj);
                  }
                },
                {
                  title: 'Yes',
                  className: 'bg-button text-white ml-2',
                  onClick: () => {
                    updatedFormData.machinesInUse = true;
                    onJobCardStatusUpdate(updatedFormData, obj);
                  }
                }
              ]
            );
          } else {
            onJobCardStatusUpdate(updatedFormData, obj);
          }
        }}
        renderer={(index: number, obj: any) => {
          let status = obj.toUpperCase().replaceAll(' ', '_');
          status = getStatusCard(status);
          return (
            <div
              className={`row parent-width p-h-s border-radius-m p-v-xs justify-content-center ${status.color} text-white border-${status.color}`}
            >
              {obj}
            </div>
          );
        }}
        width={120}
      />
    );
  };

  const onJobCardStatusUpdate = (updatedFormData: any, obj: any) => {
    updatedFormData.status = obj.toUpperCase().replaceAll(' ', '_');

    if (
      updatedFormData.status === JOB_CARD_STATUS.CANCELLED ||
      updatedFormData.status === JOB_CARD_STATUS.COMPLETED
    ) {
      if (updatedFormData.actualStartDate === null) {
        updatedFormData.actualStartDate = new Date();
      }
      if (
        updatedFormData.actualEndDate === null ||
        (isEditMode &&
          ![JOB_CARD_STATUS.COMPLETED, JOB_CARD_STATUS.CANCELLED].includes(
            props.editableJobCard.status
          ))
      ) {
        updatedFormData.actualEndDate = new Date();
      }
      if (typeof updatedFormData?.actualTime !== 'number') {
        updatedFormData.actualTime = 0;
      }
    }
    setFormData({ ...updatedFormData });
    setShowStatusPicker(false);
  };

  /********************** [BOOK-4831] Operation readonly in edit mode **************************/

  const existingOperations = dependentJobCardsMapping?.allJCDetails?.map(
    (jobCard: any) => jobCard?.operationId
  );
  const operations = operationData?.content?.filter(
    (operation: any) => !existingOperations?.includes(operation?.id)
  );

  const getOperationView = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Operation'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        titleStyle={INPUT_TITLE_STYLE}
        readOnly={isReadOnlyMode || isEditMode}
        value={formData?.operation}
        formatter={(obj: any) => {
          return obj.name;
        }}
        required={true}
        canValidate={canValidate}
        className=""
        onChange={onOperationChange}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'name',
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: any) => {
              const config: OperationsAPIConfig = {
                ...OperationsService.apiConfig,
                SearchTerm: searchValue,
                Limit: 25,
                Page: 0
              };
              OperationsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + OperationsService.operationsEndpoint()
              );
            },
            dataParser: (data: any) => {
              return data.content;
            }
          },
          data: operations ?? [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const handleDependencyChange = (op: any) => {
    const updatedFormData = { ...formData };
    if (op.operationName?.toLowerCase() === 'none') {
      let start_date = new Date(updatedFormData?.oldPlannedStartDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      setDependentOnJC(null);
    } else {
      const isCyclicDependency = JobCardHelper.isCyclicDependency(
        props.editableJobCard,
        woAssociatedJobCards,
        op
      );
      const isSingularCyclicDependency =
        JobCardHelper.isSingularCyclicDependency(
          props.editableJobCard,
          woAssociatedJobCards
        );
      if (isCyclicDependency || isSingularCyclicDependency) {
        showAlert(
          'Cyclic dependency found!',
          `You can't select this operation as its creating cyclic dependency on one of the operation.`
        );
        setDependentOnJC(null);
        return;
      }
      let start_date = new Date(op?.plannedEndDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      setDependentOnJC(op);
    }
    setFormData({ ...updatedFormData });
  };

  const getDependentOnView = () => {
    let dependentOnData =
      woAssociatedJobCards.filter((woJC: any) => woJC.id !== formData?.id) ??
      [];
    let usedJCArray = getUsedJCArray(dependentJobCardsMapping);
    dependentOnData = dependentOnData.filter(
      (woJC: any) => !usedJCArray.includes(woJC.jobCardCode)
    );
    dependentOnData.unshift({ operationName: 'None' });
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Dependent On'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        titleStyle={INPUT_TITLE_STYLE}
        readOnly={isDependentOnFieldReadOnly(
          isReadOnlyMode,
          dependentJobCardsMapping,
          presenter.isLinkedInventory
        )}
        value={dependentOnJC}
        formatter={(obj: any) => {
          return obj.operationName ?? 'None';
        }}
        required={false}
        canValidate={canValidate}
        className=""
        onChange={(op: any) => {
          handleDependencyChange(op);
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'name',
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: any) => {},
            dataParser: (data: any) => {}
          },
          data: dependentOnData ?? [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.operationName}`} />;
          }
        }}
      />
    );
  };

  const getWorkStationView = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Workstation'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        titleStyle={INPUT_TITLE_STYLE}
        readOnly={isReadOnlyMode}
        value={formData?.workStation}
        formatter={(obj: any) => {
          return obj.workstationName;
        }}
        required={false}
        canValidate={false}
        className=""
        onChange={(obj: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.workStation = obj;
          updatedFormData.workstationDetails = obj;
          setFormData({ ...updatedFormData });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: 'workstationName',
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: any) => {
              const config: WorkstationsAPIConfig = {
                ...WorkstationsService.apiConfig,
                SearchTerm: searchValue,
                Limit: 25,
                Page: 0
              };
              WorkstationsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE +
                WorkstationsService.workStationEndpoint()
              );
            },
            dataParser: (data: any) => {
              return data.content;
            }
          },
          data: workstationData?.content ?? [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.workstationName}`} />;
          }
        }}
      />
    );
  };

  const getOperatorView = () => {
    let operatorValue =
      formData?.operatorDetails && formData?.operatorDetails.operatorId === null
        ? null
        : formData?.operatorDetails;
    return (
      <DKInput
        value={operatorValue}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        titleStyle={INPUT_TITLE_STYLE}
        renderer={(operator: any) => {
          return operator['operatorName'] ?? operator['name'];
        }}
        readOnly={isReadOnlyMode}
        displayKey="name"
        title="Operators"
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        canValidate={canValidate}
        onChange={onOperatorChange}
        dropdownConfig={{
          className: '',
          title: 'Select operators',
          allowSearch: false,
          searchableKey: 'name',
          checkMarkColor: 'bg-blue',
          data: formData?.operation?.operators,
          onSearchTextChange: (text: any) => {},
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.operatorName ?? obj?.name}`} />;
          },
          onSelect: (index: any, value: any) => {},
          button: checkUserPermission(PERMISSIONS_BY_MODULE.OPERATIONS.EDIT)
            ? {
                title: 'Assign operator',
                className: 'bg-button text-white',
                onClick: () => {
                  if (formData?.operation) {
                    setEditableOperation(formData?.operation);
                    setShowAddOperationPopup(true);
                  }
                }
              }
            : null
        }}
      />
    );
  };

  const getPlanedTime = () => {
    return (
      <div className="row align-items-end gap-2">
        <DKInput
          title="Time"
          readOnly={isEditMode}
          type={INPUT_TYPE.NUMBER}
          className=""
          required={false}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          placeholder="1.5"
          titleStyle={INPUT_TITLE_STYLE}
          value={Utility.roundOffToTenantDecimalScale(
            formData?.plannedTime || 0
          )}
          onChange={onPlannedTimeChange}
          onBlur={(e: any) => onPlannedTimeBlur(e.target.value)}
        />
        <DKInput
          title=""
          readOnly={isEditMode}
          onChange={onPlannedTimeTypeChange}
          value={formData?.operationTimeType || TIME_TYPES[1]}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.DROPDOWN}
          formatter={(obj: any) => {
            return Utility.convertInTitleCase(obj?.label);
          }}
          className="mt-l"
          dropdownConfig={{
            title: '',
            allowSearch: false,
            searchableKey: '',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: null,
            data: TIME_TYPES,
            renderer: (index: any, obj: any) => {
              return (
                <DKLabel text={`${Utility.convertInTitleCase(obj?.label)}`} />
              );
            }
          }}
        />
      </div>
    );
  };

  const getIndexofUsedMachines = (ids: number[]): number[] => {
    const selecteMachinesUsed = formData?.machinesUsed ?? []; // ids of selecteds machines
    const linkedMachines = formData?.operation?.linkedInMachines ?? [];
    const selectedIndexes: number[] = [];
    linkedMachines.forEach((machine: any, index: number) => {
      if (selecteMachinesUsed.includes(String(machine.id))) {
        selectedIndexes.push(index);
      }
    });
    return selectedIndexes;
  };

  const getLinkedMachines = () => {
    const items = formData.operation?.linkedInMachines ?? [];

    return (
      <div
        className="column mb-m w-[50%]"
        style={{
          width: '49%'
        }}
      >
        <DKInput
          required={false}
          title={'Linked Machines'}
          type={INPUT_TYPE.DROPDOWN}
          displayKey="name"
          canValidate={canValidate}
          onChange={(selectedIndexes: number[]) => {
            const linkedInMachines = formData.operation?.linkedInMachines ?? [];
            let selectedMachines = selectedIndexes.map(
              (index) => linkedInMachines[index]
            );
            const idsOfSelectedMachines = selectedMachines.map((machine) =>
              String(machine.id)
            );
            const updatedFormData = { ...formData };
            updatedFormData.machinesUsed = idsOfSelectedMachines;
            setFormData({ ...updatedFormData });
          }}
          value={getIndexofUsedMachines(formData.machinesUsed || [])}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          dropdownConfig={{
            className: 'shadow-m width-auto',
            title: 'Select Machines',
            style: {},
            allowSearch: true,
            searchableKey: 'name',
            multiSelect: true,
            selectedIndexes: [],
            checkMarkColor: 'bg-blue',
            data: items,
            renderer: (index: number, obj: any) => {
              return <DKLabel text={obj.name} />;
            },
            onSelect: (index: number, value: any) => {},
            onClear: () => {}
          }}
        />
      </div>
    );
  };

  const getActualTime = () => {
    return (
      <DKInput
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={isEditMode}
        canValidate={canValidate}
        type={INPUT_TYPE.NUMBER}
        title={'Actual Time (Minutes)'}
        readOnly={
          Utility.isProcessManufacturingEnabled()
            ? isReadOnlyMode
            : (dependentJobCardsMapping?.currentJCDetails?.hasConsumption &&
                dependentJobCardsMapping?.currentJCDetails?.status ===
                  JOB_CARD_STATUS.COMPLETED) ??
              false
        }
        value={formData?.actualTime}
        onChange={(text: any) => {
          onActualTimeChange(text);
        }}
        // onBlur={() => {
        //   onActualTimeChange(
        //     Utility.roundOffToTenantDecimalScale(formData?.actualTime)
        //   );
        // }}
      />
    );
  };

  const getPlannedCostInput = () => {
    return (
      <DKInput
        title="Planned Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        readOnly={isReadOnlyMode}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operationCostDetails?.plannedCost)
          ) || 0
        }
        onChange={(text: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.operationCostDetails = {
            ...updatedFormData.operationCostDetails,
            plannedCost: text
          };
          updatedFormData.operationCostDetails =
            calculateOperationCostDetails(updatedFormData);
          setFormData({ ...updatedFormData });
        }}
      />
    );
  };

  const getAdditionalCostInput = () => {
    return (
      <DKInput
        title="Additional Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        readOnly={isReadOnlyMode}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operationCostDetails?.additionalCost)
          ) || 0
        }
        onBlur={(e: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.operationCostDetails = {
            ...updatedFormData.operationCostDetails,
            additionalCost: !isNaN(Number(e.target.value))
              ? Utility.roundOffToTenantDecimalScale(Number(e.target.value))
              : 0
          };
          updatedFormData.operationCostDetails =
            calculateOperationCostDetails(updatedFormData);
          setFormData({ ...updatedFormData });
        }}
        onChange={(text: any) => {}}
      />
    );
  };

  const getActualCostInput = () => {
    return (
      <DKInput
        title="Actual Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        readOnly={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operationCostDetails?.actualCost)
          ) || 0
        }
        onChange={(text: any) => {
          // const updatedFormData = { ...formData };
          // updatedFormData.operationCostDetails = {
          //   ...updatedFormData.operationCostDetails,
          //   actualCost: !isNaN(text) ? Number(text) : 0
          // };
          // setFormData({ ...updatedFormData });
        }}
      />
    );
  };

  const getPlannedOperatorCostInput = () => {
    return (
      <DKInput
        title="Planned Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        readOnly={isReadOnlyMode}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operatorCostDetails?.plannedCost)
          ) || 0
        }
        onChange={(text: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.operatorCostDetails = {
            ...updatedFormData.operatorCostDetails,
            plannedCost: text
          };
          updatedFormData.operatorCostDetails =
            calculateOperatorCostDetails(updatedFormData);
          setFormData({ ...updatedFormData });
        }}
      />
    );
  };

  const getAdditionalOperatorCostInput = () => {
    return (
      <DKInput
        title="Additional Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        canValidate={canValidate}
        readOnly={isReadOnlyMode}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operatorCostDetails?.additionalCost)
          ) || 0
        }
        onBlur={(e: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.operatorCostDetails = {
            ...updatedFormData.operatorCostDetails,
            additionalCost: !isNaN(e.target.value)
              ? Utility.roundOffToTenantDecimalScale(Number(e.target.value))
              : 0
          };
          updatedFormData.operatorCostDetails =
            calculateOperatorCostDetails(updatedFormData);
          setFormData({ ...updatedFormData });
        }}
        onChange={(text: any) => {}}
      />
    );
  };

  const getActualOperatorCostInput = () => {
    return (
      <DKInput
        title="Actual Cost"
        type={INPUT_TYPE.NUMBER}
        required={false}
        readOnly={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        placeholder="0.00"
        titleStyle={INPUT_TITLE_STYLE}
        value={
          Utility.roundOffToTenantDecimalScale(
            Number(formData?.operatorCostDetails?.actualCost)
          ) || 0
        }
        onChange={(text: any) => {
          const updatedFormData = { ...formData };
          updatedFormData.operatorCostDetails = {
            ...updatedFormData.operatorCostDetails,
            actualCost: text
          };
          setFormData({ ...updatedFormData });
        }}
      />
    );
  };

  const getInstructionsView = () => {
    return (
      <EmailEditor
        editorID="jobCard-editor-body"
        body={formData?.instructions}
        isPlaceholderRequired={false}
        inputPlaceholder={`Instructions for operation`}
      />
    );
  };

  const getProductionDetails = () => {
    const isStatusCancelledOrCompleted =
      formData?.status === JOB_CARD_STATUS.CANCELLED ||
      formData?.status === JOB_CARD_STATUS.COMPLETED;
    return (
      <div className="column parent-width pb-l">
        <DKLabel text="Production Details" className="fw-m fs-m" />
        <div className={`row gap-2 align-items-start mt-l mb-m`}>
          {getOperationView()}
          {isEditMode && getDependentOnView()}
          {getWorkStationView()}
          {getOperatorView()}
          {!isEditMode && getPlanedTime()}
        </div>
        {isEditMode && isMachineEnabled() && getLinkedMachines()}
        {/* Temporary Commented */}
        {/* <div className="row justify-content-end text-gray mt-1">
          {Utility.getDetailTimeConvertion(
            formData?.plannedTime,
            formData?.operationTimeType
          )}
        </div> */}
        {/* Temporary Commented */}
        {isEditMode && (
          <div className="row align-items-end gap-2 mb-m">
            {getPlanedTime()}
            {isStatusCancelledOrCompleted && getActualTime()}
          </div>
        )}
        <div className="mt-m parent-width position-relative">
          {isReadOnlyMode && (
            <div className="position-absolute parent-size cursor-not-allowed z-index-2"></div>
          )}
          {getInstructionsView()}
        </div>
      </div>
    );
  };

  const balanceDates = (from: string, to: string) => {
    if (
      Utility.isValidDate(from) &&
      Utility.isValidDate(to) &&
      from?.includes('T') &&
      to?.includes('T')
    ) {
      const zoneOffset = Utility.getTimeZoneOffsetValue();
      let formattedToDate =
        to.substring(0, to.indexOf('T')) + from.substring(from.indexOf('T'));
      if (!formattedToDate.includes('+') && !formattedToDate.includes('-')) {
        formattedToDate += zoneOffset;
      }
      return formattedToDate;
    }
    return to;
  };

  const getOperationCostDetails = () => {
    return (
      <div className="column parent-width pb-l ">
        <DKLabel text="Operation Cost Details" className="fw-m fs-m" />
        <div className="row mt-l gap-2">
          {getPlannedCostInput()}
          {getAdditionalCostInput()}
          {isEditMode && getActualCostInput()}
        </div>
        <DKLine style={{ height: 2 }} className="mt-l" />
      </div>
    );
  };

  const getOperatorCostDetails = () => {
    return (
      <div className="column parent-width pb-l ">
        <DKLabel text="Operator Cost Details" className="fw-m fs-m" />
        <div className="row mt-l mb-m gap-2">
          {getPlannedOperatorCostInput()}
          {getAdditionalOperatorCostInput()}
          {isEditMode && getActualOperatorCostInput()}
        </div>
      </div>
    );
  };

  const getProductImage = () => {
    let product = productsData?.content?.find(
      (item: any) => item.productId === formData?.workOrder?.productCode
    );
    return product?.images && product.images?.length > 0 ? (
      <div style={{ maxHeight: 150, maxWidth: 150 }}>
        <DKTooltipWrapper
          content={`<img width="180" src=${product.images[0]} />`}
          preventAutoFormat={true}
          tooltipPositionFixed={true}
        >
          <img
            className="parent-width parent-height border-radius-s"
            src={product.images[0]}
          />
        </DKTooltipWrapper>
      </div>
    ) : null;
  };

  const getDatesView = (type: JC_DATES_MODEL) => {
    let isDateFieldReadOnly = false;
    if (
      (type === JC_DATES.plannedStartDate ||
        type === JC_DATES.plannedEndDate) &&
      formData?.status !== JOB_CARD_STATUS.OPEN
    ) {
      isDateFieldReadOnly = true;
    }

    if (isReadOnlyMode) {
      isDateFieldReadOnly = true;
    }

    return (
      <div className="row justify-content-start gap-2" style={{ height: 30 }}>
        <div className="" style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}>
          <DKLabel
            text={JobCardHelper.getDateName(type)}
            className="text-gray row"
            style={{
              width: GENERALFIELD_JC_LABEL_WIDTH,
              color: INPUT_TITLE_STYLE.color
            }}
          />
        </div>
        <DKInput
          value={JobCardHelper.getDateValue(type, formData) ?? '-'}
          readOnly={isDateFieldReadOnly}
          type={INPUT_TYPE.DATE}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
          onChange={(value: any) => {
            if (isEditMode && !Utility.isEmpty(dependentOnJC)) {
              const dateVal = DateFormatService.compareDates(
                value,
                dateWithoutTime(new Date(dependentOnJC?.plannedEndDate))
              );

              if (dateVal === DATE_COMPARISON.LEFT_DATE_IS_BEFORE_RIGHT_DATE) {
                showAlert(
                  'Please note',
                  `You cannot set this date before planned end date of the job card: <span class="fw-b">${
                    dependentOnJC?.operationName ?? ''
                  }</span>.`
                );
                const updatedFormData = { ...formData };
                updatedFormData.plannedStartDate =
                  updatedFormData.plannedStartDate;
                updatedFormData.plannedEndDate = updatedFormData.plannedEndDate;
                setFormData({ ...updatedFormData });
                return;
              }
            }

            const updatedFormData = { ...formData };
            if (type === JC_DATES.plannedStartDate) {
              updatedFormData.plannedStartDate = value;
              const time: any = Utility.calculateTimeInMinutes(
                formData?.plannedTime,
                !Utility.isEmpty(formData?.operationTimeType?.value)
                  ? formData?.operationTimeType?.value
                  : TIME_TYPES[1]?.value
              );
              const endDate = DateFormatService.addMinutes(value, time);
              updatedFormData.plannedEndDate = endDate;
            } else if (type === JC_DATES.plannedEndDate) {
              const dateVal = DateFormatService.compareDates(
                value,
                dateWithoutTime(new Date(formData?.plannedStartDate))
              );
              if (dateVal === DATE_COMPARISON.LEFT_DATE_IS_BEFORE_RIGHT_DATE) {
                showAlert(
                  'Please note',
                  'You cannot set end date before the start date.'
                );
                const updatedFormData = { ...formData };
                updatedFormData.plannedEndDate = updatedFormData.plannedEndDate;
                setFormData({ ...updatedFormData });
                return;
              }
              updatedFormData.plannedEndDate = value;
            } else if (type === JC_DATES.actualStartDate) {
              const dateVal = DateFormatService.compareDates(
                dateWithoutTime(new Date(value)),
                dateWithoutTime(new Date(formData?.actualEndDate))
              );
              if (dateVal === DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE) {
                showAlert(
                  'Please note',
                  'You cannot set actual start date after the actual end date.'
                );
                const updatedFormData = { ...formData };
                updatedFormData.actualStartDate =
                  updatedFormData.actualStartDate;
                setFormData({ ...updatedFormData });
                return;
              }
              updatedFormData.actualStartDate = value;
            } else if (type === JC_DATES.actualEndDate) {
              const dateVal = DateFormatService.compareDates(
                dateWithoutTime(new Date(value)),
                dateWithoutTime(new Date(formData?.actualStartDate))
              );
              if (dateVal === DATE_COMPARISON.LEFT_DATE_IS_BEFORE_RIGHT_DATE) {
                showAlert(
                  'Please note',
                  'You cannot set actual end date before the actual start date.'
                );
                const updatedFormData = { ...formData };
                updatedFormData.actualEndDate = updatedFormData.actualEndDate;
                setFormData({ ...updatedFormData });
                return;
              }
              updatedFormData.actualEndDate = value;
            }
            setFormData({ ...updatedFormData });
          }}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          required={false}
          valueStyle={dateStyle(type)}
          className="mt-s"
        />
      </div>
    );
  };

  const getDateColor = () => {
    if (formData.actualEndDate > formData.plannedEndDate) {
      return 'green';
    } else if (formData.actualEndDate < formData.plannedEndDate) {
      return 'red';
    } else {
      return 'black';
    }
  };

  const dateStyle = (type: any) => {
    const styleForDate = {
      backgroundColor: 'white',
      border: 'none',
      width: GENERALFIELD_JC_VALUE_WIDTH,
      paddingLeft: 0
    };
    // return type === JC_DATES.actualEndDate
    //   ? { ...styleForDate, color: getDateColor() }
    //   : styleForDate;
    return styleForDate;
  };

  const getNewDateSection = () => {
    const isStatusCancelledOrCompleted =
      formData?.status === JOB_CARD_STATUS.CANCELLED ||
      formData?.status === JOB_CARD_STATUS.COMPLETED;
    return (
      <>
        {getDatesView(JC_DATES.plannedStartDate)}
        {getDatesView(JC_DATES.plannedEndDate)}
        {isStatusCancelledOrCompleted && getDatesView(JC_DATES.actualStartDate)}
        {isStatusCancelledOrCompleted && getDatesView(JC_DATES.actualEndDate)}
      </>
    );
  };
  const isNotATrackableRecord = () => {
    return ipTime !== 'NA' && holdTime !== 'NA';
  };
  const getGeneralFields = () => {
    return (
      <div className="column parent-width pb-l p-v-l pt-l">
        <div className="row justify-content-between align-items-start">
          <div className="column" style={{ width: '50%' }}>
            {getAutoNumberingView()}
            {getPostingDateView()}
            {getWorkOrderView()}
            {getBomNumberView()}
            {formData?.workOrder?.workOrderSourceDetails?.some(
              (sourceDetail: any) =>
                Utility.isNotEmpty(sourceDetail.salesOrderCustomerOrderNumber)
            ) && getSOCustomerOrderNoView()}
            {getStatusView()}
            {isNotATrackableRecord() && (
              <>
                <div
                  className="row justify-content-start gap-2"
                  style={{ height: 30 }}
                >
                  <div
                    style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}
                    className=""
                  >
                    <DKLabel
                      text="In Progress"
                      className="text-gray row"
                      style={{ color: INPUT_TITLE_STYLE.color }}
                    />
                  </div>
                  <div
                    className="row bg-white"
                    style={{ width: GENERALFIELD_JC_VALUE_WIDTH }}
                  >
                    <DKLabel text={Utility.getTimeFromSeconds(ipTime)} />
                  </div>
                </div>

                <div
                  className="row justify-content-start gap-2"
                  style={{ height: 30 }}
                >
                  <div
                    style={{ width: GENERALFIELD_JC_LABEL_WIDTH }}
                    className=""
                  >
                    <DKLabel
                      text="Hold"
                      className="text-gray row"
                      style={{ color: INPUT_TITLE_STYLE.color }}
                    />
                  </div>
                  <div
                    className="row bg-white"
                    style={{ width: GENERALFIELD_JC_VALUE_WIDTH }}
                  >
                    <DKLabel text={Utility.getTimeFromSeconds(holdTime)} />
                  </div>
                </div>
              </>
            )}
            {attachments?.length > 0 && (
              <div className="column" style={{ width: '50%' }}>
                {getNewDateSection()}
              </div>
            )}
          </div>
          {attachments?.length === 0 && (
            <div
              className="column jc-date-picker-position"
              style={{ width: '50%' }}
            >
              {getNewDateSection()}
            </div>
          )}
          {getAttachmentSection()}
        </div>
        <DKLine style={{ height: 2 }} className="mt-l" />
      </div>
    );
  };

  const getAttachmentSection = () => {
    return (
      <div
        className="column parent-width border rounded p-2"
        style={{ height: 271, width: 250 }}
      >
        <DKLabel
          text={`${
            attachments.length !== 0
              ? 'Attachments (' + attachments.length + ')'
              : ''
          }`}
          className="fw-m"
        />
        {attachments?.length === 0 && (
          <div className="column parent-width justify-content-center align-items-center parent-height m-auto">
            <DKLabel text="No attachments" className="text-gray" />
          </div>
        )}
        {attachments?.length > 0 && (
          <div className="row parent-width align-items-start flex-wrap gap-1 mt-m">
            <div
              className="column align-items-start gap-1 overflow-y-auto"
              style={{ height: 188 }}
            >
              {attachments.map((attachment: any, index: number) =>
                attachmentRowData(attachment)
              )}
            </div>
            <div className="row justify-content-between">
              {attachments?.length > 5 && (
                <DKButton
                  className="text-blue fw-m p-0 mr-s"
                  title="More"
                  style={{ padding: 0 }}
                  onClick={() => setShowAttachmentPopup(true)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  const attachmentRowData = (attachment: any) => {
    return (
      <div className="row width-auto">
        <div
          className={`row border-m border-radius-s p-h-s p-v-s bg-gray0 justify-content-between`}
          key={attachment.attachmentId}
          style={{ width: 230 }}
        >
          <div className="flex flex-row items-center">
            <DKIcon
              src={DKIcons.ic_document}
              className="column ic-s cursor-pointer opacity-60"
              onClick={() => {
                downloadAttachment(attachment);
              }}
            />
            <DKTooltipWrapper
              content={`${attachment.attachmentFileName}`}
              tooltipClassName="bg-gray0"
            >
              <DKLabel
                text={attachment.attachmentFileName}
                className=" ml-s cursor-pointer border-none"
                style={{
                  maxWidth: 180,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                onClick={() => {
                  downloadAttachment(attachment);
                }}
              />
            </DKTooltipWrapper>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////// Fields only for process manufacturing //////////////////////////
  const getManufacturingProcessFields = () => {
    const getProcessTypeSelectionView = () => {
      return (
        <div
          style={{
            width: '35%'
          }}
        >
          <DKInput
            type={INPUT_TYPE.DROPDOWN}
            title={'Process Type'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            titleStyle={INPUT_TITLE_STYLE}
            readOnly={
              isReadOnlyMode ||
              !Utility.isEmpty(
                processJCDetails?.jobCardLinkDetailsResponses?.[0]?.processType
              )
            }
            value={presenter.getSelectedProcessType()}
            formatter={(obj: any) => {
              return obj.name ?? '';
            }}
            required={false}
            canValidate={canValidate}
            className=""
            onChange={(type: any) => {
              if (
                !validateProcessTypeChange(
                  type,
                  dependentJobCardsMapping?.currentJCDetails,
                  dependentJobCardsMapping?.allJCDetails
                )
              ) {
                const jobCardData = { ...formData };
                jobCardData.processType = null;
                setFormData(jobCardData);
                setManufacturingRows(presenter.getInitialManufacturingRow());
                return;
              }
              const jobCardData = { ...formData };
              jobCardData.processType = type;
              setFormData(jobCardData);

              setManufacturingRows(presenter.getInitialManufacturingRow());
            }}
            dropdownConfig={{
              title: '',
              allowSearch: false,
              searchableKey: 'name',
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: presenter.getProcessTypeData(),
              renderer: (index: any, obj: any) => {
                return <DKLabel text={obj?.name ?? ''} />;
              }
            }}
          />
        </div>
      );
    };

    const getManufacturingGrid = () => {
      const getRowButtons = (lineItem: any) => {
        const isQCColumnChanged =
          lineItem?.jobCardLinkDetails?.[0]?.qcNeeded !==
          lineItem?.qcNeeded?.key;
        let rowButtons: any[] = [];

        const totalAllocatedQty = presenter.getAllocatedQty(
          lineItem?.detailsForQtyToBeAssign?.[0]?.warehouseInventoryData
        );

        if (!isReadOnlyMode && lineItem.editable) {
          if (
            isAssignOptionVisible(
              lineItem,
              formData?.processType,
              dependentOnJC
            )
          ) {
            rowButtons.push(
              presenter.getAssignButton(
                'Assign',
                lineItem,
                () => {
                  if (!isManufacturingRowUpdated) {
                    setIsManufacturingRowUpdated(true);
                  }
                  getProcessManufacturingAPICalls();
                },
                isQCColumnChanged
              )
            );
          }
          if (isAvailableForQC(lineItem)) {
            rowButtons.push(
              getQCAssignButton((data: any) => {
                setShowQCPopup(true);
                setSelectedGridIndex(data?.rowIndex);
              }, 'underline cursor-hand text-blue')
            );
          }

          if (isConsumptionOptionVisible(lineItem)) {
            rowButtons.push(
              getConsumeOrProcessButton(
                'Consume',
                lineItem,
                [lineItem?.jobCardLinkDetails?.[0]?.id],
                () => {
                  getProcessManufacturingAPICalls();
                }
              )
            );
          }

          if (isProcessOptionVisible(lineItem)) {
            rowButtons.push(
              getConsumeOrProcessButton(
                'Process',
                lineItem,
                [lineItem?.jobCardLinkDetails?.[0]?.id],
                () => {
                  getProcessManufacturingAPICalls();
                }
              )
            );
          }
        }

        return rowButtons;
      };

      const getRowContextMenu = (lineItem: any) => {
        let contextMenuOptions: any[] = [];
        const isQCColumnChanged =
          lineItem?.jobCardLinkDetails?.[0]?.qcNeeded !==
          lineItem?.qcNeeded?.key;

        const totalAllocatedQty = presenter.getAllocatedQty(
          lineItem?.detailsForQtyToBeAssign?.[0]?.warehouseInventoryData
        );

        const totalAssignedQty = presenter.getAllocatedQty(
          lineItem?.item?.itemWarehouseInventoryData ?? []
        );

        const totalProducedQty = JobCardHelper.getProducedQtyOnJCFormGrid(
          workOrderDetails,
          lineItem
        );

        const isTotalAssingedQTYGreater = totalAssignedQty > totalAllocatedQty;

        if (!isReadOnlyMode && lineItem.editable) {
          if (
            isAssignOptionVisible(
              lineItem,
              formData?.processType,
              dependentOnJC
            )
          ) {
            contextMenuOptions.push(
              presenter.getAssignButton(
                'Assign',
                lineItem,
                () => {
                  if (!isManufacturingRowUpdated) {
                    setIsManufacturingRowUpdated(true);
                  }
                  getProcessManufacturingAPICalls();
                },
                isQCColumnChanged
              )
            );
          }

          if (isAvailableForQC(lineItem)) {
            contextMenuOptions.push(
              getQCAssignButton((data: any) => {
                setShowQCPopup(true);
                setSelectedGridIndex(data?.rowIndex);
              })
            );
          }

          if (isConsumptionOptionVisible(lineItem)) {
            contextMenuOptions.push(
              getConsumeOrProcessButton(
                'Consume',
                lineItem,
                [lineItem?.jobCardLinkDetails?.[0]?.id],
                () => {
                  getProcessManufacturingAPICalls();
                }
              )
            );
          }

          if (isProcessOptionVisible(lineItem)) {
            contextMenuOptions.push(
              getConsumeOrProcessButton(
                'Process',
                lineItem,
                [lineItem?.jobCardLinkDetails?.[0]?.id],
                () => {
                  getProcessManufacturingAPICalls();
                }
              )
            );
          }

          let showDeleteBtn = false;
          if (
            totalProducedQty <= 0 &&
            formData?.processType?.key === JC_PROCESS_TYPE.PRODUCTION
          ) {
            showDeleteBtn = true;
          } else if (
            formData?.processType?.key !== JC_PROCESS_TYPE.PRODUCTION &&
            totalAssignedQty <= 0
          ) {
            showDeleteBtn = true;
          }

          if (showDeleteBtn) {
            contextMenuOptions.push(
              presenter.getDeleteButton(
                lineItem,
                processJCDetails,
                (makeApiCall: boolean, data: any) => {
                  if (!makeApiCall) {
                    const updatedState = [...manufacturingRows];
                    updatedState.splice(data?.rowIndex, 1);

                    if (updatedState.length === 0) {
                      const jobCardData = { ...formData };
                      jobCardData.processType = null;
                      setFormData(jobCardData);
                    }

                    setManufacturingRows([...updatedState]);
                  } else {
                    getProcessManufacturingAPICalls();
                    if (!isManufacturingRowUpdated) {
                      setIsManufacturingRowUpdated(true);
                    }
                  }
                }
              )
            );
          }
        }

        return !Utility.isEmpty(contextMenuOptions) ? contextMenuOptions : null;
      };

      return (
        <div className="row">
          <DKDataGrid
            title={``}
            needShadow={false}
            needColumnIcons={false}
            needBorder={true}
            needTrailingColumn={true}
            allowBulkOperation={false}
            allowColumnSort={false}
            filterData={[]}
            allowColumnDelete={false}
            allowRowEdit={true}
            allowColumnEdit={false}
            allowFilter={false}
            allowColumnAdd={false}
            allowBottomRowAdd={false}
            allowSearch={false}
            allowShare={false}
            width={JC_PROCESS_MANAGEMENT_GRID_WIDTH}
            rows={deepClone(manufacturingRows)?.map((itemRow: any) => {
              return {
                ...itemRow,
                rowButtons: getRowButtons(itemRow),
                rowContextMenu: getRowContextMenu(itemRow),
                nonEditableColumns: presenter.getNonEditableColumns(
                  manufacturingRows,
                  processJCDetails,
                  itemRow,
                  formData
                )
              };
            })}
            columns={[
              ...manufacturingColumns,
              {
                id: 'action',
                key: 'action',
                name: 'Actions',
                type: INPUT_TYPE.BUTTON,
                width: 190,
                options: []
              }
            ]}
            onRowUpdate={(data: any) => {
              let rows = presenter.processManufacturingGridRowUpdate(
                data,
                formData?.processType,
                dependentJobCardsMapping,
                formData
              );
              setManufacturingRows([...rows]);
            }}
            onRowClick={(data: any) => {}}
          />
        </div>
      );
    };

    const getJobCardQCPopup = () => {
      return showQCPopup ? (
        <JobCardQC
          productCode={manufacturingRows?.[selectedGridIndex]?.productCode}
          jobCardCode={formData?.jobCardCode}
          jobCardDocumentSequenceCode={formData?.documentSequenceCode}
          woCode={formData?.workOrder?.documentSequenceCode ?? ''}
          documentUOMSchemaDefinition={
            manufacturingRows?.[selectedGridIndex]?.item
              ?.documentUOMSchemaDefinition
          }
          onClose={() => {
            setShowQCPopup(false);
          }}
          onSuccess={() => {
            const [linkedItem = {}] =
              manufacturingRows?.[selectedGridIndex]?.jobCardLinkDetails || {};
            ProcessManufacturingService.jcDetailsCompletion([
              linkedItem?.id
            ]).then(
              (res: any) => {
                if (Utility.isNotEmpty(res)) {
                  setShowQCPopup(false);
                  getProcessManufacturingAPICalls();
                }
              },
              (err: any) => {}
            );
          }}
        />
      ) : null;
    };

    return (
      <div className="column parent-width pb-l">
        <DKLabel text="Process Manufacturing" className="fw-m fs-m" />
        <div className={`column parent-width gap-2 mt-l`}>
          {getProcessTypeSelectionView()}
          {!Utility.isEmpty(presenter.getSelectedProcessType()) && (
            <div className="column parent-width">
              {getManufacturingGrid()}
              {getJobCardQCPopup()}
            </div>
          )}
        </div>
        <DKLine style={{ height: 2 }} className="mt-l" />
      </div>
    );
  };

  const getCustomSaveAlert = () => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          setShowCustomSavePopup(false);
        }
      },
      {
        title: 'Yes',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          setShowCustomSavePopup(false);
          getAPICalls();
        }
      }
    ];
    showAlert(
      'Please note',
      'Tagged material has process type of production, do you wish to complete this job card?',
      buttons
    );
  };
  //////////////////////// Fields only for process manufacturing //////////////////////////

  const getWOCompletionThroughJCPopup = () => {
    return (
      <AddNewWorkOrder
        workOrder={woDetailsForAutoClosing}
        onClose={() => {
          setOpenWOCompleteProcessThroughJCPopup(false);
          setWODetailsForAutoClosing(null);
          props.onCancel();
        }}
        onSave={(res: any) => {
          setOpenWOCompleteProcessThroughJCPopup(false);
          setWODetailsForAutoClosing(null);
          props.onSuccess(res, formData?.workOrder?.workOrderId);
        }}
        continueInEditMode={(res, selectedTabIndex = 0) => {
          setOpenWOCompleteProcessThroughJCPopup(false);
          setWODetailsForAutoClosing(null);
          props.onSuccess(res, formData?.workOrder?.workOrderId);
        }}
        autoCloseWOFromJC={true}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: !presenter.isLinkedInventory
              ? JC_POPUP_WIDTH_WITHOUT_PROCESS_MANAGEMENT
              : JC_POPUP_WIDTH_PROCESS_MANAGEMENT,
            width: !presenter.isLinkedInventory
              ? JC_POPUP_WIDTH_WITHOUT_PROCESS_MANAGEMENT
              : JC_POPUP_WIDTH_PROCESS_MANAGEMENT,
            padding: 0,
            overflow: 'visible',
            height: '100%',
            maxHeight: '100%',
            borderRadius: 0
          }}
        >
          {getHeader()}
          <div className="column parent-width p-h-l overflow-y-auto hide-scroll-bar">
            {getGeneralFields()}
            {presenter.isLinkedInventory &&
              isEditMode &&
              getManufacturingProcessFields()}
            {getProductionDetails()}
            {checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.VIEW_PRICE) &&
              getOperationCostDetails()}
            {checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.VIEW_PRICE) &&
              getOperatorCostDetails()}
          </div>
          {showAddOperationPopup && (
            <AddNewOperation
              editableOperation={editableOperation}
              onCancel={() => {
                setShowAddOperationPopup(false);
                setEditableOperation({});
              }}
              onSuccess={() => {
                setShowAddOperationPopup(false);
                getAllOperations({});
                getOperationAndOperatorById();
              }}
            />
          )}
        </div>
        {showCreateProduct && (
          <CreateMRPProductView
            product={null}
            type={MRP.BILL_OF_MATERIAL}
            isCopy={false}
            readOnly={false}
            onCancel={() => {
              setShowCreateProduct(false);
            }}
            onProductDashboard={() => {}}
            apiInProgress={false}
            onSuccess={(data: any) => {
              populateBomOperations(data);
              setShowCreateProduct(false);
            }}
          />
        )}
        {showAttachmentPopup && (
          <AttachmentsPopup
            documentDetails={attachments}
            onClose={() => setShowAttachmentPopup(false)}
          />
        )}
        {!Utility.isEmptyObject(detailsPopupData) &&
          detailsPopupData?.showDetailsOpener && (
            <DetailsOpener
              data={detailsPopupData}
              onCancel={() => {
                setDetailsPopupData(null);
                getProcessManufacturingAPICalls();
              }}
              isReadOnly={false}
            />
          )}
      </div>
      {showCustomSavePopup && getCustomSaveAlert()}
      {isEditMode &&
        formData?.status !== JOB_CARD_STATUS.OPEN &&
        showTransactionHistory && (
          <TransactionHistory
            rows={trackingHistory}
            columnData={transactionColumn}
            onClose={() => {
              setShowTransactionHistory(false);
            }}
          />
        )}
      {openWOCompleteProcessThroughJCPopup && getWOCompletionThroughJCPopup()}
    </DynamicPopupWrapper>
  );
};

export default AddJobCard;
