import { useEffect, useRef, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  COUNTRY_CODES,
  DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import { Bill, billInitialState, BillProps } from '../../Models/Bill';
import BillService from '../../Services/Bill';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { setItemsFromPurchaseInvoiceItems } from './BillHelper';
import { activeTenantInfo, userInfo } from '../../Redux/Slices/AuthSlice';
import { fetchOrders } from '../../Redux/Slices/PurchaseOrdersSlice';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import {
  checkGSTINPresentForSelectedContact,
  customFieldsContainsErrors,
  getAccountsAndCFForBudgetValidation,
  getDocumentAlert,
  getTenantTaxSystem,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF,
  validateBudgetForAccounts
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { useTranslation } from 'react-i18next';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import AuthService from '../../Services/Auth';
import NumberFormatService from '../../Services/NumberFormat';
import { DraftTypes } from '../../Models/Drafts';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import DateFormatService from '../../Services/DateFormat';
import DraftService from '../../Services/Drafts';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { useHistory } from 'react-router-dom';
import { fetchWorkOuts } from '../../Redux/Slices/WorkOutSlice';
import NewFADocument from '../../SharedComponents/DocumentForm/NewFADocument';
import { FABill, faBillInitialState } from '../../Models/FABill';

const NewFABill: React.FC<BillProps> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [bill, setBill] = useState<FABill>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseInvoiceItems(props.populateFormData,props?.documentMode)
      : faBillInitialState
  );
  const [updatedBill, setUpdatedBill] = useState<FABill>({ ...bill });
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const skipTDS = useRef<boolean>(false);
  const [landedCost, setLandedCost] = useState<boolean>(
    props.draftData?.data?.landedCost
  );
  const [landedCostProducts, setLandedCostProducts] = useState<any>(
    props.draftData?.data?.landedProducts
  );
  let history = useHistory();
  const userDetails = useAppSelector(userInfo);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          onSaveAsDraft(false);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_BILL,
        data: () => {
          updateBill();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_BILL,
        data: (closeDoc: boolean) => {
          createBill(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);

    // setInvoice(setItemsFromSalesInvoiceItems(invoice));
    loadApprovalConditionList();
    if (landedCost) {
      setBill({
        ...bill,
        landedCost: landedCost,
        landedProducts: landedCostProducts
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setBill({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = (isApprovalRequired: boolean) => {
    const payload = { ...updatedBill };

    if (props.documentMode !== DOCUMENT_MODE.EDIT) {
      delete payload.documentSequenceCode;
    }

    if (isApprovalRequired) {
      payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
      payload['multiApprovalDetails'] = {
        currentLevel: payload?.['multiApprovalDetails']?.currentLevel || 1,
        approvalHistory:
          payload?.['multiApprovalDetails']?.approvalHistory || [],
        approvalRequiredFor: userDetails?.[0]?.email || ''
      };
    } else {
      payload['approvalStatus'] = payload['approvalStatus']
        ? payload['approvalStatus']
        : APPROVAL_STATUS['NOT_REQUIRED'];
    }
    payload['createdUserName'] = AuthService.getUserName();

    setButtonStatus(true);
    props.formData(payload);

    if (isApprovalRequired) {
      sendTriggerOnApproval(payload);
      if (props.documentMode === DOCUMENT_MODE.COPY) {
        let draftsData: any = {
          data: {
            type: LABELS.BILLS,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          }
        };
        DraftService.createRecord(payload, draftsData).then((response: any) => {
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.BILLS
            })
          );
        });
      }

      if (!Utility.isEmpty(payload) && payload.isConverting == true) {
        let draftsData: any = {
          data: {
            type: LABELS.BILLS,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          }
        };
        DraftService.createRecord(payload, draftsData)
          .then((response: any) => {
            dispatch(fetchOrders());
            dispatch(fetchBills());
            dispatch(
              fetchDrafts({
                tableId: draftsTableId,
                isSaveColumnId: isSavedColumnId,
                draftTypeColId: draftTypeColId,
                draftTypeColValue: LABELS.BILLS
              })
            );
            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Bills',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLS);
                }
              }
            ];
            showAlert(
              'Purchase Order converted!',
              'Purchase Order has been converted to bill successfully.',
              buttons
            );
            replaceURLToModuleURL();
          })
          .catch((err) => {
            console.error('Error while creating draft: ', err);
            replaceURLToModuleURL();
          });
      }
    } else {
      replaceURLToModuleURL();
    }
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.BILLS)
    ) {
      history.replace(PAGE_ROUTES.BILLS);
    }
  };

  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any, closeDoc: boolean) => {
    const taxSystem = getTenantTaxSystem();

    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseInvoiceAssets)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Bill date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Bill date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseInvoiceAssets.length; i++) {
      const item = docToValidate.purchaseInvoiceAssets[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    //TODO: FIX
    // if (
    //   taxSystem === TAX_SYSTEM.INDIA_GST &&
    //   (!docToValidate.shipTo?.state || !docToValidate.shipFrom?.state)
    // ) {
    //   return false;
    // }

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Check tds not deducted
    // if (taxSystem === TAX_SYSTEM.INDIA_GST && !skipTDS.current) {
    //   let tdsNotDeductedArray: any[] = [];
    //   docToValidate.purchaseInvoiceAssets.forEach((ele: any) => {
    //     if (!ele.tdsInfoIndia && ele.product.tdsApplicableIndia) {
    //       tdsNotDeductedArray.push(ele.product.name);
    //     }
    //   });
    //   if (
    //     tdsNotDeductedArray.length > 0 &&
    //     (docToValidate.contact.tdsApplicableIndia ||
    //       (docToValidate.contact.tdsInfoIndia &&
    //         docToValidate.contact.tdsInfoIndia.deducteeType))
    //   ) {
    //     openTDSDialog(
    //       tdsNotDeductedArray
    //         .map((str: string) => {
    //           return `<li>${str}</li>`;
    //         })
    //         .join(''),
    //       closeDoc
    //     );
    //     return false;
    //   }
    // }
    // Check tds not deducted ends

    // Check if Landed cost true but not allocated
    // if (docToValidate.landedCost === true){
    //   let productNames: string[] = [];
    //   docToValidate?.purchaseInvoiceAssets?.forEach((item: any) => {
    //     if(Utility.isEmpty(item.landedCostDetails)){
    //       productNames.push(item.productName);
    //     }
    //   });
    //   if (productNames.length !== 0) {
    //     let productNamesStr = productNames.map(name => `<li>${name}</li>`).join('');
    //     showAlert(
    //       '',
    //       `<div>Landed cost details are not saved yet for below products. Please check context menu for details.<div><br/><ul>${productNamesStr}</ul>`
    //     );
    //     return false;
    //   }
    // }

    return true;
  };

  const openTDSDialog = (productNames = '', closeDoc: boolean) => {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          skipTDS.current = false;
        }
      },
      {
        title: t(`CONFIRMATION_POPUP.YES`),
        className: 'bg-button text-white ml-r',
        onClick: () => {
          skipTDS.current = true;
          if (props.documentMode === DOCUMENT_MODE.EDIT) {
            updateBill(closeDoc);
          }
          if (
            props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY
          ) {
            createBill(closeDoc);
          }
        }
      }
    ];
    showAlert(
      '',
      `<ul style="list-style: disc; margin-left: 14px;">${productNames}</ul><div class="mt-r">TDS is applicable on the above items. Do you wish to skip deducting TDS?</div>`,
      buttons
    );
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    BillService.getBillDetailsByCode(docResp.purchaseInvoiceCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.BILL,
          items: [
            doc.purchaseInvoiceAssets ? { ...doc.purchaseInvoiceAssets } : []
          ],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          dispatch(fetchWorkOuts());
          if (!isUpdate) {
            getDocumentAlert(
              'Fixed Asset Bill created!',
              'Fixed Asset Bill has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.BILLS
            );
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  };

  const handleAssetDetails = (payload: any) => {
    let purchaseInvoiceAssets = payload?.purchaseInvoiceAssets?.map(
      (item: any) => {
        return {
          ...item,
          assetDetails: item.assetDetails?.map((detail: any) => {
            return {
              ...detail,
              salesIncomeAccountCode:
                typeof detail?.salesIncomeAccountCode === 'string'
                  ? detail?.salesIncomeAccountCode
                  : detail?.salesIncomeAccountCode?.code,
              profitLossAccountCode:
                typeof detail?.profitLossAccountCode === 'string'
                  ? detail?.profitLossAccountCode
                  : detail?.profitLossAccountCode?.code,
              warehouse:
                typeof detail?.warehouse === 'string'
                  ? detail?.warehouse
                  : detail?.warehouse?.code,
              depreciationConvention:
                typeof detail?.depreciationConvention === 'string'
                  ? detail?.depreciationConvention
                  : detail?.depreciationConvention?.value,
              depreciationMethod:
                typeof detail?.depreciationMethod === 'string'
                  ? detail?.depreciationMethod
                  : detail?.depreciationMethod?.value,
              depreciationStartDate:
                typeof detail?.depreciationStartDate === 'string' &&
                detail?.depreciationStartDate?.length > 0
                  ? detail?.depreciationStartDate?.toLowerCase()?.includes('z')
                    ? DateFormatService.getDateStrFromDate(
                        new Date(detail?.depreciationStartDate),
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : detail?.depreciationStartDate
                  : '',
              warrantyStartDate:
                typeof detail?.warrantyStartDate === 'string' &&
                detail?.warrantyStartDate?.length > 0
                  ? detail?.warrantyStartDate?.toLowerCase()?.includes('z')
                    ? DateFormatService.getDateStrFromDate(
                        new Date(detail?.warrantyStartDate),
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : detail?.warrantyStartDate
                  : '',
              warrantyEndDate:
                typeof detail?.warrantyEndDate === 'string' &&
                detail?.warrantyEndDate?.length > 0
                  ? detail?.warrantyEndDate?.toLowerCase()?.includes('z')
                    ? DateFormatService.getDateStrFromDate(
                        new Date(detail?.warrantyEndDate),
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : detail?.warrantyEndDate
                  : ''
            };
          })
        };
      }
    );
    return { ...payload, purchaseInvoiceAssets };
  };

  const updatePayloadDetails = (payload: any) => {
    // if (payload && payload. )
    let updatedData = payload;
    if (
      updatedData &&
      updatedData.purchaseInvoiceAssets &&
      updatedData.purchaseInvoiceAssets.length > 0
    ) {
      updatedData.purchaseInvoiceAssets.forEach((data: any) => {
        data.assetGroupId = data.fixedAssetGroup.id;
        data.assetGroupName = data.fixedAssetGroup.name;
        if (data.tax) {
          data.taxDetails = [
            {
              taxId: data.tax.id,
              taxName: data.tax.name,
              taxCode: data.tax.taxCode,
              taxRate: data.tax.percent,
              taxSeqCode: data.tax.code,
              taxType: data.tax.type,
              taxAmount: data.taxAmount,
              additionalTaxIn: data.tax.additionalTaxIn,
              taxableAmount: data.totalWithDiscount
            }
          ];
        } else {
          data.taxDetails = []
        }
        data.quantity = data.productQuantity;
        data.effectiveLife = data.effectiveLife;
        if (data.assetDetails && data.assetDetails.length > 0) {
          data.assetDetails.forEach((assetDetail: any) => {
            assetDetail.purchaseDate = DateFormatService.getDateStrFromDate(
              new Date(assetDetail.purchaseDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            assetDetail.installationDate = DateFormatService.getDateStrFromDate(
              new Date(assetDetail.installationDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            if (!Utility.isEmpty(assetDetail.tax)) {
              assetDetail.taxCode = assetDetail.tax.code;
              assetDetail.taxAmount =
                ((assetDetail.purchasePrice - assetDetail.discount) *
                  assetDetail.tax.percent) /
                100;
            }
          });
        }
        delete data.productQuantity;
        delete data.fixedAssetGroup;
      });
      updatedData.documentType = DOC_TYPE.BILL;
      //   updatedData.shipTo.destinationOfSupply = null;
      //   updatedData.shipTo =
      //     tenantInfo && tenantInfo.shippingAddresses && tenantInfo.shippingAddresses.length > 0 ? tenantInfo.shippingAddresses[0] : updatedData.billTo
      //   updatedData.shipFrom.placeOfSupply = null;
      //   delete updatedData.additionalCharges;
    }

    return updatedData;
  };

  // Create Bill
  const createBill = async (closeDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedBill);

    payload = updateAddressAsPerLocationCF(payload);
    payload = handleAssetDetails(payload);
    delete payload?.items;
    // if (payload && payload.documentType && payload.documentType === DOC_TYPE.FA_BILL)   {
    //     payload.documentType = DOC_TYPE.BILL
    // }
    // return;
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      let hasError = false;
      if (
        payload.purchaseInvoiceAssets &&
        payload.purchaseInvoiceAssets.length > 0
      ) {
        payload.purchaseInvoiceAssets.forEach((item: any) => {
          if (Utility.isEmpty(item.tax)) {
            hasError = true;
          }
        });
        if (hasError) {
          showAlert('', 'Please select tax.');
          setButtonStatus(false);
          return;
        }
      }
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload, closeDoc)) {
      setButtonStatus(false);
      return;
    }
    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setButtonStatus(false);
      return;
    } else if (isApproval) {
      onSaveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }
    if (bill.landedCost) {
      let showMsg = payload?.purchaseInvoiceAssets?.every((item: any) => {
        return !Utility.isEmpty(item.landedCostDetails);
      });
      if (!showMsg) {
        setButtonStatus(false);
        showAlert(
          '',
          'Landed cost details are not saved yet. Please check context menu for details.'
        );
        return;
      } else {
        validateAccountsBudget(payload, false, closeDoc);
      }
    } else {
      validateAccountsBudget(payload, false, closeDoc);
    }
  };

  const makeCreateAPICall = (payload: any, closeDoc: any) => {
    BillService.createBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (response: any) => {
        if (props.draftData) {
          if (closeDoc) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
          }
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            dispatch(
              deleteDrafts({
                recordId: props.draftData.id,
                tableId: draftsTableId
              })
            );
          }
          setButtonStatus(false);
          updateForm(response, payload, false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.BILLS
          })
        );
        dispatch(fetchBills());
        if (payload.isPartialBill) {
          dispatch(fetchOrders());
        }
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while creating Bill: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const updateBill = (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedBill);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    payload = handleAssetDetails(payload);
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload, closeOnUpdate)) {
      setButtonStatus(false);
      return;
    }

    validateAccountsBudget(payload, true, closeOnUpdate);
  };

  const validateAccountsBudget = (
    doc: any,
    isUpdate: boolean,
    closeDoc: boolean
  ) => {
    const accountsAndCFInfo = getAccountsAndCFForBudgetValidation(
      [...doc.purchaseInvoiceAssets],
      doc.customField,
      'accountCode',
      'tax',
      doc.exchangeRate ? doc.exchangeRate : 1,
      true
    );
    const allAccountsData = accountsAndCFInfo?.accountsInfo;
    const customField = accountsAndCFInfo?.customField;

    doc = updatePayloadDetails(doc);
    if (allAccountsData.length) {
      validateBudgetForAccounts(
        doc.documentDate,
        allAccountsData,
        customField,
        doc.journalEntryCode ? doc.journalEntryCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  setButtonStatus(false);
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate
                    ? makeUpdateAPICall(doc, closeDoc)
                    : makeCreateAPICall(doc, closeDoc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you wish to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate
              ? makeUpdateAPICall(doc, closeDoc)
              : makeCreateAPICall(doc, closeDoc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
        }
      );
    }
  };

  const makeUpdateAPICall = (payload: any, closeOnUpdate: boolean) => {
    BillService.updateBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (res) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.BILLS
          })
        );
        dispatch(fetchBills());
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while updating Bill: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleBillUpdate = (doc: Document) => {
    let billWithUpdate: FABill = {
      ...bill,
      ...doc,
      purchaseInvoiceAssets: doc.items,
      purchaseInvoiceDueDate: doc.validTillDate,
      billDue: doc.validTillDate,
      purchaseInvoiceDate: doc.documentDate,
      receiveByDate: doc.fulfillmentDate,
      currency: doc.currency,
      totalAmount: doc.totalAmount
    };

    let editBillWithExtraKeys = {
      purchaseInvoiceType: BUY_TYPE.ASSET,
      openingInvoice: false,
      documentCode: bill.purchaseInvoiceCode,
      documentType: DOC_TYPE.FA_BILL,
      currency: doc.currency,
      purchaseInvoiceAssets: billWithUpdate.purchaseInvoiceAssets?.map(
        (billDoc) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.purchaseInvoiceItemCode
          };
        }
      )
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      billWithUpdate = { ...billWithUpdate, ...editBillWithExtraKeys };
    }

    setUpdatedBill(billWithUpdate);
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseInvoiceAssets
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    BillService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };
  bill.documentType = DOC_TYPE.FA_BILL;
  if (bill.purchaseInvoiceAssets) {
    bill.items = bill.purchaseInvoiceAssets;
  }
  //   bill.items = bill.purchaseInvoiceAssets
  return (
    <NewFADocument
      booksDocument={bill}
      permissionKeys={PERMISSIONS_BY_MODULE.BILL}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleBillUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateBill(false)}
    />
  );
};

export default NewFABill;
