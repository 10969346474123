import React from "react";
import { COLOR_LIGHTGRAY, CURRENCY, SECURITY_GATE_DEFAULT_TEMPLATE_ID } from "../Constants/Constants";

import DocumentTemplate1 from "../Templates/DocumentTemplates/Template1";
import DocumentTemplate2 from "../Templates/DocumentTemplates/Template2";
import DocumentTemplate3 from "../Templates/DocumentTemplates/Template3";
import DocumentTemplate4 from "../Templates/DocumentTemplates/Template4";
import DocumentTemplate5 from "../Templates/DocumentTemplates/Template5";
import DocumentTemplate6 from "../Templates/DocumentTemplates/Template6";
import DocumentTemplate7 from "../Templates/DocumentTemplates/Template7";
import DocumentTemplate8 from "../Templates/DocumentTemplates/Template8";
import DocumentTemplate9 from "../Templates/DocumentTemplates/Template9";
import DocumentTemplate10 from "../Templates/DocumentTemplates/Template10";
import DocumentTemplate11 from '../Templates/DocumentTemplates/Template11';
import DocumentTemplate12 from '../Templates/DocumentTemplates/Template12';
import DocumentTemplate13 from '../Templates/DocumentTemplates/Template13';
import DocumentTemplate14 from '../Templates/DocumentTemplates/Template14';
import DocumentTemplate15 from '../Templates/DocumentTemplates/Template15';
import DocumentTemplate16 from '../Templates/DocumentTemplates/Template16';
import DocumentTemplate17 from '../Templates/DocumentTemplates/Template17';
import DocumentTemplate18 from '../Templates/DocumentTemplates/Template18';
import DocumentTemplate19 from '../Templates/DocumentTemplates/Template19';
import DocumentTemplate20 from '../Templates/DocumentTemplates/Template20';
import DocumentTemplate21 from '../Templates/DocumentTemplates/Template21';
import DocumentTemplate22 from '../Templates/DocumentTemplates/Template22';
import DocumentTemplate23 from '../Templates/DocumentTemplates/Template23';
import DocumentTemplate24 from '../Templates/DocumentTemplates/Template24';
import DocumentTemplate25 from '../Templates/DocumentTemplates/Template25';
import DocumentTemplate26 from '../Templates/DocumentTemplates/Template26';
import DocumentTemplate27 from '../Templates/DocumentTemplates/Template27';
import DocumentTemplate28 from '../Templates/DocumentTemplates/Template28';
import DocumentTemplate29 from '../Templates/DocumentTemplates/Template29';
import DocumentTemplate30 from '../Templates/DocumentTemplates/Template30';
import DocumentTemplate31 from '../Templates/DocumentTemplates/Template31';
import DocumentTemplate32 from '../Templates/DocumentTemplates/Template32';
import DocumentTemplate33 from '../Templates/DocumentTemplates/Template33';
import DocumentTemplate34 from '../Templates/DocumentTemplates/Template34';
import DocumentTemplate35 from '../Templates/DocumentTemplates/Template35';
import Utility, { getDateString, getIsAccountModule, getIsBillOrInvoice, getIsCheque, getIsDebitOrCreditNote, getIsEwayBill, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsGoodsReceiptNoteTemplate, getIsInspectionReport, getIsJobWorkOutOrder, getIsJournalEntry, getIsMachine, getIsMakePayment, getIsPackList, getIsPayslip, getIsPickList, getIsPurchaseRequisitionTemplate, getIsReceivePayment, getIsSOACustomer, getIsSOAVendor, getIsSalesReturn, getIsServiceOrderTemplate, getIsServiceRequisitionTemplate, getIsShipList } from "../Utilities/Utility";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import ExpenseMapper from "../DocumentMapper/ExpenseMapper";
import CreditNoteMapper from "../DocumentMapper/CreditNoteMapper";
import LabelTemplate1 from "../Templates/LabelTemplates/LabelTemplate1";
import IndiaTemplate1 from "../Templates/IndiaTemplates/IndiaTemplate1";
import IndiaTaxParser from "../Helper/IndiaTaxParser";
import SGPayslipTemplate1 from "../Templates/PayslipTemplates/sg/SGPayslipTemplate1";
import SGPayslipTemplate2 from "../Templates/PayslipTemplates/sg/SGPayslipTemplate2";
import SGPayslipTemplate3 from "../Templates/PayslipTemplates/sg/SGPayslipTemplate3";
import INPayslipTemplate1 from "../Templates/PayslipTemplates/in/INPayslipTemplate1";
import MYPayslipTemplate1 from "../Templates/PayslipTemplates/my/MYPayslipTemplate1";
import USPayslipTemplate1 from "../Templates/PayslipTemplates/us/USPayslipTemplate1";
import PayslipTemplate1 from "../Templates/PayslipTemplates/other/PayslipTemplate1";
import ApiConstants from "../API/ApiConstants";
import INPayslipTemplate2 from "../Templates/PayslipTemplates/in/INPayslipTemplate2";
import INPayslipTemplate3 from "../Templates/PayslipTemplates/in/INPayslipTemplate3";
import INPayslipTemplate4 from "../Templates/PayslipTemplates/in/INPayslipTemplate4";
import INPayslipTemplate5 from "../Templates/PayslipTemplates/in/INPayslipTemplate5";
import INPayslipTemplate6 from "../Templates/PayslipTemplates/in/INPayslipTemplate6";
import INPayslipTemplate7 from "../Templates/PayslipTemplates/in/INPayslipTemplate7";
import ChequeTemplate1 from "../Templates/ChequeTemplates/ChqueTemplate1";
import ChequeTemplate2 from "../Templates/ChequeTemplates/ChqueTemplate2";
import ChequeTemplate3 from "../Templates/ChequeTemplates/ChqueTemplate3";
import ReceiptTemplate1 from "../Templates/ReceiptTemplates/ReceiptTemplate2";
import USPayrollCheckTemplate1 from "../Templates/PayrollCheckTemplates/us/USPayrollCheckTemplate1";
import ReceiptTemplate2 from "../Templates/ReceiptTemplates/ReceiptTemplate2";
import PayslipUtility from "../Templates/PayslipTemplates/PayslipUtility";
import DocumentTemplate37 from "../Templates/DocumentTemplates/Template37";
import IndiaTemplate2 from "../Templates/IndiaTemplates/IndiaTemplate2";
import DocumentTemplate39 from "../Templates/DocumentTemplates/Template39";
import IndiaTemplate3 from "../Templates/IndiaTemplates/IndiaTemplate3";
import INPayslipTemplate8 from "../Templates/PayslipTemplates/in/INPayslipTemplate8";
import USPayslipTemplate2 from "../Templates/PayslipTemplates/us/USPayslipTemplate2";
import USPayslipTemplate3 from "../Templates/PayslipTemplates/us/USPayslipTemplate3";
import USPayslipTemplate4 from "../Templates/PayslipTemplates/us/USPayslipTemplate4";
import USPayslipTemplate5 from "../Templates/PayslipTemplates/us/USPayslipTemplate5";
import USPayslipTemplate6 from "../Templates/PayslipTemplates/us/USPayslipTemplate6";
import USPayslipTemplate7 from "../Templates/PayslipTemplates/us/USPayslipTemplate7";
import IndiaTemplate4 from "../Templates/IndiaTemplates/IndiaTemplate4";
import DocumentTemplate41 from "../Templates/DocumentTemplates/Template41";
import IndiaTemplate5 from "../Templates/IndiaTemplates/IndiaTemplate5";
import MYPayslipTemplate2 from "../Templates/PayslipTemplates/my/MYPayslipTemplate2";
import IndiaTemplate6 from "../Templates/IndiaTemplates/IndiaTemplate6";
import IndiaTemplate7 from "../Templates/IndiaTemplates/IndiaTemplate7";
import IndiaTemplate8 from "../Templates/IndiaTemplates/IndiaTemplate8";
import IndiaTemplate9 from "../Templates/IndiaTemplates/IndiaTemplate9";
import IndiaTemplate10 from "../Templates/IndiaTemplates/IndiaTemplate10";
import DocumentTemplate49 from "../Templates/DocumentTemplates/Template49";
import DocumentTemplate50 from "../Templates/DocumentTemplates/Template50";
import Address from "../Helper/Address";
import AddressParser, { AddressType, OwnerType } from "../Helper/AddressParser";
import EWayBillSummaryTemplate1 from "../Templates/EWayBillSummaryTemplates/EWayBillTemplate1";
import EWayBillDetailsTemplate1 from "../Templates/EWayBillDetailsTemplates/EWayBillTemplate1";
import SGPayslipTemplate4 from "../Templates/PayslipTemplates/sg/SGPayslipTemplate4";
import SGPayslipTemplate5 from "../Templates/PayslipTemplates/sg/SGPayslipTemplate5";
import WorkOrderTemplate1 from "../Templates/WorkOrderTemplates/WorkOrderTemplate1";
import JobCardTemplate1 from "../Templates/JobCardTemplates/JobCardTemplate1";
import PurchaseRequisitionTemplate1 from "../Templates/PurchaseRequisitionTemplates/PurchaseRequisitionTemplate1";
import QCTemplates1 from "../Templates/QCTemplates/QCTemplates1";
import USChequeTemplate1 from "../Templates/ChequeTemplates/us/USChqueTemplate1";
import USChequeTemplate2 from "../Templates/ChequeTemplates/us/USChqueTemplate2";
import { Asset } from "../assets";
import USChequeTemplate3 from "../Templates/ChequeTemplates/us/USChqueTemplate3";
import USChequeDesignTemplate from "../Templates/ChequeTemplates/us/USChequeDesignTemplate";
import JobWorkOurOrderTemplates1 from "../Templates/JobWorkOurOrderTemplates/JobWorkOurOrderTemplates1";
import MachineSchedulerTemplate1 from "../Templates/MachineSchedulerTemplates/MachineSchedulerTemplate1";
import ServiceRequisitionTemplate1 from "../Templates/RequisitionTemplates/ServiceRequisitionTemplate1";
import IndiaTemplate11 from "../Templates/IndiaTemplates/IndiaTemplate11";
import StockTransferTemplate1 from "../Templates/StockTransferTemplates/StockTransferTemplate1";
import StockIssuesTemplate1 from "../Templates/StockIssuesTemplates/StockIssuesTemplate1";
import StockRequestTemplate1 from "../Templates/StockRequestTemplates/StockRequestTemplate1";
import SecurityGateEntryTemplate1 from "../Templates/SecurityGateEntryTemplate/SecurityGateEntryTemplate1";
import ServiceOrderTemplate1 from "../Templates/ServiceOrderTemplates/ServiceOrderTemplate1";
import ServiceOrderTemplate2 from "../Templates/ServiceOrderTemplates/ServiceOrderTemplate2";
import IndiaTemplate12 from "../Templates/IndiaTemplates/IndiaTemplate12";
import IndiaTemplate13 from "../Templates/IndiaTemplates/IndiaTemplate13";
import IndiaTemplate14 from "../Templates/IndiaTemplates/IndiaTemplate14";
import GoodsReceiptNoteTemplate1 from "../Templates/GoodsReceiptNoteTemplates/GoodsReceiptNoteTemplate1";
import QCInspectionTemplate1 from "../Templates/QCInspectionTemplates/QCInspectionTemplate1";
import InspectionReportTemplate1 from "../Templates/InspectionReportTemplates/InspectionReportTemplate1";

export default class DocumentManager {

  ////////////////////////////////////////////////////////////
  ///////////////////////// DATA MODEL ///////////////////////
  ////////////////////////////////////////////////////////////

  static currentData = null;
  static setCurrentData(data) {
    DocumentManager.currentData = data;
  }

  static getDefaultData(
    templateId,
    type,
    themeColor = "#000000",
    designAssets = DocumentManager.getDesignAssets()
  ) {
    if (DocumentManager.currentData) {
      DocumentManager.currentData["templateId"] = templateId;
      DocumentManager.currentData["themeColor"] = themeColor;
      DocumentManager.currentData["designAssets"] = designAssets;
      return DocumentManager.currentData;
    }

    let date = new Date()
    let due_date = new Date()
    let additional_date = new Date()
    due_date.setDate(due_date.getDate() + 30);
    additional_date.setDate(due_date.getDate() + 31);
    let dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.format
    let defaultPayslipJSON = undefined;
    if (getIsPayslip(type)) {
      defaultPayslipJSON = PayslipUtility.getMockData(templateId)
    }
    return {
      clientName: 'Customer Name',
      clientBillToAddress: this.getBillToPlaceholder(type),
      clientShipToAddress: this.getShipToPlaceholder(type),
      templateId: TemplateSettingsManager.defaultTemplateSettings.templateInfo.id,
      type: type,
      documentType: getIsPayslip(type) ? 'earning_statement' : type,
      themeColor: TemplateSettingsManager.defaultTemplateSettings.templateInfo.themeColor,
      logo: TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.image,
      currency: (defaultPayslipJSON && defaultPayslipJSON.tenantInfo && defaultPayslipJSON.tenantInfo.currencySymbol) ? defaultPayslipJSON.tenantInfo.currencySymbol : "$",
      currencyName: (defaultPayslipJSON && defaultPayslipJSON.tenantInfo && defaultPayslipJSON.tenantInfo.currency) ? defaultPayslipJSON.tenantInfo.currency : 'USD',
      refNumber: "000001",
      supplierInvoiceNo: "SI-000001",
      companyName: (getIsPickList(type) || getIsPackList(type)) ? 'warehouse_name' : 'company_name',
      from: (getIsPickList(type) || getIsPackList(type)) ? 'warehouse_address' : 'company_billing_address',
      billTo: 'billing_address_placeholder_text_value',
      shipTo: 'shipping_address_placeholder_text_value',
      date: DocumentManager.getConvertedDate(date, dateFormat),
      dueDate: !getIsAccountModule(undefined, type) ? DocumentManager.getConvertedDate(due_date, dateFormat) : '',
      additionalDate: Utility.getIsProductDocument({ type: type }) ? DocumentManager.getConvertedDate(additional_date, dateFormat) : '',
      lineItems: [
        DocumentManager.getLineItemData(type, 1, dateFormat),
        DocumentManager.getLineItemData(type, 2, dateFormat),
        DocumentManager.getLineItemData(type, 3, dateFormat),
        DocumentManager.getLineItemData(type, 4, dateFormat),
        // DocumentManager.getLineItemData(type, 5, dateFormat),
        // DocumentManager.getLineItemData(type, 6, dateFormat),
        // DocumentManager.getLineItemData(type, 7, dateFormat),
        // DocumentManager.getLineItemData(type, 8, dateFormat),
        // DocumentManager.getLineItemData(type, 9, dateFormat),
        // DocumentManager.getLineItemData(type, 10, dateFormat),
        // DocumentManager.getLineItemData(type, 11, dateFormat),
        // DocumentManager.getLineItemData(type, 12, dateFormat),
        // DocumentManager.getLineItemData(type, 13, dateFormat),
        // DocumentManager.getLineItemData(type, 14, dateFormat),
        // DocumentManager.getLineItemData(type, 15, dateFormat),
        // DocumentManager.getLineItemData(type, 16, dateFormat),
        // DocumentManager.getLineItemData(type, 17, dateFormat),
        // DocumentManager.getLineItemData(type, 18, dateFormat),
        // DocumentManager.getLineItemData(type, 19, dateFormat),
        // DocumentManager.getLineItemData(type, 20, dateFormat),
      ],
      schedule_start_date: '12-10-2024',
      schedule_end_date: '20-10-2024',
      technician_name: "XYZ",
      machine_name: "ABC",
      schedule_id: "123",
      sopLineItems:[
        DocumentManager.getLineItemData(type, 1, dateFormat),
        DocumentManager.getLineItemData(type, 2, dateFormat),
        DocumentManager.getLineItemData(type, 3, dateFormat),
      ],
      machineMaterialItems : [
        getMachineLineItems(type, 1, dateFormat),
        getMachineLineItems(type, 2, dateFormat),
        getMachineLineItems(type, 3, dateFormat),
      ],
      productLineItems: [
        DocumentManager.getProductLineItems(type, 1, dateFormat),
        DocumentManager.getProductLineItems(type, 2, dateFormat),
        DocumentManager.getProductLineItems(type, 3, dateFormat),
      ],
      stockItems: [],
      signature: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.image,
      termsAndCondition: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text,
      notes: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text,
      designAssets: designAssets,
      dateFormat: dateFormat,
      tenantDateFormat: dateFormat,

      showDocumentType: TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible,
      showCompanyName: TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.name.isVisible,
      showCompanyLogo: TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible,
      showFrom: TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.address.isVisible,
      showClientBillToAddress: TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.isVisible,
      showClientShipToAddress: TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible,
      showRefNumber: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.referenceNumber.isVisible,
      showTermsAndCondition: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.isVisible,
      showNotes: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.isVisible,
      showStartDate: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.startDate.isVisible,
      showDueDate: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible,
      showAdditionalDate: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible,
      showStockTable: true,
      showSignature: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.isVisible,

      exchangeRateTable: TemplateSettingsManager.getExchangeRateTable(),
      exchangeRateTitle: TemplateSettingsManager.getExchangeRateTable().title,
      showExchangeRate: TemplateSettingsManager.getExchangeRateTable().isVisible,

      checkTable: TemplateSettingsManager.getCheckTable(),
      checkTitle: TemplateSettingsManager.getCheckTable().title,
      showCheckTable: TemplateSettingsManager.getCheckTable().isVisible,

      checkTableColumn: TemplateSettingsManager.getCheckTableColumns(),

      checkOtherFields: TemplateSettingsManager.getCheckOtherFields(),
      addressFontStyle: TemplateSettingsManager.getCheckAddressFontStyle(),

      billToTitle: TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title,
      shipToTitle: TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title,

      tableColumnPopup: TemplateSettingsManager.defaultTemplateSettings.tableInfo,
      componentList: TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList,
      languageInfo: TemplateSettingsManager.defaultTemplateSettings.languageInfo,

      clientEmailIDs: [],
      taxBreakdown: [
        {
          taxAmount: "0",
          taxId: "243560",
          taxName: "GST 8%"
        }
      ],

      isReadOnlyMode: false,
      showCurrency: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.isVisible,
      showCheckCurrency: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.showCheckCurrency,
      currencyFormat: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.format,
      currencyCustomValue: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.customValue ?
        TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency.customValue : '$',

      showEInvoiceSection: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible,
      country: 'in',

      showLinkDocumentNumber: TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible,
      linkDocumentType: 'Link Document',
      linkDocumentNumber: '00001',

      companyPhoneNumber: 'company_phone_number',
      companyGSTIN: "company_gstin",
      companyStateNameAndCode: "company_state_name_and_code",
      paymentTerms: "Net 30",

      customerPhoneNumber: 'customer_phone_number',
      customerGSTIN: "customer_gstin",
      customerStateNameAndCode: "customer_state_name_and_code",
      indiaTax: [
        {
          hsn_or_sac: '8471_SGST',
          taxableValue: 7000.00,
          centralTax: {
            rate: '9%',
            amount: 630.00
          },
          stateTax: {
            rate: '9%',
            amount: 630.00
          },
          totalTaxAmount: 15254.24
        },
        {
          hsn_or_sac: '8471_SGST',
          taxableValue: 72000.00,
          centralTax: {
            rate: '9%',
            amount: 2630.00
          },
          stateTax: {
            rate: '9%',
            amount: 6230.00
          },
          totalTaxAmount: 15254.24
        },
        {
          hsn_or_sac: '8471',
          taxableValue: 7000.00,
          integratedTax: {
            rate: '9%',
            amount: 630.00
          },
          totalTaxAmount: 15254.24
        },
        {
          hsn_or_sac: '8471',
          taxableValue: 17000.00,
          integratedTax: {
            rate: '19%',
            amount: 6130.00
          },
          totalTaxAmount: 152254.24
        },
        {
          hsn_or_sac: '8471',
          taxableValue: 27000.00,
          integratedTax: {
            rate: '19%',
            amount: 6130.00
          },
          totalTaxAmount: 152254.24
        },
      ],
      packageCount: '1/1',
      payslip: DocumentManager.getRequiredPayslipJSON(),
      payrollCheques: DocumentManager.getRequiredPayrollChequeJSON(),
      isFromERP: false,
      showPaymentFooter: true,
      paymentsFooter: this.getPayments(type),
      payments: this.getPayments(type),
      showGeneratedMessage: true,
      cogs: 0,
      outstandingBalance: 0,
      outstandingBalanceIncludesCurrent: 0,
      ewayBill: DocumentManager.getEwayBillData(),
      productName: 'Work Order Product Name',
      totalQty: 1,
      woStatus: 'PENDING',
      jobStatus: 'PENDING',
      qcStatus: 'PENDING',
      taggedBinWarehouseInfo: {
        code: '0000013',
        name: 'B-1',
        barcode: 'WH-0000012-0000005-0000016-0000013',
        rowCode: '0000005',
        rowName: 'R-1',
        rackCode: '0000016',
        rackName: 'Rack-1',
        warehouseCod: 'WH-0000012',
        warehouseName: 'RR'
      },
      showProductName: true,
      linkedDocuments: [
        {
          "documentType": "QUOTATION",
          "documentCode": "0000004",
          "documentSequenceCode": "Q-0000004",
          "documentCreatedDate": null
        },
        {
          "documentType": "SALES_ORDER",
          "documentCode": "0000003",
          "documentSequenceCode": "SO-0000003",
          "documentCreatedDate": null
        }
      ],
      tenantInfo: {
        currency: "USD",
        currencySymbol: "$"
      },
      exchangeRate: 1,
      subTotal: 0,
      total: 0,
      reasonToReturn: '',
      dispatchStatus: 'DISPATCHED',
      dispatchDocNo: 'DIS-123',
      termOfPayment: 'NET30',
      orderNo: 'OR-123',
      voucherNo: 'VO-123',
      productDescription: "BOM Product",
      partNo: "BOM Part 1 ",
      factoryCode: "BOM Factory Cod",
      equivalentTo: "BOM QT",
      taxRegistrationNumber: "Tax Reg 123",
      taxPayerId: "Tax Payer 123",
      fromDate: DocumentManager.getConvertedDate(date, dateFormat),
      asOfDate: DocumentManager.getConvertedDate(due_date, dateFormat),
      toDate: DocumentManager.getConvertedDate(due_date, dateFormat),
      companyRegNo: 'REG No 123',
      gstRegNo: 'GST No. 123'
    };
  }

  static updateShowHideSetting(data) {
    data.showDocumentType = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible;
    data.showCompanyName = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.name.isVisible;
    data.showCompanyLogo = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible;
    data.showFrom = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.address.isVisible;
    data.showClientBillToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.isVisible;
    data.showClientShipToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible;
    data.showRefNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.referenceNumber.isVisible;
    data.showTermsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.isVisible;
    data.showNotes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.isVisible;
    data.showStartDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.startDate.isVisible;
    data.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible;
    data.showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible;
    data.showSignature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.isVisible;

    data.billToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title;
    data.shipToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title;
    data.signature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.image;
    data.termsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text;
    data.notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text;
    return data
  }

  static getRequiredPayslipJSON() {
    switch (ApiConstants.TENANT_COUNTRY) {
      case 'IN':
        return Asset.mockData.IN_Payslip;
      case 'US':
        return Asset.mockData.US_Payslip;
      case 'MY':
        return Asset.mockData.MY_Payslip;
      case 'SG':
        return Asset.mockData.SG_Payslip;
      default:
        return DocumentManager.getDefaultPayslipDataWithCurrency(Asset.mockData.default_payslip)
    }
  }

  static getDefaultPayslipDataWithCurrency(payslipJSON) {
    if (payslipJSON) {
      payslipJSON['tenantInfo'] = {
        ...payslipJSON.tenantInfo,
        countryCode: ApiConstants.TENANT_COUNTRY,
        currencySymbol: ApiConstants.TENANT_CURRENCY ? CURRENCY[ApiConstants.TENANT_CURRENCY] : '$',
        currency: ApiConstants.TENANT_CURRENCY,
        address: {
          ...payslipJSON.tenantInfo.address,
          country: ApiConstants.TENANT_COUNTRY
        }
      }
      if(payslipJSON['employeeDetails']) {
        payslipJSON['employeeDetails'] = {
          ...payslipJSON['employeeDetails'],
          address: {
            ...payslipJSON['employeeDetails'].address,
            country: ApiConstants.TENANT_COUNTRY
          }
        }
      }
      if(payslipJSON['compliance']) {
        payslipJSON['compliance'] = {
          ...payslipJSON['compliance'],
          countryCode: ApiConstants.TENANT_COUNTRY
        }
      }
      if(payslipJSON['tenantConfiguration']) {
        payslipJSON['tenantConfiguration'] = {
          ...payslipJSON['tenantConfiguration'],
          countryCode:ApiConstants.TENANT_COUNTRY
        }
      }
    }
    return payslipJSON;
  }
  static getRequiredPayrollChequeJSON() {
    switch (ApiConstants.TENANT_COUNTRY) {
      case 'US':
        return Asset.mockData.US_Payroll_Check;
      default:
        return Asset.mockData.US_Payroll_Check;
    }
  }

  static getConvertedDate(date, format = null) {
    if (format == null) {
      return date.toLocaleDateString()
    } else {
      return getDateString(date, format)
    }
  }

  static getBillToPlaceholder(type) {
    if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToPlaceholder !== undefined) {
      return TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToPlaceholder.title
    }
    else {
      if (getIsDebitOrCreditNote(type)) {
        return ExpenseMapper.getLabel().customerAddressPlaceholder
      }
      else if (getIsDebitOrCreditNote(type)) {
        return CreditNoteMapper.getLabel().contactAddressPlaceholder
      }
      else {
        return 'billing_address_placeholder_text_value'
      }
    }
  }

  static getShipToPlaceholder(type) {
    if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToPlaceholder !== undefined) {
      return TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToPlaceholder.title
    }
    else {
      if (getIsDebitOrCreditNote(type)) {
        return ExpenseMapper.getLabel().accountNamePlaceholder
      }
      else if (getIsDebitOrCreditNote(type)) {
        return ''
      }
      else {
        return 'billing_address_placeholder_text_value'
      }
    }
  }

  static getDesignAssets(
    topBanner = null,
    bottomBanner = null,
    leftBanner = null,
    rightBanner = null,
    middleImage = null,
    backgroundImage = null
  ) {
    return {
      topBanner: topBanner,
      bottomBanner: bottomBanner,
      leftBanner: leftBanner,
      rightBanner: rightBanner,
      middleImage: middleImage,
      backgroundImage: backgroundImage,
    };
  }

  static getLineItemData(type, index, dateFormat) {
    var isExpenseOrCreditNote = getIsAccountModule(undefined, type)
    if (isExpenseOrCreditNote !== undefined) {
      if (getIsExpenseOrDeposit(type)) {
        return {
          name: ExpenseMapper.getLabel().accountName,
          description: "account_description",
          amount: 10.00,
          tax: 5,
          taxAmount: 0.5,
          paymentAmount: 9.5,
          lineNumber: index
        };
      } else if (getIsDebitOrCreditNote(type)) {
        return {
          name: ExpenseMapper.getLabel().accountName,
          amount: 10.00,
          tax: 5,
          taxAmount: 0.5,
          paymentAmount: 9.5,
          lineNumber: index
        };
      } else if (getIsFulfillment(type)) {
        return {
          code: 'F-000' + index,
          name: 'item_name',
          description: 'item_description',
          uom: 'N/A',
          requiredQuantity: 1,
          committedQuantity: 1,
          lineNumber: index,
          warehouseCode: 'WH-' + index,
          serialBatch: 'SB-' + index,
          warehouseName: 'WH-Name',
        };
      }
      else if (getIsGoodsReceipt(type)) {
        return {
          code: 'GR-000' + index,
          name: 'item_name',
          description: 'item_description',
          orderNo: '0000' + index,
          uom: 'N/A',
          requiredQuantity: 1,
          receivedQuantity: 1,
          lineNumber: index,
          warehouseCode: 'WH-' + index,
          warehouseName: 'WH-Name',
        };
      }
      else if (getIsMakePayment(type) || getIsReceivePayment(type)) {
        return {
          snNumber: index,
          invoiceNumber: 'INV-' + index,
          billNumber: 'BILL-' + index,
          totalInvoiceAmount: 100,
          totalBillAmount: 100,
          paymentMade: 100,
          currentDueAmount: 100,
          currentBalance: 100,
          paymentMedium: index === 1 ? 'cash' : index === 2 ? 'credit_note' : index === 3 ? 'prepayment' : 'cash'
        };
      }
      else if (getIsJournalEntry(type)) {
        return {
          date: new Date().toLocaleDateString(),
          journalEntryNumber: 'JE-1000',
          accountName: 'ACCT-' + index,
          accountCode: 'ACC_CO-' + index,
          description: 'Sample JE',
          credit: 100,
          debit: 100,
        };
      }
      else if (getIsSOACustomer(type) || getIsSOAVendor(type)) {
        return {
          lineNumber: index,
          documentDate: '01/10/2022',
          documentType: 'TYPE',
          documentNumber: 'DOC-' + index,
          particular: 'JE-' + index,
          credit: 100,
          debit: 100,
          amountInBase: 100,
          balanceInBase: 100,
          currency: '$',
          memo: 'MEMO'
        };
      }
      else if (getIsPickList(type)) {
        return {
          invoiceOrQuotation: 'INV000' + index,
          code: 'CODE-1000' + index,
          name: 'item_name',
          barcode: '',
          quantityToPack: 1,
          quantityForOrder: 100,
          picked: true,
        };
      }
      else if (getIsPackList(type)) {
        return {
          lineNumber: index,
          id: index,
          code: 'CODE-1000' + index,
          name: 'item_name',
          barcode: '',
          unit: 'QTY',
          packedQuantity: 100,
        };
      }
      else if (getIsShipList(type)) {
        return {
          lineNumber: index,
          delivery: 'CAR',
          carrier: 'DHL',
          numberOfCarton: 100,
          weight: '1kg',
          trackingNumber: 'TR-0000' + index,
        };
      }
      else if (getIsCheque(type)) {
        return {
          lineNumber: index,
          payTo: 'Customer_' + index,
          date: DocumentManager.getConvertedDate(new Date(), dateFormat),
          amountInWords: 'seventy four dollars and sixty one cents only',
          amount: '74.61',
          notes: 'memo text',
          signature: 'John William <br/> John Electronic',
          contactInfo: {
            billingAddress: {
              address1: "Bosworth",
              address2: "",
              city: "Midland",
              postalCode: "TX 79702",
              state: "Arkansas",
              country: "United States of America"
            }
          }
        };
      }
      else if (getIsSalesReturn(type)) {
        return {
          lineNumber: index,
          code: 'CODE-1000' + index,
          name: 'item_name',
          description: "item_description",
          fulfilledQuantity: 1,
          returnedQuantity: 1,
        };
      }
      else if (getIsJobWorkOutOrder(type)) {
        return {
          lineNumber: index,
          name: 'item_name',
          kindOfPacking: 'CODE-1000' + index,
          description: "item_description",
          dueOn: "25-09-2024",
          quantity: 1,
          rate: 100,
          per: 'Nos',
          amount: 12000,
          unitPrice: 1,
          discount: "0",
          uom: 'U' + index,
        };
      }
      else if (getIsMachine(type)){
        return {
        name: "sop_item_name",
        status: Number(index)%2===0? 'Completed': 'Open'
        }
      }
      else if (getIsInspectionReport(type)) {
        return {
          name: 'item_name',
          quantity: index,
          groupName: 'parts',
          description: 'item_description',
          advancedTrackingType: Number(index) % 2 === 0 ? 'NONE' : 'SERIAL'
        }
      }
      else {
        return {
          code: 'PROD-' + index,
          name: 'item_name',
          quantity: '1',
          description: "item_description",
          unitPrice: "0.00",
          tax: "0",
          discount: "0",
          uom: 'U' + index,
          amountBeforeTax: "0",
          lineNumber: index,
          image: '',
          serialBatch: 'SB-' + index,
          serialNumber: 'S-' + index,
          batchNumber: 'B-' + index,
          expiryDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
          manufacturingDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
          productSubstitutes: [],
          productSubstitutesCode: [],
          allProductSubstitutes: [],
          allProductSubstitutesCode: [],
          selectedRow: 'ROW-' + index,
          selectedRack: 'RACK-' + index,
          selectedBin: 'BIN-' + index,
          requestedQty: index,
          targetWarehouseName: 'Warehouse ' + index,
          targetWarehouseCode: 'WH-' + index,
          sourceWarehouseName: 'S Warehouse ' + index,
          sourceWarehouseCode: 'S WH-' + index,
          issuedQty: index * 10,
          requiredQty: index * 100,
          supplierPartDescription: "",
          supplierPartName: "",
          supplierPartNumber: ""
        };
      }
    }
    return {
      code: 'PROD-' + index,
      name: 'item_name',
      quantity: '1',
      description: "item_description",
      unitPrice: "0.00",
      tax: "0",
      discount: "0",
      uom: 'U' + index,
      amountBeforeTax: "0",
      image: '',
      serialBatch: 'SB-' + index,
      serialNumber: 'S-' + index,
      batchNumber: 'B-' + index,
      expiryDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
      manufacturingDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
      productSubstitutes: [],
      productSubstitutesCode: [],
      allProductSubstitutes: [],
      allProductSubstitutesCode: [],
    };
  }

  static getProductLineItems(type, index, dateFormat) {
    return {
      code: 'PROD-' + index,
      name: 'item_name',
      quantity: '1',
      description: "item_description",
      unitPrice: "0.00",
      tax: "0",
      discount: "0",
      uom: 'U' + index,
      amountBeforeTax: "0",
      image: '',
      serialBatch: 'SB-' + index,
      serialNumber: 'S-' + index,
      batchNumber: 'B-' + index,
      expiryDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
      manufacturingDate: DocumentManager.getConvertedDate(new Date(), dateFormat),
      productSubstitutes: [],
      productSubstitutesCode: [],
      allProductSubstitutes: [],
      allProductSubstitutesCode: [],
    };
  }

  static getTableDefaultStyle() {
    return {
      headerBackgroundColor: COLOR_LIGHTGRAY,
      headerTextColor: "#000000",
      headerBorderColor: "transparent",
      itemBorderColor: COLOR_LIGHTGRAY,
      itemBackgroundColor: null,
      itemBackgroundColorIsAlternate: null,
      footerBackgroundColor: "transparent",
      footerTextColor: "#000000",
      footerBorderColor: COLOR_LIGHTGRAY,
      subTotalBorderColor: null,
      showDiscount: true,
      discountTitle: 'Discount (-)',
      showTax: true,
      taxTitle: 'Tax',
      showUnitPrice: true,
      unitPriceTitle: 'UNIT PRICE',
      showQuantity: true,
      quantityTitle: 'QTY',
      showDescription: true,
      descriptionTitle: 'DESCRIPTION',
      showAmount: true,
      amountTitle: 'AMOUNT',
      showProductName: true,
      productNameTitle: 'NAME',

    };
  }

  static getPayments(type) {
    if (getIsBillOrInvoice(type)) {
      return [
        { amount: 0, documentType: 'make_payment', documentCode: '1' },
      ]
    }
    return undefined
  }

  ////////////////////////////////////////////////////////////
  ///////////////////// END -DATA MODEL //////////////////////
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  ////////////////////// TEMPLATE CREATION ///////////////////
  ////////////////////////////////////////////////////////////

  static getTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    if (IndiaTaxParser.getIsIndiaDefaultTaxTemplates(templateId)) {
      return this.getIndiaTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback)
    }
    if (this.getIsStockTransferTemplate(templateId)) {
      return this.getStockTransferTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback)
    }
    if (this.getIsStockIssuesTemplate(templateId)) {
      return this.getStockIssuesTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback)
    }
    if (this.getIsStockRequestTemplate(templateId)) {
      return this.getStockRequestTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback)
    }
    if (getIsPurchaseRequisitionTemplate(templateId)) {
      return this.getPurchaseRequisitionTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick);
    }
    if (getIsServiceRequisitionTemplate(templateId)) {
      return this.getServiceRequisitionTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick);
    }
    if (getIsServiceOrderTemplate(templateId)) {
      return this.getServiceOrderTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick)
    }
    if (getIsGoodsReceiptNoteTemplate(templateId)) {
      return this.getGoodsReceiptNoteTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick);
    }

    switch (templateId) {
      case 1:
        return <DocumentTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 2:
        return <DocumentTemplate2 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 3:
        return <DocumentTemplate3 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 4:
        return <DocumentTemplate4 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 5:
        return <DocumentTemplate5 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 6:
        return <DocumentTemplate6 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 7:
        return <DocumentTemplate7 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 8:
        return <DocumentTemplate8 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 9:
        return <DocumentTemplate9 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 10:
        return <DocumentTemplate10 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 11:
        return <DocumentTemplate11 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 12:
        return <DocumentTemplate12 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 13:
        return <DocumentTemplate13 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 14:
        return <DocumentTemplate14 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 15:
        return <DocumentTemplate15 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 16:
        return <DocumentTemplate16 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 17:
        return <DocumentTemplate17 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 18:
        return <DocumentTemplate18 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 19:
        return <DocumentTemplate19 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 20:
        return <DocumentTemplate20 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 21:
        return <DocumentTemplate21 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 22:
        return <DocumentTemplate22 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 23:
        return <DocumentTemplate23 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 24:
        return <DocumentTemplate24 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 25:
        return <DocumentTemplate25 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 26:
        return <DocumentTemplate26 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 27:
        return <DocumentTemplate27 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 28:
        return <DocumentTemplate28 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 29:
        return <DocumentTemplate29 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 30:
        return <DocumentTemplate30 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 31:
        return <DocumentTemplate31 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 32:
        return <DocumentTemplate32 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 33:
        return <DocumentTemplate33 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 34:
        return <DocumentTemplate34 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 35:
        return <DocumentTemplate35 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 37:
        return <DocumentTemplate37 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 39:
        return <DocumentTemplate39 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 41:
        return <DocumentTemplate41 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 49:
        return <DocumentTemplate49 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 50:
        return <DocumentTemplate50 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case SECURITY_GATE_DEFAULT_TEMPLATE_ID:
        return <SecurityGateEntryTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />
      default:
    }
  }

  static getIndiaTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 36:
        return <IndiaTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 38:
        return <IndiaTemplate2 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 40:
        return <IndiaTemplate3 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 42:
        return <IndiaTemplate4 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 43:
        return <IndiaTemplate5 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 44:
        return <IndiaTemplate6 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 45:
        return <IndiaTemplate7 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 46:
        return <IndiaTemplate8 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 47:
        return <IndiaTemplate9 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 48:
        return <IndiaTemplate10 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 51:
        return <IndiaTemplate11 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 61:
        return <IndiaTemplate12 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 62:
        return <IndiaTemplate13 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 63:
        return <IndiaTemplate14 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getIsStockTransferTemplate(templateId) {
    return templateId === 52
  }

  static getStockTransferTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 52:
        return <StockTransferTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getIsStockIssuesTemplate(templateId) {
    return templateId === 53
  }

  static getStockIssuesTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 53:
        return <StockIssuesTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getIsStockRequestTemplate(templateId) {
    return templateId === 54
  }

  static getStockRequestTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 54:
        return <StockRequestTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getServiceOrderTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 58:
        return <ServiceOrderTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 59:
        return <ServiceOrderTemplate2 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <ServiceOrderTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getGoodsReceiptNoteTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick, additionalTableCallback) {
    switch (templateId) {
      case 60:
        return <GoodsReceiptNoteTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />
      default:
        return <GoodsReceiptNoteTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />
    }
  }

  static getThemeColor(type, templateId) {
    let themeColor = "#000000"

    if (type !== undefined && type !== null) {
      if (getIsPayslip(type)) {
        return this.getPayslipThemeColor(templateId)
      }
      if (Utility.getIsReceiptPaperSize()) {
        return this.getReceiptThemeColor(templateId)
      }
      if (getIsEwayBill(type)) {
        return this.getReceiptThemeColor(templateId)
      }
    }

    switch (templateId) {
      case 1:
        themeColor = "#000000"
        break;
      case 2:
        themeColor = "#CD3333"
        break;
      case 3:
        themeColor = "#3C4E87"
        break;
      case 4:
        themeColor = "#D34C29"
        break;
      case 5:
        themeColor = "#E9AB01"
        break;
      case 6:
        themeColor = "#DFFBE1"
        break;
      case 7:
        themeColor = "#EBEBEB"
        break;
      case 8:
        themeColor = "#0259B6"
        break;
      case 9:
        themeColor = "#0E83DD"
        break;
      case 10:
        themeColor = "#DF582A"
        break;
      case 11:
        themeColor = "#6195D2"
        break;
      case 12:
        themeColor = "#334E94"
        break;
      case 13:
        themeColor = "#2FDA8B"
        break;
      case 14:
        themeColor = "#01C1DA"
        break;
      case 15:
        themeColor = "#E8903A"
        break;
      case 16:
        themeColor = "#005FA8"
        break;
      case 17:
        themeColor = "#E9F0FB"
        break;
      case 18:
        themeColor = "#01C1DA"
        break;
      case 19:
        themeColor = "#F09138"
        break;
      case 20:
        themeColor = "#3C4E87"
        break;
      case 21:
        themeColor = "#CFD4D4"
        break;
      case 22:
        themeColor = "#A9382E"
        break;
      case 23:
        themeColor = "#C3F4EC"
        break;
      case 24:
        themeColor = "#50B8F9"
        break;
      case 25:
        themeColor = "#DF582A"
        break;
      case 26:
        themeColor = "#0E83DE"
        break;
      case 27:
        themeColor = "#1f6bbd"
        break;
      case 28:
        themeColor = "#FF4E50"
        break;
      case 29:
        themeColor = "#666668"
        break;
      case 30:
        themeColor = "#F09138"
        break;
      case 31:
        themeColor = "#FF4E50"
        break;
      case 32:
        themeColor = "#5330BE"
        break;
      case 33:
        themeColor = "#E76604"
        break;
      case 34:
        themeColor = "#327EF5"
        break;
      case 35:
        themeColor = "#1DCB06"
        break;
      case 36:
        themeColor = "#000000"
        break;
      case 49:
        themeColor = "#CD3333"
        break;
      case 50:
        themeColor = "#3C4E87"
        break;
      default:
    }

    return themeColor
  }

  static getPayslipThemeColor(templateId) {
    let selectedThemeColor = "#000000"

    var themeColorList = [
      { id: 1, color: '#007944' },
      { id: 2, color: '#007944' },
      { id: 3, color: '#007944' },
      { id: 4, color: '#007944' },
      { id: 5, color: '#007944' },
      { id: 6, color: '#3247ec' },
      { id: 7, color: '#000000' },
      { id: 8, color: '#145bff' },
      { id: 9, color: '#51549e' },
      { id: 10, color: '#000000' },
      { id: 11, color: '#E9AB01' },
      { id: 12, color: '#007944' },
      { id: 13, color: '#3247ec' },
      { id: 14, color: '#000000' },
      { id: 15, color: '#145bff' },
      { id: 16, color: '#51549e' },
      { id: 17, color: '#000000' },
      { id: 18, color: '#007944' },
      { id: 19, color: '#145bff' },
      { id: 20, color: '#3247ec' },
      { id: 21, color: '#E9AB01' },
      { id: 22, color: '#007944' },
      { id: 23, color: '#007944' }
    ]

    var themeColor = themeColorList.find(x => x.id === templateId)
    if (themeColor !== undefined) {
      selectedThemeColor = themeColor.color
    }
    return selectedThemeColor
  }

  static getReceiptThemeColor(templateId) {
    var selectedThemeColor = "#000000"

    var themeColorList = [
      { id: 1, color: '#000000' },
      { id: 2, color: '#000000' },
    ]

    var themeColor = themeColorList.find(x => x.id === templateId)
    if (themeColor !== undefined) {
      selectedThemeColor = themeColor.color
    }
    return selectedThemeColor
  }

  static getLabelTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <LabelTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getEWayBillSummaryTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <EWayBillSummaryTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getEWayBillDetailsTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <EWayBillDetailsTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getMachineSchedulerTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <MachineSchedulerTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} onCustomFieldClick={onCustomFieldClick} />;
      default:
      return <MachineSchedulerTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} onCustomFieldClick={onCustomFieldClick} />;
    }
  }


  static getChequeTemplateWith(templateId, data) {
    switch (templateId) {
      case 1:
        return <ChequeTemplate1 data={data} />;
      case 2:
        return <ChequeTemplate2 data={data} />;
      case 3:
        return <ChequeTemplate3 data={data} />;
      case 4:
        return <USChequeTemplate1 data={data} />;
      case 5:
        return <USChequeTemplate2 data={data} />;
      case 6:
        return <USChequeTemplate3 data={data} />;
      case 7:
        return <USChequeDesignTemplate data={data} />;
      default:
    }
  }

  static getReceiptTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <ReceiptTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      case 2:
        return <ReceiptTemplate2 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
    }
  }

  static getPayslipTemplateWith(templateId, data) {
    switch (templateId) {
      case 1:
        return <INPayslipTemplate1 data={data} />;
      case 2:
        return <USPayslipTemplate1 data={data} />;
      case 3:
        return <SGPayslipTemplate1 data={data} />;
      case 4:
        return <MYPayslipTemplate1 data={data} />;
      case 5:
        return <PayslipTemplate1 data={data} />;
      case 6:
        return <INPayslipTemplate2 data={data} />;
      case 7:
        return <INPayslipTemplate3 data={data} />;
      case 8:
        return <INPayslipTemplate4 data={data} />;
      case 9:
        return <INPayslipTemplate5 data={data} />;
      case 10:
        return <INPayslipTemplate6 data={data} />;
      case 11:
        return <INPayslipTemplate7 data={data} />;
      case 12:
        return <INPayslipTemplate8 data={data} />;
      case 13:
        return <USPayslipTemplate2 data={data} />;
      case 14:
        return <USPayslipTemplate3 data={data} />;
      case 15:
        return <USPayslipTemplate4 data={data} />;
      case 16:
        return <USPayslipTemplate5 data={data} />;
      case 17:
        return <USPayslipTemplate6 data={data} />;
      case 18:
        return <USPayslipTemplate7 data={data} />;
      case 19:
        return <MYPayslipTemplate2 data={data} />
      case 20:
          return <SGPayslipTemplate2 data={data} />;
      case 21:
          return <SGPayslipTemplate3 data={data} />;
      case 22:
          return <SGPayslipTemplate4 data={data} />;
      case 23:
          return <SGPayslipTemplate5 data={data} />;
      default:
        return <PayslipTemplate1 data={data} />;
    }
  }

  static getPayrollCheckTemplateWith(templateId, data) {
    switch (templateId) {
      case 1:
        return <USPayrollCheckTemplate1 data={data} />;
      default:
        return <USPayrollCheckTemplate1 data={data} />;
    }
  }

  static getWorkOrderTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <WorkOrderTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <WorkOrderTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getJobWorkOutOrderTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <JobWorkOurOrderTemplates1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <JobWorkOurOrderTemplates1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getJobCardTemplateWith(templateId, data) {
    switch (templateId) {
      case 1:
        return <JobCardTemplate1 data={data} />;
      default:
        return <JobCardTemplate1 data={data} />;
    }
  }

  static getPurchaseRequisitionTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 55:
        return <PurchaseRequisitionTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <PurchaseRequisitionTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getServiceRequisitionTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch(templateId) {
      case 56:
        return <ServiceRequisitionTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <ServiceRequisitionTemplate1 tableColumn={tableColumn} data={data} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getQCTemplateWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <QCTemplates1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <QCTemplates1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getQCInspectionWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <QCInspectionTemplate1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <QCInspectionTemplate1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getInspectionReportWith(templateId, data, tableColumn, tableClickCallback, stockTableClicked, onUpdateDataCallback, paymentTableClicked, additionalTableCallback, onCustomFieldClick) {
    switch (templateId) {
      case 1:
        return <InspectionReportTemplate1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
      default:
        return <InspectionReportTemplate1 data={data} tableColumn={tableColumn} tableClicked={tableClickCallback} stockTableClicked={stockTableClicked} onUpdateDataCallback={onUpdateDataCallback} paymentTableClicked={paymentTableClicked} additionalTableCallback={additionalTableCallback} onCustomFieldClick={onCustomFieldClick} />;
    }
  }

  static getTemplateData(type, templateId) {
    const themeColor = DocumentManager.getThemeColor(type, templateId)
    TemplateSettingsManager.updateTemplateID(templateId)
    TemplateSettingsManager.updateTemplateThemeColor(themeColor)

    return DocumentManager.getDefaultData(
      templateId,
      type,
      themeColor
    );
  }

  ////////////////////////////////////////////////////////////
  /////////////////// END - TEMPLATE CREATION ////////////////
  ////////////////////////////////////////////////////////////

  static getEwayBillData() {
    var address = {
      "city": "Faridabad",
      "state": "Haryana",
      "country": "IN",
      "address1": "B 327 Basement, Nehru Ground",
      "address2": null,
      "preferred": true,
      "postalCode": "121001",
      "placeOfSupply": "Haryana",
      "destinationOfSupply": null
    }

    var data = {}
    var clientShipToAddressObj = new Address(undefined, address, undefined, undefined, undefined, undefined, OwnerType.tenant)
    data.clientShipToAddressObj = clientShipToAddressObj
    data = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, data)

    return {
      eWayBillNo: '123456789',
      eWayBillDate: '11/10/2022 10:52 AM',
      contactName: 'DEMO SOFTWARE PRIVATE LIMITED',
      contactCode: '',
      contactSeqCode: '',
      tenantName: 'DEMO TENANt SOFTWARE PRIVATE LIMITED',
      irn: 'AAAA947077f0761c3a9a9e4175c3eb6c1f2b7462f85validUpto3b5e419971d6a536cf202',
      validFrom: '14/04/2022 11:59 PM',
      validTill: '16/04/2022 11:59 PM',
      tenantGSTIN: 'AAAAFCD5862R000',
      // placeOfDispatch: 'Bangalore, Karnataka',
      placeOfDispatch: data.clientShipToAddress,
      supplierPostalCode: address.postalCode,
      contactGSTIN: 'ZZZZFCD5862R000',
      recipientPostalCode: address.postalCode,
      // placeOfDelivery: 'THOPPIL, Kerala',
      placeOfDelivery: data.clientShipToAddress,
      documentNo: 'AAABY222300002',
      documentDate: '01/10/2022',
      transactionType: 'Regular',
      valueOfGoods: 100,
      hsnCode: 84133090,
      reasonForTransportation: 'Outward - SUPPLY',
      transporter: "DEMO SOFTWARE PRIVATE LIMITED",
      mode: 'road',
      vehicleNo: 'KA01MG9999',
      transporterDocNo: '12345',
      transporterDocDate: '11/04/2022',
      transportVehicleNoDocData: 'KA01MG9999 & 12345 & 11/04/2022',
      from: 'Bangalore',
      cewbNo: '0',
      multiVehInfo: '',

      distanceInKm: '1000KM',
      //details
      validUpto: '16/04/2022 11:59 PM (Vehicle Type is REGULAR)',
      type: "Tax Invoice",
      documentSeqCode: "AAABY222300002",
      documentDetails: 'Tax Invoice - AAABY222300002 - 01/10/2022', //combine type + documentSeqCode + document Date
      acknowledgementNumber: '112210135705172',
      acknowledgementDate: '11/10/2022 10:49 AM',
      dispatchFrom: "",
      to: "Kerala",
      // shipTo: "Kerala",
      documentType: 'e_way_bill',
      goodsDetails: [
        {
          hsnCode: '84133090',
          productCode: '123123',
          productSeqCode: 'Prod-123123',
          productName: 'Apple 1',
          productDescription: 'Apple Product',
          quantity: 10,
          taxableAmount: 100,
          cessAmount: null,
          igstAmount: 20,
          sgstAmount: 0,
          cgstAmount: 0,
          cessNonAdvolAmount: null,
          otherAmount: 60,
          totalAmount: 180,
          taxRate: '1'
        },
        {
          hsnCode: '84133090',
          productCode: '123123',
          productSeqCode: 'Prod-123123',
          productName: 'Apple 1',
          productDescription: 'Apple Product',
          quantity: 50,
          taxableAmount: 10,
          cessAmount: 10,
          igstAmount: 0,
          sgstAmount: 30,
          cgstAmount: 40,
          cessNonAdvolAmount: 50,
          otherAmount: 60,
          totalAmount: 180,
          taxRate: '1'
        }
      ],
      showFrom: true,
      showCompanyLogo: true,
      showClientShipToAddress: true,
    }
  }
}


function getMachineLineItems(index){
  return {
    lineNumber: index + 1,
    code: 123,
    description: 'Machine 1',
    uom: 'UNIT',
    unitPrice: '100',
    amount: '100',
    remark: 'Remark 1',
    quantity: 1,
  }
}