import { DKIcon, DKIcons } from 'deskera-ui-library';
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  isMRPEnable,
  SPLIT_GUTTER,
  STATUS_OPTIONS,
  STEP_CARD_WIDTH,
  WAIT_INTERVALS
} from './AutomationConstants';
import Utility, { getRandomAlphaNumericString } from '../../Utility/Utility';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../Constants/TableConstants';
import { TableManager, TABLES } from '../../Managers/TableManager';
import ic_split from '../../Assets/Icons/white/ic_split.png';
import ic_product from '../../Assets/menu/white/ic_product.svg';
import ic_trigger_field_value from '../../Assets/menu/white/ic_list.png';
import ic_trigger_form_sent from '../../Assets/menu/white/ic_form.png';
import ic_invoice from '../../Assets/menu/white/ic_invoice.svg';
import ic_quote from '../../Assets/menu/white/ic_quote.svg';
import ic_order from '../../Assets/menu/white/ic_order.svg';
import ic_bill from '../../Assets/menu/white/ic_bill.svg';
import AutomationService from '../../Services/Automation';
import ic_whatsapp from '../../Assets/automation/actions/ic_whatsapp.png';
import invoice_pay from '../../Assets/Icons/invoice_pay.svg';
import bill_payment from '../../Assets/Icons/bill_payment.svg';
import invoice_fulfill from '../../Assets/Icons/invoice_fulfill.svg';
import quote_fulfill from '../../Assets/Icons/quote_fulfill.svg';
import bill_goods_received from '../../Assets/Icons/bill_goods_received.svg';
import order_goods_receive from '../../Assets/Icons/order_goods_receive.svg';
import { localizedText } from '../../Services/Localization/Localization';
import { DOC_TYPE } from '../../Constants/Constant';
export default class AutomationManager {
  static automationData: any = {};
  static steps: any = [];
  static adjacencyMatrix: any = {};
  static uiJson: any = {
    wingSpan: {},
    outerRadiusFromLeft: 0
  };

  static connectionId = null;
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getAllTriggers() {
    const MRPTriggers = isMRPEnable()
      ? [
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.WORK_ORDER_TRIGGER,
            title: 'Work Order Created'
          },
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.WAREHOUSE_INSUFFICIENT_QTY_TRIGGER,
            title: 'Work Order Shortfall Qty Alert'
          },
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.QUALITY_CHECK_REJECTION_TRIGGER,
            title: 'Quality Check Rejection trigger'
          },
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.STOCK_REQUEST_TRIGGER,
            title: 'Stock Request Created'
          },
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.STOCK_ISSUE_TRIGGER,
            title: 'Stock Issue Created'
          },
          {
            elementType: AUTOMATION_ELEMENT.TRIGGER,
            type: AUTOMATION_TRIGGERS.MACHINE_SCHEDULER_TRIGGER,
            title: 'Machine Scheduler Created'
          }
        ]
      : [];
    return [
      // {
      //   elementType: AUTOMATION_ELEMENT.TRIGGER,
      //   type: AUTOMATION_TRIGGERS.CONTACT_ADDED,
      //   title: "Contact added",
      // },

      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.INVOICE_CREATED,
        title: 'Invoice Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.QUOTE_CREATED,
        title: `${localizedText('Quote')} Created`
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BILL_CREATED,
        title: 'Bill Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.EXPENSE_BILL_CREATED,
        title: 'Expense Bill Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.ORDER_CREATED,
        title: 'Purchase Order Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.REQUISITION_CREATED,
        title: 'Requisition Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.SALES_ORDER_TRIGGER,
        title: 'Sales Order Created'
      },
      ...MRPTriggers,
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.RESTOCK_LEVEL_REACHED,
        title: 'Restocking Level Reached'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.STOCK_ADJUSTMENT_TRIGGER,
        title: 'Stock Adjustment Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.STOCK_TRANSFER_TRIGGER,
        title: 'Stock Transfer Created'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.INVOICE_PAYMENT_STATUS_CHANGED,
        title: 'Invoice Payment Status Changed'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BILL_PAYMENT_STATUS_CHANGED,
        title: 'Bill Payment Status Change'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.INVOICE_FULFILLMENT_STATUS_CHANGED,
        title: `Invoice ${localizedText('Fulfillment')} Status Change`
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.QUOTE_FULFILLMENT_STATUS_CHANGED,
        title: `${localizedText('Quote')} ${localizedText(
          'Fulfillment'
        )} Status Change`
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BILL_GR_STATUS_CHANGED,
        title: 'Bill Goods Receipt Status Change'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.ORDER_GR_STATUS_CHANGED,
        title: 'Order Goods Receipt Status Change'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.REORDER_LEVEL_REACHED,
        title: 'Product Reorder Level Reached'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_PRODUCT_BATCH_EXPIRY,
        title: 'Batch Expiry Notification'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_DATE_ALERT_TRIGGER,
        title: 'Date Alerts Trigger'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_LINKED_MODULE_ALERT_TRIGGER,
        title: 'Linked Document Alerts Trigger'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.DELIVERY_SCHEDULE,
        title: 'Delivery Schedule Notification'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_MILESTONE_ALERT_TRIGGER,
        title: 'Payment Milestone'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_SALES_ORDER_CREATE,
        title: 'Date Alerts Trigger'
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.BOOKS_GR_FF_TOLERANCE_CREATE_TRIGGER,
        title: 'GR/FF Tolerance Trigger'
      }
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getDocTypeWithAction = (actionType: string) => {
    let type = '';
    switch (actionType) {
      case AUTOMATION_TRIGGERS.INVOICE_CREATED:
        type = DOC_TYPE.INVOICE;
        break;
      case AUTOMATION_TRIGGERS.QUOTE_CREATED:
        type = DOC_TYPE.QUOTE;
        break;
      case AUTOMATION_TRIGGERS.BILL_CREATED:
        type = DOC_TYPE.BILL;
        break;
      case AUTOMATION_TRIGGERS.ORDER_CREATED:
        type = DOC_TYPE.ORDER;
        break;
      case AUTOMATION_TRIGGERS.BOOKS_SALES_ORDER_CREATE:
        type = DOC_TYPE.SALES_ORDER;
        break;
      case AUTOMATION_TRIGGERS.EXPENSE_BILL_CREATED:
        type = DOC_TYPE.EXPENSE_BILL;
        break;
      default:
        break;
    }
    return type;
  };

  static getAllActions() {
    return [
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.SEND_EMAIL,
        title: 'Send an e-mail'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
        title: 'Split Path',
        logicalOperator: 'and',
        successKey: null,
        failureKey: null
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.WAIT_FOR_TIME,
        title: 'Wait for (time)'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.SEND_SMS_MESSAGE,
        title: 'Send a text message'
      },
      // {
      //   elementType: AUTOMATION_ELEMENT.ACTION,
      //   type: AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE,
      //   title: "Send a whatsapp message",
      // },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.END_WORKFLOW,
        title: 'End automation'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_APPROVAL,
        title: 'Approval condition'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_PLUS_SEND_PRODUCT_BATCH_EXPIRY_REMINDER,
        title: 'Batch Expiry Notification'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_DATE_ALERT_ACTION,
        title: 'Date Alerts Action'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CREATE_WORK_ORDER_FROM_SALES_ORDER,
        title: 'Create Work Order'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_LINKED_MODULE_ALERT_ACTION,
        title: 'Linked Document Alerts Action'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_DELIVERY_SCHEDULE_ACTION,
        title: 'Delivery Schedule Action'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.BOOKS_MILESTONE_ALERT_ACTION,
        title: 'Send Notification'
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CONDITINAL_EMAIL,
        title: 'Conditional email based on'
      }
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getTriggerIcon(trigger: any) {
    let icon = ic_trigger_form_sent;
    switch (trigger) {
      case AUTOMATION_TRIGGERS.INVOICE_CREATED:
        icon = ic_invoice;
        break;
      case AUTOMATION_TRIGGERS.QUOTE_CREATED:
        icon = ic_quote;
        break;
      case AUTOMATION_TRIGGERS.BILL_CREATED:
        icon = ic_bill;
        break;
      case AUTOMATION_TRIGGERS.EXPENSE_BILL_CREATED:
        icon = ic_bill;
        break;
      case AUTOMATION_TRIGGERS.ORDER_CREATED:
        icon = ic_order;
        break;
      case AUTOMATION_TRIGGERS.SALES_ORDER_TRIGGER:
        icon = ic_order;
        break;
      case AUTOMATION_TRIGGERS.WORK_ORDER_TRIGGER:
        icon = ic_order;
        break;
      case AUTOMATION_TRIGGERS.BILL_PAYMENT_STATUS_CHANGED:
        icon = bill_payment;
        break;
      case AUTOMATION_TRIGGERS.INVOICE_PAYMENT_STATUS_CHANGED:
        icon = invoice_pay;
        break;
      case AUTOMATION_TRIGGERS.INVOICE_FULFILLMENT_STATUS_CHANGED:
        icon = invoice_fulfill;
        break;
      case AUTOMATION_TRIGGERS.QUOTE_FULFILLMENT_STATUS_CHANGED:
        icon = quote_fulfill;
        break;
      case AUTOMATION_TRIGGERS.BILL_GR_STATUS_CHANGED:
        icon = bill_goods_received;
        break;
      case AUTOMATION_TRIGGERS.ORDER_GR_STATUS_CHANGED:
        icon = order_goods_receive;
        break;
      case AUTOMATION_TRIGGERS.REORDER_LEVEL_REACHED:
      case AUTOMATION_TRIGGERS.RESTOCK_LEVEL_REACHED:
        // icon = order_goods_receive;
        break;
      case AUTOMATION_TRIGGERS.BOOKS_PRODUCT_BATCH_EXPIRY:
        // icon = order_goods_receive;
        break;
      case AUTOMATION_TRIGGERS.BOOKS_DATE_ALERT_TRIGGER:
        icon = DKIcons.white.ic_notification;
        // color = 'bg-telegram';
        break;
      case AUTOMATION_TRIGGERS.DELIVERY_SCHEDULE:
      case AUTOMATION_TRIGGERS.BOOKS_MILESTONE_ALERT_TRIGGER:
      case AUTOMATION_TRIGGERS.BOOKS_SALES_ORDER_CREATE:
        icon = DKIcons.white.ic_notification;
        break;
      case AUTOMATION_TRIGGERS.REQUISITION_CREATED:
        icon = ic_bill;
        break;
      case AUTOMATION_TRIGGERS.QUALITY_CHECK_REJECTION_TRIGGER:
        icon = ic_product;
        break;
      case AUTOMATION_TRIGGERS.BOOKS_GR_FF_TOLERANCE_CREATE_TRIGGER:
        icon = ic_bill;
        break;
      default:
        icon = ic_trigger_field_value;
    }
    return (
      <DKIcon src={icon} className="ic-s-2 bg-button p-s border-radius-m" />
    );
  }
  static getActionIcon(action: any) {
    let icon = DKIcons.white.ic_email;
    let color = 'bg-blue';
    switch (action) {
      case AUTOMATION_ACTIONS.SEND_EMAIL:
        icon = DKIcons.white.ic_email;
        color = 'bg-gray';
        break;
      case AUTOMATION_ACTIONS.SEND_SMS_MESSAGE:
        icon = DKIcons.white.ic_notification;
        color = 'bg-telegram';
        break;
      case AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE:
        icon = ic_whatsapp;
        color = 'bg-whats-app';
        break;
      case AUTOMATION_ACTIONS.SPLIT_WORKFLOW:
        icon = ic_split;
        color = 'bg-dark-gray';
        break;
      case AUTOMATION_ACTIONS.WAIT_FOR_TIME:
        icon = DKIcons.white.ic_notification;
        color = 'bg-yellow';
        break;
      case AUTOMATION_ACTIONS.END_WORKFLOW:
        icon = DKIcons.white.ic_warning;
        color = 'bg-red';
        break;
      case AUTOMATION_ACTIONS.END_WORKFLOW:
        icon = DKIcons.check;
        color = 'bg-green';
        break;
      case AUTOMATION_ACTIONS.BOOKS_PLUS_SEND_PRODUCT_BATCH_EXPIRY_REMINDER:
        icon = DKIcons.white.ic_notification;
        color = 'bg-telegram';
        break;
      case AUTOMATION_ACTIONS.BOOKS_DATE_ALERT_ACTION:
        icon = DKIcons.white.ic_notification;
        color = 'bg-telegram';
        break;
      case AUTOMATION_ACTIONS.CREATE_WORK_ORDER_FROM_SALES_ORDER:
        icon = ic_order;
        color = 'bg-telegram';
        break;
      case AUTOMATION_ACTIONS.BOOKS_LINKED_MODULE_ALERT_ACTION:
      case AUTOMATION_ACTIONS.BOOKS_DELIVERY_SCHEDULE_ACTION:
      case AUTOMATION_ACTIONS.BOOKS_MILESTONE_ALERT_ACTION:
        icon = DKIcons.white.ic_notification;
        color = 'bg-telegram';
        break;
      case AUTOMATION_ACTIONS.CONDITINAL_EMAIL:
        icon = DKIcons.white.ic_email;
        color = 'bg-green';
        break;
      default:
        icon = DKIcons.white.ic_email;
    }
    return (
      <DKIcon src={icon} className={'ic-s-2 p-s border-radius-m ' + color} />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getWaitIntervals = (index: any, interval: any) =>
    `${interval} ${WAIT_INTERVALS[index]}${interval === 1 ? '' : 's'}`;

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getDefaultActionData(action: any) {
    return [];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static addNewStep(
    stepData: any,
    prevStepId: any,
    indexToAdd = null,
    splitIndex = 0,
    isNewWaitNodeRequired = false
  ) {
    const newStepId = getRandomAlphaNumericString(24);

    if (indexToAdd !== null) {
      this.steps.splice(indexToAdd ? indexToAdd : 0 + splitIndex + 1, 0, {
        ...stepData,
        id: newStepId
      });
    } else {
      this.steps.push({ ...stepData, id: newStepId });
    }

    this.setAdjacencyMatrix(
      prevStepId,
      newStepId,
      stepData.type === AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
      splitIndex
    );

    if (isNewWaitNodeRequired) {
      /* Setting a default wait action (1 day) before Email based split conditions */
      const newWaitNode: any = AutomationManager.getAllActions().find(
        (actionData) => actionData.type === AUTOMATION_ACTIONS.WAIT_FOR_TIME
      );
      newWaitNode['configuration'] = {
        minutes: 1440,
        count: 1,
        intervalIndex: 0
      };

      this.addNewStep(newWaitNode, prevStepId, indexToAdd, splitIndex);
    }

    this.generateUIJson();
  }

  static updateStep(stepData: any, indexToUpdate: any) {
    /* To handle edit operation on END_WORKFLOW */
    if (!stepData.id) {
      stepData.id = this.steps[indexToUpdate]['id'];
    }

    this.steps[indexToUpdate] = stepData;
  }

  static deleteStep(index: any, parentId: any, isSplitParent?: any) {
    const stepData = this.steps[index];
    const stepId = stepData.id;

    /* If split element is deleted, delete whole tree below it */
    if (stepData.type === AUTOMATION_ACTIONS.SPLIT_WORKFLOW || isSplitParent) {
      const stepIdsToRemove = [stepId],
        stepIdQueue = [stepId];

      while (stepIdQueue.length !== 0) {
        let tempId = stepIdQueue.shift();
        const childIds = this.adjacencyMatrix[tempId] || [];
        stepIdsToRemove.push(...childIds);
        stepIdQueue.push(...childIds);
        delete this.adjacencyMatrix[tempId];
      }

      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId]?.map(
        (childId: any) => (childId === stepId ? null : childId)
      );
      this.steps = this.steps.filter(
        (step: any) => !stepIdsToRemove.includes(step.id)
      );
    } else {
      /* otherwise delete the element and assign it's child element to parent id */
      const childId = (this.adjacencyMatrix[stepId] || [])[0];
      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId].map(
        (existingChildId: any) =>
          existingChildId === stepId ? childId : existingChildId
      );

      delete this.adjacencyMatrix[stepId];
      this.steps.splice(index, 1);
    }

    this.generateUIJson();
  }

  static getAllSteps() {
    return Utility.makeCopyOfObject(this.steps);
  }

  static getStepById(id: string) {
    if (!id || !this.steps?.length) {
      return null;
    }

    const step = this.steps.find((step: any) => step.id === id);
    if (!step) {
      return null;
    }

    return Utility.makeCopyOfObject(step);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  /* Method for handling existing automations,
  saved without adjacency matrix */
  static generateAdjacencyMatrix() {
    const steps = this.steps;
    const adjacencyMatrix: any = {};

    if (steps.length) {
      const newSteps = steps.map((step: any) => ({
        ...step,
        id: getRandomAlphaNumericString(24)
      }));

      for (let counter = 1; counter < newSteps.length; counter++) {
        const sourceStepId = newSteps[counter - 1].id;
        const targetStepId = newSteps[counter].id;
        adjacencyMatrix[sourceStepId] = [targetStepId];
      }

      this.steps = newSteps;
    }

    return adjacencyMatrix;
  }

  static setAdjacencyMatrix(
    parentId: any,
    childId: any,
    isSplitAction: any,
    childIndex: any
  ) {
    if (parentId) {
      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId] || [];

      const existingChildId = this.adjacencyMatrix[parentId][childIndex];

      this.adjacencyMatrix[parentId][childIndex] = childId;

      /* For split action there can be 2 paths:
          path from 0th index is taken when split condition evaluates to true (YES),
          path from 1st index is taken when split condition evaluates to false (NO)
      */
      this.adjacencyMatrix[childId] = isSplitAction
        ? [existingChildId, null]
        : [existingChildId];
    } else {
      this.adjacencyMatrix[childId] = isSplitAction ? [null, null] : [null];
    }
  }

  static getAdjacencyMatrix() {
    return Utility.makeCopyOfObject(AutomationManager.adjacencyMatrix);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static generateUIJson() {
    this.uiJson.wingSpan = {};

    this.uiJson.outerRadiusFromLeft = this.populateWorkflowNodesWingSpan(
      this.steps[0]?.id
    );
  }

  /*
    This method evaluates and populates inner radius for each side of a `SPLIT_WORKFLOW` type node.
  */
  static populateWorkflowNodesWingSpan(stepId: any, traverseRight = false) {
    if (stepId === null) return 0;

    let parentId = null,
      children = [stepId];
    /* for finding the next SPLIT_WORKFLOW type node */
    while (children && children.length < 2) {
      parentId = children[0];
      children = this.adjacencyMatrix[parentId];
    }

    if (children) {
      const innerRadiusFromleftWing =
        this.populateWorkflowNodesWingSpan(children[0], true) + 1;
      const innerRadiusFromRightWing =
        this.populateWorkflowNodesWingSpan(children[1], false) + 1;
      this.uiJson.wingSpan[parentId] = [
        innerRadiusFromleftWing,
        innerRadiusFromRightWing
      ];

      const outerRadius: any =
        this.populateWorkflowNodesWingSpan(
          traverseRight ? children[1] : children[0],
          traverseRight
        ) + 1;
      return outerRadius;
    } else {
      return 0;
    }
  }

  static setupSplitActionConfigurations() {
    const parentKeyStepTypeMapping: any = {};

    this.steps = this.steps.map((stepData: any) => {
      const newStepData = Utility.makeCopyOfObject(stepData);
      parentKeyStepTypeMapping[newStepData.id] = newStepData.type;

      if (newStepData.type === AUTOMATION_ACTIONS.SPLIT_WORKFLOW) {
        const splitPathChildren = this.adjacencyMatrix[newStepData.id];
        newStepData.successKey = splitPathChildren[0] || null;
        newStepData.failureKey = splitPathChildren[1] || null;
        newStepData.logicalOperator = 'and';
      }

      return newStepData;
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static isDataAvailable() {
    return !(
      (this.automationData && this.automationData.isTemplate) ||
      this.automationData === null ||
      this.automationData === undefined ||
      Utility.isEmpty(this.automationData)
    );
  }
  static isSave() {
    return this.steps.length > 2 &&
      this.steps?.find((ele: any) => ele.type === 'END_WORKFLOW')
      ? false
      : true;
  }
  static isTemplate() {
    return this.automationData && this.automationData.isTemplate;
  }

  static setData(response: any) {
    this.automationData = response;
    if (this.isDataAvailable()) {
      const jsonData = this.getJSONData();
      this.setNewAutomationData(jsonData);
    } else if (this.automationData && this.automationData.isTemplate) {
      let fulfillData = this.automationData.steps.find(
        (ele: any) => ele.type === 'BOOKS_UPDATED_INVOICE_FULFILLMENT'
      );
      let paymentData = this.automationData.steps.find(
        (ele: any) => ele.type === 'BOOKS_UPDATED_PURCHASE_INVOICE_PAYMENT'
      );
      let grData = this.automationData.steps.find(
        (ele: any) => ele.type === 'BOOKS_UPDATED_PURCHASE_INVOICE_GR'
      );
      if (fulfillData) {
        let table = REMOTE_CONFIG_TABLES.INVOICE;
        let columnId: any = TableManager.getColumnId(
          table,
          COLUMN_CODE.INVOICE.FulfillmentStatus
        );
        this.automationData.steps[0].configuration.fields[0][columnId] =
          this.automationData.steps[0].configuration.fields[0][
            'fulfillmentStatus'
          ];
        delete this.automationData.steps[0].configuration.fields[0][
          'fulfillmentStatus'
        ];
      }
      if (paymentData) {
        let table = REMOTE_CONFIG_TABLES.BILL;
        let columnId: any = TableManager.getColumnId(
          table,
          COLUMN_CODE.BILL.PendingStatus
        );
        this.automationData.steps[0].configuration.fields[0][columnId] =
          this.automationData.steps[0].configuration.fields[0]['paymentStatus'];
        delete this.automationData.steps[0].configuration.fields[0][
          'paymentStatus'
        ];
      }
      if (grData) {
        let table = REMOTE_CONFIG_TABLES.BILL;
        let columnId: any = TableManager.getColumnId(
          table,
          COLUMN_CODE.BILL.ReceiptStatus
        );
        this.automationData.steps[0].configuration.fields[0][columnId] =
          this.automationData.steps[0].configuration.fields[0][
            'goodsReceiptStatus'
          ];
        delete this.automationData.steps[0].configuration.fields[0][
          'goodsReceiptStatus'
        ];
      }
      this.setNewAutomationData(this.automationData);
    } else {
      this.resetAllData();
    }
  }

  static setNewAutomationData(jsonData: any) {
    this.steps = jsonData.steps;
    this.adjacencyMatrix =
      jsonData.adjacencyMatrix || this.generateAdjacencyMatrix();
    this.generateUIJson();
  }

  static getData() {
    return this.automationData;
  }

  static resetAllData() {
    AutomationManager.steps = [];
    AutomationManager.adjacencyMatrix = {};
    this.uiJson = {
      wingSpan: {},
      outerRadiusFromLeft: 0
    };
  }
  ////////////////////////////////////////////////////////////////////////////////////

  static getRecordId() {
    if (!this.isDataAvailable()) {
      return null;
    }

    return this.automationData.data?._id;
  }
  ////////////////////////////////////////////////////////////////////////////////////

  static getAutomationName() {
    if (!this.isDataAvailable()) {
      return 'New Automation';
    }

    return this.automationData.data?.cells[
      this.getColumnId(COLUMN_CODE.AUTOMATION.NAME)
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////

  static getStatus() {
    if (!this.isDataAvailable()) {
      return false;
    }

    let status =
      this.automationData.data.cells[
        this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)
      ];

    status = JSON.stringify(status);

    return status === '[1]';
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getJSONData() {
    if (!this.isDataAvailable()) {
      return [];
    }

    let jsonData =
      this.automationData.data.cells[
        this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)
      ];

    return Utility.decodeJSON(jsonData);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getRequestToSave(_data: any) {
    let data: any = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.NAME)] = _data.name;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = _data.json;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      STATUS_OPTIONS.ACTIVE;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
      this.getConnectionId();
    return data;
  }

  static getRequestToUpdate(_data: any) {
    let data: any = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = _data.json;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      STATUS_OPTIONS.ACTIVE;
    return data;
  }

  static getRequestToUpdateStatus(isActive: any) {
    let data: any = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      isActive === true ? STATUS_OPTIONS.ACTIVE : STATUS_OPTIONS.IN_ACTIVE;

    return data;
  }

  static getColumnId(columnCode: string) {
    let id = TableManager.getColumnId(
      REMOTE_CONFIG_TABLES.AUTOMATION,
      columnCode
    );
    return id != undefined ? id : columnCode;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static fetchPageDataById(id: any, callback: any) {
    AutomationService.getData(
      id,
      (response: any) => {
        this.setData({ data: response });
        callback && callback();
      },
      (error: any) => {}
    );
  }

  static fetchPlaceholderData(callback: any) {
    AutomationService.getPlaceholderData(
      (response: any) => {
        callback && callback(response);
      },
      (error: any) => {
        callback && callback(error);
      }
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static setConnectionId(id: any) {
    this.connectionId = id;
  }
  static getConnectionId() {
    return this.connectionId;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getSplitStepDimensions(splitActionId: any, splitLevel: any) {
    const gutter = Math.floor(SPLIT_GUTTER / splitLevel);

    const splitWing = this.uiJson?.wingSpan[splitActionId] || [1, 1];
    const leftWingPadding = (splitWing[0] - 1) * STEP_CARD_WIDTH + gutter;
    const rightWingPadding = (splitWing[1] - 1) * STEP_CARD_WIDTH + gutter;

    return {
      stepWidth: STEP_CARD_WIDTH + gutter,
      leftWingPadding: leftWingPadding,
      rightWingPadding: rightWingPadding,
      leftWingHangerWidth: (splitWing[0] - 1 || 0.5) * STEP_CARD_WIDTH + gutter,
      rightWingHangerWidth:
        (splitWing[1] - 1 || 0.5) * STEP_CARD_WIDTH + gutter,
      justifyWings:
        leftWingPadding === rightWingPadding
          ? 'center'
          : leftWingPadding - rightWingPadding > 0
          ? 'end'
          : 'start'
    };
  }
}
