let ar = {
    translations: {
        "component": "مكون",
        "add": "يضيف",
        "document_type": "نوع الوثيقة",
        "select_theme_color": "حدد لون المظهر",
        "company_logo": "شعار الشركة",
        "choose_file": "اختر ملف",
        "your_company_name": "اسم شركتك",
        "company_address": "عنوان الشركة",
        "currency": "عملة",
        "no": "رقم",
        "terms_&_condition": "البنود و الظروف",
        "notes": "تلاحظ",
        "date_format": "صيغة التاريخ",
        "line_items": "البنود",
        "item_name": "اسم العنصر",
        "bill_to": "فاتورة الى",
        "ship_to": "سافر على متن سفينة ل",
        "valid_till": "صالح حتى",
        "due_date": "تاريخ الاستحقاق",
        "date": " تاريخ",
        "item_description": "وصف السلعة",
        "discount": "تخفيض",
        "tax": "ضريبة",
        "delete": "حذف",
        "add_new_item": "أضف أداة جديدة",
        "signature": "التوقيع",
        "show_discount": "إظهار الخصم",
        "show_tax": "مشاهدة الضرائب",
        "company_name": "اسم الشركة",
        "company_billing_address": "Cعنوان فواتير الشركة",
        "billing_address_placeholder_text_value": "اسم الزبون\nعنوان إرسال الفواتير الخاص بالعميل",
        "shipping_address_placeholder_text_value": "اسم الزبون\nعنوان الشحن الخاص بالعميل",
        "terms_and_condition_placeholder_text": "يحين موعد السداد في غضون 30 يومًا",
        "notes_placeholder_text": "يرجى دفع الرصيد المستحق في الوقت المحدد",
        "hide": "إخفاء",
        "show": "تبين",
        "estimate": "تقدير",
        "quotation": "اقتباس",
        "invoice": "فاتورة",
        "purchase_order": "أمر شراء",
        "bills": "الفواتير",
        "total": "مجموع",
        "number": "عدد",
        "edit": "يحرر",
        "products": "منتجات",
        "description": "وصف",
        "qty": "الكمية",
        "unit_price": "سعر الوحدة",
        "unit price": "سعر الوحدة",
        "price": "السعر",
        "amount": "مقدار",
        "subtotal": "المجموع الفرعي",
        "choose_a_template": "اختر قالبًا",
        "go_to_deskera": "الانتقال إلى Deskera",
        "print_this_document": "اطبع هذه الوثيقة",
        "send_email_to_clients": "إرسال بريد إلكتروني للعملاء",
        "document_generator": "مولد الوثيقة",
        "please_wait": "ارجوك انتظر...",
        "download_as_pdf": "تنزيل بتنسيق (.pdf)",
        "download": "تحميل",
        "print": "طباعة",
        "share": "حصة",
        "try_deskera": "جرب Deskera",
        "copy_link": "نسخ الوصلة",
        "share_on_the_web": "شارك على الويب",
        "anyone_with_the_below_link_can_view": "يمكن لأي شخص لديه الرابط أدناه المشاهدة",
        "send_your": "أرسل",
        "quickly": "بسرعة",
        "please_sign_up_with_deskera_to_send_your": "يرجى التسجيل في Deskera لإرسال ملف",
        "dont_worry_your": "لا تقلق ، الخاص بك",
        "will_still_be_here": "سيظل هنا.",
        "sign_up_for_free": "سجل مجانا",
        "already_a_user_Sign_in": "مستخدم بالفعل؟ تسجيل الدخول",
        "share_on_mail": "مشاركة على البريد الإلكتروني",
        "to": "إلى",
        "subject": "موضوع",
        "enter_recipient_email_address": "أدخل عنوان البريد الإلكتروني للمستلم",
        "enter_subject_here": "أدخل الموضوع هنا",
        "send": "يرسل",
        "cancel": "يلغي",
        "edit_table": "تحرير الجدول",
        "save": "حفظ",
        "buyer": "مشتر",
        "seller": "تاجر",
        "powered_by_deskera": "مدعوم من Deskera",
        "details": "تفاصيل",
        "other_comments": "تعليقات أخرى",
        "bill": "فاتورة",
        "order": "طلب",

        "email": "بريد إلكتروني",

        "items": "عناصر",
        "services": "خدمات",
        "hours": "ساعات",
        "rate": "معدل",
        "other": "آخر",
        "sales_tax": "ضريبة المبيعات",
        "tax_rate": "معدل الضريبة",
        "sales tax": "ضريبة المبيعات",
        "tax rate": "معدل الضريبة",

        "num": "رقم",

        "template_update_failure_message": "تعذر تحديث الإعدادات.حاول مرة اخرى!",
        "template_save_failure_message": "تعذر حفظ الإعدادات.حاول مرة اخرى!",
        "template_update_success_message": "تم حفظ النموذج بنجاح",
        "template_save_success_message": "تم حفظ النموذج بنجاح",
        "additional_information": "معلومة اضافية",
        "other_options": "خيارات أخرى",

        "image": "صورة",
        "text": "نص",
        "watermark": "علامة مائية",
        "banner": "لافتة",
        "custom_field": "حقل مخصص",
        "component_list": "قائمة المكونات",

        "email_address": "عنوان البريد الإلكتروني",
        "email_address_1": "عنوان البريد الإلكتروني",
        "is_not_valid": "غير صالح.",

        "deskera_suites": "أجنحة Deskera",
        "deskera_books": "كتب Deskera",
        "deskera_people": "الناس Deskera",
        "deskera_sales": "مبيعات Deskera",
        "try_now": "جرب الآن",

        "text_color": "لون الخط",
        "image_ratio": "نسبة الصورة(٪)",
        "text_size": "حجم الخط",
        "opacity": "العتامة",
        "font_style": "نوع الخط",
        "font_weight": "وزن الخط",
        "text_alignment": "محاذاة النص",
        "image_alignment": "محاذاة الصورة",
        "image_preview": "معاينة الصورة",
        "preview_banner": "معاينة لافتة",
        "banner_selection": "اختيار البانر",
        "duplicate": "ينسخ",
        "settings": "إعدادات",
        "expiry": "انقضاء",
        "batch_no": "رقم الحزمة.",

        "saving": "إنقاذ...",
        "save_template": "حفظ النموذج",

        "name": "اسم",

        "stock_items": "المخزن عناصر",

        "sending": "إرسال...",
        "set_as_default": "الوضع الإفتراضي",

        "no_email_subject": "لم يتم العثور على موضوع مخصص !! يرجى كتابة موضوعك المخصص والضغط على حفظ النموذج",

        "expense": "مصروف",
        "deposit": "إيداع",
        "credit_note": "اشعار دائن",
        "debit_note": "بيان بالخصم",
        "accounts": "حسابات",
        "customer_address_placeholder_text_value": "اسم الزبون\nعنوان العميل الخاص بك",
        "account_name_placeholder_text_value": "رمز الحساب\nإسم الحساب",
        "payment_to": "دفع ل",
        "from_account": "من حساب",
        "payment_from": "الدفع من",
        "to_account": "لحساب",
        "payment_date": "يوم الدفع او الاستحقاق",
        "document_date": "تاريخ الوثيقة",
        "account_name": "إسم الحساب",
        "account_description": "وصف الحساب",
        "tax_amount": "قيمة الضريبة",
        "payment_amount": "مبلغ الدفع",
        "line_amount": "مبلغ الخط",
        "total_tax_amount": "إجمالي مبلغ الضريبة",
        "contact": "اتصال",
        "driver_name": "اسم السائق",
        "transporter": "الناقل",
        "vehicle_no": "رقم المركبة",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "اسم جهة الاتصال\nعنوان جهة الاتصال الخاصة بك",

        "printing": "طباعة ..",
        "downloading": "جارى التحميل..",

        "incorrect_format": "تنسيق غير صحيح!",
        "few_formats_allowed": "يُسمح فقط بملفات jpg و jpeg و png",
        "image_missing": "الصورة مفقودة!",
        "data_missing": "البيانات مفقودة!",
        "select_image_to_proceed": "الرجاء تحديد الصورة للمتابعة",
        "no_data_to_export": "لا توجد بيانات للتصدير",

        "session_expired_title": "انتهت الجلسة!",
        "session_expired_message": "جلسة العمل الخاصة بك قد انتهت.الرجاد الدخول على الحساب من جديد.",
        "login": "تسجيل الدخول",
        "generate_link_to_share": "مشاركة المستند مع الأشخاص ",
        "burn_after_read": "حرق بعد القراءة",
        "generate_link": "توليد ارتباط للمشاركة",
        "no_never": "أبدا",
        "1_min": "1 دقيقة",
        "1_hr": "1 ساعة",
        "1_day": "يوم واحد",
        "1_year": "سنة واحدة",

        "document_expired_title": "انتهاء صلاحية وثيقة!",
        "document_expired_message": "انتهت صلاحية هذا المستند. الرجاء الاتصال بالمرسل",

        "loading": "تحميل...",
        "reference_date": "تاريخ المرجع",
        "cheque_number": "رقم الشيك",
        "deposit_to": "إيداع ل",

        "password": "محمية بكلمة مرو",
        "password_empty_title": "كلمة المرور فارغة",
        "password_empty_message": "لا يمكن أن تكون كلمة المرور فارغة. الرجاء إدخال كلمة المرور وحاول مرة أخرى",
        "document_not_found_title": "لم يتم العثور على المستند",
        "document_incorrect_password_title": "كلمة سر خاطئة",
        "document_incorrect_password_message": "الرجاء إدخال كلمة المرور الصحيحة والمحاولة مرة أخرى.",
        "proceed": "يتاب",
        "document_enter_password_title": "كلمة المرور مطلوبة",
        "document_enter_password_message": "الرجاء إدخال كلمة مرور لفتح هذا المستند والمتابعة",

        "preparing_document_email": "جارٍ تحضير المستند لإرساله بالبريد الإلكتروني ...",
        "preparing_document_print": "جارٍ تحضير المستند للطباعة ...",
        "loading_the_page": "تحميل الصفحة",
        "sender": "مرسل",
        "reply_to": "الرد على",
        "enter_sender_name": "أدخل اسم المرسل",
        "enter_reply_to_email": "أدخل الرد على البريد الإلكتروني",
        "send_a_copy_to_myself_at": "أرسل نسخة إلى نفسي على",

        "payment": "قم بالدفع",
        "paper_format": "تنسيق الورق",
        "please_provide_paper_format": "يرجى تقديم تنسيق ورقي",
        "paper_format_popup_note": "لا يزال بإمكانك تحديث تنسيق الورق من لوحة الإعداد",
        "item_number": "رقم المنتج",
        "item_code": "كود المنتج",
        "received_from": "الاستمارة المستلمة",
        "payment_details": "بيانات الدفع",
        "sn_number": "SN. عدد",
        "document_details": "تفاصيل المستند",
        "template_saved": "تم حفظ النموذج!",
        "you_design_template_has_been_saved": "تم حفظ النموذج ، يمكنك إغلاق هذه النافذة والعودة إلى تطبيق الكتب.",
        "you_design_template_has_been_saved_people": "تم حفظ النموذج ، يمكنك إغلاق هذه النافذة والعودة إلى تطبيق الأشخاص.",
        "go_to_books": "كتب غوتو",
        "item_tax": "ضريبة المنتج",
        "item_discount": "خصم المنتج",
        "uom": "UOM",
        "code": "الشفرة",
        "unitPrice": "سعر الوحدة",
        "machine_name": "اسم الجهاز",
        "technician_name": "اسم الفني",
        "start_date": "تاريخ البدء",
        "schedule_id": "هوية الجدول الزمني",
        "end_date": "تاريخ الانتهاء",
        "please_select_custom_field": "الرجاء تحديد حقل مخصص.",

        "preview_pdf": "معاينة ملف PDF",

        "template_name": "اسم القالب",
        "please_enter_template_name_to_save": "الرجاء إدخال اسم القالب للحفظ.",
        "custom_template": "نموذج مخصص",
        "add_new_template": "أضف قالبًا مخصصًا جديدًا",
        "vendor_name": "اسم البائع",

        "decimal_point": "العلامة العشرية",
        "footer": "تذييل",
        "save_as": "حفظ باسم",
        "save_as_new_template": "حفظ كنموذج جديد",
        "e_invoice_section": "قسم الفاتورة الإلكترونية",
        "amount_before_tax": "المبلغ قبل الضريبة",

        "order_no": "رقم الطلب",
        "PURCHASE_INVOICE": 'فاتورة',
        "PURCHASE_ORDER": 'طلب',
        "SALES_INVOICE": "فاتورة",
        "QUOTATION": "اقتباس",
        "fulfillment": "تحقيق، إنجاز",
        "ship_from": "الشحن من",
        "company_address_placeholder_text_value": "اسم الشركة\nعنوان شركتك",
        "goods_receipt": "استلام البضائعt",
        "required_qty": "الكمية المطلوبة",
        "received_qty": "الكمية المستلمة",
        "committed_qty": "الكمية الملتزمة",
        "warehouse_code": "كود المستودع",
        "warehouse_name": "اسم المستودع",
        "company_warehouse_name": "اسم الشركة/المستودع",
        "payment_footer": "قسط",
        "make_payment": "قم بالدفع",
        "receive_payment": "يستلم الراتب",
        "deposit_advpayment": "إيداع مسبق",
        "expense_prepayment": "الدفع المسبق",

        "delete_template": "حذف القالب",
        "delete_template_message": "هل أنت متأكد أنك تريد حذف القالب؟ بمجرد حذف القالب ، لا يمكن استرجاعه.",
        "delete_template_ok_label": "نعم فعلا. احذفه.",
        "delete_template_success_message": "تم حذف القالب بنجاح.",
        "delete_template_failure_message": "تعذر حذف الإعدادات. حاول مرة اخرى!",
        "before_tax": "قبل الضرائب",
        "outstanding": "أمتياز",

        "signature_section": "قسم التوقيع",
        "prepared_by": "أعدت بواسطة",
        "approved_by": "تمت الموافقة عليه من قبل",

        "no.": 'رقم.',
        "#": "#",
        "serial_batch": "المسلسل / دفعة",
        "amount_in_words": "المبلغ بالكلمات",
        "name_with_description": "اسم",

        "invoice_number": "رقم الفاتورة",
        "bill_number": "رقم الفاتوره",
        "total_invoice_amount": "المبلغ الإجمالي للفاتورة",
        "total_bill_amount": "إجمالي مبلغ الفاتورة",
        "payment_made": "تم الدفع",
        "current_due_amount": "المبلغ المستحق الحالي",
        "current_balance": "الرصيد الحالي",
        "payment_medium": "وسيلة الدفع",
        "cash": "نقدي",
        "prepayment": "الدفع المسبق",
        "payment_label": "قسط",
        "receipt_label": "إيصال",
        "total_row": "إجمالي الصف",

        "journal_entry": "إفتتاحية المجلة",
        "journal_entry_number": "JE لا.",
        "account_code": "رمز الحساب",
        "credit": "الإئتمان",
        "debit": "مدين",
        "GOOD_RECEIPT": "استلام البضائع",
        "MAKE_PAYMENT": "قسط",
        "RECEIVE_PAYMENT": "قسط",
        "CREDIT_NOTE": "اشعار دائن",
        "DEBIT_NOTE": "بيان بالخصم",
        "FULFILLMENT": "تحقيق، إنجاز",
        "SALES_RETURN": "عائد المبيعات",
        "PURCHASE_RETURN": "عودة شراء",
        "SALES_CONTRA": "كونترا المبيعات",
        "PURCHASE_CONTRA": "شراء كونترا",
        "BANK_TRANSFER": "التحويل المصرفي",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "تعديل",
        "memo": "مذكرة",

        "pick_list": "قائمة الانتقاء",
        "pack_list": "قائمة الحزمة",
        "ship_list": "قائمة السفن",
        "invoice_or_quotation": "الفاتورة / عرض الأسعار",
        "product_code": "كود المنتج",
        "product_name": "اسم المنتج",
        "barcode": "الرمز الشريطي",
        "quantity_to_pick": "كمية للاختيار",
        "quantity_required_for_order": "الكمية المطلوبة للطلب",
        "picked": "منتقى",
        "item_no": "رقم الصنف.",
        "unit": "وحدة",
        "packed_quantity": "الكمية المعبأة",
        "warehouse_address": "عنوان المستودع",
        "delivery": "توصيل",
        "carrier": "الناقل",
        "number_of_carton": "عدد الكرتون",
        "weight": "وزن",
        "tracking_number": "رقم التعقب",
        "package": "صفقة",
        "order_number": "رقم الأمر",

        "custom_field_is_missing_title": "الحقل المخصص مفقود",
        "custom_field_is_missing_message": "الحقل المخصص مفقود من هذا المستند. يرجى إضافة حقل مخصص من الكتاب أو المحاولة مع وثيقة أخرى.",

        "repeatable_header": "رأس الجدول القابل للتكرار",
        "margin": "هامش",
        "default": "تقصير",
        "none": "لا أحد",
        "minimum": "الحد الأدنى",
        "large": "كبير",

        "receive_by": "استلام حسب التاريخ",
        "ship_by": "تاريخ الشحن",
        "additional_date": "تاريخ إضافي",

        "header_footer_section": "مقطع رأس تذييل",
        "header": "رأس",
        "date_only": "التاريخ فقط",
        "title_only": "العنوان فقط",
        "page_count_only": "عدد الصفحات فقط",
        "date_title": "التاريخ والعنوان",
        "date_page_count": "التاريخ وعدد الصفحات",
        "title_page_count": "العنوان وعدد الصفحات",
        "date_title_space": "التاريخ والعنوان مع الفضاء",
        "date_page_count_space": "التاريخ وعدد الصفحات بمسافة",
        "title_page_count_space": "العنوان وعدد الصفحات بمسافة",
        "date_title_page_count": "التاريخ والعنوان وعدد الصفحات",
        "custom": "مخصص",
        "left": "الجهه اليسرى",
        "center": "مركز",
        "right": "الجانب الأيمن",
        "title": "عنوان",
        "page_count": "عدد الصفحات",
        "current_page_count": "عدد الصفحات الحالية",
        "total_page_count": "إجمالي عدد الصفحات",
        "custom_text": "نص مخصص",
        "page": "صفحة",

        "company_phone_number": "رقم هاتف الشركة",
        "company_gstin": "شركة GSTIN",
        "company_state_name_and_code": "اسم ورمز دولة الشركة",
        "payment_terms": "شروط الدفع",
        "customer_phone_number": "رقم هاتف العميل",
        "customer_gstin": "رقم GSTIN الخاص بالعميل",
        "customer_state_name_and_code": "اسم ورمز دولة العميل",
        "print_only_message": "هذا هو جهاز الكمبيوتر",
        "hsn_or_sac": "HSN / SAC",
        "taxable_value": "القيمة الخاضعة للضريبة",
        "integrated_tax": "ضريبة متكاملة",
        "central_tax": "الضريبة المركزية",
        "state_tax": "ضريبة الولاية",
        "cess": "CESS",
        "state_name": "اسم الولاية",
        "state_code": "الشفرة",
        "label_state_code": "رمز الدولة",

        "address_format": "تنسيق العنوان",
        "address_1": "العنوان 1",
        "address_2": "العنوان 2",
        "city": "مدينة",
        "state": "ولاية",
        "postal_code": "رمز بريدي",
        "country": "دولة",
        "is_apply_address_format_to_other_addresses": "قم بتطبيق تنسيق العنوان الحالي على جميع العناوين.",
        "product_grouping": "تجميع المنتج",
        "product_group":"مجموعة المنتجات",
        "cheque": "دفتر شيكات",
        "bank_transfer": "التحويل المصرفي",
        "card": "بطاقة",

        "payslip": "قسيمة الراتب",
        "earning_statement": "بيان الربح",
        "pay_period": "فترة الدفع",
        "pay_date": "تاريخ الدفع",
        "ssn": "SSN",
        "taxable_marital_status": "الحالة الاجتماعية الخاضعة للضريبة",
        "exemptions": "الإعفاءات",
        "date_of_joining": "تاريخ الالتحاق",
        "pf_a_c_NUMBER": "رقم PF A / C",
        "esi_number": "رقم ESI",
        "uan_number": "رقم UAN",
        "employee_id": "هوية الموظف",
        "designation": "تعيين",
        "employee_net_pay": "الموظف صافي الراتب",
        "employee_earnings": "أرباح الموظفين",
        "allowances": "البدلات",
        "deductions": "الخصومات",
        "employer_contribution": "مساهمة صاحب العمل",
        "employer_cpf": "صاحب العمل CPF",
        "net_salary_paid": "صافي الراتب المدفوع",
        "employee_taxes_withhold": "ضرائب الموظفين المقتطعة",
        "employer_taxes": "ضرائب صاحب العمل",
        "employee_deductions": "استقطاعات الموظف",
        "summary": "ملخص",
        "basic_salary": "راتب اساسي",
        "net_pay": "صافي الأجر",
        "additional_earnings": "أرباح إضافية",
        "gross_earnings": "الأرباح الإجمالية",
        "duration": "مدة",
        "current": "تيار",
        "year_to_date": "منذ بدلية العام ز حتى اليوم",
        "type": "نوع",
        "pre_tax_deductions_contributions": "الخصومات / المساهمات قبل الضرائب",
        "taxes": "الضرائب",
        "post_tax_deductions_contributions": "الخصومات / المساهمات بعد الضريبة",
        "net_pay_contributions": "صافي الأجر / المساهمات",
        "check_amount": "التحقق من المبلغ",
        "payslip_auto_message": "هذا نظام تم إنشاؤه لإيصال الدفع ولا يتطلب الختم والتوقيع",
        "employee_address": "عنوان الموظف",
        "field_configuration": "تكوين المجال",
        "table_configuration": "تكوين الجدول",
        "employee_name_address": "اسم الموظف وعنوانه",
        "employee_bank_acc_number": "رقم الحساب البنكي للموظف",
        "header_title": "عنوان الرأس",
        "product_custom_field": "حقول المنتج المخصصة",
        "quantity_and_uom": "الكمية و UOM",
        "includes_product_custom_field": "يتضمن حقول المنتج المخصصة",
        "combines_product_custom_field": "يجمع بين حقول المنتج المخصصة",
        "serial_number": "رقم سري",
        "batch_number": "رقم الدفعة",
        "manufacturing_date": "تاريخ التصنيع",
        "expiry_date": "تاريخ الانتهاء",
        "override_theme_color": "تجاوز لون النسق",
        "override_global_color": "تجاوز اللون العام",
        "contact_number": "رقم التليفون",
        "contact_code": "كود الاتصال",
        "blank": "فارغ",
        "pay_to": "دفع ل",
        "payroll_check": "شيك الرواتب",
        "pay_to_the": "دفع ل",
        "order_of": "ترتيب",
        "dollars": "دولارات",
        "payroll_period": "كشوف المرتبات لفترة",
        "repeatable_header_footer": "رأس تذييل قابل للتكرار",
        "repeatable_footer": "التذييل القابل للتكرار",
        "includes_hsn_or_sac_code": "يتضمن رمز HSN / SAC",
        "eway_bill_no": "إيواي بيل لا",
        "eway_bill_date": "تاريخ الفاتورة Eway",
        "place_of_supply": 'مكان التوريد',
        "destination_of_supply": 'وجهة التوريد',
        "source_of_supply": 'مصدر التوريد',
        "subTable_configuration": "تكوين الجدول الفرعي",
        "additional_table_configuration": "تكوين الجدول الإضافي",
        "select_new_type": "حدد نوع جديد",
        "qr_code": "رمز الاستجابة السريعة",
        "qr_string": "سلسلة QR",
        "generated_qr": "ولدت QR",
        "qr_code_with_text": "رمز الاستجابة السريعة مع النص",
        "image_with_text": "صورة مع نص",
        "qr_information": "معلومات QR",
        "additional_info": "معلومات اضافية",
        "global_discount": "الخصم العالمي",
        "pre_tax": "قبل الضرائب",
        "post_tax": "بعد الضريبة",
        "includes_uom_schema": "يتضمن مخطط UOM",
        "table": "الطاولة",
        "font": "الخط",
        "table_row_height": "ارتفاع صف الجدول",
        "padding_horizontal": "الحشو أفقي",
        "padding_vertical": "المساحة المتروكة عموديًا",
        "original": "أصلي",
        "original_for_recipient": "الأصل للمستلم",
        "duplicate_supply_of_goods": "تكرار (توريد السلع)",
        "duplicate_for_transporter": "مكرر للناقل",
        "duplicate_supply_of_service": "تكرار (توريد الخدمة)",
        "duplicate_for_supplier": "تكرار للمورد",
        "triplicate": "ثلاث نسخ",
        "triplicate_for_supplier": "ثلاث نسخ للمورد",
        "position": "موقع",
        "middle": "وسط",
        "sample_watermark": "عينة علامة مائية",
        "extra_copy": "نسخة اضافية",
        "quadruplicate": "أربع مرات",
        "MAX_IMG_UPLOAD_ALERT": "لا يمكن أن يكون حجم الصورة أكبر من 500 كيلو بايت",
        "system_default": "النظام الافتراضي",
        "qr_resize": "تغيير حجم QR",
        "top": "قمة",
        "bottom": "الأسفل",
        "click_here_to_pay": "انقر هنا للدفع",
        "generated_message": "تم إنشاء الرسالة",
        "sales_order": "طلب المبيعات",
        "help": "مساعدة",
        "includes_account_custom_field": "يتضمن الحقول المخصصة للحساب",
        "combines_account_custom_field": "يجمع بين الحقول المخصصة للحساة",
        "account_custom_field": "حقول الحساب المخصصة",
        "selection": "اختيار",
        "alias_name_address": "اسم الاسم المستعار والعنوان",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "أيام مدفوعة الأجر",
        "unpaid_days": "أيام غير مدفوعة الأجر",
        "payslip_overtime": "متأخر , بعد فوات الوقت",
        "payslip_LOP": "فقدان الأجور",
        "outstanding_balance": "اتزان رائع",
        "outstanding_balance_includes_current": "الرصيد المستحق (بما في ذلك الحالي)",
        "top_section_above_table": "القسم العلوي فوق الجدول",
        "is_group_additional_charges_tax": "سواء كانت ضريبة مجموعة إضافية",
        "supplier_invoice_no": "رقم فاتورة المورد",
        "default_template": "القالب الافتراضي",
        "contact_name": "اسم جهة الاتصال",
        "current_date": "التاريخ الحالي",
        "e_way_bill_summary": "إواي ملخص بيل",
        "e_way_bill_detail": "إيواي بيل التفاصيل",
        "table_column_alignment": "محاذاة عمود الجدول",
        "table_column_vertical_alignment": "محاذاة عمودية",
        "earnings_configuration": "تكوين الأرباح",
        "label_empty_field_message": "لا يمكن ترك تسمية الحقل فارغة ، يرجى إدخال قيمة فيها للمتابعة.",
        "product_table": "جدول المنتج",
        "rounding_off": "التقريب",
        "work_order": "أمر العمل",
        "order_prep_completed": "انتهاء تحضير الطلب",
        "barcode_settings": "إعدادات الباركود",
        "barcode_resize": "تغيير حجم الباركود",
        "comments": "التعليقات",
        "delivery_date": "تاريخ التسليم",
        "estimated_time": "الوقت المقدر",
        "actual_quantity": "الكمية الفعلية",
        "manufacture_quantity": "كمية التصنيع",
        "parent_work_order": "طلب عمل رئيسي",
        "stock_transfer": "نقل المخزون",
        "source": "المصدر",
        "destination": "الوجهة",
        "source_address": "عنوان المصدر",
        "destination_address": "عنوان الوجهة",
        "transfer_date": "تاريخ النقل",
        "verify_date": "تاريخ التحقق",
        "reason": "السبب",
        "warehouse": "المستودع",
        "stock_adjustment": "تعديل المخزون",
        "source_warehouse_name": "اسم المستودع المصدر",
        "source_warehouse_code": "رمز المستودع المصدر",
        "destination_warehouse_name": "اسم المستودع الوجهة",
        "destination_warehouse_code": "رمز المستودع الوجهة",
        "product": "المنتج",
        "show_product": "عرض المنتج",
        "hide_product_with_substitute": "إخفاء المنتج مع البديل",
        "substitute": "البديل",
        "show_all_substitute": "عرض جميع البدائل",
        "show_non_zero_substitute": "عرض البدائل المخصصة",
        "hide_substitute": "إخفاء البديل",
        "combines_line_item_global_discount": "دمج خصم عام لبند الخط",
        "unit_price_before_discount": "سعر الوحدة قبل الخصم",
        "unit_price_after_discount": "سعر الوحدة بعد الخصم",
        "discount_per_unit": "الخصم لكل وحدة",
        "show_all": "عرض الكل",
        "percentage": "نسبة مئوية",
        "cogs": "تكلفة البضاعة المباعة",
        "na": "غير متوفر",
        "con_number": "رقم طلب العميل",
        "document_label": "تسمية الباركود",
        "expected_delivery_date": "تاريخ التسليم المتوقع",
        "transaction_type": "نوع الصفقة",
        "transaction_no": "رقم الصفقة",
        "status": "الحالة",
        "qc_document": "مستند ضمان الجودة",
        "linked_po_so": "PO/SO مرتبط",
        "approver": "الموافق",
        "sample_size": "حجم العينة",
        "number_of_qty_passed": "عدد الكميات المرتبة",
        "number_of_qty_rejected": "عدد الكميات المرفوضة",
        "final_remark": "ملاحظة نهائية",
        "value": "القيمة",
        "exchange_conversion": "تحويل الصرف",
        "contact_address": "عنوان الإتصال",
        "cheque_fields": "حقول الشيك",
        "cheque_details": "تفاصيل الشيك",
        "sales_return": "مرتجعات المبيعات",
        "fulfilled_quantity": "الكمية المنفذة",
        "returned_quantity": "الكمية المرتجعة",
        "parent_linked_document_no": "رقم وثيقة الوالدين",
        "parent_linked_document_type": "نوع المستند الأصلي",
        "swap_address": "تبديل العنوان",
        "vendor_address": "عنوان البائع",
        "job_work_out_order": "طلب عمل خارجي",
        "machine": "جدولة الآلة",
        "kind_of_packing": "رقم ونوع الحزم",
        "due_on": "حتى تاريخ",
        "per": "لكل",
        "voucher_no": "رقم السند",
        "dated": "تاريخ",
        "supplier_ref_order_no": "رقم مرجعي/طلب المورد",
        "mode_term_of_payment": "وسيلة/شروط الدفع",
        "dispatch_doc_no": "رقم مستند الشحن",
        "dispatched_through": "تم الشحن عبر",
        "duration_of_process": "مدة العملية",
        "nature_of_processing": "طبيعة المعالجة",
        "company_pan": "رقم هوية الشركة",
        "authorised_signatory": "الموقع الشرعي",
        "additional_charges": "رسوم إضافية",
        "contact_no": "رقم الاتصال",
        "supplier_inv_no": "رقم فاتورة المورد",
        "basic_salary_wo_lop": "الراتب الأساسي دون فقدان الأجر",
        "cost": "تكلفة",
        "stock_request": "طلب المخزون",
        "target_warehouse_name": "اسم المستودع المستهدف",
        "target_warehouse_code": "كود المستودع المستهدف",
        "selected_row": "الصف المحدد",
        "selected_rack": "الرف المحدد",
        "selected_bin": "الصندوق المحدد",
        "requested_qty": "الكمية المطلوبة",
        "created_by": "تم الإنشاء بواسطة",
        "approved_date": "تاريخ الموافقة",
        "stock_issue": "إصدار المخزون",
        "gate_entry": "دخول بوابة الأمن",
        "issued_quantity": "الكمية المصدرة",
        "bom_product_table": "جدول المنتجات BOM",
        "list": "قائمة",
        "is_multi_product_table": "جدول منتجات متعددة",
        "is_show_optional_group": "مجموعة المنتجات الاختيارية",
        "tax_registration_number": "رقم التسجيل الضريبي",
        "tax_payer_id": "معرف المكلف بالضريبة",
        "wo_qty": "كمية أوامر العمل",
        "adhoc_items_message": "يمثل العناصر المخصصة *",
        "production_checklist": "قائمة إنتاجية",
        "product_description": "وصف المنتج",
        "total_quantity": "إجمالي الكمية",
        "job_status": "حالة العمل",
        "qc_status": "حالة ضمان الجودة",
        "show_job_status": "عرض حالة بطاقة العمل",
        "show_instruction": "عرض التعليمات",
        "optional": "اختياري",
        "document_title": "عنوان المستند",
        "barcode_for_row_rack_bin": "باركود للصف/الرف/الصندوق",
        "show_tagged_bin_warehouse": "المستودع ذو الصناديق المعلمة",
        "hsn_sac_tax_table": "جدول ضريبة HSN/SAC",
        "approval_section": "قسم الموافقة",
        "border": "الحدود",
        "spacing": "التباعد",
        "checked_by": "تم التحقق من قبل",
        "authorized_by": "مُصرّح به من قبل",
        "word_spacing": "تباعد الكلمات",
        "page_count_settings": "إعدادات تعداد الصفحات",
        "single_digit": "رقم واحد",
        "page_with_numeric": "صفحة مع رقم",
        "page_x_of_total_page": "صفحة x من إجمالي الصفحات",
        "page_x_of_total_page_slash": "صفحة x / إجمالي الصفحات",
        "service_requisition": "طلب الخدمة",
        "service_description": "وصف الخدمة",
        "sc_no":"رقم SC",
        "sc_date":"تاريخ SC",
        "sc_type":"نوع SC",
        "delivery_order_doc_and_date": "رقم وتاريخ أمر التسليم",
        "e_way_bill_doc_and_date": "رقم وتاريخ الفاتورة الإلكترونية",
        "party_order_doc_and_date": "رقم وتاريخ أمر الطرف",
        "mode_of_transport": "وسيلة النقل",
        "bank_details": "تفاصيل البنك",
        "bank_name": "اسم البنك",
        "company_seal": "ختم الشركة",
        "regd_office": "المكتب المسجل",
        "sub_total_in_words": "المجموع الفرعي بالأرقام",
        "rounding_off_in_words": "التقريب بالأرقام",
        "outstanding_balance_in_words": "الرصيد المستحق بالأرقام",
        "outstanding_balance_includes_current_in_words": "الرصيد المستحق (بما في ذلك الحالي) بالأرقام",
        "total_tax_amount_in_words": "إجمالي مبلغ الضريبة بالأرقام",
        "before_tax_in_words": "قبل الضريبة بالأرقام",
        "additional_charges_in_words": "الرسوم الإضافية بالكلمات",
        "global_discount_in_words": "الخصم العام بالأرقام",
        "cgst_in_words": "الضريبة المركزية على السلع والخدمات بالأرقام",
        "igst_in_words": "الضريبة المتكاملة على السلع والخدمات بالأرقام",
        "sgst_in_words": "الضريبة الحكومية على السلع والخدمات بالأرقام",
        "person_name": "اسم الشخص",
        "store_officer": "موظف المتجر",
        "department_holder": "رئيس القسم",
        "purchase_department": "قسم المشتريات",
        "issue_by": "أصدر بواسطة",
        "paper_orientation": "اتجاه الورق",
        "landscape": "أفقي",
        "portrait": "عمودي",
        "purchase_requisition": " طلب شراء",
        "phone": " هاتف",
        "requisition_qty": " الكمية المطلوبة",
        "stock_qty": " الكمية الأسهم",
        "prev_purchase_rate": " السابق. معدل الشراء",
        "scheduled_date": " التاريخ المقرر",
        "estimate_rate": " تقدير السعر",
        "estimate_value": " تقدير القيمة",
        "cost_center": " مركز تقييم الكلفة، مركز التسعير، مركز التثمين",
        "reserved": " محجوز",
        "division": " قسم",
        "department_name": " اسم القسم",
        "indent_no": " رقم المسافة البادئة",
        "indent_date": " تاريخ المسافة البادئة",
        "indent_type": " نوع المسافة البادئة",
        "project": " مشروع",
        "authorised_date": " التاريخ المعتمد",
        "requested_by": " بتوصية من",
        "for": " ل",
        "authorised_by": " مرخص بها من",
        "available_qty":"الكمية المتوفرة",
        "service_order": " طلب الخدمة",
        "gst_no": " رقم ضريبة السلع والخدمات",
        "gstin_no": " رقم ضريبة السلع والخدمات",
        "pan_no": " رقم المقلاة",
        "cin_no": " رقم CIN",
        "cont_person": " تابع. شخص",
        "order_date": " تاريخ الطلب",
        "shipment_address": " عنوان الشحنة",
        "prepared_date_time": "تاريخ ووقت التحضير",
        "only": "فقط",
        "completed": "مكتمل",
        "location_detail": "تفاصيل الموقع",
        "transporter_detail": "تفاصيل الناقل",
        "item_detail": "تفاصيل العنصر",
        "material_receipt_note": "مذكرة استلام المواد",
        "mrn_no": "رقم إم آر إن",
        "authorized": "مخول",
        "inspected_by": "تم الفحص بواسطة",
        "store_manager": "مدير المحل",
        "hod": "هود",
        "account_manager": "إدارة حساب المستخدم",
        "po_bill_no_date": "رقم أمر الشراء / التاريخ\nرقم الفاتورة / التاريخ",
        "short_excess_rejected_qty": "الكمية القصيرة / الزائدة / المرفوضة.",
        "accepted_qty": "الكمية المقبولة.",
        "basic_amt": "المبلغ الأساسي.",
        "charges": "رسوم",
        "last_po": "آخر طلب شراء",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "المبلغ الإجمالي",
        "total_passed_amount": "إجمالي المبلغ الذي تم تمريره",
        "inspection_required": "التفتيش مطلوب",
        "qc_inspection": "تفتيش الجودة",
        "work_order_no": "رقم أمر العمل",
        "work_order_date": "تاريخ أمر العمل",
        "work_order_due_date": "تاريخ استحقاق أمر العمل",
        "work_order_status": "حالة أمر العمل",
        "edit_table_columns": "تعديل أعمدة الجدول",
        "incoming": "الوارد",
        "final": "نهائي",
        "label": "ملصق",
        "both": "كلاهما",
        "inspection_report": "تقرير التفتيش",
        "inspection_date": "التاريخ",
        "parts": "الأجزاء",
        "reworks": "إعادة العمل",
        "final_inspection": "التفتيش النهائي",
        "product_listing": "قائمة المنتجات",
        "paper_margin": "هامش الورق",
        "is_using_custom_margin": "يستخدم هامش مخصص",
        "soa_customer": "بيان الحسابات",
        "soa_vendor": "بيان الحسابات - المورد",
        "company_reg_no": "رقم تسجيل الشركة",
        "gst_reg_no": "رقم تسجيل ضريبة السلع والخدمات",
        "from_date": "من تاريخ",
        "as_of_date": "حتى تاريخ",
        "to_date": "إلى تاريخ",
        "document_number": "رقم المستند",
        "particular": "تفصيل",
        "amount_in_base": "المبلغ بالعملة الأساسية",
        "balance_in_base": "الرصيد بالعملة الأساسية",
        "ifsc_code": "رمز إفسك",
        "purchase_request_for_quotes": "طلب عرض أسعار",
        "purchase_inward_quotation": "عرض أسعار المشتريات الواردة",
        "rfq_no": "رقم طلب عرض الأسعار",
        "show_zero": "إظهار الصفر",
        "show_total": "إظهار الإجمالي",
        "draft_watermark": "علامة مائية للمسودة",
        "draft": "مسودة",
        "rotation": "دوران",
        "invoice_summary": "ملخص الفاتورة",
        "bill_summary": "ملخص الفاتورة",
        "amount_due": "المبلغ المستحق",
        "total_pending_balance": "إجمالي الرصيد المعلق",
        "serial_batch_custom_fields": "حقول مخصصة للدفعة المتسلسلة",
        "supplier_part_name": "اسم قطعة المورد",
        "supplier_part_number": "رقم قطعة المورد",
        "supplier_part_description": "وصف قطعة المورد",
            
    }

};

export default ar
