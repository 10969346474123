import React, { useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { SalesOrder, SalesOrderInitialState } from '../../Models/SalesOrder';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  tenantSetupStauts,
  userInfo
} from '../../Redux/Slices/AuthSlice';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import Utility, { deepClone } from '../../Utility/Utility';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import { setItemsFromSalesOrderItems } from './SalesOrderHelper';
import DateFormatService from '../../Services/DateFormat';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import SalesOrderService from '../../Services/SalesOrder';
import { DraftTypes } from '../../Models/Drafts';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { CREDIT_LIMIT_TYPE } from '../../Constants/Enum';
import NumberFormatService from '../../Services/NumberFormat';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { useHistory } from 'react-router-dom';
import RateAnalysisService from '../../Services/RateAnalysis';
import DraftService from '../../Services/Drafts';
import AuthService from '../../Services/Auth';

export default function NewSalesOrder(props: any) {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    !Utility.isEmpty(props.draftData)
      ? props.draftData.isCenterAlign
      : props.isCenterAlign
  );

  const [salesOrder, setSalesOrder] = useState<SalesOrder>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromSalesOrderItems(props.populateFormData, props?.documentMode)
      : SalesOrderInitialState
  );

  const [updatedSalesOrder, setUpdatedSalesOrder] = useState<SalesOrder>();
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const userDetails: any = useAppSelector(userInfo);
  const tenantSetupStatus = useAppSelector(tenantSetupStauts);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  let history = useHistory();

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_SALES_ORDER,
        data: async (closeDoc: boolean) => {
          await createSalesOrder(closeDoc);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          updatedSalesOrder?.id ? updateSalesOrder() : onSaveAsDraft();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_SALES_ORDER,
        data: () => {
          console.log('UPDATE_SALES_ORDER clicked...');
          updateSalesOrder();
        }
      });
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setSalesOrder({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );

    if (!Utility.isEmpty(props.populateFormData)) {
      setSalesOrder({
        ...salesOrder,
        documentDate: props.populateFormData.salesOrderDate,
        validTillDate: props.populateFormData.salesOrderDueDate,
        fulfillmentDate: props.populateFormData.shipByDate
      });
    }
    loadApprovalConditionList();

    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );
  }, [props]);

  const loadApprovalConditionList = () => {
    // @ts-ignore
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = () => {
    let payload = { ...updatedSalesOrder };
    payload['approvalStatus'] = payload['approvalStatus']
      ? payload['approvalStatus']
      : APPROVAL_STATUS['NOT_REQUIRED'];
    payload['createdUserName'] = AuthService.getUserName();
    payload['dueAmount'] = 0;

    // here we explicitly remove few keys, which is not needed to be saved in component list feature
    payload = removeUnwantedPayloadKeysForDocument(payload);

    setButtonStatus(true);

    props.formData(payload);
    replaceURLToModuleURL();
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.salesOrderItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || [],
      draftCode: payload?.draftDocumentSequenceCode
        ? payload?.draftDocumentSequenceCode
        : '',
      contactName: payload?.contact?.name ? payload?.contact?.name : '',
      contactDocCode: payload?.contact?.documentSequenceCode
        ? payload?.contact?.documentSequenceCode
        : '',
      linkedDocument: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentType
        : '',
      linkedDocumentCode: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentSequenceCode
        : ''
    };
    SalesOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.SALES_ORDERS)
    ) {
      history.replace(PAGE_ROUTES.SALES_ORDERS);
    }
  };

  const closeDraftPopup = () => {
    dispatch(removeDraft(props.draftData.id));
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.salesOrderItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const label = 'Order date';
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          label,
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, label)) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.salesOrderItems.length; i++) {
      const item = docToValidate.salesOrderItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.salesOrderItems?.length
      ? docToValidate?.salesOrderItems?.reduce((total: number, item: any) => {
          return total + item?.totalWithDiscount;
        }, 0)
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        'Sales order amount can not be less than 0.'
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'salesOrderItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (docToValidate.reservedStock) {
      const isStockInvalid = docToValidate.salesOrderItems.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert('Error!', 'Insufficient Quantity to reserved');
        return false;
      }
    }
    // Validation for reserved stock ends

    //validation product having create Revenue plan on REVENUE_ARRANGEMENT

    const revRecPlansWithoutDates = docToValidate.salesOrderItems.filter(
      (itemData: any) => {
        return (
          itemData?.product?.revenueRecognitionInfo?.isDirectRevenuePositing ===
            false &&
          itemData?.product?.revenueRecognitionInfo?.allocationType !==
            'EXCLUDE' &&
          itemData?.product?.revenueRecognitionInfo?.createRevenuePlanOn ===
            'REVENUE_ARRANGEMENT' &&
          (itemData?.revRecEndDate === null ||
            itemData?.revRecStartDate === null)
        );
      }
    );
    if (!Utility.isEmpty(revRecPlansWithoutDates)) {
      showAlert('Error!', 'Rev Rec Start Date or End Date should not be empty');
      return false;
    }
    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const showCreditLimitAlert = (
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false
  ) => {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setButtonStatus(false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            makeCreateAPICall(payload, closeOnCreate);
          }
        }
      });
    }

    const baseCurrencyCode = tenantInfo.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current sales order)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  };

  const createSalesOrder = async (closeOnCreate: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedSalesOrder);
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;
    payload = {
      ...payload,
      dueAmount: payload?.totalAmount
    };

    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setButtonStatus(false);
        return;
      }
    }

    if (payload.reservedStock) {
      const isTracked = payload.salesOrderItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTracked) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(payload, 'salesOrderItems');
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.draftsTableId}/record/${props.draftData?.data?.id}`;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate
      );
    } else {
      makeCreateAPICall(payload, closeOnCreate);
    }
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    SalesOrderService.getSalesOrderByCode(docResp.salesOrderCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.SALES_ORDER,
          items: [...doc.salesOrderItems],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData?.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (!isUpdate) {
            getDocumentAlert(
              'Sales order created!',
              'Sales order has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.SALES_ORDERS
            );
            setButtonStatus(false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setButtonStatus(false);
      }
    );
  };

  const makeCreateAPICall = (payload: any, closeDoc: boolean) => {
    payload = removeUnwantedPayloadKeysForDocument(payload);
    SalesOrderService.createSalesOrder(payload).then(
      (response: any) => {
        if (props.draftData) {
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            RateAnalysisService.updateRateAnalysis({
              documentCode: response.salesOrderCode,
              documentType: 'SALES_ORDER',
              productPriceMethod:
                tenantInfo?.additionalSettings?.RATE_ANALYSIS
                  ?.PRODUCT_PRICE_METHOD,
              isDraft: props?.draftData.draftType == 'draft' ? true : false,
              draftId: props?.draftData.id,
              tableId: draftsTableId
            })
              .then(() => {})
              .catch((err: any) => {
                console.error('Error updating rate analysis: ', err);
              });
          }

          if (response.draft) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
            setButtonStatus(false);

            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Sales Orders',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.SALES_ORDERS);
                }
              }
            ];
            showAlert(
              'Sales order sent for approval!',
              'Document has been created successfully.',
              buttons
            );
          } else {
            updateForm(response, payload, false);
          }
        } else {
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        dispatch(fetchSalesOrders());
        if (payload.isPartialSalesOrder) {
          dispatch(fetchQuotes());
        }
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while creating sales order: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();

        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        dispatch(fetchSalesOrders());
        if (payload.isPartialSalesOrder) {
          dispatch(fetchQuotes());
        }
      }
    );
  };

  const updateSalesOrder = async (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedSalesOrder);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);

    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesOrderItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (payload.reservedStock) {
      const isTracked = payload.salesOrderItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTracked) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(payload, 'salesOrderItems');
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate
      );
    } else {
      makeUpdateAPICall(payload, closeOnUpdate);
    }
  };

  const makeUpdateAPICall = (payload: any, closeOnUpdate: boolean) => {
    payload.salesOrderItems.map((salesOrderItem: any) => {
      salesOrderItem.pendingQuantity =
        salesOrderItem.productQuantity -
        (+salesOrderItem?.fulfilledQuantity || 0);
      salesOrderItem.pendingQtyToConvert =
        salesOrderItem.productQuantity - (+salesOrderItem?.invoicedQty || 0);
    });

    payload = removeUnwantedPayloadKeysForDocument(payload);

    SalesOrderService.updateSalesOrder(
      payload,
      updatedSalesOrder?.id as number
    ).then(
      (response) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(response, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        dispatch(fetchSalesOrders());
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while updating sales order: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleDocumentUpdate = (doc: Document) => {
    registerInteractions();
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    let salesOrderWithUpdate = {
      ...updatedSalesOrder,
      ...doc
    };

    const extraKeys = {
      salesOrderItems: doc.items,
      salesOrderDueDate: doc.validTillDate,
      salesOrderDate: doc.documentDate,
      shipByDate: doc.fulfillmentDate,
      currency: doc.currency,
      currencyCode: doc.currencyCode,
      documentType: DOC_TYPE.SALES_ORDER,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedSalesOrder?.id
          : undefined,
      documentCode:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedSalesOrder?.salesOrderCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    salesOrderWithUpdate = {
      ...salesOrderWithUpdate,
      ...extraKeys
    };
    setUpdatedSalesOrder(salesOrderWithUpdate);
  };

  return (
    <NewDocument2
      permissionKeys={PERMISSIONS_BY_MODULE.SALES_ORDER}
      booksDocument={salesOrder}
      documentMode={props.documentMode}
      draftData={props.draftData}
      draftType={
        props?.draftData?.draftType === DraftTypes.LOCKED
          ? DraftTypes.READONLY
          : props?.draftData?.draftType
      }
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleDocumentUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateSalesOrder(false)}
      auditLogView={props.auditLogView}
    />
  );
}
