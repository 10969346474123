let pt = {
    translations: {
        "component": "Componente",
        "add": "Adicionar",
        "document_type": "Tipo de documento",
        "select_theme_color": "Selecione a cor do tema",
        "company_logo": "Logo da Empresa",
        "choose_file": "Escolha o arquivo",
        "your_company_name": "O nome da sua empresa",
        "company_address": "Endereço da companhia",
        "currency": "A Moeda",
        "no": "Não",
        "terms_&_condition": "Termos e Condições",
        "notes": "Notas",
        "date_format": "Formato de data",
        "line_items": "Itens de Linha",
        "item_name": "Nome do item",
        "bill_to": "Cobrança para",
        "ship_to": "Enviar para",
        "valid_till": "Válido até",
        "due_date": "Data de Vencimento",
        "date": " Encontro",
        "item_description": "Descrição do item",
        "discount": "Desconto",
        "tax": "Imposto",
        "delete": "Excluir",
        "add_new_item": "Adicionar novo item",
        "signature": "Assinatura",
        "show_discount": "Mostrar Desconto",
        "show_tax": "Mostrar imposto",
        "company_name": "Nome da empresa",
        "company_billing_address": "Endereço de cobrança da empresa",
        "billing_address_placeholder_text_value": "Nome do cliente \nEndereço de cobrança do seu cliente",
        "shipping_address_placeholder_text_value": "Nome do cliente \nEndereço de envio do seu cliente",
        "terms_and_condition_placeholder_text": "O pagamento é devido em 30 dias",
        "notes_placeholder_text": "Por favor, pague o saldo devido dentro do tempo",
        "hide": "ocultar",
        "show": "exposição",
        "estimate": "Estimativa",
        "quotation": "cotação",
        "invoice": "Fatura",
        "purchase_order": "Ordem de Compra",
        "bills": "Contas",
        "total": "Total",
        "number": "Número",
        "edit":"Edit",
        "products": "Produtos",
        "description": "Descrição",
        "qty": "Qty",
        "unit_price": "Preço unitário",
        "unit price": "Preço unitário",
        "price": "Preço",
        "amount": "Montante",
        "subtotal": "Subtotal",
        "choose_a_template": "Escolha um modelo",
        "go_to_deskera": "Goto Deskera",
        "print_this_document": "Imprima este documento",
        "send_email_to_clients": "Enviar e-mail para clientes",
        "document_generator": "Gerador de Documentos",
        "please_wait": "Por favor, espere...",
        "download_as_pdf": "Baixar como (.pdf)",
        "download": "Baixar",
        "print": "Impressão",
        "share": "Compartilhar",
        "try_deskera": "Experimente Deskera",
        "copy_link": "Link de cópia",
        "share_on_the_web": "Compartilhe na web",
        "anyone_with_the_below_link_can_view": "Qualquer pessoa com o link abaixo pode visualizar",
        "send_your": "Mande seu",
        "quickly": "Rapidamente",
        "please_sign_up_with_deskera_to_send_your": "Por favor, inscreva-se no Deskera para enviar seu",
        "dont_worry_your": "Não se preocupe, seu",
        "will_still_be_here": "ainda estará aqui.",
        "sign_up_for_free": "Cadastre-se gratuitamente",
        "already_a_user_Sign_in": "Já é um usuário? Assinar em",
        "share_on_mail": "Compartilhar no e-mail",
        "to": "Para",
        "subject": "Sujeito",
        "enter_recipient_email_address": "Insira o endereço de e-mail do destinatário",
        "enter_subject_here": "Insira o assunto aqui",
        "send": "Enviar",
        "cancel": "Cancelar",
        "edit_table": "Editar Tabela",
        "save": "Salve",
        "buyer": "Comprador",
        "seller": "Vendedor",
        "powered_by_deskera": "Desenvolvido por Deskera",
        "details": "Detalhes",
        "other_comments": "Outros comentários",
        "bill": "Conta",
        "order": "Ordem",

        "email": "Email",

        "items": "Itens",
        "services": "Serviços",
        "hours": "Horas",
        "rate": "Taxa",
        "other": "De outros",
        "sales_tax": "Imposto sobre vendas",
        "tax_rate": "Taxa de imposto",
        "sales tax": "Imposto sobre vendas",
        "tax rate": "Taxa de imposto",

        "num": " Não",
        "template_update_failure_message": "Não foi possível atualizar as configurações. Por favor, tente novamente!",
        "template_save_failure_message": "Não foi possível salvar as configurações. Por favor, tente novamente!",
        "template_update_success_message": "Modelo salvo com sucesso",
        "template_save_success_message": "Modelo salvo com sucesso",
        "additional_information": "informação adicional",
        "other_options": "Outras opções",

        "image": "Imagem",
        "text": "Texto",
        "watermark": "Marca d'água",
        "banner": "Bandeira",
        "custom_field": "Campos personalizado",
        "component_list": "Lista de Componentes",

        "email_address": "Endereço de e-mail",
        "email_address_1": "Endereço de email",
        "is_not_valid": "não é válido.",

        "deskera_suites": "Deskera Suites",
        "deskera_books": "Deskera Books",
        "deskera_people": "Deskera Pessoas",
        "deskera_sales": "Deskera Sales",
        "try_now": "Tente agora",


        "text_color": "Cor do texto",
        "image_ratio": "Proporção da imagem (%)",
        "text_size": "Tamanho do texto",
        "opacity": "Opacidade",
        "font_style": "Estilo de fonte",
        "font_weight": "Espessura da fonte",
        "text_alignment": "Alinhamento de Texto",
        "image_alignment": "Alinhamento de imagem",
        "image_preview": "Pré-visualização de imagem",
        "preview_banner": "Banner de visualização",
        "banner_selection": "Seleção de Banner",
        "duplicate": "Duplicado",
        "settings": "Configurações",
        "expiry": "Expiry",
        "batch_no": "Lote No.",

        "saving": "Salvando ...",
        "save_template": "Salvar modelo",

        "name": "Nome",

        "stock_items": "Itens de estoque",

        "sending": "Enviando ...",
        "set_as_default": "Conjunto padrão",

        "no_email_subject": "Nenhum assunto personalizado encontrado !! Digite seu assunto personalizado e pressione Salvar modelo",

        "expense": "Despesa",
        "depósito": "Depósito",
        "credit_note": "Nota de crédito",
        "debit_note": "Nota de débito",
        "accounts": "Contas",
        "customer_address_placeholder_text_value": "Nome do cliente\nEndereço do seu cliente",
        "account_name_placeholder_text_value": "Código da conta\nNome da conta",
        "payment_to": "Pagamento para",
        "from_account": "Da conta",
        "payment_from": "Pagamento de",
        "to_account": "Para a conta",
        "payment_date": "Data de pagamento",
        "document_date": "Data do documento",
        "account_name": "Account name",
        "account_description": "Descrição da conta",
        "tax_amount": "Valor do imposto",
        "payment_amount": "Payment Amount",
        "line_amount": "Quantidade de linha",
        "total_tax_amount": "Valor total do imposto",
        "contact": "Contato",
        "driver_name": "Nome do Motorista",
        "transporter": "Transportador",
        "vehicle_no": "Número do Veículo",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "Nome do contato\nEndereço do seu contato",

        "printing": "Imprimindo..",
        "downloading": "Baixando..",

        "incorrect_format": "Formato incorreto!",
        "few_formats_allowed": "Somente arquivos jpg, jpeg e png são permitidos",
        "image_missing": "Falta imagem!",
        "data_missing": "Dados ausentes!",
        "select_image_to_proceed": "Selecione a imagem para continuar",
        "no_data_to_export": "Sem dados para exportar",

        "session_expired_title": "Sessão expirada!",
        "session_expired_message": "Sua sessão expirou. Por favor faça login novamente.",
        "login": "Conecte-se",
        "generate_link_to_share": "Obter link",
        "burn_after_read": "Gravar depois de ler",
        "generate_link": "Gerar",
        "no_never": "Nunca",
        "1_min": "1 minuto",
        "1_hr": "1 hora",
        "1_day": "1 dia",
        "1_year": "1 ano",

        "document_expired_title": "ocumento expirado!",
        "document_expired_message": "Este documento expirou. Entre em contato com o remetente",

        "loading": "Carregando...",
        "reference_date": "Data de Referência",
        "cheque_number": "Número do cheque",
        "deposit_to": "Depositar para",

        "password": "Senha",
        "password_empty_title": "Senha vazia!",
        "password_empty_message": "A senha não pode estar vazia. Insira uma senha e tente novamente!",
        "document_not_found_title": "Documento não encontrado!",
        "document_incorrect_password_title": "Senha incorreta!",
        "document_incorrect_password_message": "Digite a senha correta e tente novamente.",
        "proceed": "Continuar",
        "document_enter_password_title": "Documento protegido por senha.",
        "document_enter_password_message": "Por favor insira uma senha.",

        "preparing_document_email": "Preparazione del documento per e-mail ...",
        "preparing_document_print": "Preparazione del documento da stampare ...",
        "loading_the_page": "Caricamento della pagina",
        "sender": "Remetente",
        "reply_to": "Responder para",
        "enter_sender_name": "Digite o nome do remetente",
        "enter_reply_to_email": "Digite Responder ao e-mail",
        "send_a_copy_to_myself_at": "Envie uma cópia para mim em",

        "payment": "O pagamento",
        "paper_format": "Formato do papel",
        "please_provide_paper_format": "Forneça o formato em papel",
        "paper_format_popup_note": "Você ainda pode atualizar o formato do papel no painel de configuração",
        "item_number": "Número de item",
        "item_code": "Código do item",
        "received_from": "Formulário Recebido",
        "payment_details": "Detalhes de pagamento",
        "sn_number": "Número de série",
        "document_details": "detalhes do documento",
        "template_saved": "Modelo salvo!",
        "you_design_template_has_been_saved": "O modelo foi salvo, você pode fechar esta janela e voltar ao aplicativo BOOKs.",
        "you_design_template_has_been_saved_people": "O modelo foi salvo, você pode fechar esta janela e voltar ao aplicativo PEOPLE.",
        "go_to_books": "Ir para Livros",
        "item_tax": "Imposto sobre o produto",
        "item_discount": "Desconto do produto",
        "uom": "UOM",
        "unitPrice": "Preço unitário",
        "quantity": "Quantidade",
        "code": "Código",
        "machine_name": "Nome da máquina",
        "technician_name": "Nome do técnico",
        "start_date": "Data de início",
        "schedule_id": "ID do cronograma",
        "end_date": "Data de término",
        "please_select_custom_field": "Selecione o campo personalizado.",

        "preview_pdf": "Preview Pdf",

        "template_name": "Nome do modelo",
        "please_enter_template_name_to_save": "Por favor, digite o nome do modelo para salvar.",
        "custom_template": "Modelo personalizado",
        "add_new_template": "Adicionar novo modelo personalizado",
        "vendor_name": "Nome do vendedor",

        "decimal_point": "Ponto decimal",
        "footer": "Rodapé",

        "save_as": "Salvar como",
        "save_as_new_template": "Salvar como novo modelo",
        "e_invoice_section": "seção de fatura eletrônica",
        "amount_before_tax": "Montante antes do imposto",

        "order_no": "Ordem No",
        "PURCHASE_INVOICE": 'Contas',
        "PURCHASE_ORDER": 'Ordem',
        "SALES_INVOICE": "Fatura",
        "QUOTATION": "cotação",
        "fulfillment": "Cumprimento",
        "ship_from": "Enviar de",
        "company_address_placeholder_text_value": "Nome da empresa\nEndereço da sua empresa",
        "goods_receipt": "Recebimento de mercadorias",
        "required_qty": "Quantidade necessária",
        "received_qty": "Quantidade Recebida",
        "commit_qty": "Quantidade comprometida",
        "Warehouse_code": "código de armazém",
        "warehouse_name": "Nome do armazém",
        "company_warehouse_name": "Nome da Empresa/Armazém",
        "payment_footer": "Pagamento",
        "make_payment": "Efetuar Pagamento",
        "receive_payment": "Recebe o pagamento",
        "deposit_advpayment": "Depósito avançado",
        "expense_prepayment": "Pré-pagamento",

        "delete_template": "Excluir modelo",
        "delete_template_message": "Tem certeza de que deseja excluir o modelo? Depois de excluído, o modelo não pode ser recuperado.",
        "delete_template_ok_label": "Sim. Exclua-o.",
        "delete_template_success_message": "Modelo excluído com sucesso.",
        "delete_template_failure_message": "Não foi possível excluir as configurações. Tente novamente!",
        "before_tax": "Antes dos impostos",
        "outstanding": "Valor pendente",

        "signature_section": "Seção de assinatura",
        "prepared_by": "Preparado por",
        "approved_by": "Aprovado por",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Serial / lote",
        "amount_in_words": "Quantia em palavras",
        "name_with_description": "Nome",

        "invoice_number": "Invoice Number",
        "bill_number": "Bill Number",
        "total_invoice_amount": "Valor total da fatura",
        "total_bill_amount": "Valor total da fatura",
        "payment_made": "Pagamento Efetuado",
        "current_due_amount": "Valor devido atual",
        "current_balance": "Saldo atual",
        "payment_medium": "Meio de pagamento",
        "cash": "dinheiro",
        "prepayment": "Pré-pagamento",
        "payment_label": "Pagamento",
        "receipt_label": "Recibo",
        "total_row": "Linha Total",

        "journal_entry": "Lançamento de diário",
        "journal_entry_number": "JE no.",
        "account_code": "Código de conta",
        "credit": "Crédito",
        "debit": "Débito",
        "GOOD_RECEIPT": "Recebimento de mercadorias",
        "MAKE_PAYMENT": "Pagamento",
        "RECEIVE_PAYMENT": "Pagamento",
        "CREDIT_NOTE": "Nota de crédito",
        "DEBIT_NOTE": "Nota de débito",
        "FULFILLMENT": "Cumprimento",
        "SALES_RETURN": "Retorno de Vendas",
        "PURCHASE_RETURN": "Devolução de Compra",
        "SALES_CONTRA": "Vendas Contra",
        "PURCHASE_CONTRA": "Compra Contra",
        "BANK_TRANSFER": "Transferência Bancária",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Ajuste",
        "memo": "Memorando",

        "pick_list": "lista de escolhas",
        "pack_list": "Lista de embalagem",
        "ship_list": "Lista de Navios",
        "invoice_or_quotation": "Fatura / Cotação",
        "product_code": "Código do produto",
        "product_name": "Nome do Produto",
        "barcode": "Código de barras",
        "quantity_to_pick": "Quantidade a escolher",
        "quantity_required_for_order": "Quantidade necessária para o pedido",
        "picked": "Escolhido",
        "item_no": "Item número.",
        "unit": "Unidade",
        "packed_quantity": "Quantidade Embalada",
        "warehouse_address": "Endereço do Armazém",
        "delivery": "Entrega",
        "carrier": "Operadora",
        "number_of_carton": "Número da embalagem",
        "weight": "Peso",
        "tracking_number": "Numero de rastreio",
        "package": "Pacote",
        "order_number": "Número do pedido",

        "custom_field_is_missing_title": "Falta campo personalizado",
        "custom_field_is_missing_message": "O campo personalizado está faltando neste documento. Adicione o campo personalizado do livro ou tente com outro documento.",

        "repeatable_header": "Cabeçalho de tabela repetível",
        "margin": "Margem",
        "default": "Predefinição",
        "none": "Nenhum",
        "minimum": "Mínimo",
        "large": "Grande",

        "receive_by": "Data de recebimento",
        "ship_by": "Data de envio",
        "additional_date": "Data Adicional",

        "header_footer_section": "Seção de rodapé do cabeçalho",
        "header": "Cabeçalho",
        "date_only": "Apenas data",
        "title_only": "Título Apenas",
        "page_count_only": "Contagem de páginas apenas",
        "date_title": "Data e Título",
        "date_page_count": "Data e contagem de páginas",
        "title_page_count": "Contagem de título e página",
        "date_title_space": "Data e título com espaço",
        "date_page_count_space": "Data e contagem de páginas com espaço",
        "title_page_count_space": "Contagem de título e página com espaço",
        "date_title_page_count": "Data, Título, Contagem de Páginas",
        "custom": "Personalizado",
        "left": "Lado esquerdo",
        "center": "Centro",
        "right": "Lado direito",
        "title": "Título",
        "page_count": "Contagem de páginas",
        "current_page_count": "Contagem de página atual",
        "total_page_count": "Contagem total de páginas",
        "custom_text": "Texto personalizado",
        "page": "Página",

        "company_phone_number": "Número de telefone da empresa",
        "company_gstin": "Empresa GSTIN",
        "company_state_name_and_code": "Nome e código do estado da empresa",
        "payment_terms": "Termos de pagamento",
        "customer_phone_number": "Número de telefone do cliente",
        "customer_gstin": "Cliente GSTIN",
        "customer_state_name_and_code": "Nome e código do estado do cliente",
        "print_only_message": "Este é um computador gerado",
        "hsn_or_sac": "HSN / SAC",
        "taxable_value": "Valor tributável",
        "integrated_tax": "Imposto Integrado",
        "central_tax": "Imposto Central",
        "state_tax": "Imposto estadual",
        "cess": "CESS",
        "state_name": "Nome do Estado",
        "state_code": "Código",
        "label_state_code": "Código Estadual",

        "address_format": "Formato de Endereço",
        "address_1": "Endereço 1",
        "address_2": "Endereço 2",
        "city": "Cidade",
        "state": "Estado",
        "postal_code": "Código postal",
        "country": "País",
        "is_apply_address_format_to_other_addresses": "Aplique o formato de endereço atual a todos os endereços.",
        "product_grouping": "Agrupamento de Produtos",
        "product_group":"Grupo de produtos",
        "cheque": "Check",
        "bank_transfer": "Transferência Bancária",
        "card": "Cartão",

        "payslip": "Payslip",
        "earning_statement": "Demonstração de ganhos",
        "pay_period": "Período de pagamento",
        "pay_date": "Dia de pagamento",
        "ssn": "SSN",
        "taxable_marital_status": "Estado civil tributável",
        "exemptions": "Isenções",
        "date_of_joining": "Data de adesão",
        "pf_a_c_NUMBER": "Número PF A / C",
        "esi_number": "Número ESI",
        "uan_number": "Número UAN",
        "employee_id": "ID do Empregado",
        "designation": "Designação",
        "employee_net_pay": "Pagamento Líquido do Funcionário",
        "employee_earnings": "Lucro do empregado",
        "allowances": "Mesadas",
        "deductions": "Deduções",
        "employer_contribution": "Contribuição do empregador",
        "employer_cpf": "CPF do empregador",
        "net_salary_paid": "Salário líquido pago",
        "employee_taxes_withhold": "Retenção de impostos de funcionários",
        "employer_taxes": "Impostos do empregador",
        "employee_deductions": "Deduções de funcionários",
        "summary": "Resumo",
        "basic_salary": "Salário básico",
        "net_pay": "Pagamento líquido",
        "additional_earnings": "Ganhos adicionais",
        "gross_earnings": "Lucro Bruto",
        "duration": "Duração",
        "current": "Atual",
        "year_to_date": "No acumulado do ano",
        "type": "Modelo",
        "pre_tax_deductions_contributions": "Deduções / contribuições antes dos impostos",
        "taxes": "Impostos",
        "post_tax_deductions_contributions": "Deduções / contribuições pós-impostos",
        "net_pay_contributions": "Salário / contribuições líquidas",
        "check_amount": "Verificar o valor",
        "payslip_auto_message": "Este é um holerite gerado pelo sistema que não requer selo e assinatura",
        "employee_address": "endereço do empregado",
        "field_configuration": "Configuração de Campo",
        "table_configuration": "Configuração da Tabela",
        "employee_name_address": "Nome e endereço do funcionário",
        "employee_bank_acc_number": "número da conta bancária do funcionário",
        "header_title": "Título do cabeçalho",
        "product_custom_field": "Campos personalizados do produto",
        "quantity_and_uom": "Quantidade e UOM",
        "includes_product_custom_field": "Inclui campos personalizados do produto",
        "combines_product_custom_field": "Combina campos personalizados do produto",
        "serial_number": "Número de série",
        "batch_number": "Número do lote",
        "manufacturing_date": "Data de fabricação",
        "expiry_date": "Data de validade",
        "override_theme_color": "Substituir cor do tema",
        "override_global_color": "Substituir cor global",
        "contact_number": "Número de telefone",
        "contact_code": "Código de contato",
        "blank": "Em branco",
        "pay_to": "Pagar para",
        "payroll_check": "Cheque de folha de pagamento",
        "pay_to_the": "PAGUE AO",
        "order_of": "ORDEM DE",
        "dollars": "DÓLARES",
        "payroll_period": "Folha de pagamento do período de",
        "repeatable_header_footer": "Rodapé de Cabeçalho Repetível",
        "repeatable_footer": "Rodapé repetível",
        "includes_hsn_or_sac_code": "Inclui código HSN/SAC",
        "eway_bill_no":  "Conta Eway Não",
        "eway_bill_date": "Data da fatura de viagem",
        "place_of_supply":'Local de Fornecimento',
        "destination_of_supply":"Destino do Fornecimento",
        "source_of_supply":"Fonte de abastecimento",
        "subTable_configuration": "Configuração da subtabela",
        "additional_table_configuration": "Configuração de Tabela Adicional",
        "select_new_type": "Selecionar novo tipo",
        "qr_code": "Código QR",
        "qr_string": "Sequência QR",
        "generated_qr": "QR gerado",
        "qr_code_with_text": "Código QR com texto",
        "image_with_text": "Imagem com texto",
        "qr_information": "Informações QR",
        "additional_info": "Informação adicional",
        "global_discount": "Desconto global",
        "pre_tax": "Pré-imposto",
        "post_tax": "Pós-imposto",
        "includes_uom_schema": "Inclui esquema de UOM",
        "table": "Mesa",
        "font": "Fonte",
        "table_row_height": "Altura da Linha da Mesa",
        "padding_horizontal": "Preenchimento Horizontal",
        "padding_vertical": "Preenchimento Vertical",
        "original": "Original",
        "original_for_recipient": "Original para destinatário",
        "duplicate_supply_of_goods": "Duplicado (Fornecimento de Mercadorias)",
        "duplicate_for_transporter": "Duplicar para transportador",
        "duplicate_supply_of_service": "Duplicado (Fornecimento de Serviço)",
        "duplicate_for_supplier": "Duplicar para fornecedor",
        "triplicate": "Triplicado",
        "triplicate_for_supplier": "Triplo para fornecedor",
        "position": "Posição",
        "middle": "Meio",
        "sample_watermark": "Exemplo de marca d'água",
        "extra_copy": "Cópia Extra",
        "quadruplicate": "Quádruplo",
        "MAX_IMG_UPLOAD_ALERT": "O tamanho da imagem não pode ser maior que 500 KB",
        "system_default": "Sistema padrão",
        "qr_resize": "Redimensionar QR",
        "top": "Parte superior",
        "bottom": "Abajo",
        "click_here_to_pay": "Clique aqui para pagar",
        "generated_message": "Mensagem gerada",
        "sales_order": "Pedido de venda",
        "help": "Ajuda",
        "includes_account_custom_field": "Inclui campos personalizados da conta",
        "combines_account_custom_field": "Combina campos personalizados da conta",
        "account_custom_field": "Campos personalizados da conta",
        "selection": "Seleção",
        "alias_name_address": "Nome e endereço do alias",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Dias pagos",
        "unpaid_days": "Dias não remunerados",
        "payslip_overtime": "Ao longo do tempo",
        "payslip_LOP": "Perda de pagamento",
        "outstanding_balance": "Saldo pendente",
        "outstanding_balance_includes_current": "Saldo pendente (incluindo atual)",
        "top_section_above_table": "Seção Superior Acima da Tabela",
        "is_group_additional_charges_tax": "Se o imposto de sobretaxa de grupo",
        "supplier_invoice_no": "Nº da fatura do fornecedor",
        "default_template": "Modelo predefinido",
        "contact_name": "Nome de contato",
        "current_date": "Data atual",
        "e_way_bill_summary": "Resumo da Conta Eway",
        "e_way_bill_detail": "Detalhe da Conta Eway",
        "table_column_alignment": "Alinhamento de Colunas da Tabela",
        "table_column_vertical_alignment": "Alinhamento Vertical",
        "earnings_configuration": "Configuração de ganhos",
        "label_empty_field_message": "O rótulo do campo não pode estar vazio, digite algum valor para continuar.",
        "product_table": "Tabela de produtos",
        "rounding_off": "Arredondamento",
        "work_order": "Ordem de Trabalho",
        "order_prep_completed": "Preparação de Pedido Concluída",
        "barcode_settings": "Configurações de Código de Barras",
        "barcode_resize": "Redimensionar Código de Barras",
        "comments": "Comentários",
        "delivery_date": "Data de entrega",
        "estimated_time": "Tempo estimado",
        "actual_quantity": "Quantidade real",
        "manufacture_quantity": "Quantidade de fabricação",
        "parent_work_order": "Ordem de Trabalho Principal",
        "stock_transfer": "Transferência de estoque",
        "source": "Origem",
        "destination": "Destino",
        "source_address": "Endereço de origem",
        "destination_address": "Endereço de destino",
        "transfer_date": "Data de transferência",
        "verify_date": "Data de verificação",
        "reason": "Motivo",
        "warehouse": "Armazém",
        "stock_adjustment": "Ajuste de estoque",
        "source_warehouse_name": "Nome do armazém de origem",
        "source_warehouse_code": "Código do armazém de origem",
        "destination_warehouse_name": "Nome do armazém de destino",
        "destination_warehouse_code": "Código do armazém de destino",
        "product": "Produto",
        "show_product": "Mostrar Produto",
        "hide_product_with_substitute": "Ocultar Produto com Substitutos",
        "substitute": "Substituto",
        "show_all_substitute": "Mostrar Todos os Substitutos",
        "show_non_zero_substitute": "Mostrar Substitutos Alocados",
        "hide_substitute": "Ocultar Substituto",
        "combines_line_item_global_discount": "Combinação de desconto global de itens da linha",
        "unit_price_before_discount": "Preço unitário antes do desconto",
        "unit_price_after_discount": "Preço unitário após desconto",
        "discount_per_unit": "Desconto Por Unidade",
        "show_all": "Mostrar Todos",
        "percentage": "Porcentagem",
        "cogs": "Custo de bens vendidos",
        "na": "ND",
        "con_number": "Número do Pedido do Cliente",
        "document_label": "Etiqueta de código de barras",
        "expected_delivery_date": "Data de Entrega Prevista",
        "transaction_type": "Tipo de Transação",
        "transaction_no": "Número da Transação",
        "status": "Status",
        "qc_document": "Documento de Controle de Qualidade",
        "linked_po_so": "PO/SO Vinculado",
        "approver": "Aprovador",
        "sample_size": "Tamanho da Amostra",
        "number_of_qty_passed": "Número de Qtd Aprovada",
        "number_of_qty_rejected": "Número de Qtd Rejeitada",
        "final_remark": "Observação Final",
        "value": "Valor",
        "exchange_conversion": "Conversão de câmbio",
        "contact_address": "endereço de contato",
        "cheque_fields": "Campos de Cheque",
        "cheque_details": "Detalhes do Cheque",
        "sales_return": "Devolução de Vendas",
        "fulfilled_quantity": "Quantidade Atendida",
        "returned_quantity": "Quantidade Devolvida",
        "parent_linked_document_no": "Documento pai não",
        "parent_linked_document_type": "Tipo de documento pai",
        "swap_address": "Trocar Endereço",
        "vendor_address": "Endereço do Fornecedor",
        "job_work_out_order": "Ordem de Trabalho Externa",
        "machine":"Escalonador de máquina",
        "kind_of_packing": "Nº e Tipo de Embalagens",
        "due_on": "Vencimento em",
        "per": "Por",
        "voucher_no": "Nº do Comprovante",
        "dated": "Datado",
        "supplier_ref_order_no": "Ref. do Fornecedor/Nº do Pedido",
        "mode_term_of_payment": "Modo/Termo de Pagamento",
        "dispatch_doc_no": "Nº do Doc. de Despacho",
        "dispatched_through": "Despachado por",
        "duration_of_process": "Duração do Processo",
        "nature_of_processing": "Natureza do Processamento",
        "company_pan": "CNPJ da Empresa",
        "authorised_signatory": "Signatário Autorizado",
        "additional_charges": "Despesas adicionais",
        "contact_no": "Contato não",
        "supplier_inv_no": "Número de fatura do fornecedor",
        "basic_salary_wo_lop": "Salário Básico sem Perda de Remuneração",
        "cost": "Custo",
        "stock_request": "Pedido de Estoque",
        "target_warehouse_name": "Nome do Armazém de Destino",
        "target_warehouse_code": "Código do Armazém de Destino",
        "selected_row": "Linha Selecionada",
        "selected_rack": "Estante Selecionada",
        "selected_bin": "Caixa Selecionada",
        "requested_qty": "Quantidade Solicitada",
        "created_by": "Criado por",
        "approved_date": "Data de Aprovação",
        "stock_issue": "Emissão de Estoque",
        "gate_entry": "Entrada de Segurança",
        "issued_quantity": "Quantidade Emitida",
        "bom_product_table": "Tabela de Produto BOM",
        "list": "Lista",
        "is_multi_product_table": "Tabela de produtos múltiplos",
        "is_show_optional_group": "Grupo de produtos opcional",
        "tax_registration_number": "Número de registro fiscal",
        "tax_payer_id": "Identificação do Contribuinte",
        "wo_qty": "Quantidade de Ordens de Trabalho",
        "adhoc_items_message": "* representa itens AdHoc",
        "production_checklist": "Lista de Verificação de Produção",
        "product_description": "Descrição do Produto",
        "total_quantity": "Quantidade Total",
        "job_status": "Status do Trabalho",
        "qc_status": "Status de Controle de Qualidade",
        "show_job_status": "Mostrar status do cartão de trabalho",
        "show_instruction": "Mostrar instrução",
        "optional": "Opcional",
        "document_title": "Título do Documento",
        "barcode_for_row_rack_bin": "Código de barras para Linha/Estante/Recipiente",
        "show_tagged_bin_warehouse": "Armazém de caixas marcadas",
        "hsn_sac_tax_table": "Tabela de impostos HSN/SAC",
        "approval_section": "Seção de Aprovação",
        "border": "Borda",
        "spacing": "Espaçamento",
        "checked_by": "Verificado por",
        "authorized_by": "Autorizado por",
        "word_spacing": "Espaçamento de palavras",
        "page_count_settings": "Configurações de contagem de páginas",
        "single_digit": "Dígito único",
        "page_with_numeric": "Página com número",
        "page_x_of_total_page": "Página x de y",
        "page_x_of_total_page_slash": "página x / y",
        "service_requisition": "Requisição de serviço",
        "service_description": "Descrição do Serviço",
        "sc_no":"SC Não",
        "sc_date":"Data SC",
        "sc_type":"Tipo SC",
        "delivery_order_doc_and_date": "Número e data da ordem de entrega",
        "e_way_bill_doc_and_date": "Número e data da fatura eletrônica",
        "party_order_doc_and_date": "Número e data do pedido da parte",
        "mode_of_transport": "Modo de transporte",
        "bank_details": "Detalhes bancários",
        "bank_name": "Nome do banco",
        "company_seal": "Selo da empresa",
        "regd_office": "Escritório registrado",
        "sub_total_in_words": "Subtotal em palavras",
        "rounding_off_in_words": "Arredondamento em palavras",
        "outstanding_balance_in_words": "Saldo pendente em palavras",
        "outstanding_balance_includes_current_in_words": "Saldo pendente (incluindo atual) em palavras",
        "total_tax_amount_in_words": "Valor total do imposto em palavras",
        "before_tax_in_words": "Antes dos impostos em palavras",
        "additional_charges_in_words": "Encargos adicionais em palavras",
        "global_discount_in_words": "Desconto global em palavras",
        "cgst_in_words": "CGST em palavras",
        "igst_in_words": "IGST em palavras",
        "sgst_in_words": "SGST em palavras",
        "person_name": "Nome da pessoa",
        "store_officer": "Responsável pela loja",
        "department_holder": "Chefe do departamento",
        "purchase_department": "Departamento de compras",
        "issue_by": "Emitido por",
        "paper_orientation": "Orientação do Papel",
        "landscape": "Paisagem",
        "portrait": "Retrato",
        "purchase_requisition": " Requisição de compra",
        "phone": " Telefone",
        "requisition_qty": " Quantidade de requisição",
        "stock_qty": " Quantidade de estoque",
        "prev_purchase_rate": " Anterior. Taxa de compra",
        "scheduled_date": " Data marcada",
        "estimate_rate": " Taxa estimada",
        "estimate_value": " Valor estimado",
        "cost_center": " Centro de custo",
        "reserved": " Reservado",
        "division": " Divisão",
        "department_name": " Nome do departamento",
        "indent_no": " Recuo Não",
        "indent_date": " Data do recuo",
        "indent_type": " Tipo de recuo",
        "project": " Projeto",
        "authorised_date": " Data Autorizada",
        "requested_by": " Requerido por",
        "for": " Para",
        "authorised_by": " Autorizado por",
        "available_qty": "Quantidade disponível",
        "service_order": " Ordem de serviço",
        "gst_no": " ICMS Não",
        "gstin_no": " GSTIN Não",
        "pan_no": " PAN Não",
        "cin_no": " CIN Não",
        "cont_person": " Cont. Pessoa",
        "order_date": " Data do pedido",
        "shipment_address": " Endereço de envio",
        "prepared_date_time": "Data e Hora Preparada",
        "only": "Apenas",
        "completed": "Concluído",
        "location_detail": "Detalhe do Local",
        "transporter_detail": "Detalhe do Transportador",
        "item_detail": "Detalhe do Item",
        "material_receipt_note": "Nota de recebimento de material",
        "mrn_no": "NRM Não",
        "authorized": "Autorizado",
        "inspected_by": "Inspecionado por",
        "store_manager": "Gerente de loja",
        "hod": "H.O.D",
        "account_manager": "Gerente de contas",
        "po_bill_no_date": "Nº da ordem de compra / Data\nNº da fatura / Data",
        "short_excess_rejected_qty": "Quantidade curta/excesso/rejeitada.",
        "accepted_qty": "Quantidade aceita.",
        "basic_amt": "Valor Básico.",
        "charges": "Cobranças",
        "last_po": "Última ordem de compra",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "Montante total",
        "total_passed_amount": "Valor Total Aprovado",
        "inspection_required": "Inspeção necessária",
        "qc_inspection": "Inspeção de QC",
        "work_order_no": "Nº da Ordem de Serviço",
        "work_order_date": "Data da Ordem de Serviço",
        "work_order_due_date": "Data de Vencimento da Ordem de Serviço",
        "work_order_status": "Status da Ordem de Serviço",
        "edit_table_columns": "Editar Colunas da Tabela",
        "incoming": "Entrando",
        "final": "Final",
        "label": "Etiqueta",
        "both": "Ambos",
        "inspection_report": "Relatório de Inspeção",
        "inspection_date": "Data",
        "parts": "Peças",
        "reworks": "Retrabalhos",
        "final_inspection": "Inspeção final",
        "product_listing": "Lista de Produtos",
        "paper_margin": "Margem do Papel",
        "is_using_custom_margin": "Está usando margem personalizada",
        "soa_customer": "Extrato de Contas",
        "soa_vendor": "Extrato de Contas - Fornecedor",
        "company_reg_no": "Nº de Reg. da Empresa",
        "gst_reg_no": "Nº de Reg. do IVA",
        "from_date": "Da Data",
        "as_of_date": "Até a Data",
        "to_date": "Até a Data",
        "document_number": "Número do Documento",
        "particular": "Particular",
        "amount_in_base": "Valor em Base",
        "balance_in_base": "Saldo em Base",
        "purchase_request_for_quotes": "Solicitação de Cotação",
        "purchase_inward_quotation": "Cotação de Compra Recebida",
        "rfq_no": "Nº da Solicitação de Cotação",
        "show_zero": "Mostrar Zero",
        "show_total": "Mostrar Total",
        "draft_watermark": "Marca d'água do Rascunho",
        "draft": "Rascunho",
        "rotation": "Rotação",
        "invoice_summary": "Resumo da Fatura",
        "bill_summary": "Resumo da Conta",
        "amount_due": "Valor Devido",
        "total_pending_balance": "Saldo Total Pendente",
        "serial_batch_custom_fields": "Campos personalizados de lote serial",
        "nome_da_parte_do_fornecedor": "Nome da peça do fornecedor",
        "supplier_part_number": "Número de peça do fornecedor",
        "supplier_part_description": "Descrição da peça do fornecedor",
    }
};

export default pt
