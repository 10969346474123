import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import ApiConstants from '../../Constants/ApiConstants';
import {
  DOC_TYPE,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ProductService from '../../Services/Product';
import WarehouseService from '../../Services/Warehouse';
import Utility, { deepClone } from '../../Utility/Utility';
import { DynamicPopupWrapper } from '../PopupWrapper';
import ReceiveJWOProduct from '../../Components/StockManagement/StockTransfer/ReceiveJWOProduct';
import { t } from 'i18next';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';

const COLUMNS = [
  {
    id: 'warehouse',
    key: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 200,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: true,
      searchableKey: 'name',
      style: {},
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: (searchValue: string) => {
          const query: string = `?limit=50&page=0&search=${searchValue}&allRRBDetails=true&query=active=true`;
          const finalEndPoint =
            ApiConstants.URL.BASE +
            ApiConstants.URL.ACCOUNTS.WAREHOUSES +
            query;
          return finalEndPoint;
        },
        dataParser: (response: any) => {
          let filtered = response?.content?.filter(
            (wh: any) => wh.warehouseType === 'NONE'
          );
          return filtered;
        },
        debounceTime: 300
      },
      data: [],
      // renderer: (index: any, obj: any) => {
      //   return <DKLabel text={obj?.name} />;
      // },
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'row',
    columnCode: 'row',
    key: 'row',
    name: 'Select Row',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Row',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'rack',
    columnCode: 'rack',
    key: 'rack',
    name: 'Select Rack',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Rack',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'bin',
    columnCode: 'bin',
    key: 'bin',
    name: 'Select Bin',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Bin',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'quantity',
    key: 'quantity',
    name: 'Allocated Qty',
    type: INPUT_TYPE.NUMBER,
    width: 210,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  }
];

const uomColumn = {
  id: 'uom',
  key: 'uom',
  name: 'UOM',
  type: INPUT_TYPE.TEXT,
  width: 110,
  systemField: true,
  editable: false,
  hidden: false,
  uiVisible: true,
  textAlign: 'left'
};

const localizedQtyColumn = {
  id: 'localizedQuantity',
  key: 'localizedQuantity',
  name: 'Base UOM Qty Allocated',
  type: INPUT_TYPE.NUMBER,
  width: 110,
  systemField: true,
  editable: true,
  hidden: false,
  uiVisible: true,
  textAlign: 'right',
  formatter: (obj: any) => {
    return Utility.roundingOff(
      obj?.rowData?.localizedQuantity ?? 0,
      QTY_ROUNDOFF_PRECISION
    );
  }
};

const NoneTrackedReceive = (props: any) => {
  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);
  const activeElementRef = useRef<Element | null>(null);

  //constants
  const productCode = props.details?.productCode ?? '';
  const isEditWithoutSaveMode = !Utility.isEmpty(
    props?.details?.warehouseInventoryData
  );
  //common states
  const [updating, setUpdating] = useState(false);
  const [saveApiLoading, setSaveApiLoading] = useState(false);
  const [lineItems, setLineItems] = useState<any>(
    isEditWithoutSaveMode ? [] : [{}]
  );
  const [columns, setColumns] = useState(getColumns());
  const [commonWarehouseByProduct, setCommonWarehouseByProduct] = useState<any>(
    []
  );
  const [productDetails, setProductDetails] = useState<any>();
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState<any>();

  const [rawMaterialToConsumedData, setRawMaterialToConsumedData] = useState(
    props.details?.rawMaterialToConsume || []
  );

  const [parentDocumentType, setParentDocumentType] = useState(
    props.parentDocumentDetails?.documentType
  );

  const [rawMaterialData, setRawMaterialData] = useState(null);

  const [isBOMRawProductSerialBatch, setIsBOMRawProductSerialBatch] = useState(
    Utility.isBatchOrSerialAdvanceTracking(
      props.details?.product?.bomProductsConfiguration
    )
  );

  //effects
  useEffect(() => {
    fetchWarehouseProductDetails();
    fetchProductDetails();
  }, []);

  useEffect(() => {
    updateConfig(null, null);
    const isEdit = Utility.isNotEmpty(props?.details?.warehouseInventoryData);
    if (Utility.isNotEmpty(commonWarehouseByProduct) && !isEdit) {
      checkForNewCase();
    }
  }, [commonWarehouseByProduct, defaultProductWarehouse, productDetails]);

  useEffect(() => {
    const isEdit = Utility.isNotEmpty(props?.details?.warehouseInventoryData);
    if (Utility.isNotEmpty(commonWarehouseByProduct)) {
      if (isEdit) {
        checkForEditCase();
      }
    }
  }, [commonWarehouseByProduct]);

  useEffect(() => {
    if (
      !Utility.isEmpty(commonWarehouseByProduct) &&
      !Utility.isEmpty(productDetails)
    ) {
      setDefaultWarehouseAction();
    }
  }, [commonWarehouseByProduct, productDetails]);

  function getColumns() {
    let cols = COLUMNS ?? [];
    if (
      props?.details?.isLocalizedUomQty &&
      props?.module === DOC_TYPE.GOODS_RECEIPT
    ) {
      cols = [...cols, localizedQtyColumn, uomColumn];
    }
    return cols;
  }

  const checkForEditCase = () => {
    let updatedLineItems: any[] = [];
    props?.details?.warehouseInventoryData?.forEach((item1: any) => {
      let whFound = commonWarehouseByProduct?.find((wh: any) => {
        return (
          wh.code === item1.warehouseCode || wh.code === item1.warehouse?.code
        );
      });
      if (whFound) {
        const lineItem = {
          ...item1,
          warehouse: whFound,
          invalidFields: []
        };
        updatedLineItems.push(lineItem);
        lineItem.invalidFields = getInvalidField(whFound, lineItem);
      }
    });
    setLineItems([...updatedLineItems]);
  };

  const checkForNewCase = () => {
    if (Utility.isNotEmpty(productDetails)) {
      let warehouse = null;
      if (Utility.isNotEmpty(defaultProductWarehouse)) {
        warehouse = defaultProductWarehouse;
      } else {
        if (Utility.isNotEmpty(props.targetWarehouse)) {
          warehouse = commonWarehouseByProduct.find(
            (element: any) => element?.code === props.targetWarehouse
          );
        }
      }
      if (Utility.isEmpty(warehouse)) {
        return;
      }
      let selectedRow =
        warehouse?.code === productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.rowCode,
              name: productDetails?.inventory?.rowName
            }
          : null;
      let selectedRack =
        warehouse?.code === productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.rackCode,
              name: productDetails?.inventory?.rackName
            }
          : null;
      let selectedBin =
        warehouse?.code === productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.binCode,
              name: productDetails?.inventory?.binName
            }
          : null;
      let uniqueRows = warehouse.warehouseRowInfos ?? [];
      let uniqueRacks = warehouse.warehouseRowInfos ?? [];
      let uniqueBins = warehouse.warehouseRowInfos ?? [];
      if (
        Utility.isRRBTaggingEnabled() &&
        Utility.isNotEmpty(props.taggedWarehouse)
      ) {
        selectedRow =
          warehouse?.code === props.taggedWarehouse?.code &&
          Utility.isNotEmpty(props.taggedWarehouse?.rowCode)
            ? {
                code: props.taggedWarehouse?.rowCode,
                name: props.taggedWarehouse?.rowName
              }
            : null;
        selectedRack =
          warehouse?.code === props.taggedWarehouse?.code &&
          Utility.isNotEmpty(props.taggedWarehouse?.rackCode)
            ? {
                code: props.taggedWarehouse?.rackCode,
                name: props.taggedWarehouse?.rackName
              }
            : null;
        selectedBin =
          warehouse?.code === props.taggedWarehouse?.code &&
          Utility.isNotEmpty(props.taggedWarehouse?.binCode)
            ? {
                code: props.taggedWarehouse?.binCode,
                name: props.taggedWarehouse?.binName
              }
            : null;
        uniqueRows =
          warehouse?.warehouseRowInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rowCode
          ) ?? [];
        uniqueRacks =
          warehouse?.warehouseRackInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rackCode
          ) ?? [];
        uniqueBins =
          warehouse?.warehouseBinInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.binCode
          ) ?? [];
      }
      const lineItem = {
        warehouse: warehouse,
        warehouseCode: warehouse.code,
        warehouseName: warehouse.name,
        rowData: uniqueRows ?? [],
        rackData: uniqueRacks ?? [],
        binData: uniqueBins ?? [],
        row: selectedRow ?? null,
        rack: selectedRack ?? null,
        bin: selectedBin ?? null,
        rowCode: selectedRow ? selectedRow.code : null,
        rowName: selectedRow ? selectedRow.name : null,
        rackCode: selectedRack ? selectedRack.code : null,
        rackName: selectedRack ? selectedRack.name : null,
        binCode: selectedBin ? selectedBin.code : null,
        binName: selectedBin ? selectedBin.name : null,
        quantity: props?.details?.quantityFulfilled,
        localizedQuantity: props?.details?.uomLocalizedquantityRequired,
        invalidFields: []
      };
      lineItem.invalidFields = getInvalidField(warehouse, lineItem);
      setLineItems([lineItem]);
    }
  };

  const getInvalidField = (wareHouse: any, lineItem: any) => {
    let invalidFields: any = [];
    if (
      !Utility.isEmpty(wareHouse?.warehouseRowInfos) &&
      Utility.isEmpty(lineItem?.['row'])
    ) {
      invalidFields.push('row');
    }
    if (
      !Utility.isEmpty(wareHouse?.warehouseRackInfos) &&
      Utility.isEmpty(lineItem?.['rack'])
    ) {
      invalidFields.push('rack');
    }
    if (
      !Utility.isEmpty(wareHouse?.warehouseBinInfos) &&
      Utility.isEmpty(lineItem?.['bin'])
    ) {
      invalidFields.push('bin');
    }
    return invalidFields;
  };

  const setDefaultWarehouseAction = () => {
    let defaultProductWarehouseData;
    if (!Utility.isEmpty(commonWarehouseByProduct)) {
      if (props.defaultProductWarehouse) {
        defaultProductWarehouseData = commonWarehouseByProduct?.find(
          (warehouse: any) => warehouse.code === props.defaultProductWarehouse
        );
      } else {
        defaultProductWarehouseData =
          commonWarehouseByProduct.find(
            (warehouse: any) =>
              warehouse.code === productDetails?.inventory?.warehouseCode
          ) || commonWarehouseByProduct[0];
        if (Utility.isEmpty(defaultProductWarehouseData)) {
          defaultProductWarehouseData =
            commonWarehouseByProduct.find(
              (warehouse: any) => warehouse.primary
            ) || commonWarehouseByProduct[0];
        }
      }
      if (
        Utility.isEmpty(defaultProductWarehouse) ||
        defaultProductWarehouse?.code !== defaultProductWarehouseData?.code
      ) {
        setDefaultProductWarehouse(defaultProductWarehouseData);
      }
    }
  };

  //ui methods
  const fetchWarehouseProductDetails = () => {
    setUpdating(true);
    const config: any = {
      ...WarehouseService.apiConfig,
      SearchTerm: '',
      Limit: 50,
      Page: 0,
      Query: 'active=true&allRRBDetails=true'
    };
    WarehouseService.getWarehousesWithRRBCombinations(config)
      .then((response: any) => {
        setUpdating(false);
        let warehouses = response?.content?.filter(
          (ele: any) => ele.warehouseType === 'NONE'
        );
        if (props?.targetWarehouse) {
          warehouses = warehouses.filter(
            (element: any) => element?.code === props.targetWarehouse
          );
        }
        if (
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(props.taggedWarehouse) &&
          !Utility.isEmpty(warehouses)
        ) {
          let uniqueRows =
            warehouses?.[0]?.warehouseRowInfos?.filter((ele: any) =>
              props.taggedWarehouse?.rowCode
                ? ele.code === props.taggedWarehouse?.rowCode
                : ele
            ) ?? [];
          let uniqueRacks =
            warehouses?.[0]?.warehouseRackInfos?.filter((ele: any) =>
              props.taggedWarehouse?.rackCode
                ? ele.code === props.taggedWarehouse?.rackCode
                : ele
            ) ?? [];
          let uniqueBins =
            warehouses?.[0]?.warehouseBinInfos?.filter((ele: any) =>
              props.taggedWarehouse?.binCode
                ? ele.code === props.taggedWarehouse?.binCode
                : ele
            ) ?? [];
          warehouses[0]['warehouseRowInfos'] = uniqueRows;
          warehouses[0]['warehouseRackInfos'] = uniqueRacks;
          warehouses[0]['warehouseBinInfos'] = uniqueBins;
        }
        setCommonWarehouseByProduct(warehouses ?? []);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const fetchProductDetails = () => {
    ProductService.getProductsByProductIds([props.details?.productCode])
      .then((res: any) => {
        setProductDetails(res?.[0]);
      })
      .catch((err: any) => {});
  };

  const validate = () => {
    let flag = true;

    const sumOfQuantities = Utility.roundingOff(
      lineItems?.reduce((acc: any, item: any) => {
        return Number(item?.quantity ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0,
      QTY_ROUNDOFF_PRECISION
    );

    const sumOfLocalizedQuantities = Utility.roundingOff(
      lineItems?.reduce((acc: any, item: any) => {
        return Number(item?.localizedQuantity ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0,
      QTY_ROUNDOFF_PRECISION
    );

    const objWithInvalidfield = lineItems?.find((item: any) => {
      return !Utility.isEmpty(item?.invalidFields);
    });

    const objWithInvalidfieldWarehouse = lineItems?.find((item: any) => {
      return Utility.isEmpty(item?.warehouse);
    });

    const isLocalizedQtyGreater =
      props?.module === DOC_TYPE.GOODS_RECEIPT &&
      sumOfLocalizedQuantities >
        Number(props?.details?.uomLocalizedquantityRequired);

    if (
      !Utility.isPurchaseToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) &&
      (sumOfQuantities > props?.details?.quantityFulfilled ||
        isLocalizedQtyGreater) &&
      parentDocumentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      let message = 'Quantity used cannot be more than required quantity.';
      let { isBillLinked } = Utility.getPurchaseLinkedDocument(
        props.parentDocumentType,
        props.parentDocumentDetails
      );
      if (isBillLinked) {
        message =
          'Quantity used cannot be more than required quantity as the Bill is already generated for this document.';
      }
      showAlert('Oops!', message);
      flag = false;
    } else if (
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !Utility.isPurchaseToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) &&
      (sumOfQuantities > props?.details?.quantityFulfilled ||
        isLocalizedQtyGreater)
    ) {
      let message = 'Quantity used cannot be more than required quantity.';
      let { isBillLinked } = Utility.getPurchaseLinkedDocument(
        props.parentDocumentType,
        props.parentDocumentDetails
      );
      if (isBillLinked) {
        message =
          'Quantity used cannot be more than required quantity as the Bill is already generated for this document.';
      }
      showAlert('Oops!', message);
      flag = false;
    } else if (
      Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !Utility.isPurchaseToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) &&
      sumOfQuantities >
        Number(
          Number(props?.details?.maxQtyToReceive) -
            Number(props?.details?.receivedQuantityInOrder)
        )
    ) {
      let message = 'Quantity used cannot be more than dispatched quantity.';
      let { isBillLinked } = Utility.getPurchaseLinkedDocument(
        props.parentDocumentType,
        props.parentDocumentDetails
      );
      if (isBillLinked) {
        message =
          'Quantity used cannot be more than required quantity as the Bill is already generated for this document.';
      }
      showAlert('Oops!', message);
      flag = false;
    }

    if (sumOfQuantities === 0) {
      flag = false;
    }

    if (
      !Utility.isEmpty(objWithInvalidfield) ||
      Utility.isEmpty(lineItems) ||
      !Utility.isEmpty(objWithInvalidfieldWarehouse)
    ) {
      flag = false;
    }

    if (
      props?.isRRBTaggingEnabled &&
      Utility.isNotEmpty(lineItems?.[0]?.bin) &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      if (
        lineItems?.[0]?.bin?.taggedQuantity >=
        tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
      ) {
        showAlert(
          'Error',
          `${lineItems?.[0]?.bin?.name} has threshold limit(${tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD}) is reached.`
        );
        flag = false;
      }
    }

    if (
      Utility.isEmpty(rawMaterialToConsumedData) &&
      isBOMRawProductSerialBatch &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      // serial
      flag = false;
      showAlert('Warning', t('JOB_WORK_OUT.RAW_MATERIAL_ALLOCATE_ERROR'));
    }

    return flag;
  };

  const onSave = () => {
    if (
      !(activeElementRef.current instanceof HTMLInputElement) &&
      !(activeElementRef.current instanceof HTMLTextAreaElement) &&
      validate()
    ) {
      props.onSave(lineItems, rawMaterialToConsumedData);
    }
  };

  const getRowRackBinEnable = () => {
    let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
    return ROW_RACK_BIN?.length > 0 ? true : false;
  };

  const uomFormatter = (obj: any) => {
    const value = props?.details?.documentUOMSchemaDefinition;
    if (!Utility.isEmpty(value) && obj.rowData.quantity !== 0) {
      return `${
        value.name
      } <br><div style="font-size: 10px; color:gray; margin-top: 3px;">${
        obj.rowData?.localizedQuantity !== undefined &&
        obj.rowData?.localizedQuantity !== null &&
        obj.rowData?.localizedQuantity !== 0
          ? `${Utility.roundOff(obj.rowData?.localizedQuantity)} ${
              DocumentConfigUtility.getBaseUomName(value).name
            }`
          : value.sourceConversionFactor
          ? `${Utility.roundOff(
              (value.sourceConversionFactor / value.sinkConversionFactor) *
                obj.rowData.quantity
            )} ${DocumentConfigUtility.getBaseUomName(value).name}`
          : ''
      }</div>`;
    } else {
      return value.name;
    }
  };

  const updateConfig = (
    selectedWarehouse: any = null,
    selectedRow: any = null,
    selectedRack: any = null,
    selectedBin: any = null
  ) => {
    let config = [...columns];
    let updatedConfig: any = [];

    let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;

    config?.forEach((col: any) => {
      if (
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        const isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
          return i.name === col.key.toUpperCase() && i.enable;
        });
        if (isRRBEnabled) {
          col = {
            ...col,
            name: isRRBEnabled?.label
          };
          updatedConfig.push(col);
        }
      } else {
        updatedConfig.push(col);
      }
    });

    let uniqueRows: any = [];
    let uniqueRacks: any = [];
    let uniqueBins: any = [];

    if (Utility.isEmpty(selectedWarehouse)) {
      uniqueRows = [];
      uniqueRacks = [];
      uniqueBins = [];
    } else {
      //rows
      uniqueRows = selectedWarehouse?.warehouseRowInfos ?? [];
      //racks
      let racks = !Utility.isEmpty(selectedWarehouse?.warehouseRackInfos)
        ? selectedWarehouse?.warehouseRackInfos?.filter((rackChip: any) => {
            if (selectedRow) {
              return rackChip.rowCode === selectedRow?.code;
            } else {
              return true;
            }
          })
        : [];
      uniqueRacks = racks ?? [];
      //bins
      let bins = !Utility.isEmpty(selectedWarehouse?.warehouseBinInfos)
        ? selectedWarehouse?.warehouseBinInfos?.filter((binChip: any) => {
            if (selectedRack) {
              return binChip.rackCode === selectedRack?.code;
            } else if (selectedRow) {
              return binChip.rowCode === selectedRow?.code;
            } else {
              return true;
            }
          })
        : [];
      uniqueBins = props?.isRRBTaggingEnabled
        ? bins?.filter(
            (ele: any) =>
              ele?.taggedQuantity <
              tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
          )
        : bins ?? [];
    }

    updatedConfig.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouse':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.warehouse?.name ?? '';
          };
          conf.dropdownConfig.allowSearch = props?.isSearchAllow ?? true;
          // ? true
          // : Utility.isEmpty(props?.isSearchAllow)
          // ? true
          // : false;
          conf.dropdownConfig.data = commonWarehouseByProduct ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = {
            getUrl: (searchValue: string) => {
              const query: string = `?limit=50&page=0&search=${searchValue}&query=active=true&allRRBDetails=true`;
              const finalEndPoint =
                ApiConstants.URL.BASE +
                ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                query;
              return finalEndPoint;
            },
            dataParser: (response: any) => {
              let filtered = response?.content?.filter(
                (wh: any) => wh.warehouseType === 'NONE'
              );
              return filtered;
            },
            debounceTime: 300
          };
          return;

        case 'row':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.row?.name ?? '';
          };
          conf.dropdownConfig.data = uniqueRows ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.code ? (
              <DKLabel text={`${obj?.name} (${obj?.code})`} />
            ) : null;
          };
          return;

        case 'rack':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.rack?.name ?? '';
          };
          conf.dropdownConfig.data = uniqueRacks ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.code ? (
              <DKLabel text={`${obj?.name} (${obj?.code})`} />
            ) : (
              ''
            );
          };
          return;

        case 'bin':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.bin?.name ?? '';
          };
          conf.dropdownConfig.data = uniqueBins ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.code ? (
              <DKLabel text={`${obj?.name} (${obj?.code})`} />
            ) : (
              ''
            );
          };
          return;

        case 'availableQty':
          conf.formatter = (obj: any) => {
            return Utility.roundingOff(
              obj?.rowData?.availableQty ?? 0,
              QTY_ROUNDOFF_PRECISION
            );
          };
          return;

        case 'quantity':
          conf.formatter = (obj: any) => {
            return Utility.roundingOff(
              obj?.rowData?.quantity ?? 0,
              QTY_ROUNDOFF_PRECISION
            );
          };
          return;

        case 'localizedQuantity':
          conf.formatter = (obj: any) => {
            return Utility.roundingOff(
              obj?.rowData?.localizedQuantity ?? 0,
              QTY_ROUNDOFF_PRECISION
            );
          };
          break;

        case 'uom':
          conf.formatter = (obj: any) => {
            return uomFormatter(obj);
          };
          break;

        default:
          break;
      }
    });

    setColumns([...updatedConfig]);
  };

  const onRowUpdate = async (data: any) => {
    if (
      data?.rowData['row']?.rowCode === null ||
      data?.rowData['rack']?.rackCode === null ||
      data?.rowData['bin']?.binCode === null
    ) {
      return;
    }

    let updatedLineItems = deepClone(lineItems);
    let invalidFields: any = [];

    if (data?.columnKey === 'warehouse') {
      updatedLineItems[data?.rowIndex]['warehouse'] =
        data?.rowData['warehouse'];
      updatedLineItems[data?.rowIndex]['warehouseName'] =
        data?.rowData['warehouse']?.name;
      updatedLineItems[data?.rowIndex]['warehouseCode'] =
        data?.rowData['warehouse']?.code;
      updatedLineItems[data?.rowIndex]['rowData'] =
        data?.rowData?.warehouse?.warehouseRowInfos ?? [];
      updatedLineItems[data?.rowIndex]['rackData'] =
        data?.rowData?.warehouse?.warehouseRackInfos ?? [];
      updatedLineItems[data?.rowIndex]['binData'] =
        data?.rowData?.warehouse?.warehouseBinInfos ?? [];
      updatedLineItems[data?.rowIndex]['quantity'] = 0;

      const selectedRow =
        data?.rowData['warehouse']?.code ===
        productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.rowCode,
              name: productDetails?.inventory?.rowName
            }
          : null;
      const selectedRack =
        data?.rowData['warehouse']?.code ===
        productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.rackCode,
              name: productDetails?.inventory?.rackName
            }
          : null;
      const selectedBin =
        data?.rowData['warehouse']?.code ===
        productDetails?.inventory?.warehouseCode
          ? {
              code: productDetails?.inventory?.binCode,
              name: productDetails?.inventory?.binName
            }
          : null;

      updatedLineItems[data?.rowIndex]['row'] = selectedRow ?? null;
      updatedLineItems[data?.rowIndex]['rack'] = selectedRack ?? null;
      updatedLineItems[data?.rowIndex]['bin'] = selectedBin ?? null;
    }

    if (data?.columnKey === 'row') {
      updatedLineItems[data?.rowIndex]['row'] = data?.rowData['row'];
      updatedLineItems[data?.rowIndex]['rack'] = null;
      updatedLineItems[data?.rowIndex]['bin'] = null;
      updatedLineItems[data?.rowIndex]['quantity'] = props?.isRRBTaggingEnabled
        ? updatedLineItems[data?.rowIndex]['quantity']
        : 0;
      if (data?.rowData['row']) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'row'
        );
      }
    }

    if (data?.columnKey === 'rack') {
      updatedLineItems[data?.rowIndex]['rack'] = data?.rowData['rack'];
      updatedLineItems[data?.rowIndex]['rackCode'] =
        data?.rowData['rack']?.rackCode;
      updatedLineItems[data?.rowIndex]['bin'] = null;
      updatedLineItems[data?.rowIndex]['quantity'] = props?.isRRBTaggingEnabled
        ? updatedLineItems[data?.rowIndex]['quantity']
        : 0;
      if (data?.rowData['rack']) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'rack'
        );
      }
    }

    if (data?.columnKey === 'bin') {
      updatedLineItems[data?.rowIndex]['bin'] = data?.rowData['bin'];
      updatedLineItems[data?.rowIndex]['binCode'] =
        data?.rowData['bin']?.binCode;
      updatedLineItems[data?.rowIndex]['quantity'] = props?.isRRBTaggingEnabled
        ? updatedLineItems[data?.rowIndex]['quantity']
        : 0;
      if (data?.rowData['bin']) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'bin'
        );
      }
    }

    if (data?.columnKey === 'quantity') {
      updatedLineItems[data?.rowIndex]['quantity'] = Utility.roundingOff(
        data?.rowData['quantity'],
        QTY_ROUNDOFF_PRECISION
      );

      if (
        Utility.isNotEmpty(props?.details?.documentUOMSchemaDefinition) &&
        props?.module === DOC_TYPE.GOODS_RECEIPT
      ) {
        updatedLineItems[data?.rowIndex]['localizedQuantity'] =
          Utility.getUomWarehouseQuantityWithoutRoundOff(
            updatedLineItems[data?.rowIndex]['quantity'],
            props?.details?.documentUOMSchemaDefinition
          );
      } else {
        updatedLineItems[data?.rowIndex]['localizedQuantity'] = 0;
      }
    }

    if (data?.columnKey === 'localizedQuantity') {
      updatedLineItems[data?.rowIndex]['localizedQuantity'] =
        Utility.roundingOff(
          data?.rowData['localizedQuantity'],
          QTY_ROUNDOFF_PRECISION
        );
    }

    //invlidfields for rrb
    if (
      !Utility.isEmpty(data?.rowData['warehouse']?.warehouseRowInfos) &&
      Utility.isEmpty(updatedLineItems[data?.rowIndex]['row'])
    ) {
      invalidFields.push('row');
    }
    if (
      !Utility.isEmpty(data?.rowData['warehouse']?.warehouseRackInfos) &&
      Utility.isEmpty(updatedLineItems[data?.rowIndex]['rack'])
    ) {
      invalidFields.push('rack');
    }
    if (
      !Utility.isEmpty(data?.rowData['warehouse']?.warehouseBinInfos) &&
      Utility.isEmpty(updatedLineItems[data?.rowIndex]['bin'])
    ) {
      invalidFields.push('bin');
    }
    if (
      !Number.isInteger(updatedLineItems[data?.rowIndex]['quantity']) &&
      isBOMRawProductSerialBatch &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      invalidFields.push('quantity');
    }

    updatedLineItems[data?.rowIndex]['invalidFields'] = invalidFields;

    updateConfig(
      data?.rowData['warehouse'],
      data?.rowData['row'],
      data?.rowData['rack'],
      data?.rowData['bin']
    );
    setLineItems([...updatedLineItems]);
  };

  const getAllocatedQty = () => {
    const sumofUsedQuantities =
      lineItems?.reduce((acc: any, item: any) => {
        return Number(item?.quantity ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0;
    const allocatedQty = Utility.roundingOff(
      sumofUsedQuantities ?? 0,
      QTY_ROUNDOFF_PRECISION
    );
    return allocatedQty;
  };

  const modifyRawMaterialData = () => {
    const objWithInvalidField = lineItems?.find((item: any) => {
      return !Utility.isEmpty(item?.invalidFields);
    });

    const objWithInvalidFieldWarehouse = lineItems?.find((item: any) => {
      return Utility.isEmpty(item?.warehouse);
    });
    if (
      Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      getAllocatedQty() >
        Number(
          Number(props?.details?.maxQtyToReceive) -
            Number(props?.details?.receivedQuantityInOrder)
        )
    ) {
      return;
    }
    if (
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      getAllocatedQty() > props?.details?.quantityFulfilled
    ) {
      return;
    }
    if (objWithInvalidField || objWithInvalidFieldWarehouse) {
      return;
    }
    let modifyData = { ...props.details };
    if (modifyData?.product?.bomProductsConfiguration) {
      modifyData.product.bomProductsConfiguration.forEach((bomProduct: any) => {
        bomProduct['requiredQuantity'] =
          bomProduct.quantity * getAllocatedQty();
      });
    }
    setRawMaterialData(modifyData);
  };
  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-tl rounded-tr">
        <div className="row w-auto pop-header-drag-handle">
          <DKLabel
            text={props?.details?.product?.name ?? ''}
            className="fw-b fs-l"
          />
          <DKLabel
            text={`(${props?.details?.product?.documentSequenceCode ?? ''})`}
            className="text-gray ml-s"
          />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={() => {
              !saveApiLoading && props.onCancel();
            }}
          />
          {parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
            isBOMRawProductSerialBatch && (
              <DKButton
                title={t('JOB_WORK_OUT.ALLOCATE')}
                className={`${
                  getAllocatedQty() !== 0
                    ? 'bg-button text-white'
                    : 'bg-gray1 cursor-not-allowed '
                } ml-r border-m`}
                onClick={() => {
                  if (getAllocatedQty() !== 0) {
                    modifyRawMaterialData();
                  }
                }}
              />
            )}
          <div
            className={`row border-radius-m ml-r ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className={''}
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getHeaderView = () => {
    const requiredQty = Utility.roundingOff(
      props?.details?.quantityFulfilled ?? 0,
      QTY_ROUNDOFF_PRECISION
    );

    const allocatedQty = getAllocatedQty();
    let isValidSumOfAllocatedQty = requiredQty !== allocatedQty;
    // will use later
    if (
      Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      allocatedQty <=
        Number(
          Number(props?.details?.maxQtyToReceive) -
            Number(props?.details?.receivedQuantityInOrder)
        )
    ) {
      isValidSumOfAllocatedQty = false;
    } else if (
      parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      requiredQty >= allocatedQty
    ) {
      isValidSumOfAllocatedQty = false;
    }

    const uom = props?.details?.documentUOMSchemaDefinition
      ? props?.details?.documentUOMSchemaDefinition?.name
      : Utility.getUOMForStockUOMId(props?.details?.product?.stockUom)?.name;
    return (
      <div className="row parent-width justify-content-end mt-m mb-m gap-2">
        <div className="column align-items-end gap-2">
          <DKLabel text="Required Qty:" className="fw-b" />
          <DKLabel text="Allocated Qty:" className="fw-b" />
        </div>
        <div className="column align-items-end gap-2">
          <div className="row align-items-end gap-1 ">
            <DKLabel text={`${requiredQty}`} className="" />
            <DKLabel
              style={{
                height: 15
              }}
              text={` ${uom}`}
              className="fs-s fw-m text-gray "
            />
          </div>
          <div className="row align-items-end gap-1 ">
            <DKLabel
              text={`${allocatedQty}`}
              className={`${isValidSumOfAllocatedQty ? 'text-red' : ''}`}
            />
            <DKLabel
              style={{
                height: 15
              }}
              text={` ${uom}`}
              className="fs-s fw-m text-gray "
            />
          </div>
        </div>
      </div>
    );
  };

  const getRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    btns.push({
      icon: DKIcons.ic_delete,
      className: ``,
      onClick: (data: any) => {
        const updatedLineItems = [...lineItems];
        updatedLineItems.splice(data?.rowIndex, 1);
        setLineItems([...updatedLineItems]);
      }
    });

    return btns;
  };

  const getGridView = () => {
    return (
      <div className="column parent-width none-tracked-receive hide-scroll-bar">
        <DKDataGrid
          title=""
          needShadow={true}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          width={'100%'}
          rows={[...deepClone(lineItems)]?.map((lineItem: any) => {
            return {
              ...lineItem,
              rowButtons: getRowButtons(lineItem),
              rowContextMenu: null
            };
          })}
          columns={[
            ...columns,
            {
              id: 'action',
              key: 'action',
              name: 'Actions',
              type: INPUT_TYPE.BUTTON,
              width: 100,
              options: []
            }
          ]}
          onRowUpdate={(data: any) => {
            onRowUpdate(data);
          }}
          onRowClick={(data: any) => {
            updateConfig(
              data?.rowData?.warehouse,
              data?.rowData?.row,
              data?.rowData?.rack,
              data?.rowData?.bin
            );
          }}
        />
      </div>
    );
  };

  const getAddButton = () => {
    return (
      <DKButton
        title={`+ Add Row`}
        onClick={() => {
          if (props.disableAddRow && lineItems.length === 1) {
            return;
          }
          let updatedLineItems = [...lineItems];
          updatedLineItems.push({
            warehouse: '',
            row: null,
            rack: null,
            bin: null,
            availableQty: 0,
            quantity: 0,
            localizedQuantity: 0
          });
          setLineItems([...updatedLineItems]);
        }}
        className={`fw-m position-absolute1 ${
          props.disableAddRow && lineItems.length === 1
            ? 'text-gray'
            : 'text-blue'
        } `}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          // marginTop: -10,
          bottom: 0
        }}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: '90vw',
            width: getRowRackBinEnable() ? 1000 : 550,
            maxHeight: '90vh',
            minHeight: 300,
            padding: 0,
            overflow: 'visible'
          }}
          onClick={() => {
            setTimeout(() => {
              activeElementRef.current = document.activeElement;
            }, 10);
          }}
        >
          {getHeader()}
          <div className="column flex-1 parent-width p-h-m">
            {getHeaderView()}
            <div className="column p-h-xl parent-width flex-1 position-relative">
              {getGridView()}
              {!props?.isRRBTaggingEnabled && getAddButton()}
            </div>
          </div>
        </div>
        {rawMaterialData && (
          <ReceiveJWOProduct
            records={[]}
            selectedRecord={null}
            detail={rawMaterialData}
            document={DOC_TYPE.JOB_WORK_OUT_ORDER}
            passingInteraction={() => {}}
            onSave={(data: any) => {
              const rawMaterialData: any = [];
              data.forEach((d: any) => {
                rawMaterialData.push(...d.serialData);
              });

              setRawMaterialToConsumedData(rawMaterialData);
              setRawMaterialData(null);
            }}
            onCancel={() => {
              setRawMaterialData(null);
            }}
            allowFullScreen
          />
        )}
      </div>
    </DynamicPopupWrapper>
  );
};

export default NoneTrackedReceive;
