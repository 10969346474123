import { showLoader, removeLoader, showAlert } from 'deskera-ui-library';
import { uniqBy } from 'lodash';
import { BomConfigurationModel } from '../../Components/Mrp/BOM_Assembly/BomDetailsTabProduct/BomConfigurationModel';
import { REQUIRED_ITEM_TABLE } from '../../Components/Mrp/Constants/TableConstant';
import { getAttachmentIdListFromAttachmentResponse } from '../../Components/Mrp/WorkOrder/InnerComponents/WOAttachments/WOAttachmentHelper';
import ApiConstants from '../../Constants/ApiConstants';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  QTY_ROUNDOFF_PRECISION,
  REGEX
} from '../../Constants/Constant';
import { Invoice } from '../../Models/Invoice';
import { selectWorkOrderCustomNumberFormats } from '../../Redux/Slices/CustomNumberFormat';
import { Store } from '../../Redux/Store';
import Utility, { reduceArrayByKey } from '../../Utility/Utility';
import DateFormatService from '../DateFormat';
import http from '../Interceptor';
import ProductService from '../Product';
import SalesOrderService, {
  ISalesOrder,
  SalesOrderItemsEntity
} from '../SalesOrder';
import { PROCESS_TYPES } from '../../Components/Mrp/Constants/MRPEnums';
import saveAs from 'file-saver';
import {
  broadcastOnWorkOrderSaved,
  isAutoCloseWOEnabled
} from '../../Components/Mrp/WorkOrder/WorkOrderHelper';

export interface IWorkOrder {
  readonly id?: number;
  bomMetaCode?: string;
  parentWorkOrderCode?: string;
  sequenceFormat?: string;
  documentSequenceCode?: string;
  productDocSeqCode?: string;
  actualEndDate?: any;
  actualStartDate?: any;
  actualYield?: number;
  deliveryDate?: any;
  description?: string;
  inProgressWarehouseCode?: string;
  manufactureQuantity?: number;
  actualQuantity?: number;
  oldPlannedStartDate?: any;
  operationCostDetails?: IActualOperatingCost;
  actualOperatingCost?: IActualOperatingCost;
  plannedStartDate?: any;
  plannedEndDate?: any;
  plannedYield?: number;
  productCode?: string;
  productName?: string;
  resetAllocation?: boolean;
  sourceWarehouseCode?: string;
  status?: string;
  targetWarehouseCode?: string;
  workOrderItems?: IWorkOrderItems[];
  workOrderSourceDetails?: [
    {
      workOrderSource: string;
      linkedDocumentCode: string;
      salesOrderCustomerOrderNumber?: string;
    }
  ];
  workOrderOperations?: IWorkOrderOperation[];
  allJobCardsCompleted?: boolean;
  workOrderCode?: string;
  targetWarehouse?: any;
  totalCompletedJobCards: number;
  totalJobCards: number;
  advancedTrackingData?: any;
  warehouseInventoryData?: any;
  product?: any;
  workOrderChildDetails?: any[];
  plannedScrapQty?: number;
  plannedByProductQty?: number;
  workOrderItemsClonedCopy?: any;
  workOrderItemsCopy?: any;
  includeByProductCost?: boolean;
  reservedStock?: boolean;
  additionalCharges?: any;
  selectedBom: BomConfigurationModel;
  linkedDocuments?: any[];
  allProductsWarehouseData?: any[]; // used when warehouse tagging is enabled
  jobWorkOutOrderCost?: any;
  images?: string[];
  customField?: any[];
  attachments?: any[];
  taggedBinWarehouseInfo?: any;
  inventory?: any;
  adhocBomMetaCode?: string;
  contactsArr?: any[];
  contactCode?: any;
  isArchived: boolean;
  isAutoClosed?: boolean;
}
export interface IWorkOrderItems {
  productCode?: string;
  bomMetaCode?: string;
  componentProductUnitQty?: number;
  availableQty?: number;
  requiredQty?: number;
  actualRequiredQty?: number;
  sourceWarehouseCode?: string;
  itemName?: any;
  warehouseInventoryData?: any[];
  bomProductSubstitutesDetails: any[];
  [key: string]: any;
}
export interface IWorkOrderOperation {
  completedQuantity?: number;
  description?: string;
  operationCode?: string;
  operationTime?: number;
  plannedEndTime?: string;
  plannedStartTime?: string;
  status?: string;
  workstationCode?: string;
  operationName?: any;
  operationCost?: number;
  operatorCost?: number;
  workstationCost?: number;
  invalidFields?: string[];
  processType?: keyof PROCESS_TYPES;
  product?: string;
  qcNeeded?: boolean;
  isNewRow?: boolean;
  linkedInMachines?: any[];
}
export interface WorkOrderAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  sort?: string;
  sortDir?: string;
  Query?: string;
  QueryParam?: String;
  FetchAttachmentDetails?: boolean;
  isDeleted?: boolean;
  isArchive?: boolean;
}
export interface IActualOperatingCost {
  actualOperatingCost?: number;
  additionalOperatingCost?: number;
  plannedOperatingCost?: number;
  totalOperatingCost?: number;
}

export const defaultConfig: WorkOrderAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  sort: 'createdOn',
  sortDir: 'DESC',
  Query: '',
  QueryParam: '',
  FetchAttachmentDetails: false,
  isDeleted: false
};

const defaultConfigForConsumption: any = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  Sort: 'createdOn',
  SortDir: 'DESC',
  FetchAttachmentDetails: false
};

class WorkOrderService {
  static apiConfig: WorkOrderAPIConfig;
  static apiConfigForConsumption: any;
  static abortController: any = null;

  static woEndPoint(apiConfig?: WorkOrderAPIConfig) {
    if (Utility.isEmpty(apiConfig)) {
      apiConfig = this.apiConfig;
    }
    if (Utility.isEmpty(apiConfig)) {
      apiConfig = defaultConfig;
    }
    let finalEndpoint = 'mrp/workorder/search';
    let query = '';
    query += `?limit=${apiConfig?.Limit}&search=${apiConfig?.SearchTerm}&page=${
      apiConfig?.Page
    }&query=${this.apiConfig?.Query || ''}&sort=${
      this.apiConfig?.sort || 'createdOn'
    }&sortDir=${this.apiConfig?.sortDir || 'DESC'}`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  }

  static getWorkOrders(avoidCancelPreviousRequest?: boolean) {
    if (!avoidCancelPreviousRequest) {
      Utility.cancelRequest(WorkOrderService.abortController);
    }
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let filterQuery = this.apiConfig.Query;
    if (this.apiConfig.isDeleted) {
      filterQuery += `,isDeleted=true`;
    } else {
      filterQuery += `,isDeleted=false`;
    }
    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        .ENABLE_ARCHIVE_WORK_ORDER
    ) {
      if (this.apiConfig.isArchive) {
        filterQuery += `,isArchived=true`;
      } else {
        filterQuery += `,isArchived=false`;
      }
    }
    let finalEndpoint = ApiConstants.URL.MRP.WORK_ORDER.GET_ALL_WORK_ORDER;
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&page=${this.apiConfig.Page}&query=${filterQuery}&sort=${
      this.apiConfig.sort
    }&sortDir=${this.apiConfig.sortDir}&fetchAttachmentDetails=${
      this.apiConfig.FetchAttachmentDetails || false
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    finalEndpoint = finalEndpoint + query;

    WorkOrderService.abortController = avoidCancelPreviousRequest
      ? null
      : Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: avoidCancelPreviousRequest
          ? null
          : WorkOrderService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getWorkOrderByCode(workOrderCode: string) {
    let finalURL = WorkOrderService.woEndPoint({
      SearchTerm: workOrderCode,
      Limit: 25,
      Page: 0,
      Query: '',
      sort: 'createdOn',
      sortDir: 'DESC'
    });

    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error deleting work order: ', err);
      });
  }

  static deleteWorkOrder(id: any) {
    return http.delete(`${ApiConstants.URL.MRP.WORK_ORDER.DELETE_WORK_ORDER}`, {
      data: id
    });
  }

  static addWorkOrder(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.WORK_ORDER.ADD_WORK_ORDER, payload)
      .catch((err: any) => {
        console.error('Error while adding work order: ', err);
        return Promise.reject(err);
      });
  }

  static updateWorkOrder(payload: any, id: any) {
    return http
      .put(ApiConstants.URL.MRP.WORK_ORDER.EDIT_WORK_ORDER(id), payload)
      .catch((err: any) => {
        console.error('Error while updating operation: ', err);
        return Promise.reject(err);
      });
  }

  static updatePatchWorkOrder(payload: any, id: any) {
    return http
      .patch(ApiConstants.URL.MRP.WORK_ORDER.EDIT_PATCH_WORK_ORDER(id), payload)
      .catch((err: any) => {
        console.error('Error while updating operation: ', err);
        return Promise.reject(err);
      });
  }
  static updateWOContact = (payload: any) => {
    return http
      .patch(ApiConstants.URL.MRP.WORK_ORDER.UPDATE_WORKORDER_CONTACT, payload)
      .catch((err: any) => {
        console.error('Error while updating operation: ', err);
        return Promise.reject(err);
      });
  };

  static fetchWOConsumptionSummary = (woCode: any) => {
    var raw = woCode;
    return fetch(
      `${ApiConstants.URL.BASE}${`mrp/workorderconsumption/workOrderCode`}`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: raw
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          return Promise.resolve(data);
        }
        return Promise.reject('');
      })
      .catch((error) => {
        return Promise.reject('');
      });
  };

  static getConsumptionHistoryEndpoint = (queryParams: any) => {
    let finalEndpoint = ApiConstants.URL.MRP.WORK_ORDER.WO_CONSUMPTION_HISTORY;
    let query = '';
    query += `?limit=${queryParams.Limit}&page=${queryParams.Page}&sort=${queryParams.Sort}&sortDir=${queryParams.SortDir}`;
    if (queryParams.Query) query += `&query=${queryParams.Query}`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static fetchConsumptionHistory() {
    if (Utility.isEmpty(this.apiConfigForConsumption)) {
      this.apiConfigForConsumption = defaultConfigForConsumption;
    }

    let finalEndpoint = ApiConstants.URL.MRP.WORK_ORDER.WO_CONSUMPTION_HISTORY;
    let query = '';
    query += `?limit=${this.apiConfigForConsumption.Limit}&page=${this.apiConfigForConsumption.Page}&sort=${this.apiConfigForConsumption.Sort}&sortDir=${this.apiConfigForConsumption.SortDir}`;
    if (this.apiConfigForConsumption.Query)
      query += `&query=${this.apiConfigForConsumption.Query}`;
    finalEndpoint = finalEndpoint + query;

    return http
      .get(finalEndpoint)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error fetching work order consumption history: ', err);
      });
  }

  static saveConsumption(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.WORK_ORDER.WO_CONSUMPTION_SAVE, payload)
      .catch((err: any) => {
        console.error('Error while saving wip consumption: ', err);
        return Promise.reject(err);
      });
  }

  static wipCompleteWO(id: any, status: any) {
    return http
      .put(ApiConstants.URL.MRP.WORK_ORDER.WIP_COMPLETE_ORDER(id, status))
      .catch((err: any) => {
        console.error('Error while completing WO: ', err);
        return Promise.reject(err);
      });
  }

  static fetchProductWIPQty(
    productCode: string,
    workOrderCode: string,
    bomMetaCode: string
  ) {
    return http
      .get(
        ApiConstants.URL.MRP.BOM_ASSEMBLY.GET_WIP_PRODUCT_QTY(
          productCode,
          workOrderCode,
          bomMetaCode
        )
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchAutoProducibleQty(workOrderCode: string) {
    return http
      .get(
        ApiConstants.URL.MRP.BOM_ASSEMBLY.GET_AUTO_PRODUCIBLE_QTY(workOrderCode)
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static autoProduction(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.BOM_ASSEMBLY.AUTO_PRODUCE, payload)
      .catch((err: any) => {
        console.error('Error while auto producing: ', err);
        return Promise.reject(err);
      });
  }

  static areAllWorkOrdersCompleted(): Promise<boolean> {
    return http
      .get(ApiConstants.URL.MRP.WORK_ORDER.ALL_WO_COMPLETED)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchLinkedRecords(workOrderCode: any) {
    return http
      .get(ApiConstants.URL.MRP.WORK_ORDER.GET_LINKED_RECORDS(workOrderCode))
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchProducibleQty(workOrderCode: any) {
    return http
      .get(ApiConstants.URL.MRP.WORK_ORDER.GET_PRODUCIBLE_QTY(workOrderCode))
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getWOSourceDetailsByDocCodeAndType(
    docCodes: string[],
    docType: DOC_TYPE
  ): Promise<any[]> {
    return http
      .post(
        ApiConstants.URL.MRP.WORK_ORDER.GET_WO_SOURCE_DETAILS_BY_DOC_CODE_AND_TYPE(
          docType
        ),
        docCodes
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static convertWOToDoc(payload: any, documentType: DOC_TYPE) {
    let convertURL = '';
    switch (documentType) {
      case DOC_TYPE.QUOTE:
        convertURL = ApiConstants.URL.MRP.CONVERT.QUOTATION;
        break;
      case DOC_TYPE.SALES_ORDER:
        convertURL = ApiConstants.URL.MRP.CONVERT.SALES_ORDER;
        break;
      case DOC_TYPE.INVOICE:
        convertURL = ApiConstants.URL.MRP.CONVERT.INVOICE;
        break;
    }
    return http
      .post(convertURL, payload)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getConsolidatedProductQuantityBySO(soCode: any[]) {
    let finalURL =
      ApiConstants.URL.SALES_ORDER
        .FETCH_CONSOLIDATED_PRODUCT_QUANTITY_BY_SO_CODES;
    return http
      .post(finalURL, soCode)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static exportWorkorderList(queryParams: any, headers: any) {
    // let url = `${ApiConstants.URL.MRP.WORK_ORDER.WORK_ORDER_EXPORT}?format=XLS&${queryParams}`;
    let url = `${ApiConstants.URL.MRP.WORK_ORDER.WORK_ORDER_EXPORT}?format=XLS&module=WORK_ORDER`;
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalEndpoint = `${ApiConstants.URL.MRP.WORK_ORDER.WORK_ORDER_EXPORT}?format=XLS&module=WORK_ORDER&headers=${headers}`;
    let query = '';
    let queryString = Utility.isNotEmpty(this.apiConfig.Query)
      ? this.apiConfig.Query?.replace(/^,/, '')
      : this.apiConfig.Query;
    queryString = Utility.isNotEmpty(queryString)
      ? queryString?.replace(/^,,/, ',')
      : queryString;
    if (this.apiConfig.isDeleted) {
      if (Utility.isNotEmpty(queryString)) {
        queryString += `,isDeleted=true`;
      } else {
        queryString += `isDeleted=true`;
      }
    } else {
      if (Utility.isNotEmpty(queryString)) {
        queryString += `isDeleted=false`;
      } else {
        queryString += `isDeleted=false`;
      }
    }
    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        .ENABLE_ARCHIVE_WORK_ORDER
    ) {
      if (this.apiConfig.isArchive) {
        queryString += `,isArchived=true`;
      } else {
        queryString += `,isArchived=false`;
      }
    }
    query += `&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&page=${this.apiConfig.Page}&query=${queryString}&sort=${
      this.apiConfig.sort
    }&sortDir=${this.apiConfig.sortDir}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    finalEndpoint = finalEndpoint + query;

    const fileName = `workorderList.xlsx`;

    return http
      .get(`${finalEndpoint}`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        saveAs(response, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateWorkOrderArchiveStatus(workorderIds: any, archive: boolean) {
    let url = `${ApiConstants.URL.MRP.WORK_ORDER.WORK_ORDER_ARCHIVE}${
      archive ? 'true' : 'false'
    }`;
    return http
      .put(url, workorderIds)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

// outside class methods
export const getDefaultCustomNumberFormatForWorkOrder = () => {
  const workOrderCustomNumberList =
    selectWorkOrderCustomNumberFormats(Store.getState()) || [];
  return (
    workOrderCustomNumberList.find((item: any) => item.isDefault) ||
    workOrderCustomNumberList[0]
  );
};

const getContactsForMultipleSOs = (salesOrder: ISalesOrder[], product: any) => {
  let contactObjArr: any[] = [];
  salesOrder.forEach((order: ISalesOrder) => {
    order.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) => {
      const codeToCompare =
        orderItem?.product?.productId ?? orderItem?.productCode;
      if (codeToCompare === product.productId) {
        contactObjArr.push(order?.contactDto);
      }
    });
  });
  contactObjArr = uniqBy(contactObjArr, 'code');
  return contactObjArr;
};

export const getWorkOrderSourceDetailsForMultipleSO = (
  salesOrder: ISalesOrder[],
  product: any
) => {
  let matchingProductWithSO: ISalesOrder[] = [];
  let workOrderSourceDetails: any = [];
  const salesDocItemCodeKey = 'salesOrderItemCode';

  salesOrder.forEach((order: ISalesOrder) => {
    order.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) => {
      const codeToCompare =
        orderItem?.product?.productId ?? orderItem?.productCode;
      if (codeToCompare === product.productId) {
        matchingProductWithSO.push(order);
      }
    });
  });

  matchingProductWithSO?.forEach((so: ISalesOrder) => {
    const lineItemForCurrentProduct: SalesOrderItemsEntity | undefined =
      so?.salesOrderItems?.find((salesOrderItem) => {
        return (
          salesOrderItem.productCode === product.productId &&
          Utility.isEmpty(
            workOrderSourceDetails?.find(
              (presentWOSourceDetail: any) =>
                presentWOSourceDetail?.salesOrderItemCode ===
                salesOrderItem?.salesOrderItemCode
            )
          )
        );
      });
    workOrderSourceDetails.push({
      workOrderSource: DOC_TYPE.SALES_ORDER,
      salesOrderSequenceCode: so?.documentSequenceCode ?? '',
      linkedDocumentCode: so?.salesOrderCode,
      salesOrderCustomerOrderNumber: so?.customerOrderNumber,
      salesOrderItemCode: lineItemForCurrentProduct
        ? lineItemForCurrentProduct?.[salesDocItemCodeKey]
        : '',
      productQuantity: lineItemForCurrentProduct?.productQuantity || 0
    });
  });

  return workOrderSourceDetails;
};

export const getConsolidatedLineItemForCurrentProduct = (
  orders: ISalesOrder[],
  currentProduct: any
) => {
  let allCommonProductFromSOItems: SalesOrderItemsEntity[] = [];
  orders?.forEach((order: ISalesOrder) => {
    order?.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) => {
      if (orderItem.productCode === currentProduct.productId) {
        allCommonProductFromSOItems.push(orderItem);
      }
    });
  });

  if (Utility.isEmpty(allCommonProductFromSOItems)) {
    return null;
  }

  const commonProductFromSOItem = {
    ...allCommonProductFromSOItems?.[0],
    productQuantity: reduceArrayByKey(
      allCommonProductFromSOItems,
      'productQuantity'
    )
  };

  return commonProductFromSOItem;
};

export const prepareWorkOrderFormData = (
  bomProductDetailsList: any[],
  salesOrder?: ISalesOrder | ISalesOrder[] | null,
  salesInvoice?: Invoice | null
): IWorkOrder[] => {
  let salesDocumentDate: any;
  let salesDocumentDueDate: any;
  let contactsArr: any[] = [];

  if (Array.isArray(salesOrder)) {
    bomProductDetailsList = uniqBy(bomProductDetailsList, 'productId');
  }

  try {
    if (salesOrder) {
      if (!Array.isArray(salesOrder)) {
        salesOrder = { ...salesOrder };
        salesDocumentDate =
          typeof salesOrder.salesOrderDate === 'string'
            ? DateFormatService.getDateFromStr(
                salesOrder.salesOrderDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
            : salesOrder.salesOrderDate;
        salesDocumentDueDate =
          typeof salesOrder.salesOrderDueDate === 'string'
            ? DateFormatService.getDateFromStr(
                salesOrder.salesOrderDueDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
            : salesOrder.salesOrderDueDate;
        contactsArr.push(salesOrder.contactDto);
      } else {
        salesOrder = [...salesOrder];
        salesOrder?.forEach((order: ISalesOrder) => {
          salesDocumentDate = new Date();
          salesDocumentDueDate =
            typeof order.salesOrderDueDate === 'string'
              ? DateFormatService.getDateFromStr(
                  order.salesOrderDueDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )
              : order.salesOrderDueDate;
          if (salesDocumentDueDate.getTime() < salesDocumentDate.getTime()) {
            salesDocumentDueDate = new Date();
          }
        });
      }
    } else if (salesInvoice) {
      salesInvoice = { ...salesInvoice };
      salesDocumentDate =
        typeof salesInvoice.salesInvoiceDate === 'string'
          ? DateFormatService.getDateFromStr(
              salesInvoice.salesInvoiceDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : salesInvoice.salesInvoiceDate;
      salesDocumentDueDate =
        typeof salesInvoice.salesInvoiceDueDate === 'string'
          ? DateFormatService.getDateFromStr(
              salesInvoice.salesInvoiceDueDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : salesInvoice.salesInvoiceDueDate;
      contactsArr.push(salesInvoice.contactDto);
    }
  } catch (err) {}

  return (bomProductDetailsList || []).map((product) => {
    // Common keys
    let lineItemForCurrentProduct: any;
    let salesDocItemCodeKey = '';
    let workOrderSourceDoc = 'NONE';
    let salesDocSequenceCode = '';
    let salesDocumentCode = '';
    let salesDocCustomerOrderNumber = '';
    let workOrderSourceDetailsArr: any[] = [];

    if (!Utility.isEmptyObject(salesOrder)) {
      if (!Array.isArray(salesOrder)) {
        // Handle Sales Order data
        lineItemForCurrentProduct = salesOrder?.salesOrderItems?.find(
          (salesOrderItem) =>
            salesOrderItem.productCode === product.productId &&
            salesOrderItem.id === product.lineItemId
        );

        salesDocItemCodeKey = 'salesOrderItemCode';
        workOrderSourceDoc = DOC_TYPE.SALES_ORDER;
        salesDocSequenceCode = salesOrder?.documentSequenceCode ?? '';
        salesDocumentCode = salesOrder?.salesOrderCode ?? '';
        salesDocCustomerOrderNumber = salesOrder?.customerOrderNumber ?? '';

        workOrderSourceDetailsArr.push({
          workOrderSource: workOrderSourceDoc,
          salesOrderSequenceCode: salesDocSequenceCode,
          linkedDocumentCode: salesDocumentCode,
          salesOrderCustomerOrderNumber: salesDocCustomerOrderNumber,
          salesOrderItemCode: salesDocItemCodeKey
            ? lineItemForCurrentProduct?.[salesDocItemCodeKey]
            : '',
          productQuantity: lineItemForCurrentProduct.productQuantity
        });
      } else {
        workOrderSourceDetailsArr = getWorkOrderSourceDetailsForMultipleSO(
          salesOrder,
          product
        );
        lineItemForCurrentProduct = getConsolidatedLineItemForCurrentProduct(
          salesOrder,
          product
        );

        contactsArr = getContactsForMultipleSOs(salesOrder, product);
      }
    } else if (!Utility.isEmptyObject(salesInvoice)) {
      // Handle Sales Invoice data
      lineItemForCurrentProduct = salesInvoice?.salesInvoiceItems?.find(
        (salesInvoiceItem) =>
          salesInvoiceItem.productCode === product.productId &&
          salesInvoiceItem.id === product.lineItemId
      );
      salesDocItemCodeKey = 'salesInvoiceItemCode';
      workOrderSourceDoc = DOC_TYPE.INVOICE;
      salesDocSequenceCode = salesInvoice?.documentSequenceCode ?? '';
      salesDocumentCode = salesInvoice?.salesInvoiceCode ?? '';
      salesDocCustomerOrderNumber = salesInvoice?.customerOrderNumber ?? '';
      workOrderSourceDetailsArr.push({
        workOrderSource: workOrderSourceDoc,
        salesOrderSequenceCode: salesDocSequenceCode,
        linkedDocumentCode: salesDocumentCode,
        salesOrderCustomerOrderNumber: salesDocCustomerOrderNumber,
        salesOrderItemCode: lineItemForCurrentProduct
          ? lineItemForCurrentProduct?.[salesDocItemCodeKey]
          : ''
      });
    }

    if (Utility.isEmpty(workOrderSourceDetailsArr)) {
      workOrderSourceDetailsArr.push({
        workOrderSource: workOrderSourceDoc,
        salesOrderSequenceCode: salesDocSequenceCode,
        linkedDocumentCode: salesDocumentCode,
        salesOrderCustomerOrderNumber: salesDocCustomerOrderNumber,
        salesOrderItemCode: salesDocItemCodeKey
          ? lineItemForCurrentProduct?.[salesDocItemCodeKey]
          : ''
      });
    }

    const dateWithoutTime = new Date();
    const requiredQuantityForWorkOrder = Utility.roundOff(
      lineItemForCurrentProduct?.productQuantity ||
        product.woRequiredQuantity ||
        1,
      QTY_ROUNDOFF_PRECISION
    );

    const defaultNumberFormat = getDefaultCustomNumberFormatForWorkOrder();
    const componentProducts = (
      product.selectedBom?.bomProductsConfiguration || []
    ).map((componentProduct: any) => ({
      ...componentProduct,
      [REQUIRED_ITEM_TABLE.PLANNED_QTY]:
        requiredQuantityForWorkOrder * componentProduct.quantity,
      [REQUIRED_ITEM_TABLE.REQUIRED_QTY]:
        requiredQuantityForWorkOrder * componentProduct.quantity,
      [REQUIRED_ITEM_TABLE.UNIT_COST]: componentProduct.cost
    }));

    let bomOperationsConfig = [
      ...(product?.selectedBom?.bomOperationsConfiguration || [])
    ].sort((a: any, b: any) => a?.sequenceNumber - b?.sequenceNumber);

    /**
     * @todo
     * Only form data with keys that's required for IWorkOrder
     */
    let woObj = {
      ...product,
      attachments: [],
      documentSequenceCode: '',
      sequenceFormat: defaultNumberFormat?.id,
      selectedBom: product.selectedBom,
      product: product,
      productCode: product.productId,
      productName: lineItemForCurrentProduct?.product?.name || product?.name,
      manufactureQuantity: requiredQuantityForWorkOrder,
      actualQuantity: requiredQuantityForWorkOrder,
      sourceWarehouseCode: '',
      targetWarehouseCode: '',
      inProgressWarehouseCode: '',
      plannedStartDate: salesDocumentDate ? salesDocumentDate : dateWithoutTime,
      contactsArr: contactsArr,
      plannedEndDate: salesDocumentDueDate
        ? salesDocumentDueDate
        : dateWithoutTime,
      deliveryDate: salesDocumentDueDate
        ? salesDocumentDueDate
        : dateWithoutTime,
      actualEndDate: salesDocumentDueDate
        ? salesDocumentDueDate
        : dateWithoutTime,
      actualStartDate: salesDocumentDate ? salesDocumentDate : dateWithoutTime,
      workOrderItems: componentProducts,
      workOrderOperations: bomOperationsConfig,
      operationCostDetails: {
        actualOperatingCost: 0,
        additionalOperatingCost: 0,
        plannedOperatingCost: 0,
        totalOperatingCost: 0
      },
      actualYield: 0,
      plannedYield: 100,
      workOrderSourceDetails: workOrderSourceDetailsArr ?? [],
      isAutoClosed: isAutoCloseWOEnabled() && bomOperationsConfig?.length > 0
    } as IWorkOrder;
    return woObj;
  });
};
export const parseDateFromStringForWorkOrder = (dateStr: string) => {
  if (!Utility.isValidDate(dateStr)) return '';

  try {
    /** Receiving date in below format in staging & Prod */
    if (new RegExp(REGEX.WO_TZ_DATE_FORMAT).test(dateStr)) {
      /* Using date fns lib to get date without timezone,
      to avoid ZEN-12233 */
      const date = DateFormatService.getDateFromStr(
        dateStr.split('T')[0],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      return date;
    } else if (dateStr.includes('T')) {
      return new Date(dateStr);
    } else {
      const date = DateFormatService.getDateFromStr(
        dateStr,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

      return date;
    }
  } catch (err) {
    return new Date(dateStr);
  }
};
export const prepareWorkOrderFormDataEditCase = (
  workOrders: any[],
  bomProductDetailsList: any[]
): IWorkOrder[] => {
  if (!workOrders && !bomProductDetailsList?.length) {
    return [];
  }
  return bomProductDetailsList.map((product: any) => {
    const workOrderData = (workOrders.find(
      (workOrder) => workOrder.productCode === product.productId
    ) || {}) as any;
    const requiredQuantityForWorkOrder = Utility.roundOff(
      workOrderData.manufactureQuantity || 1,
      QTY_ROUNDOFF_PRECISION
    );
    return {
      ...workOrderData,
      product,
      manufactureQuantity: requiredQuantityForWorkOrder,
      actualQuantity: workOrderData?.actualQuantity
        ? workOrderData?.actualQuantity
        : requiredQuantityForWorkOrder,
      bomMetaCode: workOrderData?.bomMetaCode,
      bomMetaDetails: workOrderData?.bomMetaDetails ?? null,
      selectedBom: product?.selectedBom,
      images: product?.images,
      isAutoClosed: workOrderData?.isAutoClosed ?? false,
      workOrderItems: workOrderData.workOrderItems?.map(
        (workOrderItem: any, itemIndex: number) => {
          let bomItem: any = product?.bomProductsConfiguration?.find(
            (componentProduct: any) =>
              componentProduct.productCode === workOrderItem.productCode
          );

          const quantityToConsider =
            workOrderItem?.uomQuantity ?? workOrderItem?.quantity;
          const plannedQuantityToConsider =
            workOrderItem?.plannedUomQuantity ??
            workOrderItem?.plannedQuantity ??
            0;
          const producedQuantityToConsider =
            workOrderItem?.producedUomQuantity ??
            workOrderItem?.producedQuantity ??
            0;

          const unitRequiredQty =
            quantityToConsider ??
            bomItem?.quantity ??
            Utility.roundOff(
              plannedQuantityToConsider /
                Number(workOrderData.manufactureQuantity ?? 1),
              QTY_ROUNDOFF_PRECISION
            );

          return {
            ...workOrderItem,
            itemId: workOrderItem.productCode,
            requiredQty: plannedQuantityToConsider ?? 0,
            actualRequiredQty: producedQuantityToConsider ?? 0,
            quantity: unitRequiredQty,
            componentProductUnitQty: unitRequiredQty,
            bomProductSubstitutesDetails:
              workOrderItem.productSubstitutesDetails || [],
            sequenceNumber: itemIndex + 1,
            warehouseInventoryData: workOrderItem?.warehouseInventoryData,
            reservedQuantitiesData: workOrderItem?.reservedQuantitiesData
          };
        }
      ),
      workOrderOperations: workOrderData.workOrderOperations?.map(
        (item: any) => ({
          ...item,
          cost: item.operationCost
        })
      ),
      plannedStartDate: parseDateFromStringForWorkOrder(
        workOrderData.plannedStartDate
      ),
      oldPlannedStartDate: parseDateFromStringForWorkOrder(
        workOrderData.plannedStartDate
      ),
      plannedEndDate: parseDateFromStringForWorkOrder(
        workOrderData.plannedEndDate
      ),
      deliveryDate: parseDateFromStringForWorkOrder(workOrderData.deliveryDate),
      actualEndDate: parseDateFromStringForWorkOrder(
        workOrderData.actualEndDate
      ),
      actualStartDate: parseDateFromStringForWorkOrder(
        workOrderData.actualStartDate
      ),
      bomAddCostConfiguration: product?.bomAddCostConfiguration ?? [],
      attachments: getAttachmentIdListFromAttachmentResponse(
        workOrderData.attachmentsResponses
      ),
      customField: workOrderData?.customField ?? [],
      contactsArr: workOrderData?.contactDetails ?? []
    } as IWorkOrder;
  });
};

export const addBulkWorkOrder = (payload: any[]): Promise<any> => {
  return http.post(
    ApiConstants.URL.MRP.WORK_ORDER.BULK_ADD_WORK_ORDER,
    payload
  );
};
export const fetchWarehouseInfoForAllProducts = async (
  bomProductDetailsList: any[]
) => {
  let allProductCodes: string[] = [];

  bomProductDetailsList.forEach((bomProductDetails) => {
    const bomProductCodes =
      bomProductDetails.bomProductsConfiguration?.flatMap((item: any) => {
        const mainItemProdCode = item.productCode;
        let substituteProdCodes = item.bomProductSubstitutesDetails?.map(
          (subDetail: any) => subDetail.productId
        );
        if (substituteProdCodes && substituteProdCodes?.length) {
          return [mainItemProdCode, ...substituteProdCodes];
        } else {
          return [mainItemProdCode];
        }
      }) || [];

    allProductCodes.push(...bomProductCodes);
  });
  const allUniqueProductCodes = Array.from(
    new Set(allProductCodes?.map((code: string) => code))
  );

  let warehouseProductsResponse = [];
  if (
    (Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()) &&
    !Utility.isEmpty(allUniqueProductCodes)
  ) {
    try {
      const response = await ProductService.fetchWarehouseProductsByID(
        allUniqueProductCodes
      );
      warehouseProductsResponse = response?.warehouses || [];
    } catch (err: any) {
      console.error('Error fetching product stock info: ', err);
    }
  }

  return Promise.resolve(warehouseProductsResponse);
};

export const getSOByProductCode = async (productCode: string) => {
  showLoader('Fetching if any sales orders related...');
  try {
    const response = await SalesOrderService.getSOFromProductCode(
      productCode,
      true
    );
    removeLoader();
    return Promise.resolve(response);
  } catch (err: any) {
    removeLoader();
    console.error('Error fetching SO by product code: ', err);
    return Promise.reject(err);
  }
};

export const setDefaultWhForCompleteWO = (
  payload: any,
  tenantId: any
): Promise<any> => {
  return http.post(
    ApiConstants.URL.MRP.WORK_ORDER.DEFAULT_WAREHOUSE_FOR_WO(tenantId),
    payload
  );
};
export const getDefaultWhForCompleteWO = (tenantId: any): Promise<any> => {
  return http.get(
    ApiConstants.URL.MRP.WORK_ORDER.GET_DEFAULT_WAREHOUSE_FOR_WO(tenantId)
  );
};

export const completeWOWithWIPConsumptionAndProduction = (
  id: any,
  isEditMode: boolean,
  parentWorkOrderCode: any,
  callBack: any = () => {},
  cancelCallback: any = () => {}
) => {
  showAlert(
    'Are you sure you want to complete order?',
    'You have entries in WIP consumption and production. Do you still wish to proceed?',
    [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          cancelCallback();
        }
      },
      {
        title: 'Yes',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          completeWOWithWIPConsumptionAndProductionAPICall(
            id,
            isEditMode,
            parentWorkOrderCode,
            callBack
          );
        }
      }
    ]
  );
};

export const completeWOWithWIPConsumptionAndProductionAPICall = (
  id: any,
  isEditMode: boolean,
  parentWorkOrderCode: any,
  callBack: any = () => {}
) => {
  WorkOrderService.wipCompleteWO(id, 'COMPLETED')
    .then((res: any) => {
      res &&
        broadcastOnWorkOrderSaved(
          res.workOrderCode,
          isEditMode,
          res.parentWorkOrderCode ?? parentWorkOrderCode
        );
      callBack(res);
      showAlert('Success', 'Current Work Order Saved Successfully');
    })
    .catch((err: any) => {});
};

export const woProductionPopupDataToPass = (workOrderObject: any) => {
  return {
    productName: workOrderObject?.productName,
    productCode: workOrderObject?.productCode,
    productCodeToDisplayOnly: workOrderObject?.product?.documentSequenceCode,
    uom:
      Utility.getUOMForStockUOMId(workOrderObject?.product?.stockUom)?.name ??
      '',
    stockUom: workOrderObject?.product?.stockUom,
    advancedTracking: workOrderObject?.product?.advancedTracking,
    isSubstitute: false
  };
};

export default WorkOrderService;
