import { useEffect, useState } from 'react';
import { DKSpinner, showAlert } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  LABELS
} from '../../Constants/Constant';
import { Quote } from '../../Models/Quote';
import { SalesOrderInitialState } from '../../Models/SalesOrder';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import ContactService from '../../Services/Contact';
import Utility from '../../Utility/Utility';
import { DraftTypes } from '../../Models/Drafts';
import QuotationService from '../../Services/Quotation';
import { getUpdatedQuoteObject } from './QuoteHelper';
import ic_convert_fulfill from '../../Assets/Icons/ic_convert_fulfill.svg';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import ic_convert_partially from '../../Assets/Icons/ic_convert_partially.svg';
import { isSalesOrderVisible } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { selectSalesOrderCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';
import { checkUserPermission } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

interface QuoteToSOProps {
  quoteDocument: Quote;
  closePopup: () => void;
  closeParentDoc?: () => void;
}

enum QuoteConversionAction {
  PARTIAL_SALES_ORDER = 'partial_sales_order',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_FULFILL = 'convert_and_fulfill'
}

export default function QuoteToSalesOrderPopup(props: QuoteToSOProps) {
  const [quote, setQuote] = useState(props.quoteDocument);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [type, setType] = useState<QuoteConversionAction>();
  const [contact, setContact] = useState<any>(null);

  const dispatch = useAppDispatch();
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const salesOrderSequenceFormatsInStore = useAppSelector(
    selectSalesOrderCustomNumbersFormats
  );

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const detailedContact = await ContactService.getContactDetailsById(
          props.quoteDocument?.contactDto?.id
        );
        setContact(detailedContact);
      } catch (err) {
        console.error('Error loading detailed contact: ', err);
      }
    };
    fetchContact();
  }, []);

  // Load Quote Details
  const loadQuotationDetails = async (code: any, convertType: any) => {
    QuotationService.getQuoteByCode(code).then(
      (data: any) => {
        const quoteDetailsData: any = getUpdatedQuoteObject(data);
        setQuote(quoteDetailsData);
        if (!Utility.isEmpty(quoteDetailsData)) {
          setIsApiLoading(false);
          switch (convertType) {
            case QuoteConversionAction.CONVERT_AND_FULFILL:
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.CONVERT_AND_FULFILL
              );
              break;
            case QuoteConversionAction.CONVERT_ONLY:
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.CONVERT_ONLY
              );
              break;
            case QuoteConversionAction.PARTIAL_SALES_ORDER:
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.PARTIAL_SALES_ORDER
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching quote details: ', err);
      }
    );
  };
  const checkIsLocalizedUomQty = () => {
    return quote?.quotationItemDtoList?.some(
      (item: any) => item.isLocalizedUomQty
    );
  };
  const getLinkedWorkOrders = (selectedQuote: Quote) => {
    let linkedDocuments: any = [];
    let linkedWorkOrders =
      selectedQuote?.linkedDocuments?.filter(
        (woItem: any) => woItem?.documentType === DOC_TYPE.WORK_ORDER
      ) || [];
    if (!Utility.isEmpty(linkedWorkOrders)) {
      linkedDocuments = [...linkedDocuments, ...linkedWorkOrders];
    }
    return linkedDocuments;
  };

  const getUpdatedObject = (selectedQuote: Quote) => {
    let salesOrder = {
      ...SalesOrderInitialState,
      ...selectedQuote
    };

    if (!Utility.isEmpty(contact)) {
      salesOrder = {
        ...salesOrder,
        contactDto: contact
      };
    }

    salesOrder = {
      ...salesOrder,
      documentSequenceCode: '',
      documentType: DOC_TYPE.SALES_ORDER,
      openingSalesOrder: salesOrder.openingSalesOrder || false,
      fulfillmentStatus:
        salesOrder.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: salesOrder.fulfillmentType,
      documentDate: salesOrder.documentDate,
      validTillDate: salesOrder.validTillDate,
      fulfillmentDate: salesOrder.fulfillmentDate,
      status: !salesOrder.id ? DOCUMENT_STATUS.OPEN : salesOrder.status,
      backOrder: salesOrder.backOrder ? salesOrder.backOrder : false,
      paymentStatus: salesOrder.paymentStatus,
      reservedStock: salesOrder.reservedStock
        ? salesOrder.reservedStock
        : false,
      salesOrderDueDate: salesOrder.validTillDate,
      salesOrderDate: salesOrder.documentDate,
      shipByDate: salesOrder.fulfillmentDate,
      attachments: salesOrder.entityId ? salesOrder.attachments : [],
      attachmentIds: salesOrder.entityId
        ? salesOrder.attachments?.map(Number)
        : [],
      contact: {
        ...salesOrder.contact,
        ...salesOrder.contactDto,
        address: Utility.getStringAddress(salesOrder.contactDto)
      },
      linkedQuotationDocuments: [
        {
          documentCode: selectedQuote.quotationCode as string,
          documentType: selectedQuote.documentType,
          documentSequenceCode: selectedQuote.documentSequenceCode as string
        }
      ],
      linkedWorkOrderDocuments: getLinkedWorkOrders(selectedQuote),
      salesOrderItems: salesOrder.items?.map((item) => {
        return {
          ...item,
          id: undefined,
          documentItemCode: undefined,
          documentSequenceCode: null,
          linkedQuoteItem: item.id,
          unitPriceGstInclusive: salesOrder.unitPriceGstInclusive,
          exchangeRate: salesOrder.exchangeRate,
          quantityFulfilled: item.quantityFulfilled
            ? item.quantityFulfilled
            : item.fulfilledQuantity,
          quantityInParent: item.quantityInParent
            ? item.quantityInParent
            : item.productQuantity,
          taxDetails: item.taxDetails.map((tax: any) => {
            const taxItem = { ...tax };
            delete taxItem.id;
            return taxItem;
          }),
          totalAmount: item.amount
        };
      })
    };

    return salesOrder;
  };

  const convertToPartialSO = (selectedQuote: Quote) => {
    const salesOrder = getUpdatedObject(selectedQuote);
    let partialSalesOrder = {
      ...salesOrder,
      isPartialSalesOrder: true,
      isConverting: true,
      backOrder: false,
      salesOrderItems: salesOrder.salesOrderItems?.map((item) => {
        delete item.quantityFulfilled;
        delete item.quantityInParent;

        return {
          ...item,
          isPartialSalesOrder: true
        };
      }),
      approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
      multiApprovalDetails: null
    };

    const {
      contactDto,
      items,
      quotationCode,
      quotationItemDtoList,
      documentSequenceCode,
      sequenceFormat,
      ...soToForward
    } = partialSalesOrder;

    let payloadData: any = {
      type: LABELS.SALES_ORDER,
      title: LABELS.SALES_ORDER,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: soToForward,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };

    if (!Utility.isEmpty(soToForward)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const prepareQuoteForConversion = async (
    data: Quote,
    action: QuoteConversionAction
  ) => {
    let salesOrderSequenceFormat: any;
    if (!Utility.isEmpty(salesOrderSequenceFormatsInStore)) {
      salesOrderSequenceFormat = salesOrderSequenceFormatsInStore?.find(
        (seqFormat: any) => seqFormat?.isDefault
      );
      if (!Utility.isEmpty(salesOrderSequenceFormat)) {
        salesOrderSequenceFormat = salesOrderSequenceFormat?.id;
      } else {
        salesOrderSequenceFormat = '';
      }
    }
    let itemsPendingToConvert = 0;
    let selectedQuote = {
      ...data,
      entityId: data.id ? data.id : undefined,
      documentType: DOC_TYPE.QUOTE,
      recurring: false,
      recurringActivated: false,
      backOrder: false,
      documentCode: data.quotationCode,
      currency: data.currency,
      currencyCode: data.currency,
      fulfillmentStatus:
        data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      sourceFulfillmentStatus:
        data.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: data.fulfillmentType,
      fulfillmentDate: data.fulfillmentDate,
      status: !data.id ? DOCUMENT_STATUS.OPEN : data.status,
      items: data.quotationItemDtoList
        ?.filter(
          (item) =>
            !item.optional && Utility.getPendingQtyForConversion(item) > 0
        )
        .map((item) => {
          const pendingQty = Utility.getPendingQtyForConversion(item);
          if (pendingQty > 0) {
            ++itemsPendingToConvert;
          }
          return {
            ...item,
            documentItemCode: item.quotationItemCode,
            pendingQtyToConvert: pendingQty,
            productQuantity: pendingQty,
            uomQuantity: Utility.getUomQuantity(
              pendingQty,
              item.documentUOMSchemaDefinition
            ),
            nonEditableColumns: data.reservedStock
              ? ['productQuantity', 'uom']
              : [],
            reservedQuantitiesData: item.reservedQuantitiesData?.map(
              (reservedQtyItem: any) => ({
                ...reservedQtyItem,
                availableQuantity: Utility.getUomQuantity(
                  reservedQtyItem.availableQuantity,
                  item.documentUOMSchemaDefinition
                ),
                reservedQuantity: Utility.getUomQuantity(
                  reservedQtyItem.reservedQuantity,
                  item.documentUOMSchemaDefinition
                ),
                advancedTrackingMetaDtos: reservedQtyItem
                  .advancedTrackingMetaDtos?.length
                  ? reservedQtyItem.advancedTrackingMetaDtos?.map(
                      (advTrackingDto: any) => {
                        return {
                          ...advTrackingDto,
                          batchSize: Utility.getUomQuantity(
                            advTrackingDto.batchSize,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantity: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantity,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantityFulfilled: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantityFulfilled,
                            item.documentUOMSchemaDefinition
                          ),
                          batchSizeFulfilled: Utility.getUomQuantity(
                            advTrackingDto.batchSizeFulfilled,
                            item.documentUOMSchemaDefinition
                          )
                        };
                      }
                    )
                  : []
              })
            )
          };
        }),
      processedInPPS: data.processedInPPS,
      reservedStock: data.reservedStock,
      sequenceFormat: salesOrderSequenceFormat,
      isDocumentTouched: true
    };

    if (itemsPendingToConvert === 0) {
      showAlert(
        '',
        'Sorry, all products in this Quote have been invoiced in full. You cannot convert this Quote to Sales Order anymore'
      );
    } else {
      // to set default dates for sales order
      selectedQuote = {
        ...selectedQuote,
        documentDate: '',
        fulfillmentDate: '',
        validTillDate: ''
      };
      switch (action) {
        case QuoteConversionAction.CONVERT_AND_FULFILL:
          selectedQuote = {
            ...selectedQuote,
            autoFulfill: true,
            fulfillmentComplete: true
          };
          convertToPartialSO(selectedQuote);
          break;
        case QuoteConversionAction.CONVERT_ONLY:
        case QuoteConversionAction.PARTIAL_SALES_ORDER:
          convertToPartialSO(selectedQuote);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="flex flex-col w-full px-1 pb-3 text-sm p-1">
      <div className="flex flex-row w-full">
        {`You are about to convert this ${
          Utility.isUSorg() ? 'Estimate' : 'Quote'
        }${
          props.quoteDocument?.documentSequenceCode
            ? '(' + props.quoteDocument?.documentSequenceCode + ')'
            : ''
        } to Sales Order, please select an
          action below:`}
      </div>
      <div
        className="flex flex-col mt-3 w-full"
        style={{
          pointerEvents: isApiLoading ? 'none' : 'auto',
          cursor: isApiLoading ? 'no-drop' : 'pointer'
        }}
      >
        {!(quote.linkedSalesOrders && quote.linkedSalesOrders?.length > 0) &&
          !quote.hasPartialSalesOrder && (
            <div>
              {!Utility.isAdvancedTracking(quote) &&
                !quote.reservedStock &&
                !isSalesOrderVisible() &&
                !checkIsLocalizedUomQty() &&
                checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.FULFILL) &&
                Utility.getDocumentFulfilledQty(
                  quote.quotationItemDtoList || []
                ) === 0 && (
                  <div
                    className={
                      'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m'
                    }
                    onClick={() => {
                      if (!isOpeningForm) {
                        setIsOpeningForm(true);

                        setType(QuoteConversionAction.CONVERT_AND_FULFILL);
                        setIsApiLoading(true);
                        loadQuotationDetails(
                          quote.quotationCode,
                          QuoteConversionAction.CONVERT_AND_FULFILL
                        );
                      }
                    }}
                  >
                    <div
                      className="flex rounded-l rounded-bl"
                      style={{ height: 80 }}
                    >
                      <img
                        src={ic_convert_fulfill}
                        alt="fulfill and convert"
                        style={{ width: 60 }}
                      />
                    </div>
                    <div
                      className="flex flex-col items-start px-4 leading-5 text-left"
                      style={{ width: '90%' }}
                    >
                      <span className="fw-m text-blue">
                        Fulfill and Convert
                      </span>
                      <span className="text-gray">
                        {`Fulfill this ${
                          Utility.isUSorg() ? 'Estimate' : 'Quote'
                        } in full before converting in to a
                        Full Sales Order.`}
                      </span>
                    </div>
                    {isApiLoading &&
                      type === QuoteConversionAction.CONVERT_AND_FULFILL && (
                        <div>
                          <DKSpinner
                            iconClassName="ic-r"
                            className="column pl-0 pr-s"
                          />
                        </div>
                      )}
                  </div>
                )}
              <div
                className={
                  'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
                }
                onClick={() => {
                  if (!isOpeningForm) {
                    setIsOpeningForm(true);
                    setType(QuoteConversionAction.CONVERT_ONLY);
                    setIsApiLoading(true);

                    loadQuotationDetails(
                      quote.quotationCode,
                      QuoteConversionAction.CONVERT_ONLY
                    );
                  }
                }}
              >
                <div
                  className="flex rounded-l rounded-bl"
                  style={{ height: 60 }}
                >
                  <img
                    src={ic_convert_only}
                    alt="convert fully"
                    style={{ width: 60 }}
                  />
                </div>
                <div
                  className="flex flex-col items-start px-4 leading-5 text-left"
                  style={{ width: '90%' }}
                >
                  <span className="fw-m text-blue">Convert Fully</span>
                  <span className="text-gray">
                    {`Convert this ${
                      Utility.isUSorg() ? 'Estimate' : 'Quote'
                    } into a Full Sales Order.`}
                  </span>
                </div>
                {isApiLoading &&
                  type === QuoteConversionAction.CONVERT_ONLY && (
                    <div>
                      <DKSpinner
                        iconClassName="ic-r"
                        className="column pl-0 pr-s"
                      />
                    </div>
                  )}
              </div>
            </div>
          )}

        {!quote.reservedStock && (
          <div
            className={
              'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
            }
            onClick={() => {
              if (!isOpeningForm) {
                setIsOpeningForm(true);

                setIsApiLoading(true);
                setType(QuoteConversionAction.PARTIAL_SALES_ORDER);

                loadQuotationDetails(
                  quote.quotationCode,
                  QuoteConversionAction.PARTIAL_SALES_ORDER
                );
              }
            }}
          >
            <div className="flex rounded-l rounded-bl" style={{ height: 60 }}>
              <img
                src={ic_convert_partially}
                alt="convert partially"
                style={{ width: 60 }}
              />
            </div>
            <div
              className="flex flex-col items-start px-4 leading-5 text-left"
              style={{ width: '90%' }}
            >
              <span className="fw-m text-blue">Convert Partially</span>
              <span className="text-gray">
                {`Create a Partial Sales Order from this ${
                  Utility.isUSorg() ? 'Estimate' : 'Quote'
                }.`}
              </span>
            </div>
            {isApiLoading &&
              type === QuoteConversionAction.PARTIAL_SALES_ORDER && (
                <div>
                  <DKSpinner
                    iconClassName="ic-r"
                    className="column pl-0 pr-s"
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
}
