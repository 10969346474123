import React, {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  DKButton,
  showToast,
  TOAST_TYPE,
  INPUT_VIEW_DIRECTION,
  DKInput,
  DKIcon,
  DKIcons,
  DKSpinner,
  showAlert
} from 'deskera-ui-library';
import {
  fetchAccountCustomNumberFormat,
  fetchBillsCustomNumberFormat,
  fetchBuildAssemblyCustomNumberFormat,
  fetchContactCustomNumberFormat,
  fetchCreditNotesCustomNumberFormat,
  fetchDebitNotesCustomNumberFormat,
  fetchInvoiceCustomNumberFormat,
  fetchJobCardCustomNumberFormat,
  fetchJournalEntryCustomNumberFormat,
  fetchOrdersCustomNumberFormat,
  fetchRequisitionCustomNumberFormat,
  fetchSupplierquoteCustomNumberFormat,
  fetchJobWorkOutCustomNumberFormat,
  fetchProductCustomNumberFormat,
  fetchProductionPlanCustomNumberFormat,
  fetchQuotesCustomNumberFormat,
  fetchSalesOrderCustomNumberFormat,
  fetchWorkOrderCustomNumberFormat,
  selectCustomNumberFormatByModule,
  ICustomNumberFormatStored,
  fetchSellDocumentsCustomNumberFormat,
  fetchBuyDocumentsCustomNumberFormat,
  fetchMachineCustomNumberFormat,
  fetchSeCurityGateEntryNumberFormat,
  fetchPurchaseRequestForQuoteCustomNumberFormat
} from '../../Redux/Slices/CustomNumberFormat';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  CUSTOM_NUMBER_INPUT_MODULES,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import PopupWrapper from '../PopupWrapper';
import CustomNumberFormat from './CustomNumberFormat';
import CustomDropdowList from './CustomDropdowList';
import CustomNumberFormatService from '../../Services/CustomNumberFormat';
import { useTranslation } from 'react-i18next';
import Utility from '../../Utility/Utility';
import {
  defaultCustomNumberFormatPreFixSuffix,
  generateSequenceCodeWithPrefixSuffix
} from './CustomNumber';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import useDebounce from '../../Hooks/useDebounce';
import { shallowEqual } from 'react-redux';
import {
  checkUserPermission,
  showNoPermissionAlert
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

interface ICustomNumberFormatAutoPayload {
  id: string;
  manualMode: false;
  text?: string;
}

interface ICustomNumberFormatManualPayload {
  id: null;
  manualMode: true;
  text: string;
}
type CustomNumberFormatPayload =
  | ICustomNumberFormatManualPayload
  | ICustomNumberFormatAutoPayload;

export interface ICustomNumberFormatInputProps {
  module: CUSTOM_NUMBER_INPUT_MODULES | string;
  autoNumberingFormat?: Partial<CustomNumberFormatPayload>;
  selectedFormat: (data: CustomNumberFormatPayload) => void;
  showCompact?: boolean;
  openList?: boolean;
  buttonStylePadding?: number | string;
  isRow?: boolean;
  hideLabel?: boolean;
  titleStyle?: CSSProperties;
  extraClass?: string;
}

function CustomNumberFormatInput(props: ICustomNumberFormatInputProps) {
  /**
   * Selectors
   */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const compactInputRef = useRef<HTMLInputElement>(null);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const customNumberFormatList: ICustomNumberFormatStored[] | undefined =
    useAppSelector(
      selectCustomNumberFormatByModule(props.module),
      shallowEqual
    );

  const [showCustomNumberDropdownList, setShowCustomNumberDropdownList] =
    useState<boolean>(false);

  const [showCustomNumberPopup, setShowCustomNumberPopup] =
    useState<boolean>(false);

  const [manualModeActive, setManualModeActive] = useState(
    props.autoNumberingFormat?.manualMode || false
  );
  const [manualEntry, setManualEntry] = useState(
    props.autoNumberingFormat?.text || ''
  );
  const [sequenceCode, setSequenceCode] = useState(
    props.autoNumberingFormat?.text || ''
  );

  const [customFormatNumberData, setCustomFormatNumberData] =
    useState<ICustomNumberFormatStored | null>(null);

  /**
   * Refs
   */
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateOrder: 'click' }
  };

  const isTouched = useRef(
    props.autoNumberingFormat &&
      props.autoNumberingFormat.manualMode &&
      Utility.isNotEmpty(props.autoNumberingFormat.text)
  );

  const addOrderRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const onChangeFormatRef = useRef(props.selectedFormat);
  /**
   * Derived States
   */
  const debouncedManualEntry: string = useDebounce(manualEntry, 500);
  const displaySequenceCode = (function getSequenceCodeToDisplay() {
    let displayCode = sequenceCode;

    if (manualModeActive) {
      displayCode = props.autoNumberingFormat?.text || displayCode;
    } else {
      const selectedFormatData = customNumberFormatList?.find(
        (format) => format.id === props.autoNumberingFormat?.id
      );
      displayCode = selectedFormatData?.text || displayCode;
    }

    return displayCode;
  })();
  const verificationInProgress =
    manualModeActive && manualEntry && manualEntry !== displaySequenceCode;
  const needEmptyEntryWarning =
    manualModeActive && Utility.isEmpty(manualEntry) && isTouched.current;

  useEffect(() => {
    if (props.openList) {
      setShowCustomNumberDropdownList(true);
    }
  }, [props.openList]);

  useEffect(() => {
    onChangeFormatRef.current = props.selectedFormat;
  }, [props.selectedFormat]);

  const saveSelectedFormat = useCallback(
    (formatId: string | null, updatedSequenceCode: string) => {
      updatedSequenceCode = `${updatedSequenceCode || ''}`;

      setSequenceCode(updatedSequenceCode);

      let data: CustomNumberFormatPayload = manualModeActive
        ? {
            id: null,
            manualMode: true,
            text: updatedSequenceCode
          }
        : {
            id: formatId as string,
            text: '',
            manualMode: false
          };

      onChangeFormatRef.current?.(data);
    },
    [manualModeActive]
  );

  const resetSequenceCode = useCallback(() => {
    if (manualModeActive) {
      saveSelectedFormat(null, '');
      return;
    }

    const autoNumberingFormatId = props.autoNumberingFormat?.id ?? null
    if (autoNumberingFormatId !== null) {
      return;
    }
    
    const defaultFormat =
      customNumberFormatList?.find((format) => format.isDefault) ||
      customNumberFormatList?.[0];

    if (!defaultFormat) return;

    let sequenceCode = defaultFormat.sequenceCode;
    if (
      defaultCustomNumberFormatPreFixSuffix(defaultFormat.module, sequenceCode)
    ) {
      sequenceCode = generateSequenceCodeWithPrefixSuffix(defaultFormat);
    }

    saveSelectedFormat(defaultFormat.id, sequenceCode);
  }, [manualModeActive, customNumberFormatList, saveSelectedFormat]);

  const checkCodeExists = useCallback(async () => {
    try {
      if (!Utility.isEmpty(debouncedManualEntry)) {
        isTouched.current = true;
        const codeExists =
          await CustomNumberFormatService.checkNumberFormatExistance(
            debouncedManualEntry,
            props.module
          );
        if (codeExists?.status === 200) {
          saveSelectedFormat(null, debouncedManualEntry);
        } else if (codeExists?.status === 409) {
          setManualEntry('');
          saveSelectedFormat(null, '');
          showAlert('Duplicate code exists!', codeExists?.message);
        }
      } else {
        resetSequenceCode();
      }
    } catch (err) {
      console.error('Error while checking number format: ', err);
    }
  }, [
    debouncedManualEntry,
    props.module,
    saveSelectedFormat,
    resetSequenceCode
  ]);

  useEffect(() => {
    if (
      manualModeActive &&
      debouncedManualEntry === manualEntry &&
      debouncedManualEntry !== displaySequenceCode
    ) {
      checkCodeExists();
    }
  }, [
    manualModeActive,
    manualEntry,
    debouncedManualEntry,
    displaySequenceCode,
    checkCodeExists
  ]);

  useEffect(() => {
    if (!props.autoNumberingFormat?.text) {
      resetSequenceCode();
    }
  }, [resetSequenceCode]);

  const customNumberpopupBtnConfig: BtnType[] = [
    {
      title: t(`CUSTOM_NUMBER_FORMAT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`CUSTOM_NUMBER_FORMAT.BUTTON.SAVE`),
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.SAVE_CUSTOM_DOCUMENT_NUMBER
    }
  ];

  const onSequenceCodeClick = useCallback(
    (index: number, value: ICustomNumberFormatStored) => {
      if (
        defaultCustomNumberFormatPreFixSuffix(value.module, value.sequenceCode)
      ) {
        let sequenceCode = generateSequenceCodeWithPrefixSuffix(value);
        saveSelectedFormat(value.id, sequenceCode);
      } else {
        saveSelectedFormat(value.id, value.text);
      }
      setShowCustomNumberDropdownList(false);
    },
    [saveSelectedFormat]
  );

  const onSequenceCodeEdit = (
    index: number,
    value: ICustomNumberFormatStored
  ) => {
    if (!sequenceFormatPermission) {
      showNoPermissionAlert();
      return;
    }

    setShowCustomNumberPopup(true);
    setCustomFormatNumberData(value);
  };

  const onSequenceCodeDelete = (
    index: number,
    value: ICustomNumberFormatStored
  ) => {
    if (value) {
      if (!sequenceFormatPermission) {
        showNoPermissionAlert();
        return;
      }

      let payload = { sequenceId: value.id };
      CustomNumberFormatService.deleteCustomFormatNumber(payload).then(
        (response: any) => {
          if (response && response.status === 409) {
            setManualEntry('');
            showToast(response.message, TOAST_TYPE.WARNING);
          }

          if (response && response.status === 200) {
            showToast('Deleted Successfully', TOAST_TYPE.SUCCESS);
            fetchUpdatedCustomNumberFormat();
          }
        },
        (err) => {
          console.error('Error while custom number format: ', err);
        }
      );
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowCustomNumberPopup(false);
        setCustomFormatNumberData(null);
        break;
      case POPUP_CLICK_TYPE.SAVE_CUSTOM_DOCUMENT_NUMBER:
        addOrderRef.current?.storeCallbacksRef.saveCustomDocumentNumber();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.SAVE_CUSTOM_NUMBER:
        addOrderRef.current.storeCallbacksRef.saveCustomDocumentNumber =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowCustomNumberPopup(false);
        setCustomFormatNumberData(null);
        break;
    }
  };

  const fetchUpdatedCustomNumberFormat = () => {
    switch (props.module) {
      case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
        dispatch(fetchContactCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.INVOICE:
        dispatch(
          fetchInvoiceCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.BILL:
        dispatch(
          fetchBillsCustomNumberFormat(tenantDetails?.useSeparateSequenceFormat)
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST:
        dispatch(
          fetchRequisitionCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.SUPPLIER_QUOTE:
        dispatch(
          fetchSupplierquoteCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER:
        dispatch(
          fetchOrdersCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.QUOTE:
        dispatch(
          fetchQuotesCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER:
        dispatch(
          fetchSalesOrderCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.PRODUCT:
        dispatch(fetchProductCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
        dispatch(fetchAccountCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.DEBIT_NOTE:
        dispatch(fetchDebitNotesCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY:
        dispatch(fetchJournalEntryCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.CREDIT_NOTE:
        dispatch(fetchCreditNotesCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.BUILD_ASSEMBLY:
        dispatch(fetchBuildAssemblyCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.WORK_ORDER:
        dispatch(fetchWorkOrderCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.JOB_CARD:
        dispatch(fetchJobCardCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT:
        dispatch(fetchJobWorkOutCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.SELL:
        tenantDetails?.useSeparateSequenceFormat
          ? dispatch(fetchQuotesCustomNumberFormat(true))
          : dispatch(fetchSellDocumentsCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.BUY:
        tenantDetails?.useSeparateSequenceFormat
          ? dispatch(fetchOrdersCustomNumberFormat(true))
          : dispatch(fetchBuyDocumentsCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.PRODUCTION_PLAN:
        dispatch(fetchProductionPlanCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.MACHINE:
        dispatch(fetchMachineCustomNumberFormat());
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.SECURITY_ENTRY_GATE:
        dispatch(
          fetchSeCurityGateEntryNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
      case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST_FOR_QUOTE:
        dispatch(
          fetchPurchaseRequestForQuoteCustomNumberFormat(
            tenantDetails?.useSeparateSequenceFormat
          )
        );
        break;
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    const target = e.target;
    if (!(target instanceof Element)) return;

    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setShowCustomNumberDropdownList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (compactInputRef) {
      compactInputRef.current?.focus();
    }
  }, [manualModeActive]);

  const sequenceFormatPermission = checkUserPermission(
    PERMISSIONS_BY_MODULE.SETTINGS.SEQ_NUMBER
  );

  const createNewButton = sequenceFormatPermission
    ? {
        title: `+ ${t(`CUSTOM_NUMBER_FORMAT.CUSTOM_NUMBER_FORMAT`)}`,
        className: 'text-blue fw-b ml-xs',
        onClick: () => {
          setShowCustomNumberPopup(true);
        }
      }
    : null;

  /**
   * Custom Number Format
   */

  return (
    <div
      className={
        props.showCompact ? '' : 'position-relative parent-width column'
      }
    >
      {props.showCompact && (
        <div className="position-relative custom-number-compact-input">
          {!manualModeActive && (
            <DKButton
              className={'parent-width compact-custom-no-input '}
              style={{
                borderRadius: 4,
                padding: props.buttonStylePadding ?? 4
              }}
              title={displaySequenceCode ? displaySequenceCode : 'Select'}
              onClick={() => setShowCustomNumberDropdownList((value) => !value)}
            />
          )}
          {manualModeActive && (
            <>
              <input
                name="custom-input-box"
                ref={compactInputRef}
                type="text"
                className={`${
                  needEmptyEntryWarning
                    ? `border-red`
                    : `border-transparent hover:border-gray-300`
                } w-full h-full text-right p-2 listPickerBG rounded border`}
                style={{
                  borderRadius: 4,
                  padding: 4
                }}
                disabled={!manualModeActive}
                value={manualEntry}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setManualEntry(e.target.value)
                }
              />
              <div
                className={`row row-reverse width-auto position-absolute left-1.5 cursor-pointer ${
                  verificationInProgress ? '' : 'opacity-0'
                } disable-input-mode gap-2`}
                style={{ top: 7 }}
              >
                {verificationInProgress ? (
                  <div
                    className="row width-auto"
                    title="Verifying manual input"
                  >
                    <DKSpinner iconClassName="ic-s" />
                  </div>
                ) : (
                  <div
                    className={`row width-auto`}
                    title="Disable manual input"
                  >
                    <DKIcon
                      src={DKIcons.ic_close}
                      className="ic-s"
                      onClick={() => setManualModeActive(false)}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {!props.showCompact && (
        <div
          className={
            'position-relative parent-width justify-content-between ' +
            (props.isRow ? 'row' : 'column')
          }
        >
          <div className={'mt-1 parent-width position-relative'}>
            <DKInput
              title={props.hideLabel ? '' : t(`DOCUMENT.AUTO_NUMBERING_FORMAT`)}
              direction={
                props.isRow
                  ? INPUT_VIEW_DIRECTION.HORIZONTAL
                  : INPUT_VIEW_DIRECTION.VERTICAL
              }
              canValidate={true}
              errorMessage={' '}
              className={
                manualModeActive
                  ? 'auto-numbering-format-input-manual'
                  : 'auto-numbering-format-input'
              }
              validator={() => !needEmptyEntryWarning}
              value={manualModeActive ? manualEntry : ''}
              titleStyle={props?.titleStyle}
              placeholder={
                displaySequenceCode
                  ? displaySequenceCode
                  : t(`DOCUMENT.AUTO_NUMBERING_FORMAT`)
              }
              readOnly={!manualModeActive}
              onClick={() =>
                manualModeActive
                  ? ''
                  : setShowCustomNumberDropdownList((value) => !value)
              }
              onChange={(value: string) => setManualEntry(value || '')}
            />
            {manualModeActive && (
              <div
                className={`row width-auto position-absolute right-2 cursor-pointer gap-2 ${
                  props.isRow ? 'top-2.5' : 'bottom-2.5'
                } opacity-60`}
              >
                {verificationInProgress && (
                  <div
                    className="row width-auto"
                    title="Verifying manual input"
                  >
                    <DKSpinner iconClassName="ic-s" />
                  </div>
                )}
                <div className="row width-auto" title="Disable manual input">
                  <DKIcon
                    src={DKIcons.ic_close}
                    className="ic-s"
                    onClick={() => setManualModeActive(false)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showCustomNumberDropdownList && (
        <div
          id="custom-number-list"
          className={`custom-number-list w-72 position-absolute -my-4 flex-1 z-10 ${props.extraClass}`}
        >
          <CustomDropdowList
            className="shadow-l"
            data={customNumberFormatList || []}
            needIcon={false}
            createNewButton={createNewButton}
            manualInputButton={{
              title: `Manual input`,
              className: 'text-blue fw-b ml-xs',
              onClick: () => {
                setShowCustomNumberPopup(false);
                setShowCustomNumberDropdownList(false);
                setManualModeActive(true);
              }
            }}
            onSelect={(index: number, value: ICustomNumberFormatStored) => {
              onSequenceCodeClick(index, value);
            }}
            onEdit={(index: number, value: ICustomNumberFormatStored) => {
              onSequenceCodeEdit(index, value);
            }}
            onDelete={(index: number, value: ICustomNumberFormatStored) => {
              onSequenceCodeDelete(index, value);
            }}
          />
        </div>
      )}
      {showCustomNumberPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`CUSTOM_NUMBER_FORMAT.CUSTOM_NUMBER_FORMAT`)}
          btnList={customNumberpopupBtnConfig}
          height={'35%'}
          width={'45%'}
        >
          <CustomNumberFormat
            data={customFormatNumberData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={props.module}
            onSaveSuccess={() => fetchUpdatedCustomNumberFormat()}
          />
        </PopupWrapper>
      )}
    </div>
  );
}

export default CustomNumberFormatInput;
